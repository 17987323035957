import {Injectable} from '@angular/core';
import {BaseApiRequestService} from './base-api-request.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ConfigModel} from '../../models/api/config.model';
import {ApiConstants} from '../api.constant';
import {ARApiError, ARApiUrlBuilderService, ARRequestOptions, ARResponseModel} from '@relayter/core';
import {environment} from '../../../environments/environment';
import {Deserialize} from 'cerialize';

@Injectable({providedIn: 'root'})
export class ConfigService extends BaseApiRequestService {
    private configSubject = new BehaviorSubject<ConfigModel>(null);
    public config$ = this.configSubject.asObservable();

    public refreshConfig(): Observable<ConfigModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONFIG,
            ApiConstants.API_METHOD_WEB
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.doRequest(options).subscribe(
                (res: ARResponseModel) => {
                    if (res.data) {
                        const config = Deserialize(res.data, ConfigModel);
                        this.configSubject.next(config);
                        obs.next(config);
                    } else {
                        obs.error(new Error('No config returned'));
                    }
                    obs.complete();
                },
                (err: ARApiError) => {
                    // real app breaking error log to bugsnag?
                    obs.error(err);
                });
        });
    }

    public removeConfig(): void {
        this.configSubject.next(null);
    }

}
