<form class="fragment-form" [formGroup]="formGroup">
    <nuc-form-field label="Type">
        <nuc-dropdown formControlName="type"
                      placeholder="Select a type"
                      [nullOption]="false"
                      [total]="fragmentTypeOptions.length"
                      [items]="fragmentTypeOptions"
                      [required]="true">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.controls.fixedValue" label="Value">
        <nuc-input formControlName="fixedValue" placeholder="Enter the value" required></nuc-input>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.controls.property" label="Property">
        <nuc-dropdown formControlName="property"
                      placeholder="Select a property"
                      [nullOption]="false"
                      [total]="properties.length"
                      [items]="properties"
                      [required]="true">
        </nuc-dropdown>
    </nuc-form-field>
</form>
