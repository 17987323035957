/**
 *  rl-role.model
 *  app-relayter-web
 *  Created by nandamhuis on 23/02/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */
import {autoserialize} from 'cerialize';
import {ITableItem} from '../interfaces/table-item.interface';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class RoleModel implements IDropdownItem, ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public users: number = 0;
    // eslint-disable-next-line id-blacklist
    @autoserialize public number: number;
    @autoserialize public root: boolean;
    @autoserialize public permissions: string[] = [];

    /**
     * IDropdownItem implementation
     * @returns {string}
     */
    public getTitle(): string {
        return this.name;
    }

    /**
     * IDropdownItem implementation
     * @returns {string}
     */
    public getValue(): string {
        return this._id;
    }
}
