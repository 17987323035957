/**
 * task-error.model
 * app-relayter-web
 * Created by borisnelissen on 07/02/2019.
 * Copyright © 2019 Creative Media Network. All rights reserved.
 */
import {autoserialize} from 'cerialize';

export class TaskErrorModel {
    @autoserialize public title: string;
    @autoserialize public message: string;
    @autoserialize public taskName: string;
    @autoserialize public type: string;
}
