import {TemplateAreaModel} from '../../../../../../../../models/api/template-area.model';
import {TemplateAreaItemPixi} from './template-area-item.pixi';
import {Subject} from 'rxjs';
import {IEditorOptions} from '../spread-editor.component';

export class AreaClickEvent {
    constructor(public readonly area: TemplateAreaPixi,
                public readonly row: number,
                public readonly column: number) {}
}

export class TemplateAreaPixi extends PIXI.Container {

    private onAreaClicked = new Subject<AreaClickEvent>();
    public onAreaClicked$ = this.onAreaClicked.asObservable();
    private scaleRatio: number;

    constructor(public templateArea: TemplateAreaModel, private editorOptions: IEditorOptions, scaleRatio: number = 1) {
        super();
        this.scaleRatio = scaleRatio;
        this.update();
    }

    private update(): void {
        this.x = this.templateArea.position.x;
        this.y = this.templateArea.position.y;

        for (let row = 0; row < this.templateArea.rows; row++) {
            for (let column = 0; column < this.templateArea.columns; column++) {
                const area = new TemplateAreaItemPixi(this.templateArea, row, column, this.editorOptions, this.scaleRatio);

                if (this.editorOptions.editEnabled) {
                    area.on('click', () => this.onAreaClicked.next(new AreaClickEvent(this, row, column)));
                }

                this.addChild(area);
            }
        }
    }

    public highlightChildren(row: number, column: number, rowSpan: number, columnSpan: number): void {
        this.children.forEach((child) => {
            if (child instanceof TemplateAreaItemPixi) {
                child.setHighlighted(
                    child.row >= row &&
                    child.row < row + rowSpan &&
                    child.column >= column &&
                    child.column < column + columnSpan
                );
            }
        });
    }

    public resetHighlight(): void {
        this.children.forEach((child) => {
            if (child instanceof TemplateAreaItemPixi) {
                child.setHighlighted(false);
            }
        });
    }

    public getRowAndColumnForGlobalPosition(x: number, y: number): {row: number; column: number} {
        const point = this.toLocal(new PIXI.Point(x, y));
        const width = this.templateArea.getColumnWidth() + this.templateArea.columnGutter;
        const height = this.templateArea.getRowHeight() + this.templateArea.rowGutter;

        return {
            row: Math.floor(point.y / height),
            column: Math.floor(point.x / width)
        };
    }
}
