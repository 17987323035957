<div class="menu-component-container"
     [@containerAnimation]="{value: containerState, params: {speed: containerSpeed, delay: contentDelay}}"
     (@containerAnimation.done)="onAnimationDone($event)"
     (@containerAnimation.start)="animationStateChanged.emit($event)">
    <div class="menu-component-content"
         [@contentAnimation]="{value: containerState, params: {speed: contentSpeed, delay: contentDelay}}"
         (@contentAnimation.done)="onAnimationDone($event)"
         [@changeAnimation]="contentState"
         (@changeAnimation.done)="onAnimationDone($event)">

        <ng-content></ng-content>

    </div>
</div>
