<nuc-button-bar class="generate-button">
    <nuc-button-secondary
        [disabled]="selection.isEmpty()"
        text="Add selected items"
        icon="nucicon_gear"
        iconPosition="end"
        (click)="openTemplateSelectionModal()">
    </nuc-button-secondary>
</nuc-button-bar>

<briefing-table [campaignId]="campaign._id"
                [publicationId]="publication._id"
                [additionalColumns]="additionalColumns"
                [selectionMode]="ESelectionMode.MULTI"
                [tableId]="tableId"
                [selection]="selection">
</briefing-table>
