import {ItemPixi, MoveEvent, ResizeEvent} from '../item/item.pixi';
import {Subject} from 'rxjs';

export class ItemStagePixi extends PIXI.Container {

    private onItemMoving = new Subject<MoveEvent>();
    public onItemMoving$ = this.onItemMoving.asObservable();

    private onItemMoved = new Subject<MoveEvent>();
    public onItemMoved$ = this.onItemMoved.asObservable();

    private onItemMoveCancelled = new Subject<MoveEvent>();
    public onItemMoveCancelled$ = this.onItemMoveCancelled.asObservable();

    private onItemResizing = new Subject<ResizeEvent>();
    public onItemResizing$ = this.onItemResizing.asObservable();

    private onItemResized = new Subject<ResizeEvent>();
    public onItemResized$ = this.onItemResized.asObservable();

    private onItemRemoved = new Subject<ItemPixi>();
    public onItemRemoved$ = this.onItemRemoved.asObservable();

    private onItemEdit = new Subject<ItemPixi>();
    public onItemEdit$ = this.onItemEdit.asObservable();

    private onItemClicked = new Subject<ItemPixi>();
    public onItemClicked$ = this.onItemClicked.asObservable();

    constructor() {
        super();
    }

    public addItem(item: ItemPixi): void {

        item.onItemMoving$.subscribe((event) => {
            this.resetBlocking();
            this.setChildIndex(event.item, this.children.length - 1);

            this.getItems().forEach((child) => child.setBlocking(child.blocks(event)));

            this.onItemMoving.next(event);
        });

        item.onItemMoved$.subscribe((event) => {
            this.resetBlocking();
            if (event.templateArea && !this.getItems().find((child) => child.blocks(event))) {
                (event.item as ItemPixi).commit(event);
                this.onItemMoved.next(event);
            } else {
                event.item.reset();
                this.onItemMoveCancelled.next(event);
            }
        });

        item.onItemResizing$.subscribe((resizeEvent) => {
            this.resetBlocking();
            this.setChildIndex(resizeEvent.item, this.children.length - 1);

            this.getItems().forEach((child) => child.setBlocking(child.blocks(resizeEvent)));

            this.onItemResizing.next(resizeEvent);
        });

        item.onItemResized$.subscribe((resizeEvent) => {
            this.resetBlocking();

            if (!this.getItems().find((child) => child.blocks(resizeEvent))) {
                (resizeEvent.item as ItemPixi).commit(resizeEvent);
                this.onItemResized.next(resizeEvent);
            } else {
                resizeEvent.item.reset();
                this.onItemMoveCancelled.next(resizeEvent);
            }
        });

        item.onItemRemoved$.subscribe((removedItem) => this.onItemRemoved.next(removedItem));

        item.onItemEdit$.subscribe((itemToEdit) => this.onItemEdit.next(itemToEdit));

        item.onItemClicked$.subscribe((clickedItem) => this.onItemClicked.next(clickedItem));

        this.addChild(item);
    }

    public getItems(): ItemPixi[] {
        return this.children.filter((child) => child instanceof ItemPixi) as ItemPixi[];
    }

    public resetBlocking(): void {
        this.getItems().forEach((child) => child.setBlocking(false));
    }

}
