import {Pipe, PipeTransform} from '@angular/core';
import {EDataFieldTypes} from '../../../../app.enums';

@Pipe({name: 'dataFieldFormIcon'})
export class DataFieldFormIconPipe implements PipeTransform {

    public transform(datFieldType): string {
        let icon;
        switch(datFieldType) {
            case EDataFieldTypes.STRING:
                icon = 'string';
                break;
            case EDataFieldTypes.NUMBER:
                icon = 'number';
                break;
            case EDataFieldTypes.DATE:
                icon = 'date';
                break;
            case EDataFieldTypes.BOOLEAN:
                icon = 'boolean';
                break;
            case EDataFieldTypes.LIST:
                icon = 'list';
                break;
            case EDataFieldTypes.ENUM:
                icon = 'enum';
                break;
        }

        return `/assets/images/data-management/${icon}.svg`;
    }

}
