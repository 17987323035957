import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PackageSetupDataFieldModel} from '../../../models/api/package-setup.model';
import {ETabFormGroup} from '../package-rule-form.component';

@Component({
    selector: 'package-rule-material-data-form-component',
    templateUrl: 'package-rule-material-data-form.component.html',
    styleUrls: ['package-rule-material-data-form.component.scss']
})

export class PackageRuleMaterialDataFormComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Input() public dataFields: PackageSetupDataFieldModel[];
    @Input() public dataFieldValues: Record<string, any>;

    public formGroup: UntypedFormGroup;
    public readonly FORM_GROUP_NAME = ETabFormGroup.PACKAGE_RULE_MATERIAL_DATA;

    public ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        if (!this.form.contains(this.FORM_GROUP_NAME)) {
            this.formGroup = new UntypedFormGroup({});
            this.form.addControl(this.FORM_GROUP_NAME, this.formGroup);

            if (this.dataFields?.length) {
                const data = {};
                for (const dataField of this.dataFields) {
                    this.formGroup.addControl(dataField.fieldName, new UntypedFormControl());
                    if (this.dataFieldValues) {
                        data[dataField.fieldName] = this.dataFieldValues[dataField.fieldName];
                    }
                }
                this.formGroup.patchValue(data);
            }
        } else {
            this.formGroup = this.form.get(this.FORM_GROUP_NAME) as UntypedFormGroup;
        }
    }
}
