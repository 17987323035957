import {autoserialize, autoserializeAs} from 'cerialize';
import {UntypedFormGroup} from '@angular/forms';
import {DataFieldModel} from './data-field.model';

export enum EFormContext {
    BRIEFING = 'briefing',
    PRODUCT = 'product',
    ASSET = 'asset'
}

export class FormFieldOptionsModel {
    @autoserialize public editable: boolean;
    @autoserialize public label: string;
    @autoserialize public placeholder: string;
    @autoserialize public multiline?: boolean;

    public static fromFormGroup(formGroup: UntypedFormGroup): FormFieldOptionsModel {
        const options = new FormFieldOptionsModel();

        options.editable = formGroup.get('editable')?.value;
        options.placeholder = formGroup.get('placeholder')?.value;
        options.multiline = formGroup.get('multiline')?.value;

        return options;
    }
}

export class FormFieldModel {
    @autoserializeAs(DataFieldModel) public dataField: DataFieldModel;
    @autoserializeAs(FormFieldOptionsModel) public options: FormFieldOptionsModel;
}

export class FormRowModel {
    @autoserializeAs(FormFieldModel) public fields: FormFieldModel[];
}

export class FormModel {
    @autoserialize public _id: string;
    @autoserialize public context: EFormContext;
}

export class FormDetailModel {
    @autoserialize public _id: string;
    @autoserialize public context: EFormContext;
    @autoserializeAs(FormRowModel) public rows: FormRowModel[];
}

// POST / PUT

export class FormFieldPostModel {
    @autoserialize public dataField: string;
    @autoserializeAs(FormFieldOptionsModel) public options: FormFieldOptionsModel;

    constructor(dataField: string, options: FormFieldOptionsModel) {
        this.dataField = dataField;
        this.options = options;
    }
}

export class FormRowPostModel {
    @autoserializeAs(FormFieldPostModel) public fields: FormFieldPostModel[];

    constructor(fields: FormFieldPostModel[]) {
        this.fields = fields;
    }
}

export class FormPostModel {

    @autoserializeAs(FormRowPostModel) public rows: FormRowPostModel[];
    @autoserialize public context: EFormContext;

    constructor(rows: FormRowPostModel[], context: EFormContext) {
        this.rows = rows;
        this.context = context;
    }
}
