<nuc-button-bar>
    <h1>User management</h1>
    <nuc-button-primary text="Invite user"
                        *ngIf="permissions.POST_INVITE | userIsAllowedTo"
                        (click)="openInviteUserModal()" i18n>
    </nuc-button-primary>
</nuc-button-bar>

<ng-container *ngIf="tabs.length > 0; else noPermissionEmptyState">
    <div class="bottom-line">
        <nuc-tab-bar [tabs]="tabs" [(activeTab)]="activeTab" (activeTabChange)="onActiveTabChanged($event)"></nuc-tab-bar>
    </div>

    <router-outlet></router-outlet>
</ng-container>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
