import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {QueryParams} from '../../classes/query-params';
import {CustomWorkflowFilterOptionModel} from '../../models/api/custom-workflow-filter-option.model';
import {EDataCollectionName} from '../../app.enums';

@Injectable({
    providedIn: 'root'
})
export class DataCollectionService extends BaseApiRequestService {

    public getDataCollectionValues(collectionName: EDataCollectionName,
                                   property: string,
                                   filterOptions: Record<string, any>,
                                   limit?: number,
                                   offset?: number,
                                   search?: string,
                                   idProperty?: string,
                                   workflowFilterId?: string): Observable<ARPagedResponseDataModel<CustomWorkflowFilterOptionModel>> {

        const queryParams = new QueryParams();
        queryParams.setLimitAndOffsetParams(limit, offset);
        queryParams.setSearchParams(search);
        queryParams.addParam('collectionName', collectionName);
        queryParams.addParam('property', property);
        queryParams.addParam('idProperty', idProperty);
        queryParams.addParam('workflowFilterId', workflowFilterId);

        if (filterOptions) {
            const filterParts = [];
            Object.keys(filterOptions).forEach(key => {
                if (filterOptions[key]) filterParts.push(`${key}=${filterOptions[key]}`);
            });
            queryParams.addParam('filters', filterParts.join('|'));
        }

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
                ApiConstants.API_BASE_PATH,
                ApiConstants.API_GROUP_DATA_COLLECTION_VALUES],
            queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, CustomWorkflowFilterOptionModel);
        });
    }
}
