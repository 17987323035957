import {CampaignItemModel} from '../api/campaign-item.model';

export class CampaignItemResultModel {
    public campaignItems: CampaignItemModel[];
    public hasNext: boolean;

    constructor(campaignItems: CampaignItemModel[], hasNext: boolean) {
        this.campaignItems = campaignItems;
        this.hasNext = hasNext;
    }
}
