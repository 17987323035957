import {Component, AfterViewInit, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';
import {ARBaseComponent} from '@relayter/core';
import {Router} from '@angular/router';
import {AppConstants} from '../../app.constants';

@Component({
    selector: 'rl-onboarding-container-component',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'onboarding-container.component.html',
    styleUrls: ['onboarding-container.component.scss'],
})

export class OnboardingContainerComponent extends ARBaseComponent implements OnInit, AfterViewInit {
    @HostBinding('class.loaded') public loaded = false;

    constructor(private router: Router) {
        super();
    }

    public ngAfterViewInit(): void {
        // TODO: see this https://github.com/angular/angular/issues/6005 | Nan
        setTimeout(() => this._setLoaded());
    }

    public ngOnInit(): void {
        if (window.location.pathname === AppConstants.AUTH_REDIRECT) {
            this.router.navigate([AppConstants.LOGIN_REDIRECT]);
        }
    }

    private _setLoaded(): void {
        this.loaded = true;
    }
}
