import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {CampaignItemBodyModel, CampaignItemModel} from '../../models/api/campaign-item.model';

@Injectable({
    providedIn: 'root'
})
export class CampaignItemsService extends BaseApiRequestService {
    /**
     * Get the details of a campaign item
     */
    public getDetails(campaignId: string, itemId: string): Observable<CampaignItemModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_ITEMS,
            itemId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, CampaignItemModel);
        });
    }

    /**
     * Update a campaign item
     */
    public updateCampaignItem(campaignId: string, campaignItemId: string, campaignItem: CampaignItemBodyModel): Observable<CampaignItemModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_ITEMS,
            campaignItemId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = campaignItem;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, CampaignItemModel);
        });
    }

    /**
     * Post a new campaign item
     * @param {string} campaignId
     * @param {CampaignItemModel} campaignItem
     * @returns {Observable<CampaignItemModel>}
     */
    public postCampaignItem(campaignId: string, campaignItem: CampaignItemBodyModel): Observable<CampaignItemModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_ITEMS,
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = campaignItem;

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, CampaignItemModel);
        });
    }

    /**
     * Delete a campaign item
     * @param {string} id - campaign id
     * @param {string} itemId - campaign item id
     * @returns {Observable<boolean>}
     */
    public deleteCampaignItem(id: string, itemId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_CAMPAIGNS, id, ApiConstants.API_METHOD_ITEMS, itemId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }
}
