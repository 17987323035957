import {autoserialize, autoserializeAs} from 'cerialize';
import {SizeModel} from './size.model';
import {PointModel} from './point.model';

export class TemplateAreaModel {
    @autoserialize public _id: string;
    @autoserializeAs(SizeModel) public size: SizeModel;
    @autoserializeAs(PointModel) public position: PointModel;
    @autoserialize public columns: number;
    @autoserialize public rows: number;
    @autoserialize public columnGutter: number;
    @autoserialize public rowGutter: number;

    constructor(position: PointModel, size: SizeModel, columns: number, rows: number, columnGutter: number, rowGutter: number, _id?: string) {
        this.position = position;
        this.size = size;
        this.columns = columns;
        this.rows = rows;
        this.columnGutter = columnGutter;
        this.rowGutter = rowGutter;
        this._id = _id;
    }

    public getRowHeight(): number {
        return (this.size.height - (this.rows - 1) * this.rowGutter) / this.rows;
    }

    public getColumnWidth(): number {
        return (this.size.width - (this.columns - 1) * this.columnGutter) / this.columns;
    }
}
