<form [formGroup]="formGroup">

    <nuc-form-field label="Color">
        <nuc-dropdown formControlName="color" [items]="colorOptions"></nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Message">
        <nuc-textarea formControlName="message" [noHorizontalResize]="true"></nuc-textarea>
    </nuc-form-field>
</form>
