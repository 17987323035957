import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CustomWorkflowActionModel} from '../../models/api/custom-workflow-action.model';
import {Subject} from 'rxjs';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {EPackageType, IPackageType
// eslint-disable-next-line max-len
} from '../../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-files/custom-workflow-files-download/package-type/files-download-package-type.component';
import {ARLogger} from '@relayter/core';
import {takeUntil} from 'rxjs/operators';
import {Toaster} from '../../classes/toaster.class';
import {EPublicationJobType, IDownloadPackageJobData, PublicationsService} from '../../api/services/publications.service';

export interface IDownloadPackageTypeModalData {
    actions: CustomWorkflowActionModel[];
    publicationId: string;
    publicationItemIds: string[];
    stepId: string;
    variantId: string;
    workflowLayoutId: string;
    allowedPackageTypes?: EPackageType[];
    downloadAction?: CustomWorkflowActionModel; // if this exists, it's the new structure: download action with package types in the options
}

@Component({
    selector: 'download-package',
    templateUrl: './download-package.component.html',
    styleUrls: ['./download-package.component.scss']
})
export class DownloadPackageComponent implements OnInit, OnDestroy {
    private _selectedPackageType: IPackageType;

    public publicationId: string;
    public publicationItemId: string;

    public actions: CustomWorkflowActionModel[];
    public get selectedPackageType(): IPackageType {
        return this._selectedPackageType;
    }
    public set selectedPackageType(packageType: IPackageType) {
        this._selectedPackageType = packageType;
        this.updateDownloadButton(packageType);
    }

    private downloadButton: ButtonConfig;

    private onDestroySubject = new Subject<void>();

    constructor(private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private publicationsService: PublicationsService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IDownloadPackageTypeModalData) {
    }

    public ngOnInit(): void {
        this.initializeModalButtons();

        this.actions = this.modalData.actions.filter((action) => this.userIsAllowedToPipe.transform(action.permissions));
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initializeModalButtons(): void {
        this.downloadButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Download', null, null, false);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const confirm = new FullModalActionModel(this.downloadButton);
        const cancel = new FullModalActionModel(cancelButton);

        cancel.observable.subscribe(() => this.fullModalService.close());
        confirm.observable.subscribe(() => this.postPackageDownloadJob());
        this.fullModalService.setModalActions([cancel, confirm]);
    }

    public postPackageDownloadJob(): void {
        const jobData: any = {
            publicationItems: this.modalData.publicationItemIds,
            packageType: this.selectedPackageType.packageType,
            step: this.modalData.stepId,
            publicationId: this.modalData.publicationId,
            variantId: this.modalData.variantId,
            format: this.selectedPackageType.format,
            size: this.selectedPackageType.size
        } as IDownloadPackageJobData;

        if (jobData.packageType === EPackageType.MERGED_PDF_IN_LAYOUT) {
            if (!this.modalData.workflowLayoutId) {
                Toaster.error(`No layout configured in the workflow. Cannot download: ${EPackageType.MERGED_PDF_IN_LAYOUT}`);
                return;
            }
            jobData['layoutId'] = this.modalData.workflowLayoutId;
        }

        this.publicationsService.postJob(EPublicationJobType.PACKAGE_GENERATION, jobData)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => {
                    ARLogger.debug('Job scheduled: ' + result._id);
                    this.fullModalService.close();
                },
                error: Toaster.handleApiError
            });
    }

    private updateDownloadButton(packageType: IPackageType): void {
        this.downloadButton.disabled = !packageType;
    }
}
