import {autoserialize} from 'cerialize';

export class PositionModel {
    @autoserialize public x: number = 0;
    @autoserialize public y: number = 0;

    constructor(percentageX?: number, percentageY?) {
        this.x = percentageX;
        this.y = percentageY;
    }
}
