<form class="mainForm" [formGroup]="formGroup">
    <nuc-form-field label="Item group name">
        <nuc-input formControlName="name" placeholder="Item group name"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Limit">
        <nuc-input formControlName="limit"
                   placeholder="Group limit"
                   type="number"
                   nucTooltip="Limit the number of items to generate within this group. 0 means all items will be generated."></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Stacking option">
        <nuc-dropdown
            [items]="stackOptions"
            [total]="stackOptions.length"
            placeholder="Choose a stacking option"
            formControlName="stack"
            nucTooltip="Control how the items should stack within this group."></nuc-dropdown>
    </nuc-form-field>


</form>
