import {EColumnType, ITableColumn} from '@relayter/rubber-duck';
import {AppConstants} from '../../app.constants';
import {RLDatePipe} from '../../pipes/rl-date.pipe';


export class BriefingTableData {
    public static readonly PermanentColumns: ITableColumn[] = [
        {
            title: '',
            selectionColumnTitle: 'Thumbnail',
            key: 'thumb',
            type: EColumnType.THUMBNAIL,
            format: (value) => value ? value : AppConstants.ICONS.IMAGE_MAIN,
            clickable: true,
            sticky: true
        }
    ];

    public static readonly DefaultColumns: ITableColumn[] = [{
        title: 'Briefing Item ID',
        key: 'briefingItemId',
        sortProperty: 'briefingItemId'
    }, {
        title: 'Date created',
        key: 'createdAt',
        sortProperty: 'createdAt',
        format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
    }, {
        title: 'Date modified',
        key: 'updatedAt',
        sortProperty: 'updatedAt',
        format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
    }];
}
