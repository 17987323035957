/**
 *  breadcrumb-collection.model
 *  app-relayter-web
 *  Created by nandamhuis on 15/05/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */

export class BreadcrumbModel {
    public title: string;
    public route: string;

    constructor(title?: string, route?: string) {
        this.title = title;
        this.route = route;
    }
}
