import {Injectable} from '@angular/core';
import {BaseApiRequestService} from './base-api-request.service';
import {Observable} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {ApiConstants} from '../api.constant';
import {environment} from '../../../environments/environment';
import {QueryParams} from '../../classes/query-params';
import {SortDirection} from '@angular/material/sort';
import {VariantModel, VariantPatchModel, VariantPostModel} from '../../models/api/variant.model';
import {Cursor} from '../api-cursor';
import {AppConstants} from '../../app.constants';

@Injectable({providedIn: 'root'})
export class VariantService extends BaseApiRequestService {
    private static API_GROUP_VARIANTS = 'variants';

    public getVariants(campaignId?: string,
                       limit = AppConstants.PAGE_SIZE_MAX,
                       offset = 0,
                       cursor?: Cursor,
                       sortProperty = 'name',
                       sortOrder: SortDirection = 'asc'): Observable<ARPagedResponseDataModel<VariantModel>> {

        const queryParams = new QueryParams()
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setLimitAndOffsetParams(limit, offset)
            .setCursor(cursor)
            .addParam('campaignId', campaignId);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            VariantService.API_GROUP_VARIANTS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => this.handlePagedResponse(options, obs, VariantModel));
    }

    public postVariant(variant: VariantPostModel): Observable<VariantModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            VariantService.API_GROUP_VARIANTS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = variant;
        return new Observable((obs) => this.handleDetailResponse(options, obs, VariantModel));
    }

    public patchVariant(id: string, variant: VariantPatchModel): Observable<VariantModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            VariantService.API_GROUP_VARIANTS, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = variant;
        return new Observable((obs) => this.handleDetailResponse(options, obs, VariantModel));
    }

    public getVariant(id: string): Observable<VariantModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            VariantService.API_GROUP_VARIANTS,
            id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => this.handleDetailResponse(options, obs, VariantModel));
    }
}
