<div class="file-container"
     [nucTooltip]="text">
    <div class="content dark">
        <i class="{{icon}}"></i>
        <div class="text"><span class="file-name">{{text}}</span></div>
        <nuc-icon-button icon="nucicon_trash_fill"
                         (click)="onDeleteClicked()"></nuc-icon-button>
    </div>
    <div class="progress-wrapper" [class.done]="progress === 100"
         [style.-webkit-mask-size.%]="progress">
        <div class="progress-mask">
        </div>
        <div class="content green">
            <i class="{{icon}}"></i>
            <div class="text"><span class="file-name">{{text}}</span></div>
            <nuc-icon-button icon="nucicon_trash_fill"
                             (click)="onDeleteClicked()"></nuc-icon-button>
        </div>
    </div>
</div>
