<div *ngIf="!!formGroup" [formGroup]="formGroup">
    <div class="container" *ngIf="variantEnabled && variants.length">
        <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">Variant</nuc-label>
        <div class="variant-selection">
            <p>Export variants data fields in: </p>
            <nuc-dropdown class="variant"
                          placeholder="Select variant"
                          formControlName="variant"
                          [nullOption]="false"
                          [data]="selectedVariant"
                          (dataChange)="onVariantChanged($event)"
                          [items]="variants">
            </nuc-dropdown>
        </div>
    </div>
    <div class="container">
        <nuc-list-box title="Export type"
                      subtitle="Select a export type you wish to download."
                      [items]="exportFileTypes"
                      formControlName="exportType">
        </nuc-list-box>
    </div>

</div>
