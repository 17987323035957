/**
 *  campaign.model
 *  app-relayter-web
 *  Created by nandamhuis on 15/05/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */
import {autoserialize, autoserializeAs, inheritSerialization} from 'cerialize';
import {VariantModel} from './variant.model';

export class CampaignModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserializeAs(Date) public startDate: Date;
    @autoserializeAs(Date) public endDate: Date;
    @autoserialize public status: string;
    @autoserializeAs(VariantModel) public variants: VariantModel[];
    public startEndDate: string;
}

export class CampaignPutModel {
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserializeAs(Date) public startDate: Date;
    @autoserializeAs(Date) public endDate: Date;
    @autoserialize public status: string;
    constructor(name: string, tags: string[], startDate: Date, endDate: Date, status: string) {
        this.name = name;
        this.tags = tags;
        this.startDate = startDate;
        this.endDate = endDate;
        this.status = status;
    }
}
@inheritSerialization(CampaignPutModel)
export class CampaignPostModel extends CampaignPutModel {
    @autoserialize public variants: string[];

    constructor(name: string, tags: string[], startDate: Date, endDate: Date, status: string, variants: string[]) {
        super(name, tags, startDate, endDate, status);
        this.variants = variants;
    }
}
