import {inject, Injectable} from '@angular/core';
import {BaseApiRequestService} from './base-api-request.service';
import {concat, Observable, of} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {ApiConstants} from '../api.constant';
import {JobModel} from '../../models/api/job.model';
import {environment} from '../../../environments/environment';
import {GroupModel, GroupPatchModel, GroupPostModel} from '../../models/api/group.model';
import {QueryParams} from '../../classes/query-params';
import {SortDirection} from '@angular/material/sort';
import {reduce, switchMap, tap} from 'rxjs/operators';
import {AppConstants} from '../../app.constants';
import {MonitoredJobsService} from './monitored-jobs.service';

export enum EGroupJobTypes {
    DELETE_GROUPS = 'DELETE_GROUPS'
}

export interface IDeleteGroupJobData {
    groupIds: string[];
}

export type IGroupJobData = IDeleteGroupJobData;

@Injectable({
    providedIn: 'root'
})
export class GroupService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    private static API_GROUP_GROUPS = 'groups';

    public getGroup(groupId: string): Observable<GroupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            GroupService.API_GROUP_GROUPS, groupId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => this.handleDetailResponse(options, obs, GroupModel));
    }

    public getGroups(limit?: number, offset?: number,
                     sortProperty?: string, sortOrder?: SortDirection, search?: string): Observable<ARPagedResponseDataModel<GroupModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setSearchParams(search);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            GroupService.API_GROUP_GROUPS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => this.handlePagedResponse(options, obs, GroupModel));
    }

    public getAllGroups(sortProperty?: string, sortOrder?: SortDirection): Observable<GroupModel[]> {
        return this.getGroups(AppConstants.PAGE_SIZE_MAX, 0, sortProperty, sortOrder).pipe(
            switchMap((result) => {
                const observables = [of(result)];
                const totalPages = Math.ceil(result.total / AppConstants.PAGE_SIZE_MAX);
                for (let page = 0; page < totalPages - 1; page++) {
                    observables.push(this.getGroups(AppConstants.PAGE_SIZE_MAX, (page + 1) * AppConstants.PAGE_SIZE_MAX));
                }
                return concat(...observables);
            }),
            reduce((acc, val) => acc.concat(val.items), [])
        );
    }

    public postGroup(group: GroupPostModel): Observable<GroupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            GroupService.API_GROUP_GROUPS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = group;
        return new Observable((obs) => this.handleDetailResponse(options, obs, GroupModel));
    }

    public patchGroup(groupId: string, group: GroupPatchModel): Observable<GroupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            GroupService.API_GROUP_GROUPS,
            groupId
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = group;
        return new Observable((obs) => this.handleDetailResponse(options, obs, GroupModel));
    }

    public postJob(jobType: EGroupJobTypes, jobData: IGroupJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            GroupService.API_GROUP_GROUPS,
            ApiConstants.API_METHOD_JOBS
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};
        return new Observable((obs) => this.handleDetailResponse(options, obs, JobModel)).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
        );
    }
}
