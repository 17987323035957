<form [formGroup]="formGroup" *ngIf="formGroup">
    <nuc-label *ngIf="selectedVariant" icon="nucicon_variant" iconColorClass="variant-highlight">{{selectedVariant.getTitle()}}</nuc-label>
    <nuc-form-field>
        <nuc-label>Master page<rl-loading-indicator size="tiny" *ngIf="masterPageSubscription && !masterPageSubscription.closed"></rl-loading-indicator></nuc-label>
        <nuc-dropdown
            [searchable]="true"
            placeholder="Select a master page"
            formControlName="masterPage"
            (requestData)="getMasterPages($event)"
            [items]="masterPages"
            [total]="totalMasterPages">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.get('showMasterPage') && formGroup.value.masterPage">
        <nuc-checkbox formControlName="showMasterPage" text="Show Master page on background"></nuc-checkbox>
    </nuc-form-field>

    <nuc-form-field>
        <nuc-label>InDesign Library<rl-loading-indicator size="tiny" *ngIf="librarySubscription && !librarySubscription.closed"></rl-loading-indicator></nuc-label>
        <nuc-dropdown
            [searchable]="true"
            placeholder="Choose a library"
            formControlName="library"
            (requestData)="getIndesignLibraries($event)"
            [items]="libraries"
            [total]="totalLibraries">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field>
        <nuc-label>Format ruleset<rl-loading-indicator size="tiny" *ngIf="ruleSetSubscription && !ruleSetSubscription.closed"></rl-loading-indicator></nuc-label>
        <nuc-dropdown placeholder="Choose a format"
                      [searchable]="true"
                      formControlName="ruleSet"
                      [items]="ruleSets"
                      (requestData)="getFormatRulesets($event)"
                      [total]="totalFormatRulesets">
        </nuc-dropdown>
    </nuc-form-field>
</form>
