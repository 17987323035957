import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {LayoutModel} from '../../models/api/layout.model';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class LayoutsService extends ApiService<LayoutModel>{
    constructor() {
        super([ApiConstants.API_GROUP_LAYOUTS], LayoutModel);
    }
}
