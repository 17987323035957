import {autoserialize} from 'cerialize';

export class ChangePassword {
    @autoserialize public newPassword: string;
    @autoserialize public oldPassword: string;

    constructor(newPassword: string, oldPassword: string) {
        this.newPassword = newPassword;
        this.oldPassword = oldPassword;
    }
}
