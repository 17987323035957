import {ApiRequest} from './api.request';
import {IResponseModel} from '../../models/interfaces/response-model.interface';

export class ApiDetailsRequest extends ApiRequest {
    protected get urlComponents() {
        return [
            ...super.urlComponents,
            this.itemId
        ];
    };

    constructor(public pathComponents: string[] = [],
                public model: IResponseModel,
                public itemId: string) {
        super(pathComponents, model);
    }
}
