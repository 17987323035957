import {Component, Input} from '@angular/core';
import {CustomWorkflowComponentModel} from '../../../../../../models/api/custom-workflow-component.model';

@Component({
    selector: 'rl-custom-workflow-placeholder',
    templateUrl: './custom-workflow-placeholder.component.html',
    styleUrls: ['./custom-workflow-placeholder.component.scss']
})
export class CustomWorkflowPlaceholderComponent {
    @Input() public component: CustomWorkflowComponentModel;
}
