export class MatrixUrlWorkflowParams {
    public step: string;
    public component: string;

    constructor(step?: string, component?: string) {
        if (step) {
            this.step = step;
        }
        if (component) {
            this.component = component;
        }
    }
}
