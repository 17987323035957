<div class="rl-collection-view">
    <rl-collection-options-view
        [(sort)]="sort"
        [(sortDescending)]="sortDescending"
        [disableSort]="disableSort"
        [isLoading]="isLoading"
        [title]="'Showing ' + items.length + ' results'"
        [viewId]="viewId"
        (optionsChanged)="onCollectionOptionsChanged($event)"
        [searchPlaceholder]="searchPlaceholder"
        [sortingOptions]="sortingOptions"
        (onSearch)="onSearchValueUpdated.emit($event)"
        [searchValue]="searchValue"
        [advancedFilter]="advancedFilter"
        [propertySettingsContext]="propertySettingsContext"
        [disableNextPage]="disableNextPage"
        [disablePageOptions]="disablePageOptions">
    </rl-collection-options-view>
    <rl-grid-view
        [items]="items"
        [propertySettingsContext]="propertySettingsContext"
        [isLoading]="isLoading"
        [isSearching]="isSearching"
        [activeItem]="selectedItem"
        [selectedItems]="selectedItems"
        (gridViewItemClicked)="onGridViewItemClicked($event)"
        (gridViewItemDoubleClicked)="onGridViewItemDoubleClicked($event)"
        [selectMultipleItems]="selectMultipleItems">
    </rl-grid-view>
</div>
