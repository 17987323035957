<ng-container>
    <div class="list-view">
        <!--needed to get the elevation hover effect for bogdan-->
        <div class="content-wrapper">
            <div class="header">
                <div class="sort-container" *ngIf="sortOption">
                    <div class="popout-button">
                        <nuc-button-secondary text="Sort"
                                              [disabled]="loading"
                                              iconPosition="end"
                                              [nucTooltip]="sortValue.getTitle()"
                                              [matMenuTriggerFor]="menu">
                        </nuc-button-secondary>
                        <mat-menu #menu="matMenu" class="menu-items-container">
                            <button mat-menu-item
                                    *ngFor="let sortOption of sortOptions"
                                    (click)="setSortValue(sortOption)"
                                    [ngClass]="sortValue === sortOption && 'selected-option'"
                                    [disableRipple]="true">
                                {{sortOption.getTitle()}}
                            </button>
                        </mat-menu>
                    </div>

                    <nuc-button-secondary [icon]="sortAsc ? 'nucicon_sort_asc' : 'nucicon_sort_dsc'"
                                          [nucTooltip]="sortAsc ? 'Sort ascending' : 'Sort descending'"
                                          [disabled]="loading"
                                          (click)="sortAsc = !sortAsc; onSortValueChanged()"></nuc-button-secondary>
                </div>
                <nuc-input class="search"
                           [(data)]="searchValue"
                           prefix="nucicon_search"
                           (debounceValueChanged)="refreshData()"
                           placeholder="Search notes">
                </nuc-input>
                <sticky-list-data-filter [filters]="dataFilters"></sticky-list-data-filter>
            </div>
            <ng-container *ngIf="stickies.length > 0 else emptyState">
                <div *ngFor="let note of stickies; first as first" class="note-item"
                     (click)="selectStickyNote(note)"
                     [class.border-top]="first">
                    <div class="note-header">
                        <div class="user-info-date">
                            <img src="/assets/images/icon_profile.svg"/>
                            <div class="info-container">
                                <p class="body-strong black text-ellipsis"
                                    [nucTooltip]="note.createdBy?.fullName">{{note.createdBy?.fullName | nullUndefinedFormatter}}</p>
                                <span class="body-strong">{{note.createdAt | RLDatePipe:dateFormats.STICKY_DATE}}</span>
                            </div>
                        </div>
                        <img class="pin"
                             [src]="note.status | getNotePinImagePipe"
                             nucTooltipPosition="left"
                             [nucTooltip]="note.status"
                             (click)="selectPubItem(note, $event)"/>
                    </div>
                    <p class="message text-ellipsis">{{note.message}}</p>
                    <div class="briefing-info" *ngIf="note.campaignItem; else noLinkedItem">
                        <div class="briefing-property" *ngFor="let prop of briefingItemFields">
                            <p class="body-strong text-ellipsis">{{prop.label}}</p>
                            <p class="text-ellipsis">{{note.campaignItem.dataFields | getProperty:prop.property:dataFieldFormatter | nullUndefinedFormatter}}</p>
                        </div>
                    </div>
                    <ng-template #noLinkedItem>
                        <p class="no-linked-item">No linked briefing item.</p>
                    </ng-template>
                    <hr/>
                </div>
            </ng-container>
            <div class="footer" *ngIf="hasNext">
                <nuc-button-secondary (click)="loadMore()" text="Show more"></nuc-button-secondary>
            </div>
        </div>

    </div>
</ng-container>
<ng-template #emptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/sticky_list_small.svg"
                               title="There are no notes for this item."
                               [small]="true">
    </nuc-empty-state-component>
</ng-template>
