import {ARApiError, ARLogger} from '@relayter/core';
import {ErrorFormatterUtil} from './error-formatter.util';
import {EToastType, ToastDataModel, ToastService} from '@relayter/rubber-duck';
import {ErrorConstants} from '../api/error.constants';

export enum TIME_OUT_TYPES {
    LONG = 10000
}

export class ToastOptionModel {
    public loading?: boolean = false;
    public timeout?: TIME_OUT_TYPES;
}

export class Toaster {

    private static toastService: ToastService;
    private static readonly TOASTER_NOT_INITIALIZED_MSG = 'Toaster: Could not show toast: Toaster not initialized.';

    /**
     * Send out an error toast with an optional title
     */
    public static error(message: string, title?: string, options?: ToastOptionModel): void {
        if (Toaster.toastService) {
            title = title || 'Error';
            const toastData = new ToastDataModel(EToastType.ERROR, title, message, options?.loading, options?.timeout);
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error(Toaster.TOASTER_NOT_INITIALIZED_MSG);
        }
    }

    /**
     * Handle an incoming ARApiError by mapping its error code to
     * the corresponding localized message and send out a warning toast
     * @param {ARApiError} error
     * @param {ToastOptionModel} [options]
     */
    public static handleApiWarning(error: ARApiError, options?: ToastOptionModel): void {
        if (Toaster.toastService) {
            const toastData = new ToastDataModel(
                EToastType.WARNING,
                'Warning',
                ErrorFormatterUtil.formatError(error),
                options?.loading,
                options?.timeout
            );
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error(Toaster.TOASTER_NOT_INITIALIZED_MSG);
        }
    }

    /**
     * Handle an incoming ARApiError by showing the error details or mapping its error code to
     * the corresponding localized message and send out an error toast
     * @param {ARApiError} error
     */
    public static handleApiError(error: ARApiError): void {
        if (Toaster.toastService) {
            // Redirect HTTP error status 503 (Service Unavailable)
            if (error?.code === ErrorConstants.API_ERROR_CODES.SERVICE_UNAVAILABLE) {
                Toaster.handleApiWarning(error, {timeout: TIME_OUT_TYPES.LONG});
            } else {
                Toaster.toastService.show(new ToastDataModel(EToastType.ERROR, 'Error', ErrorFormatterUtil.formatError(error)));
            }
        } else {
            ARLogger.error(Toaster.TOASTER_NOT_INITIALIZED_MSG);
        }
    }

    /**
     * Send out a warning toast with an optional title
     */
    public static warn(message: string, title?: string, options?: ToastOptionModel): void {
        if (Toaster.toastService) {
            title = title || 'Warning';
            const toastData = new ToastDataModel(EToastType.WARNING, title, message, options?.loading, options?.timeout);
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error(Toaster.TOASTER_NOT_INITIALIZED_MSG);
        }
    }

    /**
     * Send out an error toast for not yet implemented features
     */
    public static notYetImplementedError(): void {
        if (Toaster.toastService) {
            const toastData = new ToastDataModel(
                EToastType.ERROR,
                'Not yet implemented',
                'TODO: This functionality has not yet been implemented');

            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error(Toaster.TOASTER_NOT_INITIALIZED_MSG);
        }
    }

    /**
     * Send out a success toast with an optional title
     */
    public static success(message: string, title?: string, options?: ToastOptionModel): void {
        if (Toaster.toastService) {
            title = title || 'Success';
            const toastData = new ToastDataModel(EToastType.SUCCESS, title, message, options?.loading, options?.timeout);
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error(Toaster.TOASTER_NOT_INITIALIZED_MSG);
        }
    }

    /**
     * General method to use toasts
     * @param toastData
     */
    public static openToast(toastData: ToastDataModel): void {
        if (Toaster.toastService) {
            Toaster.toastService.show(toastData);
        } else {
            ARLogger.error(Toaster.TOASTER_NOT_INITIALIZED_MSG);
        }
    }

    /**
     * Initialize the Toaster
     */
    public static initialize(toastService: ToastService): void {
        Toaster.toastService = toastService;
    }
}
