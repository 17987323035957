import {Component, Input, EventEmitter, Output, OnDestroy, OnInit} from '@angular/core';
import {ICollectionViewItem} from '../../models/interfaces/collection-view-item.interface';
import {PropertySetting, PropertySettingsModel} from '../property-settings/property-settings.model';
import {EPropertySettingsContext, PropertySettingsService} from '../property-settings/property-settings.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {EAssetDisplayProperties} from '../../pipes/asset-display.pipe';

@Component({
    selector: 'rl-grid-view',
    templateUrl: 'grid-view.component.html',
    styleUrls: ['grid-view.component.scss']
})

export class GridViewComponent implements OnInit, OnDestroy {
    @Input() public isLoading: boolean;
    @Input() public items: ICollectionViewItem[];
    @Input() public activeItem: ICollectionViewItem;
    @Input() public selectMultipleItems: boolean = false;
    @Input() public selectedItems: string[];
    @Input() public propertySettingsContext: EPropertySettingsContext;
    @Input() public isSearching: boolean;

    @Output() public gridViewItemClicked = new EventEmitter();
    @Output() public gridViewItemDoubleClicked = new EventEmitter();

    public EAssetDisplayProperties = EAssetDisplayProperties;

    public isSingleClick = true;

    private onDestroySubject = new Subject<void>();
    public properties: PropertySetting[];

    constructor(private propertySettingsService: PropertySettingsService) {}

    public ngOnInit(): void {
        if (this.propertySettingsContext) {
            this.propertySettingsService.getSettings(this.propertySettingsContext).pipe(
                takeUntil(this.onDestroySubject)
            ).subscribe(
                (settings: PropertySettingsModel) => {
                    this.properties = [...settings.relayterFields, ...settings.dataFields];
                });
        }
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * On card clicked responder function, if a value is passed, find the current active item based on that value and emit gridViewItemClicked emitter
     * @param {string} value
     */
    public onCardClicked(value: string): void {
        this.isSingleClick = true;
        if (value) {
            this.activeItem = this.items.find((item) => item._id === value);
            setTimeout(() => {
                if (this.isSingleClick) {
                    this.gridViewItemClicked.emit(this.activeItem);
                }
            }, 200);
        }
    }

    public onCardDoubleClicked(value: string): void {
        this.isSingleClick = false;
        if (value) {
            this.activeItem = this.items.find((item) => item._id === value);
            this.gridViewItemDoubleClicked.emit(this.activeItem);
        }
    }

    /**
     * Check if item is selected
     * @param {ICollectionViewItem} item
     * @returns {boolean}
     */
    public checkIfItemIsSelected(item: ICollectionViewItem): boolean {
        if (this.selectMultipleItems) {
            return this.selectedItems.findIndex((itemId) => itemId === item._id) !== -1;
        }
        return this.activeItem && this.activeItem._id === item._id;
    }
}
