import {TemplateContentModel} from '../template-detail/template-content/template-content.model';

/**
 * template-content.pixi
 * app-relayter-web
 * Created by borisnelissen on 25/07/2019.
 * Copyright © 2019 Creative Media Network. All rights reserved.
 */

export enum PixiTemplateContentStatus {
    Normal = 'normal',
    Active = 'active',
    Inactive = 'inactive'
}

export class PixiTemplateContent extends PIXI.Container {
    private static readonly TEMPLATE_BORDER_COLOR = 0x9B9B9B; // Light-grey
    private static readonly TEMPLATE_BACKGROUND_COLOR = 0xF3F3F3; // Dove
    private static readonly TEMPLATE_BORDER_COLOR_ACTIVE = 0x3FBB87; // relayter-green
    private static readonly TEMPLATE_BACKGROUND_COLOR_ACTIVE = 0x3FBB87; // relayter-green (alpha 0.25)
    private static readonly TEMPLATE_COLLISION_TINT_COLOR = 0xFF0000;
    private static readonly TEXT_COLOR = '#000a12';

    private content: TemplateContentModel;
    private index: number;
    private readonly templateScale: number;
    private status: PixiTemplateContentStatus = PixiTemplateContentStatus.Normal;
    private collision = false;

    // TODO: Add shadow as border

    constructor(content: TemplateContentModel, index: number, templateScale: number) {
        super();
        this.content = content;
        this.index = index;
        this.templateScale = templateScale;
        this.interactive = true;
        this.buttonMode = true;
        this.update();
    }

    public update(): void {
        this.removeChildren();
        this.x = this.content.x * this.templateScale;
        this.y = this.content.y * this.templateScale;
        const width = this.content.width * this.templateScale;
        const height = this.content.height * this.templateScale;
        const columnGutter = this.content.columnGutter * this.templateScale;
        const rowGutter = this.content.rowGutter * this.templateScale;

        const columnGutterSum = (this.content.columns - 1) * columnGutter;
        const rowGutterSum = (this.content.rows - 1) * rowGutter;

        const columnWidth = (width - columnGutterSum) / this.content.columns;
        const rowHeight = (height - rowGutterSum) / this.content.rows;

        let backgroundColor = this.collision ? PixiTemplateContent.TEMPLATE_COLLISION_TINT_COLOR : PixiTemplateContent.TEMPLATE_BACKGROUND_COLOR;
        let borderColor = PixiTemplateContent.TEMPLATE_BORDER_COLOR;
        let textStyle = new PIXI.TextStyle({fontSize: 12, fontWeight: 'normal', fill: [PixiTemplateContent.TEXT_COLOR]});
        let backgroundColorAlpha = 1;
        let alpha = .5;
        if (this.status === PixiTemplateContentStatus.Active) {
            backgroundColor = this.collision ? PixiTemplateContent.TEMPLATE_COLLISION_TINT_COLOR :
                PixiTemplateContent.TEMPLATE_BACKGROUND_COLOR_ACTIVE;
            borderColor = PixiTemplateContent.TEMPLATE_BORDER_COLOR_ACTIVE;
            textStyle = new PIXI.TextStyle({fontSize: 12, fontWeight: 'bold', fill: [PixiTemplateContent.TEXT_COLOR]});
            backgroundColorAlpha = .25;
            alpha = 1;
        }

        for (let row = 0; row < this.content.rows; row++) {
            for (let column = 0; column < this.content.columns; column++) {
                const area = new PIXI.Graphics();
                area.beginFill(backgroundColor, backgroundColorAlpha);
                area.lineStyle(1, borderColor);
                area.drawRect(column * columnWidth + columnGutter * column, row * rowHeight + rowGutter * row, columnWidth, rowHeight);
                area.endFill();
                this.addChild(area);
            }
        }

        this.alpha = alpha;

        const text = new PIXI.Text(`${this.index + 1}`.padStart(2, '0') + '.', textStyle);
        text.x = 5;
        text.y = 5;

        this.addChild(text);
    }

    public setCollision(collision: boolean): void {
        this.collision = collision;
        this.update();
    }

    public setStatus(status: PixiTemplateContentStatus): void {
        this.status = status;
        this.update();
    }

}
