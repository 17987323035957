<p *ngIf="title" class="section-header">{{title}}</p>

<div class="information-columns">
    <ng-container *ngFor="let field of informationDetails">
        <p class="body-strong" [class.xl-view]="!sidebar">{{field.title}}</p>
        <p [class.text-right]="sidebar" class="text-end">
            <ng-container *ngIf="!field.isTag">
                {{item | propertySettingDisplay:field}}
            </ng-container>
            <ng-container *ngIf="field.isTag">
                <div class="tags" [class.tags-right]="sidebar">
                    <nuc-tag-card *ngFor="let tag of item.tags"
                                  [tag]="tag"
                                  [deletable]="false"
                                  [hoverEffect]="false">
                    </nuc-tag-card>
                </div>
            </ng-container>
        </p>
    </ng-container>
</div>
