<div class="form-group input-group reactive-input"
     [ngClass]="smallInputBox ? 'small-input' : ''"
        [class.fill-space]="fillSpace">
    <input type="file"
           #fileInput
           name="file-[]"
           id="fileInput"
           class="input-file"
           [class.isDragging]="isDragging"
           [multiple]="multiple"
           (change)="onFilesChanged($event);"
           (dragenter)="onDragEntered()"
           (dragleave)="onDragExited()"
           (dragend)="onDragExited()"/>

    <label class="head-label" *ngIf="!smallInputBox" for="fileInput">
        <div class="drag-drop-row">
            <img [src]="getDragDropImage(isDragging)"/>
        </div>
        <div class="drag-drop-row">
            <p class="text-center">{{getDragDropTitle(isDragging)}}</p>
        </div>
        <div class="drag-drop-row">
            <nuc-button-secondary (click)="fileInput.click()" text="Browse files"></nuc-button-secondary>
        </div>
    </label>

    <label *ngIf="smallInputBox" class="sub-label small-input" for="fileInput">
        <p>{{getDragDropTitle(isDragging)}}</p>
        <nuc-button-secondary text="Browse files"></nuc-button-secondary>
    </label>
</div>
