/**
 * Created by jiameiwu on 25/09/2018.
 */
import {autoserialize, autoserializeAs} from 'cerialize';
import {DataFieldDataTypeEnumerationModel} from './data-field-data-type-enumeration.model';
import {EDataFieldFormatter, EDataFieldTypes} from '../../app.enums';

export class DataFieldDataTypeModel {
    @autoserialize public type: EDataFieldTypes;
    @autoserialize public formatter: EDataFieldFormatter;
    @autoserializeAs(DataFieldDataTypeEnumerationModel) public enumeration: DataFieldDataTypeEnumerationModel;
}
