import {autoserialize, autoserializeAs} from 'cerialize';
import {ChannelModel} from './channel.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {SizeModel} from './size.model';
import {TemplateMarginsModel} from './template-margins.model';

class MasterPageFilesModel {
    @autoserialize public preview: string;
    @autoserialize public template: string;
}

export class MasterPageModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(ChannelModel) public publicationType: ChannelModel;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
    @autoserialize public pageCount: number;
    @autoserializeAs(SizeModel) public pageSize: SizeModel;
    @autoserializeAs(MasterPageFilesModel) public files: MasterPageFilesModel;
    @autoserializeAs(TemplateMarginsModel) public margins: TemplateMarginsModel;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}

export class MasterPagePatchModel {
    public name: string;

    constructor(name: string) {
        this.name = name;
    }

}
