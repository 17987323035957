import {
    AfterViewInit, ComponentRef,
    Directive,
    ElementRef, EmbeddedViewRef, Input, OnDestroy, OnInit,
    Renderer2, TemplateRef,
    ViewContainerRef
} from '@angular/core';
import {
    ButtonSecondaryComponent,
    FullModalConfig,
    FullModalRef,
    FullModalService
} from '@relayter/rubber-duck';
import {FullScreenContainerComponent} from './full-screen-container/full-screen-container.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ARLogger} from '@relayter/core';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';

export enum EFullscreenEvents {
    CLOSE
}
export enum EFullscreenModalEvents {
    FULLSCREEN_TOGGLE
}

@Directive({
    selector: '[fullscreenForm]'
})
export class FullscreenFormDirective implements AfterViewInit, OnInit, OnDestroy {
    private form: HTMLElement;
    private embeddedViewRef: EmbeddedViewRef<any>;
    private fullscreen: boolean = true;
    private fullScreenBtnRef: ComponentRef<ButtonSecondaryComponent>;
    private modalRef: FullModalRef<any>;

    private eventsSubject: Subject<EFullscreenEvents>;
    private modalEventSubject: Subject<EFullscreenModalEvents>;
    private onDestroy$: Subject<void> = new Subject<void>();

    @Input()
    set fullscreenForm(events: Subject<EFullscreenEvents>) {
        this.eventsSubject = events;
    }

    private viewId: string;
    private buttonClassLocation: string;

    @Input()
    set fullscreenFormViewId(id: string) {
        this.viewId = id;
    }

    @Input()
    set fullscreenFormBtnContainer(className: string) {
        this.buttonClassLocation = className;
    }

    @Input()
    set fullscreenFormModalEventSubject(fullscreenModalEvent: Subject<EFullscreenModalEvents>) {
        this.modalEventSubject = fullscreenModalEvent;
    }

    constructor(private el: ElementRef,
                private renderer: Renderer2,
                private viewContainerRef: ViewContainerRef,
                private templateRef: TemplateRef<any>,
                private modalService: FullModalService,
                private userSettingsStorageService: UserSettingsStorageService) {
        this.form = this.el.nativeElement;
    }

    public ngAfterViewInit(): void {
        this.fullscreen = this.userSettingsStorageService.loadSettings(this.viewId) !== 'false';
        if (!this.buttonClassLocation) {
            ARLogger.warn('No class given to place the fullscreen button. button will be placed somewhere in the outer container');
        }
        const buttonContainer = this.renderer.parentNode(this.form).getElementsByClassName(this.buttonClassLocation)[0] as HTMLElement;
        this.renderer.insertBefore(buttonContainer, this.fullScreenBtnRef.location.nativeElement, buttonContainer?.childNodes[0]);

        this.eventsSubject.pipe(takeUntil(this.onDestroy$)).subscribe((event: EFullscreenEvents) => {
            if (event === EFullscreenEvents.CLOSE && this.fullscreen) {
                this.modalRef.close();
            }
        });

        if (this.fullscreen) {
            this.viewContainerRef.detach(this.viewContainerRef.indexOf(this.embeddedViewRef));
            const config = new FullModalConfig('', '');
            config.confirmClose = true;
            this.modalRef = this.modalService.openEmptyModal(FullScreenContainerComponent, config);
            this.modalRef.componentInstance.setView(this.embeddedViewRef);
            this.modalRef.afterClosed()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((result) => {
                    this.modalEventSubject.next(result);
                });
        }
    }

    public ngOnInit(): void {
        // Listen to events
        this.fullScreenBtnRef = this.viewContainerRef.createComponent(ButtonSecondaryComponent);
        this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);

        // Pass the template to the dynamic component
        this.fullScreenBtnRef.instance.icon = 'nucicon_fullscreen';
        this.renderer.listen(this.fullScreenBtnRef.location.nativeElement, 'click', () => {
            this.fullscreen = !this.fullscreen;
            this.userSettingsStorageService.storeSettings(this.viewId, this.fullscreen.toString());
            if (this.fullscreen) {
                this.viewContainerRef.detach(this.viewContainerRef.indexOf(this.embeddedViewRef));
                const config = new FullModalConfig('', '');
                config.confirmClose = true;
                this.modalRef = this.modalService.openEmptyModal(FullScreenContainerComponent, config);
                this.modalRef.componentInstance.setView(this.embeddedViewRef);
                this.modalRef.afterClosed()
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe((result) => {
                        this.modalEventSubject.next(result);
                    });
            } else {
                this.viewContainerRef.insert(this.embeddedViewRef);
                this.modalRef.close(EFullscreenModalEvents.FULLSCREEN_TOGGLE);
            }
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
