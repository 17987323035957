import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';

export class TabBarItemModel<T = string> implements ITabBarItem {
    public disabled?: boolean;
    public active?: boolean;
    public data?: T;

    constructor(public title: string, public index: number, data?: T) {
        this.title = title;
        this.index = index;
        if (data) {
            this.data = data;
        }
    }
}
