import {Component, OnInit} from '@angular/core';
import {Auth0Service} from '../../services/auth0.service';

@Component({
    selector: 'rl-app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {
    constructor(private auth0: Auth0Service) {
    }

    public ngOnInit(): void {
        // In order to restore local authentication status after a refresh, we'll call the localAuthSetup() method when the app initializes.
        this.auth0.localAuthSetup();
    }

}
