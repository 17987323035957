import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EIdentifierSettingFragmentType} from '../../../models/api/custom-workflow-identifier-setting.model';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {distinctUntilChanged} from 'rxjs/operators';
import {RulePropertyModel} from '../../../models/api/rule-property.model';

@Component({
    selector: 'workflow-identifier-setting-fragment-form-component',
    templateUrl: 'workflow-identifier-setting-fragment-form.component.html',
    styleUrls: ['workflow-identifier-setting-fragment-form.component.scss']
})
export class WorkflowIdentifierSettingFragmentFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    @Input() public formGroup: UntypedFormGroup;
    @Input() public properties: RulePropertyModel[];
    @Input() public readonly fragmentTypeOptions: DropdownItem<string>[];

    public ngOnInit(): void {
        this.formGroup.controls.type.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((type) => {

                const selectedFragmentType = type?.getTitle();
                switch (selectedFragmentType) {
                    case EIdentifierSettingFragmentType.DERIVED: {
                        this.formGroup.removeControl('fixedValue');
                        if (!this.formGroup.controls.property) {
                            this.formGroup.addControl('property', new UntypedFormControl(null, Validators.required));
                        }
                        return;
                    }
                    case EIdentifierSettingFragmentType.FIXED: {
                        this.formGroup.removeControl('property');
                        if (!this.formGroup.controls.fixedValue) {
                            this.formGroup.addControl('fixedValue', new UntypedFormControl(null, Validators.required));
                        }
                        return;
                    }
                    case EIdentifierSettingFragmentType.RANDOM: {
                        this.formGroup.removeControl('fixedValue');
                        this.formGroup.removeControl('property');
                        return;
                    }
                }
            });
    }
}
