import {ITableItemPatchModel} from '@relayter/rubber-duck';

type valueReturnType = string | boolean| string[] | number;

export class TableInlineEditingUtil {

    public static getPropertyAndValue(patchObj: ITableItemPatchModel): {property: string; value: valueReturnType} {
        const property = Object.keys(patchObj.body)[0];
        const value = patchObj.body[property] !== null
                        && patchObj.body[property] !== undefined
                        && patchObj.body[property] !== ''
                        ? patchObj.body[property] : null;
        return {property, value};
    }

    /**
     * Recursively traverse the object, creating properties if they don't already exist and assigns val as a value
     * @param path - String that represents the property. Nested properties are encoded with dot notation
     * @param val - the value to assign to the newly created property
     * @param [obj] - Object to update. Defaults to {};
     * @param [variantKey] - The variant key to store the value
     */
    public static fillObjectFromPath(path: string, val: any, obj: any = {}, variantKey?: string): any {
        const properties = path.split('.');
        const lastProperty = properties.pop();
        // fill the nested objects, creating properties if they don't exist
        const lastObj = properties.reduce((o, property) => o[property] = o[property] || {}, obj);
        if (variantKey) {
            if (!lastObj[lastProperty]) {
                lastObj[lastProperty] = {};
            }
            lastObj[lastProperty][variantKey] = val;
        } else {
            lastObj[lastProperty] = val;
        }
        return obj;
    }
}
