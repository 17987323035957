import {SubMenuItem} from './sub-menu-item.model';

export class SubMenuSection {
    public title: string;
    public subMenuItems: SubMenuItem[] = [];

    constructor(subMenuItems: SubMenuItem[], title?: string) {
        this.subMenuItems = subMenuItems;
        this.title = title;
    }
}
