import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ARPagedResponseDataModel} from '@relayter/core';
import {RoleModel} from '../../../models/api/role.model';
import {RolesService} from '../../../api/services/roles.service';
import {Toaster} from '../../../classes/toaster.class';
import {AppConstants} from '../../../app.constants';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {
    BUTTON_TYPE,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent, ITableAction,
    ITableColumn, NucDialogConfigModel, NucDialogService
} from '@relayter/rubber-duck';
import {IRoleFormModalData, RoleNameFormComponent} from '../../../forms/role-name-form/role-name-form.component';
import {filter, takeUntil} from 'rxjs/operators';
import {MatrixUrlParams} from '../../../models/ui/matrix-url-params.model';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {Subject, Subscription} from 'rxjs';

@Component({
    selector: 'rl-roles-overview',
    templateUrl: 'roles-overview.component.html',
    styleUrls: ['roles-overview.component.scss'],
    providers: [PaginatorService]
})

export class RolesOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public tableId = 'permission-groups-overview';
    public items: RoleModel[];
    public isLoading = false;
    public total: number;

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name'
        },
        {
            title: 'Users',
            key: 'users',
            sortProperty: 'users'
        }
    ];
    public actions: ITableAction[];
    public pageSize: number;
    public pageIndex: number;
    private onDestroySubject = new Subject<void>();
    public rolesSubscription = new Subscription();
    public disableNextPage = true;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private rolesService: RolesService,
                private fullModalService: FullModalService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                private dialogService: NucDialogService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.setTableActions();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;

                this.pageSize = pagination.pageSize;

                this.updateUrl();
                this.getRoles();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.sortProperty = params['sortProperty'];
        this.sortOrder = params['sortOrder'];
        this.searchValue = params['search'];

        this.paginatorService.setPageIndex(this.tableId, this.pageIndex);
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PUT_ROLE)) {
            this.actions = [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_ROLE)) {
            this.actions = this.actions?.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) || [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    public resetPageIndex(): void {
        this.paginatorService.setPageIndex(this.tableId, 1); // reset pageIndex
    }

    /**
     * Gets roles from API
     */
    private getRoles(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_ROLES)) return;

        if (this.rolesSubscription && !this.rolesSubscription.closed) {
            this.rolesSubscription.unsubscribe();
        }

        this.rolesSubscription = this.rolesService.getRoles(
            this.pageSize,
            (this.pageIndex - 1) * this.pageSize,
            this.sortProperty,
            this.sortOrder,
            this.searchValue
        ).pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (roles: ARPagedResponseDataModel<RoleModel>) => {
                    this.total = roles.total;
                    this.items = roles.items;
                    this.disableNextPage = this.pageSize * this.pageIndex >= this.total;
                },
                Toaster.handleApiError
            );
    }

    /**
     * Responder, opens create role dialog
     */
    public onCreateRoleButtonClicked(): void {
        const config = new FullModalConfig(
            'Create a role',
            'Fill in the information below to create a new role.', {});
        config.confirmClose = true;

        this.fullModalService.open(RoleNameFormComponent, config)
            .afterClosed().pipe(filter((refresh: boolean) => refresh))
            .subscribe(() => this.getRoles());
    }

    /**
     * On table view row clicked navigate to role page
     * @param {IItemClickEvent} itemClickEvent
     */
    public onItemClicked(itemClickEvent: IItemClickEvent): void {
        const item = itemClickEvent.item;

        if (item && this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_ROLE)) {
            this.router.navigate([`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.ROLE_MANAGEMENT, item._id]);
        }
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.resetPageIndex();
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const role = event.item as RoleModel;

        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.onEditRoleClicked(role);
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.openDeleteDialog(role);
        }
    }

    /**
     * On Edit role clicked, if there is a role, init the form
     */
    public onEditRoleClicked(role: RoleModel): void {
        const editFormData: IRoleFormModalData = {role};
        const config = new FullModalConfig(
            'Edit a role',
            'Fill in the information below to edit a role.',
            editFormData);
        config.confirmClose = true;

        this.fullModalService.open(RoleNameFormComponent, config)
            .afterClosed().pipe(filter((refresh) => refresh))
            .subscribe(() => this.getRoles());
    }

    public openDeleteDialog(role: RoleModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete role', `Please confirm that you wish to delete ${role.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.removeRole(role);
        });

    }

    private removeRole(role: RoleModel): void {
        this.rolesService.deleteRole(role._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                () => {
                    Toaster.success('Role successfully removed');
                    this.getRoles();
                },
                Toaster.handleApiError);
    }

    /**
     * Create a MatrixUrlModel so the url always has the correct amount of parameters
     * @return {MatrixUrlParams}
     */
    public createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    /**
     * Updates the url when properties changes (pageIndex / pageSize)
     * Called by onPaginationChanged, reload, onTableOptionsChanged
     * Forces an update of the data through the route params subscription
     */
    protected updateUrl(): void {
        this.router.navigate([AppConstants.ROLES_PATH, this.createMatrixUrl()]);
    }
}
