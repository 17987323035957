import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {ARLogger} from '@relayter/core';
import {ChannelModel} from '../../models/api/channel.model';
import {Toaster} from '../../classes/toaster.class';
import {distinctUntilChanged, finalize, map, startWith, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {IUploadUpdate} from '../../components/upload-file-component/upload-file.component';
import {EUploadStatus} from '../../components/upload-file-component/upload.model';
import {EMasterPageJobType, IAddMasterPageJobData, IUpdateMasterPageJobData, MasterPagesService} from '../../api/services/master-pages.service';
import {JobModel} from '../../models/api/job.model';
import {MasterPageModel, MasterPagePatchModel} from '../../models/api/master-page.model';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {FileTypeUtil} from '../../classes/file-type.util';
import {PublicationTypesService} from '../../api/services/publication-types.service';

interface IMasterPageModalData {
    masterPage?: MasterPageModel;
}

@Component({
    selector: 'rl-master-page-form-component',
    templateUrl: 'master-page-form.component.html',
    styleUrls: ['master-page-form.component.scss']
})

export class MasterPageFormComponent implements OnInit, OnDestroy {
    public formGroup: UntypedFormGroup;
    public channels: ChannelModel[] = [];

    public uploadUpdateSubject = new BehaviorSubject<IUploadUpdate>(null);
    public uploadUpdate: IUploadUpdate;
    public fileTypeCategories = [FileTypeUtil.CATEGORIES.INDESIGN_TEMPLATE];

    private onDestroySubject = new Subject<void>();
    private saveButton: ButtonConfig;
    private readonly masterPage: MasterPageModel;

    constructor(private fullModalService: FullModalService,
                private publicationTypesService: PublicationTypesService,
                private masterPagesService: MasterPagesService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) private modalData: IMasterPageModalData) {
        this.masterPage = this.modalData?.masterPage;
    }

    public ngOnInit(): void {
        this.initModalButtons();
        this.initForm();
        this.trackStatus();
        this.getChannels();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initModalButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close());
        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => this.saveMasterPage());
        this.fullModalService.setModalActions([cancel, save]);
    }

    private initForm(): void {
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl(this.masterPage?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            publicationType: new UntypedFormControl(
                {value: this.masterPage?.publicationType, disabled: !!this.masterPage}, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
        });
    }

    private getChannels(): void {
        this.publicationTypesService.find()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => this.channels = result.items,
                error: Toaster.handleApiError
            });
    }

    private trackStatus(): void {
        combineLatest([
            this.formGroup.statusChanges.pipe(startWith(this.formGroup.status), distinctUntilChanged(), map((status) => status === 'VALID')),
            this.uploadUpdateSubject.pipe(map((uploadUpdate) => {
                this.uploadUpdate = uploadUpdate;
                // when updating a master page, file is not required
                return uploadUpdate?.status === EUploadStatus.Done || (this.modalData?.masterPage && !uploadUpdate);
            }))])
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(([formValid, uploadValid]) => this.saveButton.disabled = !formValid || !uploadValid);
    }

    private saveMasterPage(): void {
        this.saveButton.loading = true;

        const name = this.formGroup.value.name.trim();
        const publicationType = this.formGroup.value?.publicationType?._id;
        const s3Key = this.uploadUpdate?.s3Key;
        const itemId = this.masterPage?._id;
        const obs: Observable<JobModel | MasterPageModel> = this.masterPage ? s3Key
                ? this.masterPagesService.postJob(EMasterPageJobType.UPDATE_MASTER_PAGE, {itemId, name, s3Key} as IUpdateMasterPageJobData)
                : this.masterPagesService.patchMasterPage(itemId, new MasterPagePatchModel(name))
            : this.masterPagesService.postJob(EMasterPageJobType.ADD_MASTER_PAGE, {name, s3Key, publicationType} as IAddMasterPageJobData);

        obs.pipe(
            finalize(() => this.saveButton.loading = false),
            takeUntil(this.onDestroySubject)
        ).subscribe({
            next: (result) => {
                if (result instanceof JobModel) {
                    ARLogger.debug('InDesign Library Job scheduled: ' + result._id);
                    this.fullModalService.close({jobId: result._id});
                } else {
                    Toaster.success('Master page updated successfully');
                    this.fullModalService.close({});
                }
            },
            error: Toaster.handleApiError
        });
    }
}
