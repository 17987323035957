import {autoserialize} from 'cerialize';

export class ValueModel {
    @autoserialize tag: string;
    @autoserialize property: string;
    @autoserialize format: string;
    @autoserialize formatString: string;
    @autoserialize required: boolean; // This property is not used in generation and will be removed

    constructor(tag: string, property: string, format?: string, formatString?: string, required = true) {
        this.tag = tag;
        this.property = property;
        this.format = format;
        this.formatString = formatString;
        this.required = required;
    }
}
