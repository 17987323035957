import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {StatisticsService} from '../../../../api/services/statistics.service';
import {TeamAccountService} from '../../../../api/services/team-account.service';
import {TeamModel} from '../../../../models/api/team.model';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';

import {ChartData, ChartOptions, ChartType} from 'chart.js';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {StringUtil} from '../../../../classes/string-util';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {StatisticsPeriodTotalModel} from '../../../../models/api/statistics-period-total.model';
import {BaseChartDirective} from 'ng2-charts';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-general-information',
    templateUrl: './general-information.component.html',
    styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
    private destroyRef: DestroyRef = inject(DestroyRef);

    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {duration: 0}
    };
    public barChartType: ChartType = 'bar';
    public barChartLegend = false;
    public barChartData: ChartData = {
        datasets: [{
            data: [],
            label: 'Total material created',
            backgroundColor: AppConstants.FIRST_BRAND_COLOR,
            hoverBackgroundColor: AppConstants.FIRST_BRAND_COLOR
        }],
    };
    public teamDetails$: Observable<void | TeamModel>;
    public formGroup: UntypedFormGroup;

    public storage$: Observable<{ value: number }>;
    public creations$: Observable<{ value: number }>;

    public periods: DropdownItem<string>[] = Object.values(StatisticsService.EStatisticsPeriod)
        .map((period) => new DropdownItem<string>(StringUtil.makeFirstCharUppercase(period), period));

    public permissions = AppConstants.PERMISSIONS;

    constructor(private statisticsService: StatisticsService,
                private teamService: TeamAccountService,
                private userIsAllowedToPipe: UserIsAllowedToPipe) {
    }

    public ngOnInit(): void {

        this.teamDetails$ = this.teamService.getTeamDetails().pipe(
            catchError(async (error) => Toaster.handleApiError(error)),
            takeUntilDestroyed(this.destroyRef)
        );

        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_TEAM_STORAGE)) {
            this.storage$ = this.statisticsService.getStorage('ALL').pipe(
                catchError(async (error) => Toaster.handleApiError(error)),
                map((stats: ARPagedResponseDataModel<StatisticsPeriodTotalModel>) => {
                    return {value: stats.items[0]?.value || 0};
                }),
                takeUntilDestroyed(this.destroyRef)
            );
        }
        this.creations$ = this.statisticsService.getPeriodTotals('MONTH').pipe(
            map((stats) => {
                const last6Months = stats.items.slice(0, 6);
                const value = last6Months.reduce((a, item) => {
                    return a + item.value;
                }, 0);

                return {value: Math.round(value / 6)};
            }),
            takeUntilDestroyed(this.destroyRef)
        );

        this.formGroup = new UntypedFormGroup({
            period: new UntypedFormControl(
                new DropdownItem<string>(StringUtil.makeFirstCharUppercase(StatisticsService.EStatisticsPeriod.MONTH),
                    StatisticsService.EStatisticsPeriod.MONTH))
        });

        this.formGroup.valueChanges.subscribe((value: { period: DropdownItem<any> }) => {
            this.getStatistics(value.period.getValue());
        });

        this.getStatistics(StatisticsService.EStatisticsPeriod.MONTH);
    }

    private getStatistics(period): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_STATISTICS)) {
            this.statisticsService.getPeriodTotals(period)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (results: ARPagedResponseDataModel<StatisticsPeriodTotalModel>) => {
                        this.barChartData.labels = results.items.map(item => item.getPeriod());
                        this.barChartData.datasets[0].data = results.items.map(item => item.value);
                        this.chart.update();
                    },
                    error: Toaster.handleApiError
                });
        }
    }
}
