import {autoserialize, autoserializeAs} from 'cerialize';

export enum EIdentifierSettingFragmentType {
    DERIVED = 'DERIVED',
    FIXED = 'FIXED',
    RANDOM = 'RANDOM',
}

export class CustomWorkflowIdentifierSettingFragmentModel {
    @autoserialize public type: EIdentifierSettingFragmentType;
    @autoserialize public fixedValue?: string;
    @autoserialize public property?: string;
}

export class CustomWorkflowIdentifierSettingModel {
    @autoserialize public join: string;
    @autoserialize public sequence: boolean;
    @autoserializeAs(CustomWorkflowIdentifierSettingFragmentModel)
    public fragments: CustomWorkflowIdentifierSettingFragmentModel[];

    constructor(join: string, sequence: boolean) {
        this.join = join;
        this.sequence = sequence;
        this.fragments = [];
    }

}
