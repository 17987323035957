import {autoserialize, autoserializeAs} from 'cerialize';
import {SpreadNoteModel} from './spread-note.model';
import {AppConstants} from '../../app.constants';
import {ISpreadContentTypeBody} from '../interfaces/spread-content-type-body.interface';
import {CampaignItemModel} from './campaign-item.model';
import {AssetModel} from './asset.model';
import {ContentAreaModel} from './publication-item-content.model';

export class SpreadContentUpdateModel {
    @autoserialize public contentType: string;
    @autoserializeAs(ContentAreaModel) public area: ContentAreaModel;
    @autoserialize public campaignItem: string;
    @autoserializeAs(SpreadNoteModel) public note: SpreadNoteModel;
    @autoserialize public asset: string;

    constructor(contentModel: ISpreadContentTypeBody, area: ContentAreaModel) {
        if (contentModel instanceof CampaignItemModel) {
            this.contentType = AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM;
            this.campaignItem = contentModel._id;
        }
        if (contentModel instanceof AssetModel) {
            this.contentType = AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.ASSET;
            this.asset = contentModel._id;
        }
        if (contentModel instanceof SpreadNoteModel) {
            this.contentType = AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.NOTE;
            this.note = contentModel;
        }
        this.area = area;
    }

}
