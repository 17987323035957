/**
 * template-size.model
 * app-relayter-web
 * Created by borisnelissen on 23/07/2019.
 * Copyright © 2019 Creative Media Network. All rights reserved.
 */
import {EPublicationType} from '../publication-type.enum';
import {TemplateMarginsModel} from '../../../../../models/api/template-margins.model';

export enum ETemplateType {
    Single = 'Single',
    Spread = 'Spread'
}

export class TemplateSizeModel {
    private static readonly DEFAULT_POS_WIDTH = 200;
    private static readonly DEFAULT_POS_HEIGHT = 200;
    private static readonly DEFAULT_PRINT_WIDTH = 210;
    private static readonly DEFAULT_PRINT_HEIGHT = 297;
    private static readonly DEFAULT_PRINT_MARGIN = 10;
    private static readonly DEFAULT_WEB_WIDTH = 200;
    private static readonly DEFAULT_WEB_HEIGHT = 200;

    public readonly width: number;
    public readonly height: number;
    public readonly margins: TemplateMarginsModel;
    public readonly templateType: ETemplateType;

    public static fromFormValues(formValues: any): TemplateSizeModel {
        // For PosTemplates formValues.margin & formValues.templateType are undefined
        return new TemplateSizeModel(formValues.width, formValues.height, formValues.margins, formValues.templateType);
    }

    public static defaultSize(publicationType: EPublicationType): TemplateSizeModel {
        switch (publicationType) {
            case EPublicationType.PRINT_MAGAZINE:
                return new TemplateSizeModel(
                        TemplateSizeModel.DEFAULT_PRINT_WIDTH, TemplateSizeModel.DEFAULT_PRINT_HEIGHT,
                        new TemplateMarginsModel(TemplateSizeModel.DEFAULT_PRINT_MARGIN, TemplateSizeModel.DEFAULT_PRINT_MARGIN,
                        TemplateSizeModel.DEFAULT_PRINT_MARGIN, TemplateSizeModel.DEFAULT_PRINT_MARGIN),
                        ETemplateType.Single);
            case EPublicationType.POS:
                return new TemplateSizeModel(
                        TemplateSizeModel.DEFAULT_POS_WIDTH, TemplateSizeModel.DEFAULT_POS_HEIGHT,
                        new TemplateMarginsModel(0, 0, 0, 0),
                        ETemplateType.Single);
            case EPublicationType.WEB:
                return new TemplateSizeModel(
                        TemplateSizeModel.DEFAULT_WEB_WIDTH, TemplateSizeModel.DEFAULT_WEB_HEIGHT,
                        new TemplateMarginsModel(0, 0, 0, 0),
                        ETemplateType.Single);
        }
    }

    constructor(width: number, height: number, margins: TemplateMarginsModel, templateType?: ETemplateType) {
        this.width = width;
        this.height = height;
        this.margins = margins;
        this.templateType = templateType;
    }

    public isValid(publicationType: EPublicationType): boolean {
        switch (publicationType) {
            case EPublicationType.PRINT_MAGAZINE:
                return this.width > 0 && this.height > 0 && this.margins && this.margins.marginTop >= 0 && this.margins.marginBottom >= 0 &&
                    this.margins.marginStart >= 0 && this.margins.marginEnd >= 0 && !!this.templateType;
            case EPublicationType.POS:
            case EPublicationType.WEB:
                return this.width > 0 && this.height > 0 && this.margins && this.margins.marginTop >= 0 && this.margins.marginBottom >= 0 &&
                    this.margins.marginStart >= 0 && this.margins.marginEnd >= 0;
            default:
                return false;
        }
    }
}
