import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {QueryParams} from '../../classes/query-params';

@Injectable({
    providedIn: 'root'
})
export class PublicationItemsService extends BaseApiRequestService {
    public getContentBriefingChanges(publicationId: string,
                                     publicationItemId: string,
                                     datetime: Date): Observable<ARPagedResponseDataModel<CampaignItemModel>> {
        const queryParams = new QueryParams().addParam('datetime', new Date(datetime).toISOString());
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            publicationItemId,
            ApiConstants.API_METHOD_CONTENT,
            ApiConstants.API_METHOD_CAMPAIGN_ITEMS_CHANGES
        ], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, CampaignItemModel);
        });
    }
}
