import {Injectable} from '@angular/core';

@Injectable()
export class RoleDetailContainerService {
    private roleId: string;

    public setRoleId(roleId: string): void {
        this.roleId = roleId;
    }

    public getRoleId(): string {
        return this.roleId;
    }
}
