import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {QueryParams} from '../../classes/query-params';
import {SortDirection} from '@angular/material/sort';
import {PackageModel, PackagePatchModel} from '../../models/api/package.model';
import {PackageItemModel, PackageItemPostModel} from '../../models/api/package-item.model';
import {Cursor} from '../api-cursor';

@Injectable({
    providedIn: 'root'
})
export class PackagesService extends BaseApiRequestService {
    public getPackagesForCampaign(campaignId: string,
                                  limit: number,
                                  offset: number,
                                  cursor?: Cursor,
                                  sortProperty?: string,
                                  sortOrder?: SortDirection,
                                  search?: string,
                                  publicationItem?: string,
                                  assignedToPackage: boolean = false): Observable<ARPagedResponseDataModel<PackageModel>> {
        const queryParams = new QueryParams()
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setLimitAndOffsetParams(limit, offset)
            .setSearchParams(search)
            .setCursor(cursor)
            .addParam('publicationItemId', publicationItem)
            .addParam('assignedToPackage', assignedToPackage);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_PACKAGES
        ], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable(obs => {
            this.handlePagedResponse(options, obs, PackageModel);
        });
    }

    public getPackageForCampaign(campaignId: string, packageId: string): Observable<PackageModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_PACKAGES,
            packageId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable(obs => this.handleDetailResponse(options, obs, PackageModel));
    }

    public patchPackageForCampaign(campaignId: string, packageId: string, patchBody: PackagePatchModel): Observable<PackageModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_PACKAGES,
            packageId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = patchBody;
        return new Observable(obs => this.handleDetailResponse(options, obs, PackageModel));
    }

    public getPackageItemsForCampaignPackage(campaignId: string,
                                             packageId: string,
                                             limit: number,
                                             offset: number,
                                             cursor?: Cursor,
                                             sortProperty?: string,
                                             sortOrder?: SortDirection): Observable<ARPagedResponseDataModel<PackageItemModel>> {
        const queryParams = new QueryParams()
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setLimitAndOffsetParams(limit, offset)
            .setCursor(cursor);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_PACKAGES,
            packageId,
            ApiConstants.API_METHOD_ITEMS
        ], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => this.handlePagedResponse(options, obs, PackageItemModel));
    }

    public patchPackageItem(campaignId: string,
                            packageId: string,
                            packageItemId: string,
                            patchBody: PackagePatchModel): Observable<PackageItemModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_PACKAGES,
            packageId,
            ApiConstants.API_METHOD_ITEMS,
            packageItemId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = patchBody;
        return new Observable(obs => this.handleDetailResponse(options, obs, PackageItemModel));
    }

    public postPackageItem(campaignId: string,
                           campaignPackageId: string,
                           packageItem: PackageItemPostModel): Observable<PackageItemModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_PACKAGES,
            campaignPackageId,
            ApiConstants.API_METHOD_ITEMS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = packageItem;

        return new Observable(obs => {
            this.handleDetailResponse(options, obs, PackageItemModel);
        });
    }

    public deleteCampaignPackage(campaignId: string, packageId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_PACKAGES,
            packageId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public deletePackageItem(campaignId: string, packageId: string, packageItemId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_PACKAGES,
            packageId,
            ApiConstants.API_METHOD_ITEMS,
            packageItemId
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

}
