import {Pipe, PipeTransform} from '@angular/core';
import {EBooleanText} from '../app.enums';

@Pipe({name: 'booleanDisplay'})
export class BooleanDisplayPipe implements PipeTransform {
    private static privatePipe = new BooleanDisplayPipe();

    public static transform(value?: any): string {
        return BooleanDisplayPipe.privatePipe.transform(value);
    }

    /**
     * PipeTransform implementation.
     * Formats the boolean input value to a display value
     * @param {boolean} [value] A boolean value, undefined will return an empty string
     * @returns {string} - Yes, No or empty string value
     */
    public transform(value?: any): string {
        return typeof value === 'boolean' ? value === true ? EBooleanText.TRUE : EBooleanText.FALSE : null;
    }
}
