<ng-container *ngIf="tabs.length > 0; else emptyState">
    <div class="header">
        <h1>Briefing data management</h1>
    </div>
    <nuc-tab-bar [tabs]="tabs"
                 [(activeTab)]="activeTab"
                 (activeTabChange)="onActiveTabChanged($event)">
    </nuc-tab-bar>

    <hr>

    <router-outlet></router-outlet>
</ng-container>

<ng-template #emptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
