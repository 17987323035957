import {Injectable} from '@angular/core';
import {CursorArray} from '../api/api-cursor';
export enum EStateContext {
    PRODUCT_OVERVIEW = 'PRODUCT_OVERVIEW',
    ASSET_OVERVIEW = 'ASSET_OVERVIEW'
}

/**
 * Used as a Singleton to save cursor states between pages for instance overview and its detail page
 */
@Injectable({
    providedIn: 'root'
})
export class StateService {
    public pageIndex: Map<string, number> = new Map();
    public cursorArray: Map<string, CursorArray> = new Map();

    public setPageIndex(context: EStateContext, pageIndex: number): void {
        this.pageIndex.set(context, pageIndex);
    }

    public setCursorArray(context: EStateContext, cursorArray: CursorArray): void {
        this.cursorArray.set(context, cursorArray);
    }

    public getPageIndex(context: EStateContext): number {
        return this.pageIndex.get(context);
    }

    public getCursorArray(context: EStateContext): CursorArray {
        return this.cursorArray.get(context);
    }
}
