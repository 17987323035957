import {autoserialize, inheritSerialization} from 'cerialize';
import {AppConstants} from '../../app.constants';

export enum EJobStatus {
    QUEUED = 'QUEUED',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    FAILED = 'FAILED',
}

export class MonitoredJobModel {
    @autoserialize public _id: string;
    @autoserialize public type: string;

    constructor(_id: string, type: string) {
        this._id = _id;
        this.type = type;
    }

    get title(): string {
        return AppConstants.JOBS[this.type].title;
    }
}

@inheritSerialization(MonitoredJobModel)
export class JobModel extends MonitoredJobModel {
    @autoserialize public result: string;
    @autoserialize public status: EJobStatus;

    constructor(_id: string, type: string, status?: EJobStatus, result?: string) {
        super(_id, type);

        this.status = status;
        this.result = result;
    }
}
