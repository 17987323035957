import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';

export interface IBriefingChange {
    publicationItemId: string;
    campaignItemId: string;
}

@Injectable()
export class CustomWorkflowBriefingChangesDataService implements OnDestroy {
    private selectedBriefingChange = new ReplaySubject<IBriefingChange>(1);
    public selectedBriefingChange$ = this.selectedBriefingChange.asObservable();

    private refreshBriefingChanges = new Subject<void>();
    public refreshBriefingChanges$ = this.refreshBriefingChanges.asObservable();

    private subjects = [
        this.selectedBriefingChange,
        this.refreshBriefingChanges
    ];

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    public announceRefreshBriefingChanges(): void {
        this.refreshBriefingChanges.next();
    }

    public setSelectedBriefingChange(briefingChange: IBriefingChange): void {
        this.selectedBriefingChange.next(briefingChange);
    }
}
