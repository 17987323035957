import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PackageSetupDataFieldModel} from '../../../../../../../../models/api/package-setup.model';
import {AssignToPackageService} from '../assign-to-package.service';
import {takeUntil} from 'rxjs/operators';
import {Toaster} from '../../../../../../../../classes/toaster.class';
import {Subject, Subscription} from 'rxjs';
import {PackageModel} from '../../../../../../../../models/api/package.model';
import {ARApiError} from '@relayter/core';
import {AppConstants} from '../../../../../../../../app.constants';
import {PackagesService} from '../../../../../../../../api/services/packages.service';
import {UserIsAllowedToPipe} from '../../../../../../../../pipes/user-is-allowed-to.pipe';

@Component({
    selector: 'assign-to-package-material-data-form-component',
    templateUrl: 'assign-to-package-material-data-form.component.html',
    styleUrls: ['assign-to-package-material-data-form.component.scss']
})

export class AssignToPackageMaterialDataFormComponent implements OnInit, OnDestroy {
    public readonly permissions = AppConstants.PERMISSIONS;
    public static readonly CONTROL_PREFIX = 'campaignPackage_';

    @Input() public form: UntypedFormGroup;
    @Input() public campaignId: string;
    public dataFieldValues: Record<string, any>;

    public campaignPackage: PackageModel;
    public dataFields: PackageSetupDataFieldModel[];

    public formGroup: UntypedFormGroup;
    public controlName: string;

    public campaignPackageSubscription: Subscription;
    private onDestroySubject = new Subject<void>();

    constructor(private assignToPackageService: AssignToPackageService,
                private packagesService: PackagesService,
                private userIsAllowedToPipe: UserIsAllowedToPipe) {
    }

    public ngOnInit(): void {
        this.assignToPackageService.campaignPackage$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe(
            (campaignPackage) => {
                this.campaignPackage = campaignPackage;
                this.getCampaignPackageDetails();
            },
            (error) => Toaster.handleApiError(error));
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initForm(): void {
        this.controlName = this.campaignPackage ? `${AssignToPackageMaterialDataFormComponent.CONTROL_PREFIX}${this.campaignPackage._id}` : null;
        // remove control if campaign package is changed
        Object.keys(this.form.controls).forEach(key => {
            if (key.startsWith(AssignToPackageMaterialDataFormComponent.CONTROL_PREFIX) && key !== this.controlName) {
                this.form.removeControl(key);
            }
        });

        if (!this.controlName) {
            return;
        }

        if (!this.form.contains(this.controlName)) {
            this.formGroup = new UntypedFormGroup({});
            this.form.addControl(this.controlName, this.formGroup);

            if (this.dataFields?.length) {
                for (const dataField of this.dataFields) {
                    this.formGroup.addControl(dataField.fieldName, new UntypedFormControl());
                }
            }
        } else {
            this.formGroup = this.form.get(this.controlName) as UntypedFormGroup;
        }
    }

    /**
     * Get campaign package details from the api
     */
    private getCampaignPackageDetails(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_PACKAGE_DETAILS)) {
            return;
        }

        if (this.campaignPackageSubscription) {
            this.campaignPackageSubscription.unsubscribe();
        }

        if (!this.campaignId || ! this.campaignPackage) {
            this.dataFields = [];
            this.initForm();
            return;
        }

        this.campaignPackageSubscription = this.packagesService.getPackageForCampaign(this.campaignId, this.campaignPackage._id)
            .subscribe((campaignPackage: PackageModel) => {
                    this.dataFields = campaignPackage.packageSetup.materialDataFields;
                    this.initForm();
                },
                (err: ARApiError) => Toaster.handleApiError(err));
    }
}
