<div class="card-view-container">
    <div class="card-view" *ngFor="let exportOption of allNoteExportTypes">
        <div class="card-content">
            <div>
                <p class="body-strong">{{exportOption.name}}</p>
                <p>{{exportOption.description}}</p>
            </div>

            <div>
                <nuc-radiobutton [dataItems]="[' ']"
                                 [data]="exportOption === selectedExportOption && ' '"
                                 (click)="onNoteExportOptionClicked(exportOption)"></nuc-radiobutton>
            </div>
        </div>
    </div>
</div>
