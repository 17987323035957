import {Component, Input, ViewEncapsulation} from '@angular/core';

export enum EDownloadFileType {
    INDD,
    PDF,
    IMAGE
}

@Component({
    selector: 'download-button',
    templateUrl: './download-button.component.html',
    styleUrls: ['./download-button.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DownloadButtonComponent {
    @Input() public type: EDownloadFileType; // used to set the icon in the download button.

    public readonly DownloadFileType = EDownloadFileType;
}
