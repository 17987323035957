<div class="header-row">
    <h1>{{role?.name}}</h1>
</div>

<ng-container *ngIf="tabs.length > 0; else noPermissionEmptyState">
    <div class="bottom-line">
        <nuc-tab-bar [tabs]="tabs" [(activeTab)]="activeTab"></nuc-tab-bar>
    </div>

    <router-outlet></router-outlet>
</ng-container>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
