<div class="description">
    <nuc-label>Variant data</nuc-label>
    <p>The variant of the data field will be used if available</p>
</div>

<div class="description">
    <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">Variant(s)</nuc-label>
    <nuc-dropdown-multiselect class="variant"
                              placeholder="Select variants"
                              [data]="selectedVariants"
                              (dataChange)="onVariantsChanged($event)"
                              [items]="variants">
    </nuc-dropdown-multiselect>
</div>

<ng-container *ngIf="variantPresetFormArray?.controls.length > 0">
    <hr>

    <form [formGroup]="formGroup">
        <div class="description">
            <nuc-label>Preset per variant</nuc-label>
            <p>It is optional to set a preset per selected variant</p>
        </div>

        <ng-container formArrayName="variantPresets">
            <ng-container *ngFor="let control of variantPresetFormArray.controls; last as isLast">
                <template-variant-preset [formGroup]="control"
                                         [publicationType]="publicationType">
                </template-variant-preset>
                <hr *ngIf="!isLast">
            </ng-container>
        </ng-container>

    </form>
</ng-container>
