<overlay-button icon="nucicon_filter"
                [showIndicator]="activeFilterCount > 0"
                [nucTooltip]="'Filter'"
                [content]="advancedFilters">
</overlay-button>

<ng-template #advancedFilters>
    <div class="content">
        <div class="filters-header">
            <div class="title">
                <h2>Filter</h2>
                <nuc-hint>{{activeFilterCount}} active</nuc-hint>
            </div>
            <div class="buttons">
                <nuc-button-secondary icon="nucicon_reset" [nucTooltip]="'Reset'"
                                      (click)="onReset()"></nuc-button-secondary>
                <nuc-button-primary [disabled]="filtersForm?.pristine || !filtersForm?.valid" text="Apply"
                                    (click)="onApply()"></nuc-button-primary>
            </div>
        </div>
        <form *ngIf="filtersForm" [formGroup]="filtersForm">
            <div *ngFor="let dataFilter of filters" class="data-field-filter-input">
                <div class="filter-field-header">
                    <nuc-label>{{dataFilter.displayName}}</nuc-label>
                </div>
                <div class="input-field"
                     [class.flex-input-field]="dataFilter.modelName === EDataFilterModels.DATA_FILTER && dataFilter.type === FILTER_DATA_TYPES.LIST">
                    <nuc-input *ngIf="dataFilter.type === FILTER_DATA_TYPES.STRING"
                               pattern="^[a-zA-Z0-9].*"
                               type="text"
                               [prefix]="'nucicon_search'"
                               [formControlName]="dataFilter.property"
                               [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)">
                    </nuc-input>

                    <nuc-input *ngIf="dataFilter.type === FILTER_DATA_TYPES.NUMBER"
                               type="number"
                               [prefix]="'nucicon_search'"
                               [formControlName]="dataFilter.property"
                               [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)">
                    </nuc-input>

                    <nuc-dropdown *ngIf="dataFilter.type === FILTER_DATA_TYPES.ENUM"
                                  [formControlName]="dataFilter.property"
                                  [items]="dataFilter.items"
                                  [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)">
                    </nuc-dropdown>

                    <nuc-dropdown *ngIf="dataFilter.type === FILTER_DATA_TYPES.BOOLEAN"
                                  [formControlName]="dataFilter.property"
                                  [items]="booleanDropdownItems"
                                  [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)">
                    </nuc-dropdown>

                    <ng-container *ngIf="dataFilter.modelName === EDataFilterModels.DATA_FILTER">
                        <rl-data-filter-suggestive
                            *ngIf="dataFilter.type === FILTER_DATA_TYPES.LIST"
                            [dataFilter]="dataFilter"
                            [formControlName]="dataFilter.property">
                        </rl-data-filter-suggestive>
                    </ng-container>

                    <ng-container *ngIf="dataFilter.modelName === EDataFilterModels.DATA_FIELD_FILTER">
                        <rl-data-field-multiselect
                            *ngIf="dataFilter.type === FILTER_DATA_TYPES.LIST"
                            [dataFilter]="dataFilter"
                            [formControlName]="dataFilter.property"
                            [requestProperties]="requestOptions">
                        </rl-data-field-multiselect>
                    </ng-container>
                </div>
            </div>
            <div class="data-field-filter-input">
                <div class="input-field">
                    <nuc-checkbox
                        text="Selected publication item only"
                        textPosition="start"
                        [formControlName]="'publicationItem'"
                        [disabled]="false"
                        [required]="false"
                        nucTooltip="This will only show filter results for your currently selected publication item">
                    </nuc-checkbox>
                </div>
            </div>
        </form>

    </div>
</ng-template>
