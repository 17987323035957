/**
 *  rl-validator-password-repeatable
 *  app-relayter-web
 *  Created by nandamhuis on 09/02/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */

import {UntypedFormControl} from '@angular/forms';

/** Export the function so we can use it on code as well */
export function RLRepeatablePasswordValidator(field: UntypedFormControl): void {
    if (field['_parent']) {
        const parent =  field['_parent'];

        if (!parent.get('repeatPassword')) {
            throw new Error('Implement "repeatPassword" field in combination with the repeatable password field');
        }

        parent.get('repeatPassword').updateValueAndValidity();
    }
}
