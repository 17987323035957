import {Component, DestroyRef, Inject, inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogActionModel,
    DialogButtonConfig,
    NUC_DIALOG_CUSTOM_CONTENT_DATA,
    NucDialogCustomContentService
} from '@relayter/rubber-duck';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {WorkflowConfigurationModel} from '../../../../../../models/api/workflow-configuration.model';
import {CustomWorkflowStepModel} from '../../../../../../models/api/custom-workflow-step.model';
import {
    EWorkflowConfigurationJobType,
    IDeleteWorkflowConfigurationStepJobData,
    WorkflowConfigurationsService
} from '../../../../../../api/services/workflow-configurations.service';
import {Toaster} from '../../../../../../classes/toaster.class';

export interface IWorkflowConfigurationStepDeletionDialogData {
    workflowConfiguration: WorkflowConfigurationModel;
    step: CustomWorkflowStepModel;
}

@Component({
    selector: 'workflow-configuration-step-deletion-dialog-component',
    templateUrl: 'workflow-configuration-step-deletion-dialog.component.html',
    styleUrls: ['workflow-configuration-step-deletion-dialog.component.scss']
})
export class WorkflowConfigurationStepDeletionDialogComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private workflowConfiguration: WorkflowConfigurationModel;
    private step: CustomWorkflowStepModel;

    public formGroup: UntypedFormGroup;
    public stepOptions: CustomWorkflowStepModel[];
    private deleteButton: DialogButtonConfig;

    constructor(private workflowConfigurationService: WorkflowConfigurationsService,
                private dialogCustomContentService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) private modalData: IWorkflowConfigurationStepDeletionDialogData) {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.step = this.modalData.step;
    }

    public ngOnInit(): void {
        this.setupPopup();
        this.setupForm();
    }

    private setupForm(): void {
        this.stepOptions = this.workflowConfiguration.steps.filter((step) => step.name !== this.step.name);
        this.formGroup = new FormGroup({
            destinationStep: new FormControl()
        });

        this.formGroup.statusChanges
            .pipe(
                distinctUntilChanged(),
                map((status) => status === 'VALID'),
                takeUntilDestroyed(this.destroyRef))
            .subscribe((isValid) => this.deleteButton.disabled = !isValid);
    }

    private setupPopup(): void {

        this.deleteButton = new DialogButtonConfig(BUTTON_TYPE.DESTRUCTIVE, 'Delete', false, false, true);
        const deleteAction = new DialogActionModel(new Subject<void>(), this.deleteButton);

        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancelAction = new DialogActionModel(new Subject<void>(), cancelButton);

        this.dialogCustomContentService.setDialogActions([cancelAction, deleteAction]);
        deleteAction.observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.deleteButton.loading = true;
            this.postDeleteStepJob();
        });
        cancelAction.observable.subscribe(() => this.dialogCustomContentService.close());
    }

    private postDeleteStepJob(): void {
        const jobData = {
            workflowConfigurationId: this.workflowConfiguration._id,
            stepId: this.step._id,
            destinationStepId: this.formGroup.get('destinationStep').value.getValue()
        } as IDeleteWorkflowConfigurationStepJobData;

        this.workflowConfigurationService
            .postJob(EWorkflowConfigurationJobType.DELETE_WORKFLOW_CONFIGURATION_STEP_JOB, jobData)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (job) => {
                    this.dialogCustomContentService.close(job);
                },
                error: (error) => {
                    Toaster.handleApiError(error);
                    this.deleteButton.loading = false;
                }
            });
    }
}
