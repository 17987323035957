import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {CustomWorkflowActionModel} from '../../../../../../models/api/custom-workflow-action.model';
import {StickyNoteModel} from '../../../../../../models/api/sticky-note.model';
import {FilesRevisionModel, PublicationItemModel} from '../../../../../../models/api/publication-item.model';

@Injectable()
export class CustomWorkflowPreviewDataService implements OnDestroy {
    private actions = new ReplaySubject<CustomWorkflowActionModel[]>(1);
    public actions$ = this.actions.asObservable();

    private publicationItems = new ReplaySubject<PublicationItemModel[]>(1);
    public publicationItems$ = this.publicationItems.asObservable();

    private stickyNotes = new Subject<StickyNoteModel[]>();
    public stickyNotes$ = this.stickyNotes.asObservable();

    private selectedFilesRevision = new ReplaySubject<FilesRevisionModel>(1);
    public selectedFilesRevision$ = this.selectedFilesRevision.asObservable();

    private subjects = [
        this.actions,
        this.publicationItems,
        this.stickyNotes,
    ];

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    // we set actions here
    public setActions(actions: CustomWorkflowActionModel[]): void {
        this.actions.next(actions);
    }

    public setPublicationItems(items: PublicationItemModel[]): void {
        this.publicationItems.next(items);
    }

    public setStickyNotes(stickyNotes: StickyNoteModel[]): void {
        this.stickyNotes.next(stickyNotes);
    }

    public setSelectedFilesRevision(item: FilesRevisionModel): void {
        this.selectedFilesRevision.next(item);
    }
}
