import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupService} from '../../../../api/services/group.service';
import {UserService} from '../../../../api/services/users.service';
import {SortDirection} from '@angular/material/sort';
import {GroupModel} from '../../../../models/api/group.model';
import {UserModel} from '../../../../models/api/user.model';
import {Subject, Subscription} from 'rxjs';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {AppConstants} from '../../../../app.constants';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {takeUntil} from 'rxjs/operators';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {EColumnSize, ESortOrder, ISortOptionEvent, ITableColumn} from '@relayter/rubber-duck';
import {Toaster} from '../../../../classes/toaster.class';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';

@Component({
    selector: 'rl-group-detail-component',
    templateUrl: 'group-detail.component.html',
    styleUrls: ['group-detail.component.scss'],
    providers: [PaginatorService]
})

export class GroupDetailComponent extends RLTableComponent implements OnInit, OnDestroy {
    public tableId = 'content-group-detail';
    private groupId: string;

    public group: GroupModel;
    public groupSubscription: Subscription;

    public users: UserModel[];
    public userSubscription: Subscription;

    public columns: ITableColumn[] = [
        {
            title: 'Full name',
            key: 'fullName',
            size: EColumnSize.LARGE,
            sortProperty: 'fullName'
        },
        {
            title: 'Email',
            key: 'email',
            size: EColumnSize.BASE,
            sortProperty: 'email'
        },
        {
            title: 'Roles',
            key: 'roles',
            size: EColumnSize.BASE,
            format: (roles) => roles?.map(role => role.name).join(', ')
        }
    ];

    public pageIndex: number;
    public pageSize: number;
    public sortProperty: string;
    public sortOrder: SortDirection;
    public totalItemCount: number;
    private onDestroySubject = new Subject<void>();
    public disableNextPage = true;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private groupService: GroupService,
                private userService: UserService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.groupId = this.route.snapshot.params.groupId;

        this.getGroupDetails();
        this.subscribeToRoute();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.updateUrl();
                this.getData();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private subscribeToRoute(): void {
        this.route.params.pipe(takeUntil(this.onDestroySubject)).subscribe((params) => {
            this.pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
            this.sortOrder = params.sortOrder;
            this.sortProperty = params.sortProperty;
            this.searchValue = params.search;

            this.updateUrl();
            this.setPageIndex(this.pageIndex);
        });
    }

    private updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.SETTINGS,
            AppConstants.CONTEXT_URL.CONTENT_GROUPS,
            this.groupId, this.getMatrixUrl()
        ], {replaceUrl: true});
    }

    private getMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    public getGroupDetails(): void {
        this.groupSubscription = this.groupService.getGroup(this.groupId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result) => this.group = result);
    }

    public getData(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_USERS)) return;

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        this.userSubscription = this.userService.getUsers(this.groupId,
            this.pageSize, (this.pageIndex - 1) * this.pageSize,
            this.sortProperty, this.sortOrder, this.searchValue)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result) => {
                    this.users = result.items;
                    this.totalItemCount = result.total;
                    this.disableNextPage = this.pageSize * this.pageIndex >= this.totalItemCount;
                },
                Toaster.handleApiError);
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.setPageIndex();
    }

}
