import { Pipe, PipeTransform } from '@angular/core';
import {PublicationModel} from '../models/api/publication.model';
import {EPublicationType} from '../pages/relayter/templates/template-detail/publication-type.enum';

@Pipe({
  name: 'isPublicationType'
})
export class IsPublicationTypePipe implements PipeTransform {

  public transform(publication: PublicationModel, publicationType: EPublicationType): boolean {
      return publication && publication.channel ?
          publication.channel.name === publicationType : false;
  }
}
