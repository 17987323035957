/**
 * Created by nandamhuis on 09/01/2017.
 */

import {autoserialize} from 'cerialize';

export class Page {
    @autoserialize
    public name: string; // Collection view mode
    @autoserialize
    public actions: string[]; // Title of the items
}
