<ng-container *ngIf="subscription?.closed; else loadingContainer">
    <div class="container" *ngIf="total && total > -1; else emptyState">

        <button class="download-button" *ngIf="downloadEnabled" (click)="openDownloadModal()">Download files ({{total}})</button>
        <div class="spacer" *ngIf="downloadEnabled && uploadEnabled"></div>

        <button class="upload-button" *ngIf="uploadEnabled" (click)="openUploadModal()">Upload files</button>
    </div>
</ng-container>

<ng-template #loadingContainer>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>

<ng-template #emptyState>
    <nuc-empty-state-component
        imageUrl="assets/images/empty_states/files.svg"
        title="No publication items"
        subtitle="Move publication items to this step in order to download/upload the files.">
    </nuc-empty-state-component>
</ng-template>
