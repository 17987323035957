import {EDataFieldTypes} from '../../app.enums';
import {DataFilterModel, EDataFilterModels} from './data-filter.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DataFieldDataTypeEnumerationModel} from '../api/data-field-data-type-enumeration.model';

export class DataFieldFilterModel extends DataFilterModel {
    public dataFieldId: string;
    public enableAutocomplete: boolean;

    constructor(displayName: string, propertyPath: string, type: EDataFieldTypes,
                items: IDropdownItem[], dataFieldId: string, enumeration: DataFieldDataTypeEnumerationModel,
                requestOptions: Record<string, any>, enableAutocomplete: boolean) {
        super(displayName, propertyPath, type, items, enumeration, requestOptions);
        this.modelName = EDataFilterModels.DATA_FIELD_FILTER;
        this.dataFieldId = dataFieldId;
        this.enableAutocomplete = enableAutocomplete;
    }
}
