<form *ngIf="formGroup; else error" [formGroup]="formGroup">
    <nuc-form-field label="Field">
        <nuc-dropdown [items]="propertyOptions"
                      [searchable]="true"
                      (requestData)="searchProperties($event)"
                      [total]="propertyOptions.length"
                      formControlName="property"
                      placeholder="Choose the property"></nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.get('property').value?.enableVariants" label="Variant">
        <nuc-dropdown [items]="variants"
                      [nullOption]="false"
                      [total]="variants.length"
                      formControlName="variant"
                      placeholder="Choose the variant"></nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.get('property').value?.isArray" label="Operator">
        <nuc-dropdown [items]="operators" [total]="operators.length" formControlName="operator" placeholder="Choose the operator"></nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="conditionTypes?.length" label="Type">
        <nuc-dropdown [items]="conditionTypes" [total]="conditionTypes.length" formControlName="type" placeholder="Choose type"></nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="inputType" [ngSwitch]="inputType" label="Value">
        <nuc-input *ngSwitchCase="'number'" formControlName="value" placeholder="Define the number value" type="number"></nuc-input>
        <nuc-input *ngSwitchCase="'string'" formControlName="value" placeholder="Define the string value" type="string"></nuc-input>
        <nuc-dropdown  *ngSwitchCase="'dropdown'"
                       [items]="dropdownItems"
                       [total]="dropdownItems.length"
                       formControlName="value"
                       placeholder="Select option">
        </nuc-dropdown>
        <nuc-datepicker *ngSwitchCase="'date'" formControlName="value"></nuc-datepicker>
    </nuc-form-field>
</form>

<nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteClicked.emit()"></nuc-button-secondary>

<ng-template #error>
    <p>Please pass a FormGroup as Input</p>
</ng-template>
