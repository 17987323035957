import {IFileType} from './file-type.util';
import {AssetModel} from '../models/api/asset.model';

export class AssetsUtil {
    /**
     * Filter assets by file type category
     */
    static filterAssetsByCategory(assets: AssetModel[], category: IFileType) {
        if (!Array.isArray(assets) || !category || !Array.isArray(category.extensions)) {
            return assets;
        }

        return assets.filter(asset => AssetsUtil.isAssetOfCategory(asset, category));
    }

    static isAssetOfCategory(asset: AssetModel, category: IFileType) {
        if (!Array.isArray(category.extensions)) {
            return false;
        }

        return category.extensions.includes(asset.type);
    }
}
