import {Component, OnInit} from '@angular/core';
import {DataFilterComponent} from '../../../../../../../../../components/data-filter/data-filter.component';
import {AdvancedFiltersDataService} from '../../../../../../../../../api/services/advanced-filters.data-service';
import {ActivatedRoute} from '@angular/router';
import {PublicationItemSelectionService} from '../../../../custom-workflow-item-selection/publication-item-selection.service';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {WorkflowLayoutItem} from '../../../../../../../../../models/api/custom-workflow/workflow-layout-item.model';
import {DataFieldsApiService} from '../../../../../../../../../api/services/data-fields.api.service';

@Component({
    selector: 'sticky-list-data-filter',
    templateUrl: './sticky-list-data-filter.component.html',
    styleUrls: ['./sticky-list-data-filter.component.scss']
})
export class StickyListDataFilterComponent extends DataFilterComponent implements OnInit {

    private publicationItemSubscription: Subscription;
    constructor(public dataFieldsService: DataFieldsApiService,
                public advancedFiltersDataService: AdvancedFiltersDataService,
                protected route: ActivatedRoute,
                private publicationItemSelectionService: PublicationItemSelectionService) {
        super(dataFieldsService, advancedFiltersDataService, route);
    }

    public ngOnInit() {
        super.ngOnInit();
        this.filtersForm.addControl('publicationItem', new UntypedFormControl());
    }

    public onApply(): void {
        if (this.filtersForm.get('publicationItem').value) {
            this.filterOnPublicationItem();
        } else {
            this.cancelFilterOnPublicationItem();
        }
    }

    private filterOnPublicationItem(): void {
        if (this.publicationItemSubscription && this.publicationItemSubscription.closed === false) {
            // Subscription already created and not closed
            this.setFilterOnPublicationItem(this.publicationItemSelectionService.getSelectedWorkflowLayoutItem());
            return;
        }
        this.publicationItemSubscription = this.publicationItemSelectionService.selectedWorkflowLayoutItem$
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.onDestroySubject)
            )
            .subscribe((selectedWorkflowLayoutItem) => this.setFilterOnPublicationItem(selectedWorkflowLayoutItem));
    }

    private setFilterOnPublicationItem(selectedItem: WorkflowLayoutItem): void {
        const publicationItemIds = selectedItem ? selectedItem.publicationItems.map(item => item._id) : [];
        // Can set value to null, when no item selected, the subscription is still alive
        this.filtersForm.get('publicationItem').setValue(publicationItemIds.length > 0 ? publicationItemIds : null);
        super.onApply();
    }

    private cancelFilterOnPublicationItem(): void {
        if (this.publicationItemSubscription && this.publicationItemSubscription.closed === false) {
            this.publicationItemSubscription.unsubscribe();
        }
        this.setFilterOnPublicationItem(null);
    }
}
