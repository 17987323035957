import {autoserialize} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {FormatRulesetItemModel} from './format-ruleset-item.model';
import {FormatRulesetAssetItemModel} from './format-ruleset-asset-item.model';
import {FormatRulesetItemGroupModel} from './format-ruleset-item-group.model';

export class FormatRulesetModel implements IDropdownItem {

    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public libraries: string[] = [];
    @autoserialize public items: FormatRulesetItemModel[] = [];
    @autoserialize public assetItems: FormatRulesetAssetItemModel[] = [];
    @autoserialize public itemGroups: FormatRulesetItemGroupModel[] = [];
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}

export class FormatRulesetPatchModel {
    @autoserialize public name: string;
    @autoserialize public libraries: string[];

    constructor(name?: string, libraries?: string[]) {
        this.name = name;
        this.libraries = libraries;
    }
}

export class FormatRulesetPostModel {
    @autoserialize public name: string;
    @autoserialize public libraries: string[];
    @autoserialize public team: string;
    @autoserialize public items: FormatRulesetItemModel[];
    @autoserialize public assetItems: FormatRulesetAssetItemModel[];

    constructor(name?: string,
                libraries?: string[],
                team?: string,
                items?: FormatRulesetItemModel[],
                assetItems?: FormatRulesetAssetItemModel[]) {
        this.name = name;
        this.libraries = libraries;
        this.team = team;
        this.items = items;
        this.assetItems = assetItems;
    }
}
