import {Component, OnDestroy, OnInit} from '@angular/core';
import {Toaster} from '../../../../../classes/toaster.class';
import {Subject, Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../../../../classes/validators/rl-validators.constant';
import {map, take, takeUntil} from 'rxjs/operators';
import {TemplatePresetModel} from './template-preset.model';
import {TemplateTypeModel} from '../../../../../models/api/template-type.model';
import {TemplateTypeService} from '../../../../../api/services/template-types.service';
import {TemplateDataService} from '../template.data-service';
import {EPublicationType} from '../publication-type.enum';
import {MasterPageModel} from '../../../../../models/api/master-page.model';
import {ETemplateType, TemplateSizeModel} from '../template-size/template-size.model';
import {TemplateMarginsModel} from '../../../../../models/api/template-margins.model';
import {DropdownItem} from '../../../../../models/ui/dropdown-item.model';

@Component({
    selector: 'rl-template-preset',
    templateUrl: 'template-preset.component.html',
    styleUrls: ['template-preset.component.scss']
})

export class TemplatePresetComponent implements OnInit, OnDestroy {
    public publicationType: EPublicationType;
    public EPublicationType = EPublicationType;

    public templateTypes: TemplateTypeModel[];

    public templateTypeSubscription: Subscription;
    public addTemplateTypeSubscription: Subscription;

    public formGroup: UntypedFormGroup;

    public tagOptions: DropdownItem<string>[] = [];
    private templateSize: TemplateSizeModel;

    private onDestroySubject = new Subject<void>();

    constructor(private templateDataService: TemplateDataService,
                private templateTypeService: TemplateTypeService) {
    }

    public ngOnInit(): void {
        this.templateDataService.publicationType$.pipe(
            take(1),
            takeUntil(this.onDestroySubject)
        ).subscribe((publicationType) => {
            this.publicationType = publicationType;
            this.initFormGroup();
        });

        this.templateDataService.templateSize$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((templateSize) => this.templateSize = templateSize);

        this.formGroup.get('masterPage').valueChanges.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((masterPage: MasterPageModel) => this.onMasterPageChanged(masterPage));

        this.formGroup.get('showMasterPage').valueChanges.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((showMasterPage: boolean) =>
            this.templateDataService.setBackgroundUrl(showMasterPage && this.formGroup.value.masterPage.files ?
                this.formGroup.value.masterPage.files.preview :
                null));

        this.formGroup.statusChanges.pipe(
            map(() => TemplatePresetModel.fromFormValues(this.formGroup.value)),
            takeUntil(this.onDestroySubject)
        ).subscribe((templatePreset) => this.templateDataService.setTemplatePreset(templatePreset));

        this.templateDataService.templatePreset$.pipe(
            take(1),
            takeUntil(this.onDestroySubject)
        ).subscribe((templatePreset: TemplatePresetModel) => {
            if (templatePreset) {
                this.tagOptions = templatePreset.tags;
                this.formGroup.patchValue(templatePreset);

                if (templatePreset.templateType) {
                    this.formGroup.patchValue({templateType: [templatePreset.templateType]});
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initFormGroup(): void {
        switch (this.publicationType) {
            case EPublicationType.PRINT_MAGAZINE:
            case EPublicationType.WEB:
                this.formGroup = new UntypedFormGroup({
                    name: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                    tags: new UntypedFormControl([]),
                    masterPage: new UntypedFormControl(''),
                    showMasterPage: new UntypedFormControl(true),
                    library: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                    ruleSet: new UntypedFormControl({value: '', disabled: false}, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
                });
                break;
            case EPublicationType.POS:
                this.formGroup = new UntypedFormGroup({
                    name: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                    tags: new UntypedFormControl([]),
                    masterPage: new UntypedFormControl(''),
                    showMasterPage: new UntypedFormControl(true),
                    library: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                    ruleSet: new UntypedFormControl({value: '', disabled: false}, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                    templateType: new UntypedFormControl([], RLValidatorConstants.VALIDATOR_SETS.EXACTLY_ONE)
                });
                break;
        }
    }

    public onMasterPageChanged(masterPage: MasterPageModel): void {
        if (masterPage) {
            const newTemplateSize = new TemplateSizeModel(masterPage.pageSize.width,
                masterPage.pageSize.height,
                new TemplateMarginsModel(masterPage.margins.marginTop, masterPage.margins.marginBottom,
                    masterPage.margins.marginStart, masterPage.margins.marginEnd),
                masterPage.pageCount === 1 ? ETemplateType.Single : ETemplateType.Spread);
            this.templateDataService.setTemplateSize(newTemplateSize);
            this.templateDataService.setBackgroundUrl(this.formGroup.value.showMasterPage && masterPage.files ? masterPage.files.preview : null);
        } else {
            this.templateDataService.setBackgroundUrl(null);
        }
    }

    public onTagChanged(tag: string): void {
        this.tagOptions = tag && tag.length && tag.trim().length ? [new DropdownItem<string>(tag.trim(), tag.trim())] : [];
    }

    public onTemplateTypeSearchChanged(value: string): void {
        if (this.templateTypeSubscription && !this.templateTypeSubscription.closed) {
            this.templateTypeSubscription.unsubscribe();
            this.templateTypes = [];
        }
        this.templateTypeSubscription = this.templateTypeService.getTemplateTypes(100, 0, value)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => this.templateTypes = result.items,
                error:Toaster.handleApiError
            });
    }

    public onTemplateTypeAdded(value: string): void {
        this.templateTypeSubscription?.unsubscribe();

        this.addTemplateTypeSubscription = this.templateTypeService.addNewTemplateType(value)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (templateType) => this.formGroup.patchValue({templateType: [templateType]}),
                error: Toaster.handleApiError
            });
    }

}
