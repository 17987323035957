import {ApiRequest} from './api.request';
import {IPatchBodyModel} from '../../models/interfaces/patch-body-model.interface';
import {ERequestMethod} from '../../app.enums';
import {ARRequestOptions} from '@relayter/core';
import {IResponseModel} from '../../models/interfaces/response-model.interface';
import {IPutBodyModel} from '../../models/interfaces/put-body-model.interface';

export class ApiUpdateRequest extends ApiRequest {
    public apiMethod = ERequestMethod.PATCH;

    protected get urlComponents() {
        return [
            ...super.urlComponents,
            this.itemId
        ];
    };

    constructor(public pathComponents: string[] = [],
                public model: IResponseModel,
                public itemId: string,
                public body: IPatchBodyModel | IPutBodyModel) {
        super(pathComponents, model);
    }

    public get options(): ARRequestOptions {
        const options = super.options;
        options.body = this.body;

        return options;
    }
}
