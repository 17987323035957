<form [formGroup]="transferOwnershipForm">
    <nuc-form-field label="New owner" *ngIf="usersSubscription?.closed; else loading">
        <nuc-dropdown formControlName="newOwner"
                      placeholder="Select the new owner"
                      [items]="users"
                      [limit]="limit"
                      (requestData)="getUsers($event.limit, $event.offset)"
                      [total]="totalUsers"
                      [required]="true"
                      [disabled]="users?.length === 0 || roles?.length === 0">
        </nuc-dropdown>
        <nuc-error *ngIf="transferOwnershipForm.get('newOwner').invalid &&
                          transferOwnershipForm.get('newOwner').dirty &&
                          transferOwnershipForm.get('newOwner').hasError('required')">
            {{validationMessages['newOwner']['required']}}
        </nuc-error>
    </nuc-form-field>

    <nuc-form-field label="Your new role" *ngIf="rolesSubscription?.closed; else loading">
        <nuc-dropdown-multiselect formControlName="newRoles"
                                  placeholder="Select your new role(s)"
                                  [required]="true"
                                  [items]="roles"
                                  [disabled]="users?.length === 0 || roles?.length === 0">
        </nuc-dropdown-multiselect>
        <nuc-error *ngIf="transferOwnershipForm.get('newRoles').invalid &&
                          transferOwnershipForm.get('newRoles').dirty &&
                          transferOwnershipForm.get('newRoles').hasError('required')">
            {{validationMessages['newRoles']['required']}}
        </nuc-error>
    </nuc-form-field>

    <ng-container *ngIf="usersSubscription?.closed && users?.length === 0 || rolesSubscription?.closed && roles?.length === 0;">
        <hr>
        <nuc-label>This action is not allowed:</nuc-label>
        <ul>
            <li class="not-allowed-message black" *ngIf="users?.length === 0">There is no user to transfer ownership
                to
            </li>
            <li class="not-allowed-message black" *ngIf="roles?.length === 0">You can't transfer ownership because
                there
                are no roles available
            </li>
        </ul>
    </ng-container>
</form>

<ng-template #loading>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
