<div class="title">
    <h2>View settings</h2>
</div>
<div class="content">
    <form [formGroup]="dataFieldsFormArray" *ngIf="dataFieldsFormArray && dataFields?.length">
        <div *ngFor="let dataField of dataFields; index as index" class="item">
            <nuc-checkbox *ngIf="dataField.enableVariants"
                          [formControlName]="index"
                          [text]="dataField.name"
                          icon="nucicon_variant"
                          [iconColor]="VARIANT_ICON_COLOR">
            </nuc-checkbox>
            <nuc-checkbox *ngIf="!dataField.enableVariants"
                          [formControlName]="index"
                          [text]="dataField.name">
            </nuc-checkbox>
        </div>
    </form>

    <form [formGroup]="relayterFieldsFormArray" *ngIf="relayterFieldsFormArray && relayterFields?.length" class="relayter-fields-form">
        <div *ngFor="let relayterField of relayterFields; index as index" class="item">
            <nuc-checkbox [formControlName]="index" [text]="relayterField.title"></nuc-checkbox>
        </div>
    </form>
</div>

<ng-container *ngIf="dataSubscription && !dataSubscription.closed">
    <rl-loading-indicator></rl-loading-indicator>
</ng-container>
