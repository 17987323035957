<div class="campaign-info">
    <div class="header">
        <h1>{{campaign?.name}}</h1>

        <nuc-button-bar *ngIf="campaign">
            <nuc-button-secondary
                *ngIf="permissions.PUT_PACKAGES_ITEMS | userIsAllowedTo"
                text="Refresh package data"
                (click)="openRefreshConfirmationDialog()">
            </nuc-button-secondary>

            <nuc-button-secondary
                *ngIf="permissions.POST_CAMPAIGN_JOB | userIsAllowedTo"
                (click)="openExportModal()"
                text="Export package data">
            </nuc-button-secondary>

            <nuc-button-secondary text="Briefing"
                                  (click)="campaignSetupBriefingClicked()"
                                  *ngIf="permissions.GET_CAMPAIGN_ITEMS | userIsAllowedTo" i18n>
            </nuc-button-secondary>

            <div class="popout-button">
                <nuc-button-primary text="Add"
                                    icon="nucicon_dots_vertical"
                                    iconPosition="end"
                                    [matMenuTriggerFor]="menu"
                                    *ngIf="(permissions.POST_CAMPAIGN_JOB | userIsAllowedTo) || (permissions.POST_CAMPAIGN_PUBLICATION | userIsAllowedTo)">
                </nuc-button-primary>

                <mat-menu #menu="matMenu" class="menu-items-container">
                    <button mat-menu-item
                            *ngIf="permissions.POST_CAMPAIGN_PUBLICATION | userIsAllowedTo"
                            (click)="openCreatePublicationModal()"
                            [disableRipple]="true">
                        Add publication
                    </button>
                    <button mat-menu-item
                            *ngIf="permissions.POST_CAMPAIGN_JOB | userIsAllowedTo"
                            (click)="openCreatePackageModal()"
                            [disableRipple]="true">Add packages</button>
                </mat-menu>
            </div>
        </nuc-button-bar>
    </div>

    <div class="detail">
        <p class="body-strong">Status</p>
        <div>
            <nuc-badge-primary>{{getReadableStatus()}}</nuc-badge-primary>
        </div>

        <p class="body-strong">Date</p>
        <p>{{campaign?.startDate | RLDatePipe:dateFormats.TABLE_DATERANGE}} to {{campaign?.endDate | RLDatePipe:dateFormats.TABLE_DATERANGE}}</p>
    </div>
</div>

<nuc-tab-bar [tabs]="tabs"
             [(activeTab)]="activeTab"
             (activeTabChange)="onActiveTabChanged($event)">
</nuc-tab-bar>

<hr>

<router-outlet></router-outlet>
