import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {EFormContext, FormDetailModel, FormModel, FormPostModel} from '../../models/api/form.model';
import {QueryParams} from '../../classes/query-params';
import {SortDirection} from '@angular/material/sort';

@Injectable({
    providedIn: 'root'
})
export class FormService extends BaseApiRequestService {

    private static readonly API_GROUP_FORMS = 'forms';

    public getForms(context?: EFormContext,
                    limit?: number, offset?: number,
                    sortProperty?: string, sortOrder?: SortDirection): Observable<ARPagedResponseDataModel<FormModel>> {

        const queryParams = new QueryParams()
            .addParam('context', context)
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            FormService.API_GROUP_FORMS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, FormModel);
        });
    }

    public getForm(formId: string): Observable<FormDetailModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            FormService.API_GROUP_FORMS,
            formId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, FormDetailModel);
        });
    }

    public postForm(form: FormPostModel): Observable<FormModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            FormService.API_GROUP_FORMS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = form;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, FormModel);
        });
    }

    public putForm(formId: string, form: FormPostModel): Observable<FormModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            FormService.API_GROUP_FORMS,
            formId
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = form;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, FormModel);
        });
    }
}
