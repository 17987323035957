import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MenuItem} from '../../models/ui/menu-item.model';

@Component({
  selector: 'rl-notifications-menu-icon',
  templateUrl: './notifications-menu-icon.component.html',
  styleUrls: ['./notifications-menu-icon.component.scss']
})
export class NotificationsMenuIconComponent {
    @Input() public menuItem: MenuItem;
    @Input() public selectedMenuItem: MenuItem;
    @Input() public currentRoute: string;
    @Input() public unreadIcon: boolean;
    @Output() public onClick = new EventEmitter<MenuItem>();
}
