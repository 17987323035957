import {autoserialize, autoserializeAs} from 'cerialize';
import {DataFieldDataTypeModel} from './data-field-data-type.model';
import {ITableItem} from '@relayter/rubber-duck';
import {IDataFieldTableColumn} from '../interfaces/datafield-table-column.interface';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {IPropertyField} from '../../components/property-settings/property-settings.model';
import {EDataFieldTypes} from '../../app.enums';
import {RLDatePipe} from '../../pipes/rl-date.pipe';

export class DataFieldModel implements ITableItem, IDataFieldTableColumn, IDropdownItem, IPropertyField {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public fieldName: string;
    @autoserializeAs(DataFieldDataTypeModel) public dataType: DataFieldDataTypeModel;
    @autoserialize public collectionName: string;
    @autoserialize public enableVariants = false;
    @autoserialize public showInFilter = false;
    @autoserialize public enableAutocomplete = false;

    getTitle(): string {
        return this.name;
    }

    getValue(): string {
        return this._id;
    }

    public getDataType(): EDataFieldTypes {
        return this.dataType.type;
    }

    public getPropertyField(): string {
        return this.fieldName;
    }

    public getDateFormat(): Record<string, any> {
        return RLDatePipe.dateFormats.DEFAULT;
    }
}

export class DataFieldPatchModel {
    public dataType: DataFieldDataTypeModel;
    public showInFilter: boolean;
    public enableVariants: boolean;
    public enableAutocomplete: boolean;
}
