import {ApiRequest} from './api.request';
import {ERequestMethod} from '../../app.enums';
import {ARRequestOptions} from '@relayter/core';
import {IResponseModel} from '../../models/interfaces/response-model.interface';
import {ICreateBodyModel} from '../../models/interfaces/create-body-model.interface';

export class ApiPostRequest extends ApiRequest {
    public apiMethod = ERequestMethod.POST;

    constructor(public pathComponents: string[] = [],
                public model: IResponseModel,
                public body: ICreateBodyModel) {
        super(pathComponents, model);
    }

    public get options(): ARRequestOptions {
        const options = super.options;
        options.body = this.body;

        return options;
    }
}
