<div class="sub-menu-container">
    <div class="title">
        <h2>{{menuItem?.title}}</h2>
        <nuc-button-secondary tiny="true" icon="nucicon_close" (click)="closeClicked.emit()"></nuc-button-secondary>
    </div>

    <div class="menu-sub-section" *ngFor="let subSection of menuItem?.subMenuSections">

        <div class="section-header menu-sub-section-header" *ngIf="subSection.title">{{subSection.title}}</div>

        <div class="menu-link-container" matRipple *ngFor="let subItem of subSection.subMenuItems">

            <p class="menu-link" *ngIf="!subItem.action" [routerLink]="subItem.route"
               [routerLinkActive]="'active-route'" (click)="closeClicked.emit()">{{subItem.title}}</p>

            <p class="menu-link" *ngIf="subItem.action"
               (click)="handleSubItemAction(subItem); closeClicked.emit()">{{subItem.title}}</p>

        </div>
    </div>
</div>
