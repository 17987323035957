import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {PackagesService} from '../../api/services/packages.service';
import {PackageModel, PackagePatchModel} from '../../models/api/package.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Toaster} from '../../classes/toaster.class';

export interface IPackageFormData {
    campaignId: string;
    package: PackageModel;
}

@Component({
    selector: 'package-form-component',
    templateUrl: 'package-form.component.html',
    styleUrls: ['package-form.component.scss']
})

export class PackageFormComponent implements OnInit, OnDestroy {
    private saveButton: ButtonConfig;
    private onDestroySubject = new Subject<void>();
    public formGroup: UntypedFormGroup;

    constructor(private packagesService: PackagesService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IPackageFormData) {
    }

    public ngOnInit(): void {
        this.setupForm();
        this.initButtons();
        this.trackFormStatus();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private setupForm(): void {
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl(this.modalData.package.name, Validators.required),
            description: new UntypedFormControl(this.modalData.package.description)
        });
    }

    private initButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, this.formGroup.status !== 'VALID');
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close());
        saveAction.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.saveButton.disabled = true;
            this.editPackage();
        });

        const actions = [cancelAction, saveAction];
        this.fullModalService.setModalActions(actions);
    }

    private trackFormStatus(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === 'VALID'),
            takeUntil(this.onDestroySubject)
        ).subscribe((isValid: boolean) => this.saveButton.disabled = !isValid);
    }

    private editPackage(): void {
        const body = new PackagePatchModel(this.formGroup.value.name.trim(), this.formGroup.value.description);
        this.packagesService.patchPackageForCampaign(this.modalData.campaignId, this.modalData.package._id, body)
            .subscribe((result) => {
                this.fullModalService.close(result);
                Toaster.success('Campaign package updated successfully');
            }, (error) => {
                Toaster.handleApiError(error);
                this.saveButton.loading = false;
                this.saveButton.disabled = false;
            });
    }
}
