<form *ngIf="form" [formGroup]="form">
    <nuc-form-field label="InDesign library name">
        <nuc-input formControlName="name"
                   placeholder="InDesign library name">
        </nuc-input>
        <nuc-error *ngIf="form.touched && form.controls.name.errors?.required">{{validationMessages.name.required}}</nuc-error>
    </nuc-form-field>

    <rl-upload-file-component
        title="Library file"
        (onUploadUpdate)="uploadUpdateSubject.next($event)"
        [fileTypeCategories]="fileTypeCategories">
    </rl-upload-file-component>
</form>
