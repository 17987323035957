export class LinkedAssetsToProductsModel {
    public selectedAssets: string[];
    public selectedProducts: string[];

    /**
     * @constructor
     * @param {string[]} selectedAssets
     * @param {string[]} selectedProducts
     */
    constructor(selectedAssets: string[], selectedProducts: string[]) {
        this.selectedAssets = selectedAssets;
        this.selectedProducts = selectedProducts;
    }
}
