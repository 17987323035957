import {ItemBackgroundPixi} from './item-background.pixi';

export class NoteItemBackgroundPixi extends ItemBackgroundPixi {

    protected createBackgroundBody(width: number, height: number, color?: number): PIXI.Graphics {
        const body = new PIXI.Graphics();

        body.beginFill(color || ItemBackgroundPixi.BACKGROUND_COLOR, 0.1);
        body.lineStyle(2 / this.scaleRatio, color || ItemBackgroundPixi.BACKGROUND_COLOR, 1);
        body.drawRect(0, 0, width, height);
        body.endFill();
        return body;
    }

    protected addHighlightBorder(width: number, height: number): void {
        this.highlightBorder = new PIXI.Graphics();
        this.highlightBorder.lineStyle(2 / this.scaleRatio, ItemBackgroundPixi.HIGHLIGHT_BORDER_COLOR);
        this.highlightBorder.drawRect(0, 0, width, height);
        this.highlightBorder.alpha = 0;
    }
}
