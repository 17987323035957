import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {TemplateContentModel} from './template-content/template-content.model';
import {ETemplateType, TemplateSizeModel} from './template-size/template-size.model';
import {TemplatePresetModel, TemplateVariantPresetModel} from './template-preset/template-preset.model';
import {TemplateBodyModel, TemplateDetailModel} from '../../../../models/api/template.model';
import {map, startWith} from 'rxjs/operators';
import {EPublicationType} from './publication-type.enum';
import {TemplateMarginsModel} from '../../../../models/api/template-margins.model';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {TemplateVariantModel} from './template-variant/template-variant.model';

@Injectable()
export class TemplateDataService {
    private backgroundUrlSubject = new BehaviorSubject<string>(null);
    public backgroundUrl$ = this.backgroundUrlSubject.asObservable();

    private contentsSubject = new ReplaySubject<TemplateContentModel[]>(1);
    public contents$ = this.contentsSubject.asObservable();

    private selectedContentIndexSubject = new ReplaySubject<number>(1);
    public selectedContentIndex$ = this.selectedContentIndexSubject.asObservable();

    private hoveredContentIndexSubject = new ReplaySubject<number>(1);
    public hoveredContentIndex$ = this.hoveredContentIndexSubject.asObservable();

    private templateSizeSubject = new ReplaySubject<TemplateSizeModel>(1);
    public templateSize$ = this.templateSizeSubject.asObservable();

    private templatePresetSubject = new ReplaySubject<TemplatePresetModel>(1);
    public templatePreset$ = this.templatePresetSubject.asObservable();

    private templateVariantSubject = new ReplaySubject<TemplateVariantModel>(1);
    public templateVariant$ = this.templateVariantSubject.asObservable();

    private publicationTypeSubject = new ReplaySubject<EPublicationType>(1);
    public publicationType$ = this.publicationTypeSubject.asObservable();

    private collisionsSubject = new ReplaySubject<Set<TemplateContentModel>>(1);
    public collisions$ = this.collisionsSubject.asObservable();

    public template$: Observable<TemplateBodyModel> =
        combineLatest([this.publicationType$, this.templatePreset$, this.templateSize$, this.templateVariant$, this.contents$, this.collisions$])
            .pipe(
                map(([publicationType, preset, size, templateVariant, contents, collisions]) => {
                    return preset.isValid(publicationType)
                            && size.isValid(publicationType)
                            && templateVariant.isValid()
                            && !!contents && contents.length && collisions.size === 0 ?
                        TemplateBodyModel.templateFromTemplateEditorModels(publicationType, preset, size, templateVariant, contents) : null;
                }),
                startWith(null)
            );

    private resetSelectionSubjects(): void {
        this.selectedContentIndexSubject.next(-1);
        this.hoveredContentIndexSubject.next(-1);
        this.backgroundUrlSubject.next(null);
    }

    public setBackgroundUrl(signedUrl: string): void {
        this.backgroundUrlSubject.next(signedUrl);
    }

    public setContents(contents: TemplateContentModel[]): void {
        this.contentsSubject.next(contents);
    }

    public setSelectedContentIndex(index: number): void {
        this.selectedContentIndexSubject.next(index);
    }

    public setHoveredContentIndex(index: number): void {
        this.hoveredContentIndexSubject.next(index);
    }

    public setTemplateSize(templateSize: TemplateSizeModel): void {
        this.templateSizeSubject.next(templateSize);
    }

    public setTemplatePreset(templatePreset: TemplatePresetModel): void {
        this.templatePresetSubject.next(templatePreset);
    }

    public setTemplateVariant(templateVariant: TemplateVariantModel): void {
        this.templateVariantSubject.next(templateVariant);
    }

    // this is used to check overlapping areas
    public setCollisions(collisions: Set<TemplateContentModel>): void {
        this.collisionsSubject.next(collisions);
    }

    public initWithDefaults(publicationType: EPublicationType): void {
        this.setTemplatePreset(new TemplatePresetModel());
        this.templateSizeSubject.next(TemplateSizeModel.defaultSize(publicationType));
        this.initDefaultContents(TemplateSizeModel.defaultSize(publicationType), publicationType);
        this.collisionsSubject.next(new Set());
        this.templateVariantSubject.next(new TemplateVariantModel([]));
        this.resetSelectionSubjects();
    }

    public initFromTemplate(template: TemplateDetailModel): void {
        this.initPresetsFromTemplate(template);
        this.initSizesFromTemplate(template);
        this.initContentsFromTemplate(template);
        this.collisionsSubject.next(new Set());
        this.initVariantsFromTemplate(template);
        this.resetSelectionSubjects();
    }

    public setPublicationType(publicationType: EPublicationType): void {
        this.publicationTypeSubject.next(publicationType);
    }

    private initPresetsFromTemplate(template: TemplateDetailModel): void {
        const templateTags = template.tags ? template.tags.map((tag) => new DropdownItem(tag, tag)) : [];
        const presetModel = new TemplatePresetModel(template.name, templateTags, template.masterPage, template.indesignLibrary,
                                                    template.formatRuleset, template.templateType);
        this.setTemplatePreset(presetModel);
    }

    private initSizesFromTemplate(template: TemplateDetailModel): void {
        const sizeModel = new TemplateSizeModel(
            template.pageSize.width,
            template.pageSize.height,
            new TemplateMarginsModel(template.margins.marginTop, template.margins.marginBottom,
                template.margins.marginStart, template.margins.marginEnd),
            template.pages === 1 ? ETemplateType.Single : ETemplateType.Spread);
        this.templateSizeSubject.next(sizeModel);
    }

    private initContentsFromTemplate(template: TemplateDetailModel): void {
        const contents = template.areas.map((area) => {
            return new TemplateContentModel(
                area.position.x,
                area.position.y,
                area.size.width,
                area.size.height,
                area.columns,
                area.rows,
                area.columnGutter,
                area.rowGutter,
                area._id,
            );
        });
        this.contentsSubject.next(contents);
    }
    private initVariantsFromTemplate(template: TemplateDetailModel): void {
        const presets = template.variantPresets?.map(preset => {
            return new TemplateVariantPresetModel(preset.variant, preset.masterPage, preset.indesignLibrary, preset.formatRuleset);
        }) || [];
        this.templateVariantSubject.next(new TemplateVariantModel(presets));
    }

    private initDefaultContents(sizeModel: TemplateSizeModel, publicationType: EPublicationType): void {
        const contents = [EPublicationType.POS, EPublicationType.WEB].includes(publicationType) ?
            [TemplateContentModel.formSingleContentFromTemplateSizeModel(sizeModel)] : [];
        this.contentsSubject.next(contents);
    }
}
