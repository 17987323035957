import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuComponent} from './menu.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {SubMenuComponent} from './sub-menu/sub-menu.component';
import {RDModule} from '@relayter/rubber-duck';
import {MatRippleModule} from '@angular/material/core';
import {RouterModule} from '@angular/router';
import {NotificationsSubMenuComponent} from './notifications-sub-menu/notifications-sub-menu.component';
import {SubMenuContainerComponent} from './sub-menu-container/sub-menu-container.component';
import {MenuIconComponent} from './menu-icon/menu-icon.component';
import {ComponentsModule} from '../components/components.module';
import {NotificationsMenuIconComponent} from './notifications-menu-icon/notifications-menu-icon.component';
import {UnreadNotificationsPipe} from './unread-notifications.pipe';
import {NotificationItemComponent} from './notifications-sub-menu/notification-item/notification-item.component';
import {ExpiresInPipe} from './notifications-sub-menu/notification-item/expires-in.pipe';
import {NotificationIconPipe} from './notifications-sub-menu/notification-item/notification-icon.pipe';
import {FirstSentencePipe} from './notifications-sub-menu/notification-item/first-sentence.pipe';
import {PipesModule} from '../pipes/pipes.module';

const components = [
    MenuComponent,
    SubMenuComponent,
    NotificationsSubMenuComponent,
    NotificationItemComponent,
    SubMenuContainerComponent,
    MenuIconComponent,
    NotificationsMenuIconComponent,
    UnreadNotificationsPipe,
    ExpiresInPipe,
    NotificationIconPipe,
    FirstSentencePipe,
];

@NgModule({
    declarations: components,
    imports: [
        CommonModule,
        OverlayModule,
        PortalModule,
        RDModule,
        MatRippleModule,
        RouterModule,
        ComponentsModule,
        PipesModule
    ],
    exports: [
        MenuComponent,
    ]
})
export class MenuModule {
}
