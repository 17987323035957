import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {PackageRuleModel, PackageSetupModel, PackageSetupPatchModel} from '../../models/api/package-setup.model';
import {QueryParams} from '../../classes/query-params';
import {SortDirection} from '@angular/material/sort';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-jobs.service';

export enum EPackageSetupJobType {
    PACKAGE_SETUP_RULE_COPY_JOB = 'PACKAGE_SETUP_RULE_COPY_JOB',
    PACKAGE_SETUP_COPY_JOB = 'PACKAGE_SETUP_COPY_JOB'
}

@Injectable({
    providedIn: 'root'
})
export class PackageSetupService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    public postPackageSetup(packageSetup: PackageSetupModel): Observable<PackageSetupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = packageSetup;

        return new Observable(obs => {
            this.handleDetailResponse(options, obs, PackageSetupModel);
        });
    }

    public getPackageSetups(limit: number,
                            offset: number,
                            sortProperty?: string,
                            sortOrder?: SortDirection,
                            search?: string): Observable<ARPagedResponseDataModel<PackageSetupModel>> {
        const queryParams = new QueryParams()
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setLimitAndOffsetParams(limit, offset)
            .setSearchParams(search);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable(obs => {
            this.handlePagedResponse(options, obs, PackageSetupModel);
        });
    }

    public getPackageSetup(packageSetupId: string): Observable<PackageSetupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS, packageSetupId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable(obs => {
            this.handleDetailResponse(options, obs, PackageSetupModel);
        });
    }

    public postPackageSetupRule(packageSetupId: string, packageSetupRuleBody: PackageRuleModel): Observable<PackageSetupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS,
            packageSetupId,
            ApiConstants.API_GROUP_PACKAGE_SETUPS_RULES
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = packageSetupRuleBody;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, PackageSetupModel);
        });

    }

    public putPackageSetupRule(packageSetupId: string, ruleId: string,
                               packageSetupRuleBody: PackageRuleModel): Observable<PackageSetupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS,
            packageSetupId,
            ApiConstants.API_GROUP_PACKAGE_SETUPS_RULES,
            ruleId
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = packageSetupRuleBody;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, PackageSetupModel);
        });
    }

    public deletePackageSetupRule(packageSetupId: string, packageSetupRuleId: string): Observable<PackageSetupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS,
            packageSetupId,
            ApiConstants.API_GROUP_PACKAGE_SETUPS_RULES,
            packageSetupRuleId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, PackageSetupModel);
        });
    }

    public patchPackageSetup(packageSetupId, packageSetup: PackageSetupPatchModel): Observable<PackageSetupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS,
            packageSetupId
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = packageSetup;

        return new Observable(obs => {
            this.handleDetailResponse(options, obs, PackageSetupModel);
        });
    }

    public deletePackageSetup(packageSetupId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS,
            packageSetupId
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;

        return new Observable(obs => this.handleNoErrorResponse(options, obs));
    }

    public postJob(jobType: EPackageSetupJobType, jobData: Record<string, any>): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS,
            ApiConstants.API_METHOD_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
        );
    }

    public getPackageSetupRules(packageSetupId: string,
                                limit?: number,
                                offset?: number,
                                sortProperty?: string,
                                sortOrder?: SortDirection,
                                search?: string): Observable<ARPagedResponseDataModel<PackageRuleModel>> {
        const queryParams = new QueryParams().setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setSearchParams(search);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PACKAGE_SETUPS,
            packageSetupId,
            ApiConstants.API_GROUP_PACKAGE_SETUPS_RULES
        ], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable(obs => {
            this.handlePagedResponse(options, obs, PackageRuleModel);
        });
    }

}
