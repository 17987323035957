<ng-container *ngIf="dataFields && form; else loadingIndicator">

    <div class="data-fields-container" cdkDropList cdkScrollable>

        <div *ngFor="let dataField of (dataFields | dataFieldFilter: rows)"
             class="data-field"
             cdkDrag
             (cdkDragData)="dataField"
             (cdkDragStarted)="dragging = true; dropAction = null"
             (cdkDragDropped)="onDrop($event, dataField)">

            <img [src]="dataField.dataType.type | dataFieldFormIcon" />

            <p class="body-strong text-ellipsis">{{dataField.name}}</p>
        </div>

    </div>

    <div class="form-builder-container" cdkScrollable>

        <nuc-button-bar>
            <nuc-button-secondary (click)="open = !open"
                                  [nucTooltip]="open ? 'Minimize' : 'Maximize'"
                                  [icon]="open ? 'nucicon_minimize' : 'nucicon_maximize'">
            </nuc-button-secondary>
        </nuc-button-bar>

        <nuc-empty-state-component *ngIf="!dragging && !rows.length"
            imageUrl="assets/images/empty_states/forms.svg"
            title="Setup your form"
            subtitle="Drag in data fields from the left to update the form">
        </nuc-empty-state-component>

        <div class="form-builder"
             cdkDropList
             cdkDropListLockAxis="y"
             cdkDropListOrientation="vertical"
             (cdkDropListDropped)="onRowDropped($event)">

            <div class="form-row" *ngIf="dragging">

                <rl-drop-location (mouseenter)="dropAction = {type: EDropActionType.NEW_TOP}"
                                  (mouseleave)="dropAction = null"
                                  text="Drop data field"
                                  [style.grid-column]="2">
                </rl-drop-location>

            </div>

            <div class="form-row" *ngFor="let row of rows" cdkDrag>
                <div *cdkDragPlaceholder class="row-placeholder">
                    <rl-drop-location text="Insert here" [hoverEnabled]="false"></rl-drop-location>
                </div>

                <rl-drop-location *ngIf="dragging && row.fields.length !== 3"
                                  (mouseenter)="dropAction = {type: EDropActionType.INSERT_LEFT, row: row}"
                                  (mouseleave)="dropAction = null"
                                  text="Drop"
                                  [left]="true">
                </rl-drop-location>

                <div class="items">
                    <rl-form-field *ngFor="let field of row.fields"
                                   (onDeleteButtonClick)="onDeleteFieldClicked(field, row)"
                                   [open]="open"
                                   [formGroup]="form.controls[field.fieldName]"
                                   [dataField]="field">
                    </rl-form-field>
                </div>

                <rl-drop-location *ngIf="dragging && row.fields.length !== 3"
                                  (mouseenter)="dropAction = {type: EDropActionType.INSERT_RIGHT, row: row}"
                                  (mouseleave)="dropAction = null"
                                  text="Drop"
                                  [right]="true">
                </rl-drop-location>

                <nuc-button-secondary cdkDragHandle icon="nucicon_drag-and-drop" *ngIf="!dragging"></nuc-button-secondary>

            </div>


            <div class="form-row" *ngIf="dragging && rows.length">

                <rl-drop-location (mouseenter)="dropAction = {type: EDropActionType.NEW_BOTTOM}"
                                  (mouseleave)="dropAction = null"
                                  text="Drop data field"
                                  [style.grid-column]="2">
                </rl-drop-location>

            </div>

        </div>

    </div>
</ng-container>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
