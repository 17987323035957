<div class="container" *ngIf="addPagesAction; else emptyState">
    <div *ngFor="let pageType of pageTypes">
        <div class="smooth-animated" [class]="pageType.type | lowercase" (click)="openAddPageModal(pageType)"></div>
        <div class="button-link">{{pageType.title}}</div>
    </div>
</div>

<ng-template #emptyState>
    <nuc-empty-state-component
        imageUrl="assets/images/empty_states/no_permission.svg"
        title="No permission"
        subtitle="You don't have permissions to add pages.">
    </nuc-empty-state-component>
</ng-template>
