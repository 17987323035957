<nuc-tab-bar [tabs]="tabBarItems" [(activeTab)]="selectedTab"></nuc-tab-bar>
<hr class="tab-bar-hr"/>

<ng-container [ngSwitch]="selectedTab.index">
    <ng-container  *ngSwitchCase="TAB_PACKAGE_TYPE">
        <files-download-package-type-component *ngIf="actions"
            [actions]="actions"
            [(selectedPackageType)]="selectedPackageType">
        </files-download-package-type-component>
    </ng-container>

    <files-download-item-selection-component  *ngSwitchCase="TAB_ITEMS"
        class="items-container"
        [activeFilters]="modalData.activeFilters"
        [publication]="modalData.publication"
        [step]="modalData.step"
        [activeVariant]="modalData.variant"
        [(selectedPublicationItems)]="selectedPublicationItems">
    </files-download-item-selection-component>
</ng-container>
