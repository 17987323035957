<div class="rule-progress" [style.background-image]="'url(/assets/images/' + imageName + ')'"></div>
<div [formGroup]="form">
    <div class="rule-conditions-form" [formArrayName]="FORM_GROUP_NAME">
        <p class="section-header">Conditions</p>
        <p class="explanation">Set up one or more conditions.</p>
        <div class="condition-field" *ngFor="let control of conditionsFormArray.controls; index as index">
            <condition-form
                [formGroup]="control"
                [condition]="ruleConditions[index]"
                (deleteClicked)="onDeleteCondition(index)"
                [variants]="variants"
                [ruleProperties]="ruleProperties">
            </condition-form>
        </div>
        <nuc-button-secondary class="add-condition" text="Add condition" icon="nucicon_add" iconPosition="end"
                              (click)="onAddConditionClicked()"></nuc-button-secondary>
    </div>
</div>
