import {ProductModel} from '../../models/api/product.model';
import {AssetModel} from '../../models/api/asset.model';
import {DataFieldModel} from '../../models/api/data-field.model';
import {PropertySetting} from '../property-settings/property-settings.model';
import {EDataFieldTypes} from '../../app.enums';
import {RLDatePipe} from '../../pipes/rl-date.pipe';

export class InformationDetailModel extends PropertySetting {
    private static DATE_FORMAT = RLDatePipe.dateFormats.TABLE_DETAILED;

    public isTag?: boolean;

    static createInformationDetails(item: ProductModel | AssetModel, dataFields: DataFieldModel[]): InformationDetailModel[] {
        const details: InformationDetailModel[] = [];
        if (item instanceof ProductModel) {
            details.push(...InformationDetailModel.getDataFieldInformationDetails(item, dataFields));
            if (item.tags?.length > 0) {
                const tagsProperty = new InformationDetailModel('Tags', 'tags', EDataFieldTypes.LIST);
                tagsProperty.isTag = true;
                details.push(tagsProperty);
            }
            details.push(...[
                new InformationDetailModel('RAN', 'ran', EDataFieldTypes.STRING),
                new InformationDetailModel('Date modified', 'updatedAt', EDataFieldTypes.DATE, InformationDetailModel.DATE_FORMAT),
                new InformationDetailModel('Date created', 'createdAt', EDataFieldTypes.DATE, InformationDetailModel.DATE_FORMAT)
            ]);
        } else if (item instanceof AssetModel) {
            details.push(...InformationDetailModel.getDataFieldInformationDetails(item, dataFields));
            details.push(...[
                new InformationDetailModel('File type', 'fileInfo', EDataFieldTypes.STRING),
                new InformationDetailModel('Resolution', 'resolution', EDataFieldTypes.STRING),
                new InformationDetailModel('Print size', 'printSizeName', EDataFieldTypes.STRING),
                new InformationDetailModel('Date modified', 'updatedAt', EDataFieldTypes.DATE, InformationDetailModel.DATE_FORMAT),
                new InformationDetailModel('Date created', 'createdAt', EDataFieldTypes.DATE, InformationDetailModel.DATE_FORMAT)
            ]);
        }

        return details;
    }

    private static getDataFieldInformationDetails(item: ProductModel|AssetModel, dataFields: DataFieldModel[]): InformationDetailModel[] {
        const details = [];

        if (!item.dataFields || !dataFields) {
            return details;
        }

        for (const field of dataFields) {
            if (typeof item.dataFields[field.fieldName] === 'boolean' || item.dataFields[field.fieldName]) {
                details.push(new PropertySetting(field.name, `dataFields.${field.fieldName}`, field.dataType.type));
            }
        }

        return details;
    }
}
