<form [formGroup]="form" *ngIf="form">
    <nuc-form-field label="Event">
        <nuc-input formControlName="event"
                   type="string"
                   placeholder="Fill in the name of the mediaValet webhook event">
        </nuc-input>
    </nuc-form-field>
    <nuc-form-field label="Categories">
        <nuc-suggestive-input
            [options]="categories"
            formControlName="categories"
            placeholder="Fill in the categories you want to keep in sync">
        </nuc-suggestive-input>
    </nuc-form-field>
    <nuc-form-field label="Meta data fields">
        <p class="description">Name of the meta data fields have to be the same as the fields from the imported file.
            Fields that do not match will not be imported.
        </p>
        <ng-container *ngIf="metaDataFormArray?.length > 0 else emptyState">
            <div class="form-array" formArrayName="metaData">
                <div class="form-group" [formGroupName]="i" *ngFor="let mappingForm of metaDataFormArray.controls; let i = index">
                    <div class="field">
                        <nuc-dropdown formControlName="dataFieldName"
                                      [items]="dataFieldOptions"
                                      [total]="dataFieldOptions.length"
                                      placeholder="Select asset data"
                                      required>
                        </nuc-dropdown>
                        <nuc-error *ngIf="mappingForm.get('dataFieldName').invalid &&
                          mappingForm.get('dataFieldName').dirty &&
                          mappingForm.get('dataFieldName').hasError('duplicateValues')">
                            {{VALIDATOR_MESSAGES.UNIQUE}}
                        </nuc-error>
                    </div>
                    <div class="connector">
                        <div class="connector-image" [class.inactive]="!mappingForm.valid"></div>
                    </div>
                    <div class="field">
                        <nuc-input formControlName="name"
                                   type="string"
                                   placeholder="Provide asset data column">
                        </nuc-input>
                        <nuc-error *ngIf="mappingForm.get('name').invalid &&
                          mappingForm.get('name').dirty &&
                          mappingForm.get('name').hasError('duplicateValues')">
                            {{VALIDATOR_MESSAGES.UNIQUE}}
                        </nuc-error>
                    </div>
                    <nuc-button-secondary icon="nucicon_trash_fill" (click)="onDeleteDataClicked(i)"></nuc-button-secondary>
                </div>
            </div>
        </ng-container>

        <ng-template #emptyState>
            <nuc-empty-state-component title="This section is empty. Add the first item."
                                       [small]="true"
                                       imageUrl="assets/images/empty_states/packages-selection.svg">
            </nuc-empty-state-component>
        </ng-template>
        <div class="button-row">
            <nuc-button-secondary text="Add meta data" (click)="onAddDataClicked()"></nuc-button-secondary>
        </div>
    </nuc-form-field>
</form>
