import {autoserialize} from 'cerialize';

/**
 * UserTeamModel - Defined to prevent circular dependencies on user -> team -> owner (= UserModel)
 */
export class UserTeamModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public teamHash: string;
}
