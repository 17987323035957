<form [formGroup]="formGroup">
    <nuc-form-field class="span-2" label="Type" *ngIf="publicationType === EPublicationType.PRINT_MAGAZINE">
        <nuc-radiobutton formControlName="templateType"
                         [dataItems]="[ETemplateType.Single, ETemplateType.Spread]"
                         [disabled]="!!templatePreset?.masterPage"
                         radiobuttonPosition="horizontal">
        </nuc-radiobutton>
    </nuc-form-field>

    <nuc-form-field label="Width">
        <nuc-input formControlName="width" type="number" [disabled]="!!templatePreset?.masterPage"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Height">
        <nuc-input formControlName="height" type="number" [disabled]="!!templatePreset?.masterPage"></nuc-input>
    </nuc-form-field>

    <ng-container formGroupName="margins">
        <p class="section-header span-2">Margins</p>
        <nuc-form-field class="span-2">
            <nuc-checkbox formControlName="allMarginsTheSame" text="All sides are the same" [disabled]="!!templatePreset?.masterPage"></nuc-checkbox>
        </nuc-form-field>

        <nuc-form-field label="Top">
            <nuc-input formControlName="marginTop" type="number" [disabled]="!!templatePreset?.masterPage"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Bottom">
            <nuc-input formControlName="marginBottom" type="number" [disabled]="!!templatePreset?.masterPage"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="{{formGroup.value.templateType === ETemplateType.Single ? 'Left' : 'Inside'}}">
            <nuc-input formControlName="marginStart" type="number" [disabled]="!!templatePreset?.masterPage"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="{{formGroup.value.templateType === ETemplateType.Single ? 'Right' : 'Outside'}}">
            <nuc-input formControlName="marginEnd" type="number" [disabled]="!!templatePreset?.masterPage"></nuc-input>
        </nuc-form-field>
    </ng-container>
</form>
