import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {QueryParams} from '../../classes/query-params';
import {MasterPageModel, MasterPagePatchModel} from '../../models/api/master-page.model';
import {SortDirection} from '@angular/material/sort';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';
import {TemplateModel} from '../../models/api/template.model';
import {MonitoredJobsService} from './monitored-jobs.service';

export enum EMasterPageJobType {
    ADD_MASTER_PAGE = 'ADD_MASTER_PAGE',
    UPDATE_MASTER_PAGE = 'UPDATE_MASTER_PAGE',
}

export interface IUpdateMasterPageJobData {
    name: string;
    s3Key: string;
    itemId: string;
}

export interface IAddMasterPageJobData {
    name: string;
    s3Key: string;
    publicationType: string;
}

@Injectable({
    providedIn: 'root'
})
export class MasterPagesService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    public postJob(jobType: EMasterPageJobType, jobData: IAddMasterPageJobData | IUpdateMasterPageJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_MASTER_PAGES,
            ApiConstants.API_METHOD_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
        );
    }

    public getMasterPage(masterPageId: string): Observable<MasterPageModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_MASTER_PAGES, masterPageId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, MasterPageModel);
        });
    }

    public getMasterPages(
        publicationType?: EPublicationType,
        limit?: number,
        offset?: number,
        sort?: string,
        sortType?: SortDirection,
        search?: string): Observable<ARPagedResponseDataModel<MasterPageModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sort, sortType)
            .setSearchParams(search);

        if (publicationType) {
            queryParams.addParam('publicationType', publicationType);
        }

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_MASTER_PAGES], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, MasterPageModel);
        });
    }

    public getMasterPageUsage(masterPageId: string, limit?: number, offset?: number): Observable<ARPagedResponseDataModel<TemplateModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_MASTER_PAGES, masterPageId, ApiConstants.API_METHOD_USAGE], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, TemplateModel);
        });
    }

    public deleteMasterPage(masterPageId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_MASTER_PAGES, masterPageId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public patchMasterPage(masterPageId: string, body: MasterPagePatchModel): Observable<MasterPageModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_MASTER_PAGES, masterPageId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = body;
        return new Observable((obs) => this.handleDetailResponse(options, obs, MasterPageModel));
    }
}
