import {ISerializable} from 'cerialize';

/**
 * id-serializer
 * app-relayter-web
 * Created by borisnelissen on 27/12/2018.
 * Copyright © 2018 Creative Media Network. All rights reserved.
 */

export class IdSerializer implements ISerializable {

    public Serialize(json: any): any {
        return json._id;
    }

    public Deserialize(): any {
        return null;
    }
}
