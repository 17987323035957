import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AppConstants} from '../../../../app.constants';
import {IPaginationEvent} from '../../../../components/paginator/paginator.component';

@Injectable()
export class CampaignDetailService implements OnDestroy {
    private publicationAddedSubject = new Subject<void>();
    public publicationAdded$: Observable<void> = this.publicationAddedSubject.asObservable();

    private packageAddedSubject = new Subject<void>();
    public packageAdded$: Observable<void> = this.packageAddedSubject.asObservable();

    private publicationsPaginationSubject = new BehaviorSubject<IPaginationEvent>({
        pageSize: AppConstants.PAGE_SIZE_DEFAULT,
        pageIndex: AppConstants.PAGE_INDEX_DEFAULT} as IPaginationEvent);

    private subjects = [
        this.publicationAddedSubject,
        this.packageAddedSubject,
        this.publicationsPaginationSubject
    ];

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    public onPublicationAdded(): void {
        this.publicationAddedSubject.next();
    }

    public onPackageAdded(): void {
        this.packageAddedSubject.next();
    }
}
