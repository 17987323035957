import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConstants} from '../../app.constants';
import {UserSettingsStorageService} from './user-settings-storage.service';

export interface ITableConfigStorage {
    tableId: string;
    storageKey: string;
}

@Injectable({
    providedIn: 'root'
})
export class TableConfigService {

    private static readonly LOCAL_STORAGE_KEY_TABLE_PAGE_SIZE: string = 'table_page_size';

    // TODO: Rename to table_columns when we remove the old table config
    private static readonly LOCAL_STORAGE_KEY_TABLE_COLUMNS: string = 'table_columns_new';

    protected constructor(private userSettingsStorageService: UserSettingsStorageService) {}

    /**
     * @param tableId - the table for which we are getting the stored pageSize (defaults to 20)
     */
    public getStoredPageSize(tableId: string): Observable<number> {
        return new Observable((obs) => {
            const storedPageSizeConfig = this.userSettingsStorageService.loadSettings(TableConfigService.LOCAL_STORAGE_KEY_TABLE_PAGE_SIZE);

            if (!storedPageSizeConfig || !storedPageSizeConfig[tableId]) {
                obs.next(AppConstants.PAGE_SIZE_DEFAULT);
                return obs.complete();
            }

            obs.next(storedPageSizeConfig[tableId]);
            obs.complete();
        });
    }

    /**
     *
     * @param tableId - the table for which we are storing the pageSize
     * @param pageSize
     */
    public storePageSize(tableId: string, pageSize: number): void {
        let storedPageSizeConfig = this.userSettingsStorageService.loadSettings(TableConfigService.LOCAL_STORAGE_KEY_TABLE_PAGE_SIZE);

        if (!storedPageSizeConfig) {
            storedPageSizeConfig = {};
        }

        storedPageSizeConfig[tableId] = pageSize;
        this.userSettingsStorageService.storeSettings(TableConfigService.LOCAL_STORAGE_KEY_TABLE_PAGE_SIZE, storedPageSizeConfig);
    }

    public getSelectedColumnNames(tableId: string): Observable<string[] | null> {
        return new Observable<string[]>((obs) => {
                const tableConfig = this.userSettingsStorageService.loadSettings(TableConfigService.LOCAL_STORAGE_KEY_TABLE_COLUMNS);

                if (!tableConfig || !tableConfig[tableId]) {
                    obs.next();
                    return obs.complete();
                }

                obs.next(tableConfig[tableId]);
                obs.complete();
            }
        );
    }

    public storeColumnNames(tableId: string, columns: string[]): void {
        let tableConfig = this.userSettingsStorageService.loadSettings(TableConfigService.LOCAL_STORAGE_KEY_TABLE_COLUMNS);

        if (!tableConfig) {
            tableConfig = {};
        }

        tableConfig[tableId] = columns;
        this.userSettingsStorageService.storeSettings(TableConfigService.LOCAL_STORAGE_KEY_TABLE_COLUMNS, tableConfig);
    }
}
