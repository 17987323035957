<span class="button-wrapper" id="button-wrapper" (click)="clickAnimation.beginElement()">
    <svg version="1.1" id="wrapper" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 52 55" style="enable-background:new 0 0 55 55;" xml:space="preserve">

    <defs>
        <filter id="shadow">
            <feDropShadow dx="0" dy="1" stDiviation="0.2" flood-opacity="0.3"/>
        </filter>
    </defs>
    <g class="test-fill">
        <g id="Layer_1_00000145049705839642624940000005430178395308294820_" class="box-shadow">
            <path class="st0 test-fill" d="M11.1,1h21.6c1,0,1.9,0.4,2.7,1.1l8.1,8.1c0.7,0.7,1.1,1.7,1.1,2.7v34.4c0,2.1-1.7,3.8-3.8,3.8H11.1
                c-2.1,0-3.8-1.7-3.8-3.8V4.8C7.4,2.7,9.1,1,11.1,1z"/>
        </g>
        <g id="Layer_2_00000175312047920007686200000017196327493697204117_">
            <animate
                id="startHover"
                attributeName="visibility"
                values="hidden"
                dur=".1s"
                begin="wrapper.mouseenter"
                fill="freeze"
            />
            <animate
                attributeName="visibility"
                to="visible"
                dur=".1s"
                fill="freeze"
                begin="stopHover.end"
            />

                <g>
                    <g>
                        <path class="st1" d="M40.9,51.5H11.1c-2.3,0-4.2-1.9-4.2-4.2V4.8c0-2.3,1.9-4.2,4.2-4.2h21.6c1.1,0,2.2,0.4,3,1.2l8.1,8.1
                            c0.8,0.8,1.2,1.9,1.2,3v34.4C45.1,49.6,43.2,51.5,40.9,51.5z M11.1,1.5C9.3,1.5,7.9,3,7.9,4.8v42.5c0,1.8,1.5,3.2,3.2,3.2h29.8
                            c1.8,0,3.2-1.5,3.2-3.2V12.9c0-0.9-0.3-1.7-1-2.3L35,2.5c-0.6-0.6-1.4-1-2.3-1H11.1z"
                              fill="white">
                        </path>
                    </g>
                    <ng-container [ngSwitch]="type">
                        <g id="pdf" *ngSwitchCase="DownloadFileType.PDF">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6119 30.6036C21.1113 31.497 20.617 32.3271 20.2685 32.7453C20.1481
                                32.8594 19.9897 32.9291 19.7806 32.9291C19.3814 32.9291 19.0709 32.6186 19.0709 32.2194C19.0709 32.0293 19.1533 31.8455
                                19.261 31.7251C19.6792 31.402 20.5853 31.0154 21.6119 30.6036ZM32.2257 27.4226C32.625 27.4226 32.9354 27.7331 32.9354
                                28.1323C32.9354 28.5315 32.625 28.842 32.2257 28.842C31.4273 28.842 30.2867 28.4808 29.3743 27.9739C30.4198 27.6824 31.5604
                                27.4226 32.2257 27.4226ZM25.2428 25.1477C25.7751 25.9968 26.4024 26.7889 27.0867 27.4923C26.0349 27.7774 25.002 28.1513
                                23.9881 28.5378C24.476 27.4289 24.8689 26.282 25.2428 25.1477ZM24.9386 19.0646C25.3378 19.0646 25.6483 19.375 25.6483
                                19.7743C25.6483 20.3065 25.3632 21.2887 25.0337 22.3469C24.5901 21.3204 24.2289 20.3319 24.2289 19.7743C24.2289 19.375 24.5394
                                19.0646 24.9386 19.0646ZM24.9386 18C23.9628 18 23.158 18.8048 23.158 19.7806C23.158 20.9909 23.8297 22.499 24.5394
                                23.9121C23.9818 25.6483 23.3545 27.5113 22.5497 29.0764C20.9022 29.7228 19.4321 30.2044 18.5513 30.9141C18.5386 30.9267 18.5259
                                30.9457 18.5133 30.9584C18.1901 31.2879 18 31.7378 18 32.2194C18 33.1952 18.8048 34 19.7806 34C20.2558 34 20.7184 33.8226 21.0479
                                33.4804C21.0606 33.4741 21.0733 33.4614 21.0796 33.455C21.7323 32.6756 22.499 31.2689 23.177 29.9826C24.7485 29.3616 26.4024
                                28.7343 27.9929 28.3541C29.1525 29.2919 30.838 29.9129 32.2193 29.9129C33.1952 29.9129 34 29.1081 34 28.1323C34 27.1564
                                33.1952 26.3517 32.2193 26.3517C31.1104 26.3517 29.4946 26.7509 28.2653 27.1628C27.2642 26.225 26.3453 25.0527 25.6863
                                23.8044C26.1552 22.3533 26.7065 20.8958 26.7065 19.7679C26.7129 18.7984 25.9145 18 24.9386 18Z"
                                      fill="#FA0F00"/>
                        </g>
                         <g id="indd" *ngSwitchCase="DownloadFileType.INDD">
                            <path class="st2" d="M32.8,17.5h-2.4c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1v4.1c-0.3,0-0.5,0-0.8,0c-3.8,0-6.1,2.8-6.1,6.5
                                c0,4.3,2.4,6.3,5.7,6.3c1.4,0,2.7-0.3,3.9-0.9c0.1-0.1,0.1-0.1,0.1-0.2c-0.1-0.5-0.1-1-0.1-1.5V17.6
                                C32.9,17.5,32.9,17.5,32.8,17.5z M30.2,31.9c-0.3,0.1-0.7,0.2-1.1,0.2c-1.7,0-3.1-1.2-3.1-4c0-2.5,1.3-4,3.3-4
                                c0.4,0,0.7,0,0.9,0.2V31.9z"/>
                            <path class="st2" d="M18.6,17.5c-0.1,0-0.2,0-0.2,0.2v16.6c0,0.1,0,0.2,0.2,0.2h2.1c0.1,0,0.2,0,0.2-0.2V17.7
                                c0-0.1-0.1-0.2-0.2-0.2H18.6z"/>
                        </g>
                        <g id="image" *ngSwitchCase="DownloadFileType.IMAGE">
                            <path
                                d="M22.1288 25.0489L19.606 29.0411C19.343 29.4573 19.642 30 20.1343 30H32.0301C32.4845 30 32.787 29.5305 32.5993 29.1168L29.477 22.2349C29.2565 21.7491 28.5679 21.745 28.3417 22.2282L26.3347 26.5157C26.1638 26.8809 25.697 26.991 25.3809 26.7409L23.0449 24.8927C22.7538 24.6623 22.3271 24.7351 22.1288 25.0489Z"
                                fill="url(#paint0_linear_2850_3773)"/>
                            <circle cx="23" cy="21" r="1" fill="url(#paint1_linear_2850_3773)"/>
                            <defs>
                                <linearGradient id="paint0_linear_2850_3773" x1="26" y1="21" x2="26" y2="30"
                                                gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#40C38C"/>
                                    <stop offset="1" stop-color="#3FBB87"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_2850_3773" x1="23" y1="20" x2="23" y2="22"
                                                gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#40C38C"/>
                                    <stop offset="1" stop-color="#3FBB87"/>
                                </linearGradient>
                            </defs>
                        </g>
                    </ng-container>
                </g>
        </g>
        <g id="Layer_3" class="st3" visibility="hidden">

            <animate
                attributeName="visibility"
                from="hidden"
                to="visible"
                dur=".1s"
                fill="freeze"
                begin="startHover.begin"
            />

            <animate
                attributeName="visibility"
                to="hidden"
                fill="freeze"
                begin="stopHover.end"
            />
            <g class="st4">
                <path class="st5" d="M40.9,51.5H11.1c-2.3,0-4.2-1.9-4.2-4.2V4.8c0-2.3,1.9-4.2,4.2-4.2h21.6c1.1,0,2.2,0.4,3,1.2l8.1,8.1
                    c0.8,0.8,1.2,1.9,1.2,3v34.4C45.1,49.6,43.2,51.5,40.9,51.5z M11.1,1.5C9.3,1.5,7.9,3,7.9,4.8v42.5c0,1.8,1.5,3.2,3.2,3.2h29.8
                    c1.8,0,3.2-1.5,3.2-3.2V12.9c0-0.9-0.3-1.7-1-2.3L35,2.5c-0.6-0.6-1.4-1-2.3-1H11.1z"/>
            </g>
            <g class="st4">
                <mask maskUnits="userSpaceOnUse" x="18.7" y="16" width="14.6" height="30"
                      id="mask0_158_7283_00000022551284694008448090000016848395785351100568_">M18,34h16v2H18V34z
                    <path class="st6" x="18.7" y="16" width="14.6" height="20" d="M 18 44 H 33.6 V 14 H 18.7 L 18 44"
                          fill="red"/>

                </mask>
                <g class="st7">
                    <path class="st5 arrow" y="0" d="M33.2,23.8l-6.8,6.9c-0.2,0.2-0.5,0.2-0.7,0l0,0l-6.8-6.9c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.4-0.1
                            h3.4V16h6.9v6.9h3.4c0.3,0,0.5,0.2,0.5,0.5C33.3,23.6,33.2,23.7,33.2,23.8z">
                        <animateMotion
                            dur=".3s"
                            path="M 0 -16, V 2, V 0"
                            fill="freeze"
                            repeatCount="1"
                            begin="startHover.end"/>
                        <animateMotion
                            id="stopHover"
                            dur=".2s"
                            repeatCount="1"
                            path="M 0 0, V -16"
                            fill="freeze"
                            begin="wrapper.mouseleave"/>
                        <animateMotion
                            #clickAnimation
                            bergin="wrapper.click"
                            id="clickAnimation"
                            dur=".2s"
                            repeatCount="1"
                            path="M 0 0, V 30"/>
                    </path>
                </g>
                <mask maskUnits="userSpaceOnUse"
                      id="mask_lines">M18,34h16v2H18V34z
                        <path class="st6" d="M 6 34 H 44 V 38 H 6 L 6 34"
                              fill="#ff0000"/>

                </mask>
                <g class="st8">
                    <rect x="2" y="36" class="st5" width="11" height="2" fill-opacity="1">
    <!--                    hover start-->
                        <animate
                            id="test"
                            attributeName="x"
                            values="2;15;12;15"
                            dur=".3s"
                            repeatCount="1"
                            fill="freeze"
                            begin="startHover.end"
                        />
                        <!--                    hover stop-->
                        <animate
                            attributeName="x"
                            values="15;8"
                            dur=".2s"
                            repeatCount="1"
                            fill="freeze"
                            begin="stopHover.begin"/>
                        <!--                    Click-->
                        <animate
                            attributeName="x"
                            values="15;8"
                            dur=".2s"
                            repeatCount="1"
                            begin="clickAnimation.begin"/>
                        <animate
                            attributeName="width"
                            values="11;0"
                            dur=".2s"
                            repeatCount="1"
                            begin="clickAnimation.begin"/>
                    </rect>

                    <rect x="50" y="36" class="st5" width="11" height="2" fill-opacity="1">
                        <animate
                            attributeName="x"
                            values="50;26;29;26"
                            dur=".3s"
                            repeatCount="1"
                            fill="freeze"
                            begin="startHover.end"/>
                        <!--                    Stop hover-->
                        <animate
                            attributeName="x"
                            values="26;33"
                            dur=".2s"
                            repeatCount="1"
                            fill="freeze"
                            begin="stopHover.begin"/>
                        <!--                    Click-->
                        <animate
                            attributeName="x"
                            values="26;42"
                            dur=".2s"
                            repeatCount="1"
                            begin="clickAnimation.begin"/>
                        <animate
                            attributeName="width"
                            values="11;0"
                            dur=".2s"
                            repeatCount="1"
                            begin="clickAnimation.begin"/>
                    </rect>
                    </g>
            </g>
        </g>
    </g>
</svg>

</span>
