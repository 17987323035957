import {ARUserStorage} from '@relayter/core';
import {UserModel} from '../models/api/user.model';
import {Deserialize, Serialize} from 'cerialize';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

/**
 * TODO - Remove dependency from external Aurora library or move ARUserStorage to Relayter
 */
export class UserStorage extends ARUserStorage {
    private static subject: Subject<UserModel> = new Subject<UserModel>();
    private static clientIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(UserStorage.getClientId());

    private static accessTokenSubject = new Subject<string>();

    /**
     * Set a user object in the localstorage
     * @param {UserModel} user
     */
    public static setUser(user: UserModel): void {
        const userString = JSON.stringify(Serialize(user));
        localStorage.setItem(this.STORE_KEYS.USER, userString);
        this.subject.next(user);
    }

    /**
     * Get a user object from the localstorage
     */
    public static getUser(): UserModel {
        const user = localStorage.getItem(this.STORE_KEYS.USER);
        const json = JSON.parse(user);
        return Deserialize(json, UserModel);

    }

    /**
     * Clear the user in the localstorage
     */
    public static removeUser(): void {
        localStorage.removeItem(this.STORE_KEYS.USER);
        this.subject.next(null);
    }

    /**
     * Get a user object from the localstorage
     * @returns {Observable<UserModel>}
     */
    public static getSubject(): Observable<UserModel> {
        return this.subject.asObservable();
    }

    /**
     * Set auth0 client id
     */
    public static setClientId(clientId: string): void {
        localStorage.setItem('CLIENT_ID', clientId);
        this.clientIdSubject.next(clientId);
    }

    /**
     * Get the auth0 client id from the localstorage
     */
    public static getClientId(): string {
        return localStorage.getItem('CLIENT_ID');
    }

    /**
     * Clear the auth0 client id in the localstorage
     */
    public static removeClientId(): void {
        localStorage.removeItem('CLIENT_ID');
        this.clientIdSubject.next(null);
    }

    /**
     * Get a client id string from the localstorage
     * @returns {Observable<string>}
     */
    public static getClientIdSubject(): Observable<string> {
        return this.clientIdSubject.asObservable();
    }

    /**
     * Method override. In addition to setting the token, it notifies observers that a new token is available
     * @param accessToken
     */
    public static setAccessToken(accessToken): void {
        super.setAccessToken(accessToken);
        this.accessTokenSubject.next(accessToken);
    }

    /**
     * Method override. In addition to removing the token, it notifies observers that the token is removed
     */
    public static removeAccessToken(): void {
        super.removeAccessToken();
        this.accessTokenSubject.next(null);
    }

    public static getAccessTokenUpdates(): Observable<string> {
        return this.accessTokenSubject.asObservable();
    }
}
