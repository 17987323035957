import {Directive, ElementRef} from '@angular/core';

export enum EScrollPosition {
    TOP,
    MIDDLE
}

@Directive({
    selector: '[scrollIntoView]'
})
/**
 * Adds option to scrollable view items container to scroll to given index
 * @example
 * <div style="overflow: scroll">
 *     <div class="items-container" scrollIntoView>
 *         <nuc-small-card-view *ngFor="let item of publicationItems; index as index">
 *              <div class="text-data-content" content>
 *                  {{(item | publicationItemDisplay: EPublicationDisplayProperties.TITLE) | nullUndefinedFormatter}}
 *              </div>
 *         </nuc-small-card-view>
 *     </div>
 * </div>
 */
export class ScrollItemIntoViewDirective {

    private scrollView: ElementRef;

    constructor(private el: ElementRef) {
        this.scrollView = el;
    }

    /**
     * Scroll the given item to the center of the scroll window
     * @param {number} index
     * @param {EScrollPosition} position
     */
    public scrollToActiveItem(index: number, position = EScrollPosition.MIDDLE): void {
        if (index < 0) {
            return;
        }

        const selectedItem = this.scrollView.nativeElement.children[index];
        if (!selectedItem) return; // prevent nullPointers if the status of the list items change mid scroll

        // Scroll view can be embedded in other view, so we need to check its host offset to the top
        const hostOffsetTop = this.scrollView.nativeElement.parentElement ? this.scrollView.nativeElement.parentElement.offsetTop : 0;
        const parentOffsetTop = this.scrollView.nativeElement.offsetTop;
        // Correct for parent
        const offsetTop = selectedItem.offsetTop - parentOffsetTop;
        const itemHeight = selectedItem.getBoundingClientRect().height;
        const containerHeight = this.scrollView.nativeElement.parentElement.clientHeight;
        // Calculate scroll, correct for offset of the parent
        let scrollTo;
        switch (position) {
            case EScrollPosition.TOP:
                scrollTo = offsetTop - (hostOffsetTop - parentOffsetTop);
                break;
            case EScrollPosition.MIDDLE:
            default:
                // Get offset of the item in the scroll view
                const centerOffset = (containerHeight / 2) - (itemHeight / 2);
                scrollTo = offsetTop - (hostOffsetTop - parentOffsetTop) - centerOffset;
                break;
        }
        this.scrollView.nativeElement.parentElement.scrollTo(0, scrollTo);
    }
}
