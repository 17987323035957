import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppConstants} from '../../../app.constants';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {TabBarRoutableItem} from './tab-bar-routable-item.model';
import {Subject} from 'rxjs';
import {RLBaseComponent} from '../../../components/rl-base-component/rl-base.component';
import {filter, takeUntil} from 'rxjs/operators';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';

@Component({
    selector: 'rl-team-settings-component',
    templateUrl: 'team-settings.component.html',
    styleUrls: ['team-settings.component.scss']
})

export class TeamSettingsComponent extends RLBaseComponent implements OnInit, OnDestroy {

    public generalTab = new TabBarRoutableItem(
        'General',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.TEAM_SETUP, AppConstants.CONTEXT_URL.GENERAL_INFORMATION],
        [AppConstants.PERMISSIONS.GET_TEAM_DETAILS]
    );

    private connectionsTab = new TabBarRoutableItem(
        'Connections', [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.TEAM_SETUP, AppConstants.CONTEXT_URL.CONNECTIONS],
        [AppConstants.PERMISSIONS.GET_CONNECTIONS]
    );

    private masterBriefingsTab = new TabBarRoutableItem(
        'Master briefing', [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.TEAM_SETUP, AppConstants.CONTEXT_URL.MASTER_BRIEFING],
        [AppConstants.PERMISSIONS.GET_CAMPAIGN_ITEM]
    );

    public tabs = [this.generalTab, this.connectionsTab, this.masterBriefingsTab];
    public activeTab: TabBarRoutableItem;

    private onDestroySubject: Subject<void> = new Subject<void>();

    constructor(private router: Router, private userIsAllowedToPipe: UserIsAllowedToPipe) {
        super();
        this.setActiveTab(this.router.url);
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this.onDestroySubject)
            ).subscribe((event: NavigationEnd) => {
                this.setActiveTab(event.url);
            });
    }

    private setActiveTab(url: string): void {
        if (url === `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.TEAM_SETUP}/${AppConstants.CONTEXT_URL.GENERAL_INFORMATION}`) {
            this.activeTab = this.tabs[0];
        } else if (url === `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.TEAM_SETUP}/${AppConstants.CONTEXT_URL.CONNECTIONS}`) {
            this.activeTab = this.tabs[1];
        } else if (url ===
            `/${AppConstants.CONTEXT_URL.SETTINGS}/${AppConstants.CONTEXT_URL.TEAM_SETUP}/${AppConstants.CONTEXT_URL.MASTER_BRIEFING}`
        ) {
            this.activeTab = this.tabs[2];
        }
    }

    public ngOnInit(): void {
        this.tabs = this.tabs.filter((tab) => this.userIsAllowedToPipe.transform(tab.permissions));
    }

    public onActiveTabChanged(newTab: ITabBarItem): void {
        if (newTab) {
            this.activeTab = this.tabs.find((tab) => tab.title === newTab.title);
            this.updateUrl();
        }
    }

    public updateUrl(): void {
        if (this.activeTab) {
            this.router.navigate(this.activeTab.url, {skipLocationChange: false});
        }
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
