<div class="header" [class.open]="open">

    <div class="title-container">
        <img [src]="dataField.dataType.type | dataFieldFormIcon" />
        <p class="body-strong">{{dataField.name}}</p>
    </div>

    <div class="buttons">
        <nuc-button-secondary (click)="onDeleteButtonClick.emit()" tiny="true" icon="nucicon_trash_fill"></nuc-button-secondary>
    </div>

</div>

<form [formGroup]="formGroup" class="settings" *ngIf="open">

    <nuc-form-field>
        <nuc-checkbox text="Editable" formControlName="editable"></nuc-checkbox>
    </nuc-form-field>

    <p class="section-header" *ngIf="dataField.dataType.type !== EDataFieldTypes.BOOLEAN">Settings</p>

    <ng-container [ngSwitch]="dataField.dataType.type">

        <ng-container *ngSwitchCase="EDataFieldTypes.NUMBER">

            <nuc-form-field label="Placeholder">
                <nuc-input formControlName="placeholder"></nuc-input>
            </nuc-form-field>

        </ng-container>

        <ng-container *ngSwitchCase="EDataFieldTypes.DATE">

            <nuc-form-field label="Placeholder">
                <nuc-input formControlName="placeholder"></nuc-input>
            </nuc-form-field>

        </ng-container>

        <ng-container *ngSwitchCase="EDataFieldTypes.ENUM">

            <nuc-form-field label="Placeholder">
                <nuc-input formControlName="placeholder"></nuc-input>
            </nuc-form-field>

        </ng-container>

        <ng-container *ngSwitchCase="EDataFieldTypes.STRING">

            <nuc-form-field label="Placeholder">
                <nuc-input formControlName="placeholder"></nuc-input>
            </nuc-form-field>

            <nuc-form-field>
                <nuc-checkbox text="Multiline" formControlName="multiline"></nuc-checkbox>
            </nuc-form-field>

        </ng-container>

        <ng-container *ngSwitchCase="EDataFieldTypes.LIST">

            <nuc-form-field label="Placeholder">
                <nuc-input formControlName="placeholder"></nuc-input>
            </nuc-form-field>

        </ng-container>

    </ng-container>

</form>
