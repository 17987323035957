<form [formGroup]="formGroup">

    <nuc-form-field label="Name">
        <nuc-input formControlName="name" placeholder="The name of the campaign"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Tags">
        <nuc-suggestive-input
            formControlName="tags"
            (searchTermChange)="onTagChanged($event)"
            [options]="tagOptions"
            placeholder="Add tags">
        </nuc-suggestive-input>
    </nuc-form-field>

    <nuc-form-field label="Status">
        <nuc-dropdown
            formControlName="status"
            placeholder="The status of the campaign"
            [items]="statusOptions">
        </nuc-dropdown>
    </nuc-form-field>

    <form [formGroup]="dateFormGroup">
        <nuc-form-field label="Campaign Dates">

            <div class="date-container">
                <nuc-datepicker
                    formControlName="startDate"
                    placeholder="Pick a start date">
                </nuc-datepicker>

                <nuc-datepicker
                    formControlName="endDate"
                    placeholder="Pick an end date">
                </nuc-datepicker>
            </div>

            <nuc-error *ngIf="dateFormGroup.errors">{{dateFormGroup.errors.dateError}}</nuc-error>

        </nuc-form-field>
    </form>

    <nuc-form-field *ngIf="variants?.length" label="Variants" icon="nucicon_variant" iconColorClass="variant-highlight">
        <nuc-dropdown-multiselect
            [placeholder]="modalData.campaign ? 'No variants' : 'Select variants'"
            formControlName="variants"
            [items]="variants">
        </nuc-dropdown-multiselect>
    </nuc-form-field>
</form>
