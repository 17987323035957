import {SortDirection} from '@angular/material/sort';

export class MatrixUrlParams {
    public pageIndex: number;
    public pageSize: number;
    public sortProperty: string;
    public sortOrder: SortDirection;
    public search: string;

    constructor(pageIndex?: number, pageSize?: number, sortProperty?: string, sortOrder?: SortDirection, search?: string) {
        if (pageIndex) {
            this.pageIndex = pageIndex;
        }
        if (pageSize) {
            this.pageSize = pageSize;
        }
        if (sortProperty) {
            this.sortProperty = sortProperty;
        }
        if (sortOrder) {
            this.sortOrder = sortOrder;
        }
        if (search) {
            this.search = search;
        }
    }
}
