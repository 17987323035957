/**
 *  country.util
 *  app-relayter
 *  Created by nandamhuis on 30/01/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */
import * as countriesProvider from 'i18n-iso-countries';
import {AppConstants} from '../app.constants';
import {CountryModel} from '../models/ui/country.model';
import {environment} from '../../environments/environment';

export class CountryUtil {
    private static countryArray: CountryModel[];
    private static countries: Record<string, string>;
    private static langCode: string;
    private static countryCode: string;

    /**
     * Get the locale from the localstorage, save the country and language code and fill an array with country models from the countries provider
     */
    public static initialize(): void {
        const code = localStorage.getItem(AppConstants.LOCALSTORAGE_LOCALE) || environment.DEFAULT_LOCALE;
        this.langCode = code.substring(0, code.indexOf('-')).toLowerCase();
        this.countryCode = code.substring(code.indexOf('-') + 1).toUpperCase();
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        countriesProvider.registerLocale(require(`i18n-iso-countries/langs/${this.langCode}.json`));
        this.countries = countriesProvider.getNames(this.langCode);
        this.countryArray = [];

        for (const key of Object.keys(this.countries)) {
            const value = this.countries[key];
            this.countryArray.push(new CountryModel({title: value, code: key}));
        }
    }

    /**
     * Get countries array
     * @returns {Array<CountryModel>}
     */
    public static getCountryArray(): CountryModel[] {
        return this.countryArray;
    }

    /**
     * Get countries array
     * @returns {CountryModel}
     */
    public static getCountryWithCode(code: string): CountryModel {
        return this.countryArray.find((country) => {
            return country.code === code;
        });
    }
}
