import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {StatisticsPeriodTotalModel} from '../../models/api/statistics-period-total.model';
import {BaseApiRequestService} from './base-api-request.service';
import {QueryParams} from '../../classes/query-params';

enum EStatisticsPeriod  {
    YEAR = 'YEAR',
    MONTH = 'MONTH',
    DAY = 'DAY'
}

@Injectable({
    providedIn: 'root'
})
export class StatisticsService extends BaseApiRequestService {
    static EStatisticsPeriod = EStatisticsPeriod;

    /**
     * Get collection creation statistics grouped by period.
     *
     * @param {string} period
     */
    public getPeriodTotals(period: string): Observable<ARPagedResponseDataModel<StatisticsPeriodTotalModel>> {
        const queryParams = new QueryParams();
        queryParams.addParam('period' , period);
        queryParams.addParam('collection', 'RLPublicationItem');
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_STATISTICS
        ], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleArrayResponse(options, obs, StatisticsPeriodTotalModel);
        });
    }

    public getStorage(period: string): Observable<ARPagedResponseDataModel<StatisticsPeriodTotalModel>> {
        const queryParams = new QueryParams();
        queryParams.addParam('period' , period);
        queryParams.addParam('collection', 'RLFile');
        queryParams.addParam('field', 'size');
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_STATISTICS
        ], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleArrayResponse(options, obs, StatisticsPeriodTotalModel);
        });
    }

}
