<div [formGroup]="optionForm" class="form-row" *ngIf="optionForm">
    <nuc-form-field class="data-field" label="Data field">
        <nuc-dropdown [items]="dataFields"
                      searchable="true"
                      placeholder="Select data field"
                      [nullOption]="false"
                      (requestData)="onRequestDataFields($event)"
                      formControlName="dataField"></nuc-dropdown>
        <nuc-error *ngIf="optionForm.errors">{{optionForm.errors | displayFormError}}</nuc-error>
    </nuc-form-field>
    <nuc-form-field class="data-field" label="Import behaviour (when already in your campaign)">
        <nuc-dropdown [items]="behaviorOptions()"
                      placeholder="Select behavior"
                      [nullOption]="false"
                      formControlName="behavior"></nuc-dropdown>
    </nuc-form-field>
    <nuc-form-field class="Value" label="Value" *ngIf="optionForm.contains('value')">
        <rl-data-field-input [readonly]="false"
                             [showLabels]="false"
                             [dataField]="dataFieldControl.value"
                             [options]="dataFieldControl.value.options"
                             controlName="value"
                             [form]="optionForm">
        </rl-data-field-input>
    </nuc-form-field>
</div>
<nuc-button-secondary icon="nucicon_trash_fill" (click)="removeOption()"></nuc-button-secondary>
