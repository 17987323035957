import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'initials'})
export class InitialsPipe implements PipeTransform {

    public transform(value: string): string {
        return value
            .split(' ')
            .reduce((acc, val) => [...acc, val[0]], [])
            .filter((char) => !!char && char.length > 0)
            .splice(0,2)
            .join(' ');
    }
}
