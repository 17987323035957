export class ItemBackgroundPixi extends PIXI.Container {

    protected static readonly BACKGROUND_COLOR = 0xF3F3F3;
    protected static readonly BORDER_COLOR = 0x9B9B9B;
    protected static readonly HIGHLIGHT_BORDER_COLOR = 0x3FBB87;
    protected static readonly HIGHLIGHT_BACKGROUND_COLOR = 0xB7DFCD;

    protected static readonly BLOCKING_BORDER_COLOR = 0xFF0000;
    protected static readonly BLOCKING_BACKGROUND_COLOR = 0xEE0000;

    protected highlightBackground: PIXI.Graphics;
    protected highlightBorder: PIXI.Graphics;
    protected blockingArea: PIXI.Graphics;

    protected scaleRatio: number;

    constructor(width: number, height: number, color?: number, scaleRatio: number = 1) {
        super();
        this.scaleRatio = scaleRatio;
        this.setBackground(width, height, color);
    }

    protected setBackground(width: number, height: number, color?: number): void {
        const body = this.createBackgroundBody(width, height, color);

        this.addHighLightBackground(width, height);

        this.addHighlightBorder(width, height);

        this.addBlockingArea(width, height);

        this.addChild(body);
        this.addChild(this.highlightBackground);
        this.addChild(this.highlightBorder);
        this.addChild(this.blockingArea);
    }

    protected createBackgroundBody(width: number, height: number, color?: number): PIXI.Graphics {
        const body = new PIXI.Graphics();

        body.beginFill(color || ItemBackgroundPixi.BACKGROUND_COLOR, 0.5);
        body.lineStyle(1 / this.scaleRatio, ItemBackgroundPixi.BORDER_COLOR, 1);
        body.drawRect(0, 0, width, height);
        body.endFill();
        return body;
    }

    protected addHighLightBackground(width: number, height: number): void {
        this.highlightBackground = new PIXI.Graphics();
        this.highlightBackground.beginFill(ItemBackgroundPixi.HIGHLIGHT_BACKGROUND_COLOR);
        this.highlightBackground.drawRect(0, 0, width, height);
        this.highlightBackground.endFill();
        this.highlightBackground.alpha = 0;
    }

    protected addHighlightBorder(width: number, height: number): void {
        this.highlightBorder = new PIXI.Graphics();
        this.highlightBorder.lineStyle(1 / this.scaleRatio, ItemBackgroundPixi.HIGHLIGHT_BORDER_COLOR);
        this.highlightBorder.drawRect(0, 0, width, height);
        this.highlightBorder.alpha = 0;
    }

    protected addBlockingArea(width: number, height: number): void {
        this.blockingArea = new PIXI.Graphics();
        this.blockingArea.beginFill(ItemBackgroundPixi.BLOCKING_BACKGROUND_COLOR);
        this.blockingArea.lineStyle(1, ItemBackgroundPixi.BLOCKING_BORDER_COLOR, 0);
        this.blockingArea.drawRect(0, 0, width, height);
        this.blockingArea.endFill();
        this.blockingArea.alpha = 0;
    }

    public setHovered(hovered: boolean): void {
        this.highlightBorder.alpha = hovered ? 1 : 0;
    }

    public setBlocking(blocking: boolean): void {
        this.blockingArea.alpha = blocking ? .25 : 0;
    }

}
