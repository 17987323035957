import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'nullUndefinedFormatter'})
export class NullUndefinedPipe implements PipeTransform {
    private static privatePipe = new NullUndefinedPipe();

    public static readonly defaultValues = {
        DELETED_USER: 'Deleted user',
        DELETED_ASSET: 'Deleted asset',
        DELETED_BRIEFING_ITEM: 'Deleted briefing item',
    };

    public transform(value: any, defaultValue = '-'): string {
        return value === null || value === undefined ? defaultValue : value;
    }

    public static transform(value: any, defaultValue = '-'): string {
        return NullUndefinedPipe.privatePipe.transform(value, defaultValue);
    }
}
