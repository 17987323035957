import {Component, OnDestroy, OnInit} from '@angular/core';
import {Toaster} from '../../classes/toaster.class';
import {ARApiError} from '@relayter/core';
import {AssetService, EAssetJobType} from '../../api/services/asset.service';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService} from '@relayter/rubber-duck';
import {FileTypeUtil} from '../../classes/file-type.util';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'asset-form-component',
    templateUrl: './asset-form.component.html',
    styleUrls: ['./asset-form.component.scss']
})
export class AssetFormComponent implements OnInit, OnDestroy {
    public s3Keys: string[] = [];
    public fileTypeCategories = FileTypeUtil.DOWNLOAD_CATEGORIES;
    public saveButton: ButtonConfig;
    private onDestroySubject = new Subject<void>();

    constructor(private assetService: AssetService,
                private fullModalService: FullModalService) {
    }

    public ngOnInit(): void {
        this.initButtons();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Add', null, null, true);

        const cancel = new FullModalActionModel(cancelButton);
        const save = new FullModalActionModel(this.saveButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        save.observable.subscribe(() => this.onCreateAssetsSubmitted());

        const actions = [cancel, save];
        this.fullModalService.setModalActions(actions);
    }

    /**
     * On files changed
     * @param {string[]} s3Keys
     */
    public onUploadedS3KeysChanged(s3Keys: string[]): void {
        this.s3Keys = s3Keys;
        this.saveButton.disabled = s3Keys.length === 0;
    }

    public onCreateAssetsSubmitted(): void {
        this.saveButton.loading = true;

        const jobData = {
            assetIdentifier: 'name',
            channelIds: [],
            imageType: '',
            s3Keys: this.s3Keys
        };

        this.assetService.postJob(EAssetJobType.ADD_DEFAULT_ASSETS_NEW, jobData)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: () => {
                    this.saveButton.loading = false;
                    this.fullModalService.close(true);
                },
                error: (err: ARApiError) => {
                    this.saveButton.loading = false;
                    this.fullModalService.close(false);
                    Toaster.handleApiError(err);
                }
            });
    }
}
