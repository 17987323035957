import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    ESortOrder,
    FullModalActionModel,
    FullModalService,
    ISortOptionEvent,
    ITableColumn, ITableItem,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {PackageSetupService} from '../../api/services/package-setups.service';
import {AppConstants} from '../../app.constants';
import {PackageSetupModel} from '../../models/api/package-setup.model';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {SortDirection} from '@angular/material/sort';
import {ARLogger, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {Subject, Subscription} from 'rxjs';
import {CampaignService, ECampaignJobTypes, ICampaignAddPackagesJobData} from '../../api/services/campaigns.service';
import {RLTableComponent} from '../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {takeUntil} from 'rxjs/operators';
import {PaginatorService} from '../../components/paginator/paginator.service';

export interface IPackagesFormComponentData {
    campaignId: string;
}

@Component({
    selector: 'packages-form',
    templateUrl: './packages-form.component.html',
    styleUrls: ['./packages-form.component.scss'],
    providers: [PaginatorService]
})
export class PackagesFormComponent extends RLTableComponent implements OnInit, OnDestroy {
    private readonly campaignId: string;
    private saveButton: ButtonConfig;
    public tableId = 'packages-form-table';

    public readonly permissions = AppConstants.PERMISSIONS;

    public columns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        sortProperty: 'name',
    }, {
        title: 'Description',
        key: 'description',
        sortProperty: 'description'
    }];

    public subscription: Subscription;
    public total: number;
    public packageSetups: PackageSetupModel[] = [];
    public selectedSetups: PackageSetupModel[] = [];
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage: boolean;

    public sortProperty: string;
    public sortOrder: SortDirection;
    private onDestroySubject = new Subject<void>();
    public searching: boolean;

    constructor(@Inject(NUC_FULL_MODAL_DATA) private modalData: IPackagesFormComponentData,
                private fullModalService: FullModalService,
                private packageSetupService: PackageSetupService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private campaignService: CampaignService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
        this.campaignId = modalData.campaignId;
    }

    public ngOnInit(): void {
        this.setupModal();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getPackageSetups();
            });


        this.setPageIndex();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private setupModal(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false));
        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.saveButton.disabled = true;
            this.postJob();
        });
        this.fullModalService.setModalActions([cancel, save]);
    }

    private getPackageSetups(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PACKAGE_SETUPS)) return;

        this.subscription = this.packageSetupService.getPackageSetups(
            this.pageSize, (this.pageIndex - 1) * this.pageSize, this.sortProperty, this.sortOrder, this.searchValue)
            .subscribe({
                next: (result: ARPagedResponseDataModel<PackageSetupModel>) => {
                    ({total: this.total, items: this.packageSetups} = result);
                    this.disableNextPage = this.total <= this.pageIndex * this.pageSize;
                },
                error: Toaster.handleApiError
            });
    }

    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        if (event.column?.sortProperty) {
            this.sortProperty = event.column?.sortProperty;
            this.sortOrder = event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

    public onSelectionChanged(selectedPackageSetups: ITableItem[]): void {
        this.selectedSetups = selectedPackageSetups as PackageSetupModel[];

        this.saveButton.disabled = this.selectedSetups.length === 0;
    }

    private postJob(): void {
        const selectedSetupIds = this.selectedSetups.map(setup => setup._id);
        const jobData = {campaignId: this.campaignId, packageSetups: selectedSetupIds} as ICampaignAddPackagesJobData;
        this.campaignService.postJob(ECampaignJobTypes.CAMPAIGN_ADD_PACKAGES_JOB, jobData)
            .subscribe({
                next: (job) => {
                    this.fullModalService.close(job._id); // close with job id
                    ARLogger.debug('Add campaign packages job scheduled: ' + job._id);
                },
                error: (error) => {
                    Toaster.handleApiError(error);
                    this.saveButton.disabled = false;
                    this.saveButton.loading = false;
                }
            });
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        if (this.searchValue !== searchValue) {
            this.searchValue = searchValue;
            this.searching = !!this.searchValue;

            this.setPageIndex();
        }
    }
}
