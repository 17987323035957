import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {AwsSignedUrlModel} from '../../models/api/aws-signed-url.model';
import {HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UploadModel} from '../../components/upload-file-component/upload.model';
import {shareReplay, switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AmazonService extends BaseApiRequestService {
    /**
     * Upload a file to amazon by getting a tempUrl and then uploading there
     * Returns an UploadModel that holds Observables
     */
    public createUpload(file: File): UploadModel {
        const signedUrl$ = this.getSignedRTemporaryUploadUrl(file.name).pipe(
            shareReplay(1)
        );
        const upload$ = signedUrl$.pipe(
            switchMap((signedUrl) => this.uploadFileToAmazon(file, signedUrl)),
            shareReplay(1)
        );

        return new UploadModel(file, signedUrl$, upload$);
    }

    /**
     * Get signed temporary upload url
     * @param {string} fileName
     */
    private getSignedRTemporaryUploadUrl(fileName: string): Observable<AwsSignedUrlModel> {
        const params = new HttpParams()
            .set('fileName', fileName.replace(/[^a-zA-Z0-9_.-\s]/g, '')); // Only allow alphanumeric and - . _ characters

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_SIGN_AWS_S3]);
        const options: ARRequestOptions = new ARRequestOptions();

        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        options.params = params;

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, AwsSignedUrlModel);
        });
    }

    /**
     * Upload file to Amazon S3 bucket with reportProgress true
     * @param {File} file - The file to upload
     * @param {AwsSignedUrlModel} signedUrl - A signed url, signed by our api
     */
    private uploadFileToAmazon(file: File, signedUrl: AwsSignedUrlModel): Observable<HttpEvent<Record<string, any>>> {
        let headers = new HttpHeaders();
        if (signedUrl.headers) {
            for (const header of signedUrl.headers) {
                headers = headers.append(header.key, header.value);
            }
        }
        const req = new HttpRequest(ApiConstants.REQUEST_METHODS.PUT, signedUrl.signedRequest, file, {
            headers,
            reportProgress: true
        });
        return this.httpClient.request(req);
    }
}
