<form class="container" *ngIf="formGroup; else loadingIndicator" [formGroup]="formGroup">
    <nuc-form-field *ngIf="publicationVariantControl" label="Variant" iconColorClass="variant-highlight"
                    icon="nucicon_variant" class="variant-selection">
        <p>Export publication item files variant: </p>
        <nuc-dropdown
            formControlName="variant"
            placeholder="Select a variant"
            [items]="publicationVariants"
            [nullOption]="false"
            (requestData)="getPublicationVariants($event.offset)"
            required>
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Package Type">
        <nuc-dropdown
            (dataChange)="packageTypeSelectionChanged($event)"
            formControlName="packageType"
            placeholder="Select a package type"
            [items]="packageTypes"
            required>
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Data export Format" *ngIf="showDataExportFormat">
        <nuc-dropdown
            formControlName="dataExportFormat"
            placeholder="Select a data export format"
            [items]="dataExportFormats">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Asset export Format" *ngIf="showAssetExportFormat">
        <nuc-dropdown
            formControlName="assetExportFormat"
            placeholder="Select an asset export format"
            [items]="assetExportFormats">
        </nuc-dropdown>
    </nuc-form-field>
</form>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
