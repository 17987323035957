<form [formGroup]="formGroup">

    <nuc-form-field label="Name">
        <nuc-input formControlName="name"
                   placeholder="Enter package name"
                   required>
        </nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Description">
        <nuc-textarea formControlName="description"
                      minRows="3"
                      placeholder="Enter package description">
        </nuc-textarea>
    </nuc-form-field>

</form>
