/**
 *  file-size.util
 *  app-relayter-web
 *  Created by nandamhuis on 19/04/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */

export class RLFileSizeUtil {

    public static formatBytesToReadableFileSize(bytes: number): string {
        let size = '';
        const FRACTION = 2;
        const KILOBYTE = 1024;

        const mBSize = Number((bytes / (KILOBYTE * KILOBYTE))).toFixed(FRACTION);
        const kBSize = Number((bytes / KILOBYTE)).toFixed(FRACTION);

        if (parseInt(kBSize, 10) > KILOBYTE) {
            size = `${mBSize} MB`;
        } else {
            size = `${kBSize} KB`;
        }
        return size;
    }
}
