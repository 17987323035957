<div *ngIf="selectedContentIndex < 0">
    <div class="content-overview-header">
        <nuc-label>Blocks</nuc-label>
        <nuc-button-secondary icon="nucicon_add" (click)="onAddContentButtonClicked()"></nuc-button-secondary>
    </div>

    <div class="overview-grid">
        <div *ngFor="let content of contents; let index = index"
             class="list-item"
             (mouseenter)="templateDataService.setHoveredContentIndex(index)"
             (mouseleave)="templateDataService.setHoveredContentIndex(-1)">

            <p class="body-strong">{{index + 1 | number: '2.0'}}. {{content.columns}}x{{content.rows}}</p>

            <p>{{content.width}}x{{content.height}}</p>

            <nuc-button-secondary
                icon="nucicon_edit"
                (click)="templateDataService.setSelectedContentIndex(index); templateDataService.setHoveredContentIndex(-1)">
            </nuc-button-secondary>

            <nuc-button-secondary
                icon="nucicon_trash_fill"
                (click)="onDeleteButtonClicked(index)">
            </nuc-button-secondary>
        </div>
    </div>
</div>

<form [formGroup]="formGroup" *ngIf="selectedContentIndex >= 0">
    <div class="content-detail-header">
        <nuc-button-secondary icon="nucicon_chevron_left" (click)="templateDataService.setSelectedContentIndex(-1)"></nuc-button-secondary>
        <p class="section-header text-center">Edit content block</p>
        <nuc-button-secondary icon="nucicon_trash_fill" (click)="onDeleteButtonClicked(selectedContentIndex)"></nuc-button-secondary>
    </div>

    <nuc-form-field label="X">
        <nuc-input formControlName="x" type="number"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Y">
        <nuc-input formControlName="y" type="number"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Width">
        <nuc-input formControlName="width" type="number"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Height">
        <nuc-input formControlName="height" type="number"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Columns">
        <nuc-input formControlName="columns" type="number"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Rows">
        <nuc-input formControlName="rows" type="number"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Column gutter">
        <nuc-input formControlName="columnGutter" type="number"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Row gutter">
        <nuc-input formControlName="rowGutter" type="number"></nuc-input>
    </nuc-form-field>

</form>
