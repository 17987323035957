import {Pipe, PipeTransform} from '@angular/core';

export enum ETransformAction {
    FIRST_LETTER
}

@Pipe({name: 'stringTransform'})
export class StringTransformPipe implements PipeTransform {

    private static privatePipe = new StringTransformPipe();

    public static format(text: string, transformAction: ETransformAction): string {
        return StringTransformPipe.privatePipe.transform(text, transformAction);
    }

    public transform(text: string, transformAction: ETransformAction): string {
        if (transformAction === ETransformAction.FIRST_LETTER) {
            return text?.substring(0, 1).toUpperCase() + text?.substring(1);
        }

        return '';
    }
}
