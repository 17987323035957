import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable()
export class AdvancedFiltersDataService {

    private filterValuesSubject = new ReplaySubject<Record<string, any>>(1);

    private filterValues$ = this.filterValuesSubject.asObservable();

    public setFilterValues(values: Record<string, any>): void {
        this.filterValuesSubject.next(values);
    }

    public getFilterValues(): Observable<Record<string, any>> {
        return this.filterValues$;
    }
}
