<form [formGroup]="form">

    <nuc-form-field label="Email address">
        <nuc-input formControlName="email" placeholder="Enter email address"></nuc-input>

        <ng-container *ngIf="form.controls.email.touched && form.controls.email.errors">
            <nuc-error *ngIf="form.controls.email.errors.pattern">{{validationMessages.email.pattern}}</nuc-error>
            <nuc-error *ngIf="form.controls.email.errors.required">{{validationMessages.email.required}}</nuc-error>
        </ng-container>
    </nuc-form-field>

    <nuc-form-field label="Roles" *ngIf="!rolesLoading; else loadingIndicator">
        <nuc-dropdown-multiselect
            formControlName="roles"
            [items]="roles"
            placeholder="Select roles(s)">
        </nuc-dropdown-multiselect>

        <ng-container *ngIf="form.controls.roles.touched && form.controls.roles.errors">
            <nuc-error *ngIf="form.controls.roles.errors.required">{{validationMessages.roles.required}}</nuc-error>
        </ng-container>
    </nuc-form-field>

    <ng-container *ngIf="!groupsLoading; else loadingIndicator">
        <nuc-form-field label="Groups" *ngIf="groups.length">
            <nuc-dropdown-multiselect
                formControlName="groups"
                [items]="groups"
                placeholder="Select group(s)">
            </nuc-dropdown-multiselect>
        </nuc-form-field>
    </ng-container>
</form>

<ng-template #loadingIndicator>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
