// Event types sent by the server related to communication protocol implementation
export enum EUserEventType {
    JOB_UPDATE = 'JOB_UPDATE'
}

// Event types sent by the server for the user
export enum EPublicationEventType {
    TRANSITION_ITEM_UPDATE = 'TRANSITION_ITEM_UPDATE'
}

export enum ENotificationEventType {
    NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE'
}

export type ESocketEventType = EUserEventType | EPublicationEventType | ENotificationEventType;
