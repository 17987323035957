import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'rl-drop-location',
    templateUrl: 'drop-location.component.html',
    styleUrls: ['drop-location.component.scss']
})
export class DropLocationComponent {

    @Input() public text: string;
    @HostBinding('class.hover-enabled') @Input() public hoverEnabled = true;

    @HostBinding('class.left') @Input() public left: boolean;
    @HostBinding('class.right') @Input() public right: boolean;

}
