<nuc-tab-bar [tabs]="tabbarItems"
             [(activeTab)]="selectedTab">
</nuc-tab-bar>
<hr class="full-width-tab-hr"/>

<form [formGroup]="form">
    <ng-container [ngSwitch]="selectedTab.index">
        <assign-to-package-packages-form-component *ngSwitchCase="TAB_PACKAGES"
                                                   [campaignId]="campaignId"
                                                   [publicationItemId]="publicationItemId"
                                                   [form]="form">
        </assign-to-package-packages-form-component>
        <assign-to-package-material-data-form-component *ngSwitchCase="TAB_MATERIAL_DATA"
                                                        [campaignId]="campaignId"
                                                        [form]="form">
        </assign-to-package-material-data-form-component>
    </ng-container>
</form>
