import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {TemplateContentModel} from '../template-detail/template-content/template-content.model';
import {ETemplateType, TemplateSizeModel} from '../template-detail/template-size/template-size.model';
import {PixiSizeIndicator} from './size-indicator.pixi';
import {PixiTemplateContent, PixiTemplateContentStatus} from './template-content.pixi';
import {PixiTemplate} from './template.pixi';
import {Subject} from 'rxjs';
import {TemplateDataService} from '../template-detail/template.data-service';
import {takeUntil} from 'rxjs/operators';
import {EPublicationType} from '../template-detail/publication-type.enum';
import ApplicationOptions = PIXI.ApplicationOptions;

@Component({
    selector: 'rl-new-template-editor',
    templateUrl: 'new-template-editor.component.html',
    styleUrls: ['new-template-editor.component.scss']
})
export class NewTemplateEditorComponent implements AfterViewInit, OnDestroy {
    private static readonly EDITOR_MARGIN = 50;
    private static readonly PAGE_BACKGROUND_COLOR = 0xffffff;

    @Input() public editorWidth: number = 800;
    @Input() public editorHeight: number = 600;

    private publicationType: EPublicationType;
    private templateSize: TemplateSizeModel;
    private contents: TemplateContentModel[];
    private backgroundUrl: string;

    private selectedContentIndex: number;
    private hoveredContentIndex: number;

    @ViewChild('editor', {static: true}) public editor: ElementRef;

    private pixiApp: PIXI.Application;
    private backgroundStage: PIXI.Container = new PIXI.Container();
    private templateStage: PIXI.Container = new PIXI.Container();
    private contentStage: PIXI.Container = new PIXI.Container();
    private indicatorStage: PIXI.Container = new PIXI.Container();
    private scalingStage: PIXI.Container = new PIXI.Container();

    private scale = 1;

    public collisions: Set<TemplateContentModel>;

    private onDestroySubject = new Subject<void>();

    constructor(private templateDataService: TemplateDataService) {}

    public ngAfterViewInit(): void {
        this.initializePixi();

        this.templateDataService.backgroundUrl$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((signedUrl) => {
            this.backgroundUrl = signedUrl;
            this.loadBackground();
        });

        this.templateDataService.publicationType$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((publicationType) => this.publicationType = publicationType);

        this.templateDataService.templateSize$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((templateSize) => {
            this.templateSize = templateSize;
            if (this.templateSize) {
                this.updateContentsForPOSandWeb();
                this.update();
            }
        });

        this.templateDataService.contents$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((contents) => {
            this.contents = contents;
            this.update();
        });

        this.templateDataService.selectedContentIndex$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((selectedContentIndex) => {
            this.selectedContentIndex = selectedContentIndex;
            this.update();
        });

        this.templateDataService.hoveredContentIndex$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((hoveredContentIndex) => {
            this.hoveredContentIndex = hoveredContentIndex;
            this.update();
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.pixiApp.destroy(true);
    }

    private initializePixi(): void {
        const editorOptions: ApplicationOptions = {
            backgroundColor: NewTemplateEditorComponent.PAGE_BACKGROUND_COLOR,
            resolution: window.devicePixelRatio,
            view: this.editor.nativeElement,
            autoResize: true
        };

        this.pixiApp = new PIXI.Application(this.editorWidth, this.editorHeight, editorOptions);

        this.scalingStage.addChild(this.backgroundStage);
        this.scalingStage.addChild(this.templateStage);
        this.scalingStage.addChild(this.contentStage);

        this.pixiApp.stage.addChild(this.scalingStage);
        this.pixiApp.stage.addChild(this.indicatorStage);

        this.update();
    }

    private loadBackground(): void {
        // If a background url is set and the resource was not loaded before, add it to the loader
        if (this.backgroundUrl && !PIXI.loader.resources[this.backgroundUrl]) {
            PIXI.loader.add(this.backgroundUrl);
            PIXI.loader.load(() => this.update());
        } else {
            // If no background we can just refresh to remove any old background
            this.update();
        }
    }

    private update(): void {
        this.clearStages();
        if (!this.templateSize) {
            return;
        }

        // Scale the content, so we always see the complete template
        // Adjust width & height for to always leave some space on the sides
        const width = this.templateSize.templateType === ETemplateType.Spread ? this.templateSize.width * 2 : this.templateSize.width;
        const height = this.templateSize.height;

        this.scale = Math.min((this.pixiApp.renderer.screen.width - NewTemplateEditorComponent.EDITOR_MARGIN) / width,
            (this.pixiApp.renderer.screen.height - NewTemplateEditorComponent.EDITOR_MARGIN) / height);

        this.drawTemplate();
        this.drawBackground();
        if (this.contents) {
            this.detectCollisions();
            this.drawContents();
        }
        this.drawIndicators();

        // Position baseStage in the center of the view
        this.scalingStage.pivot.x = this.scalingStage.width / 2;
        this.scalingStage.pivot.y = this.scalingStage.height / 2;
        this.scalingStage.x = this.pixiApp.renderer.screen.width / 2;
        this.scalingStage.y = this.pixiApp.renderer.screen.height / 2;

        // Position Indicator stage
        this.indicatorStage.x = this.scalingStage.x - this.scalingStage.width / 2;
        this.indicatorStage.y = this.scalingStage.y - this.scalingStage.height / 2;
    }

    private clearStages(): void {
        this.templateStage.removeChildren();
        this.backgroundStage.removeChildren();
        this.contentStage.removeChildren();
        this.indicatorStage.removeChildren();
    }

    private detectCollisions(): void {
        this.collisions = new Set<TemplateContentModel>();

        for (let i = 0; i <  this.contents.length; i++) {
            const content1 = this.contents[i];
            for (let j = i + 1; j < this.contents.length; j++) {
                const content2 = this.contents[j];
                if (content1.x + content1.width > content2.x &&
                    content1.x < content2.x + content2.width &&
                    content1.y + content1.height > content2.y &&
                    content1.y < content2.y + content2.height) {
                    this.collisions.add(content1);
                    this.collisions.add(content2);
                }
            }
        }
        this.templateDataService.setCollisions(this.collisions);
    }

    private drawBackground(): void {
        if (!this.backgroundUrl) { // If no background is set don't add a sprite
            return;
        }

        const resource = PIXI.loader.resources[this.backgroundUrl];
        if (!resource) { // If no resource, wait for the resource to load and next update call
            return;
        }

        const sprite = new PIXI.Sprite(resource.texture);
        this.backgroundStage.addChild(sprite);
        sprite.width = this.templateSize.templateType === ETemplateType.Spread ?
            this.templateSize.width * this.scale * 2 :
            this.templateSize.width * this.scale;
        sprite.height = this.templateSize.height * this.scale;
    }

    private drawTemplate(): void {
        const template = new PixiTemplate(this.templateSize, this.scale);
        this.templateStage.addChild(template);
    }

    private drawContents(): void {
        for (let i = 0; i < this.contents.length; i++) {
            const content = this.contents[i];
            this.drawContent(content, i, this.collisions.has(content));
        }
    }

    private drawContent(content: TemplateContentModel, index: number, collision: boolean): void {
        const templateContent = new PixiTemplateContent(content, index, this.scale);

        templateContent.on('pointerdown', () => this.templateDataService.setSelectedContentIndex(index));

        if (this.selectedContentIndex >= 0) {
            templateContent.setStatus(this.selectedContentIndex === index ? PixiTemplateContentStatus.Active : PixiTemplateContentStatus.Inactive);
        } else if (this.hoveredContentIndex >= 0) {
            templateContent.setStatus(this.hoveredContentIndex === index ? PixiTemplateContentStatus.Active : PixiTemplateContentStatus.Inactive);
        } else {
            templateContent.setStatus(PixiTemplateContentStatus.Normal);
        }

        if (collision) {
            templateContent.setCollision(collision);
        }

        this.contentStage.addChild(templateContent);
    }

    private drawIndicators(): void {
        let unit;
        switch (this.publicationType) {
            case EPublicationType.WEB:
                unit = 'px';
                break;
            case EPublicationType.PRINT_MAGAZINE:
            case EPublicationType.POS:
            default:
                unit = 'mm';
                break;
        }

        if (this.templateSize.templateType === ETemplateType.Spread) {
            const spreadWidthIndicator = new PixiSizeIndicator(this.templateStage.width, `${this.templateSize.width * 2} ${unit}`);
            spreadWidthIndicator.x = this.templateStage.width / 2;
            spreadWidthIndicator.y = -20;
            this.indicatorStage.addChild(spreadWidthIndicator);

            const pageWidthIndicator = new PixiSizeIndicator(this.templateStage.width / 2, `${this.templateSize.width} ${unit}`);
            pageWidthIndicator.x = this.templateStage.width / 4;
            pageWidthIndicator.y = this.templateStage.height + 20;
            this.indicatorStage.addChild(pageWidthIndicator);
        } else {
            const pageWidthIndicator = new PixiSizeIndicator(this.templateStage.width, `${this.templateSize.width} ${unit}`);
            pageWidthIndicator.x = this.templateStage.width / 2;
            pageWidthIndicator.y = -20;
            this.indicatorStage.addChild(pageWidthIndicator);
        }

        const pageHeightIndicator = new PixiSizeIndicator(this.templateStage.height, `${this.templateSize.height} ${unit}`);
        pageHeightIndicator.x = -20;
        pageHeightIndicator.y = this.templateStage.height / 2;
        pageHeightIndicator.rotation = -Math.PI / 2;

        this.indicatorStage.addChild(pageHeightIndicator);
    }

    /**
     * For POS And Web we need to announce new content according to the template size
     */
    private updateContentsForPOSandWeb(): void {
        if (this.contents && this.contents.length && [EPublicationType.POS, EPublicationType.WEB].includes(this.publicationType)) {
            const content = TemplateContentModel.formSingleContentFromTemplateSizeModel(this.templateSize, this.contents[0]._id);
            this.templateDataService.setContents([content]);
        }
    }
}
