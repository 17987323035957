import {FormArray, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Checks if the form array has a minimal length
 * @param {number} minLength
 * @returns {object | null}
 * @constructor
 */
export function FormArrayMinLengthValidator(minLength: number = 1): ValidatorFn {
    return (formArray: FormArray): ValidationErrors => {

        if (formArray.controls.length < minLength) {
            return {minLength: true};
        }
        return null;
    };
}
