<h2>General information</h2>
<ng-container *ngIf="teamDetails$ | async as teamDetails else loadingStorage">
    <div class="general-info-container">
        <p class="body-strong">Team name</p>
        <p>{{teamDetails.name}}</p>

        <p class="body-strong">Owner</p>
        <div>
            <p>{{teamDetails.owner.fullName}}</p>
            <p>{{teamDetails.owner.email}}</p>
        </div>

        <ng-template *ngIf="teamDetails.companyName">
            <p class="body-strong">Company</p>
            <p>{{teamDetails.companyName}}</p>
        </ng-template>

        <ng-template *ngIf="teamDetails.country">
            <p class="body-strong">Country</p>
            <p>{{teamDetails.country}}</p>
        </ng-template>
    </div>

    <hr>

    <h2>Usage in your plan</h2>

    <p class="section-header">Seats</p>

    <div class="user-count">
        <nuc-progress-bar [value]="(teamDetails.userCount / teamDetails.userLimit) * 100"></nuc-progress-bar>
        <p class="body-strong">User count</p>
        <p>{{teamDetails.userCount}} {{teamDetails.userCount === 1 ? 'user' : 'users'}} out
            of {{teamDetails.userLimit}}</p>
    </div>
    <ng-container *ngIf="permissions.GET_TEAM_STORAGE | userIsAllowedTo">
        <p class="section-header">Storage</p>

        <div class="team-storage" >
            <ng-container *ngIf="storage$ | async as storageData else loadingStorage">
            <nuc-status-bar [value]="(storageData.value / teamDetails.maxAllowedStorage) * 100"></nuc-status-bar>
            <p class="body-strong">Used storage</p>
            <p><span
                [class.danger]="(storageData.value / teamDetails.maxAllowedStorage) * 100 > 90">{{storageData.value | filesize}}</span>
                of {{teamDetails.maxAllowedStorage | filesize}}</p>
            </ng-container>
        </div>
    </ng-container>

    <p class="section-header">Creation</p>

    <div class="team-creation">
        <ng-container *ngIf="creations$ | async as averageCreatedItems else loadingStorage">
            <nuc-status-bar [value]="(averageCreatedItems.value / teamDetails.maxAllowedCreation) * 100"></nuc-status-bar>
            <p class="body-strong" [nucTooltip]="'This is an average of the last 6 months'">Created items - monthly average</p>
            <p><span
                [class.danger]="(averageCreatedItems.value / teamDetails.maxAllowedCreation) * 100 > 90">{{averageCreatedItems.value}}</span>
                of {{teamDetails.maxAllowedCreation}}</p>
        </ng-container>
    </div>
</ng-container>
<ng-template #loadingStorage>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>


<div *ngIf="permissions.GET_STATISTICS | userIsAllowedTo">
    <p class="section-header">Generation</p>
    <p>Usage statistics about the materials created in all your publications</p>

    <form [formGroup]="formGroup" class="usage_filters">
        <nuc-form-field label="Calendar period">
            <nuc-dropdown [items]="periods" formControlName="period" [nullOption]="false"></nuc-dropdown>
        </nuc-form-field>
    </form>
    <div class="usage">
        <canvas baseChart
                height="300"
                [data]="barChartData"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [type]="barChartType">
        </canvas>
    </div>
</div>
