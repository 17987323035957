import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'expiresIn'
})
export class ExpiresInPipe implements PipeTransform {

    public transform(date: Date): string {
        const now = new Date();
        if (date > now) {
            const seconds = (date.getTime() - now.getTime()) / 1000;
            if (seconds < 60) {
                return `${Math.floor(seconds)} second(s)`;
            }

            const minutes = seconds / 60;
            if (minutes < 60) {
                return `${Math.floor(minutes)} minute(s)`;
            }

            const hours = minutes / 60;
            if (hours < 24) {
                return `${Math.floor(hours)} hour(s)`;
            }

            const days = hours / 24;
            return `${Math.floor(days)} day(s)`;

        } else {
            return 'expired';
        }
    }

}
