import {autoserialize} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class VariantModel implements IDropdownItem<string> {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public key: string;
    @autoserialize public updatedAt: string;
    @autoserialize public createdAt: string;

    getTitle(): string {
        return this.name;
    }

    getValue(): string {
        return this.key;
    }
}

export class VariantPostModel {
    @autoserialize public name: string;
    constructor(name: string) {
        this.name = name;
    }
}

export class VariantPatchModel {
    @autoserialize public name: string;
    constructor(name: string) {
        this.name = name;
    }
}
