/**
 * Created by marcelhoogesteger on 16/12/2019.
 */

import {autoserialize} from 'cerialize';

export class TemplateMarginsModel {
    @autoserialize public marginTop: number;
    @autoserialize public marginBottom: number;
    @autoserialize public marginStart: number;
    @autoserialize public marginEnd: number;

    constructor(marginTop: number, marginBottom: number, marginStart: number, marginEnd: number) {
        this.marginTop = marginTop;
        this.marginBottom = marginBottom;
        this.marginStart = marginStart;
        this.marginEnd = marginEnd;
    }
}
