import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {map, take, takeUntil} from 'rxjs/operators';
import {TemplateVariantModel} from './template-variant.model';
import {TemplateDataService} from '../template.data-service';
import {EPublicationType} from '../publication-type.enum';
import {VariantModel} from '../../../../../models/api/variant.model';
import {TemplateVariantPresetModel} from '../template-preset/template-preset.model';
import {RLValidatorConstants} from '../../../../../classes/validators/rl-validators.constant';

@Component({
    selector: 'template-variant',
    templateUrl: 'template-variant.component.html',
    styleUrls: ['template-variant.component.scss']
})

export class TemplateVariantComponent implements OnInit, OnDestroy {

    @Input() public variants: VariantModel[];

    public formGroup = new UntypedFormGroup({});
    public variantPresetFormArray = new UntypedFormArray([]);
    public selectedVariants: VariantModel[] = [];
    public templatePresets: TemplateVariantPresetModel[] = [];
    public publicationType: EPublicationType;
    public EPublicationType = EPublicationType;

    private onDestroySubject = new Subject<void>();

    constructor(private templateDataService: TemplateDataService) {
    }

    public ngOnInit(): void {
        this.templateDataService.publicationType$.pipe(
            take(1),
            takeUntil(this.onDestroySubject)
        ).subscribe((publicationType) => {
            this.publicationType = publicationType;
        });

        this.templateDataService.templateVariant$.pipe(
            take(1),
            takeUntil(this.onDestroySubject)
        ).subscribe((templateVariant: TemplateVariantModel) => {
            if (templateVariant) {
                this.templatePresets = templateVariant.variantPresets;
                this.selectedVariants = templateVariant.variants.map(selectedVariant =>
                    this.variants.find(variant => variant._id === selectedVariant._id));
            }

            this.initForm();
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initForm(): void {
        this.formGroup.addControl('variantPresets', this.variantPresetFormArray);
        // Add presets in the order of the selected variants
        this.selectedVariants.forEach((variant) => {
            const preset = this.templatePresets.find(templatePreset => templatePreset.variant._id === variant._id);
            const variantPresetForm = new UntypedFormGroup({
                variant: new UntypedFormControl(preset.variant),
                masterPage: new UntypedFormControl(preset.masterPage),
                library: new UntypedFormControl(preset.indesignLibrary, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                ruleSet: new UntypedFormControl({value: preset.formatRuleSet, disabled: !preset.indesignLibrary},
                    RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
            });
            this.variantPresetFormArray.push(variantPresetForm);
        });

        this.variantPresetFormArray.valueChanges.pipe(
            map(() => TemplateVariantModel.fromFormValues(this.formGroup.get('variantPresets')?.value)),
            takeUntil(this.onDestroySubject)
        ).subscribe((templateVariant) => {
            this.templateDataService.setTemplateVariant(templateVariant);
        });
    }

    public onVariantsChanged(variants: VariantModel[]): void {
        // Determine which variant is added/removed

        // Check Removed
        const deletedVariant = this.selectedVariants.find(selectedVariant => !variants.find(variant => variant._id === selectedVariant._id));
        if (deletedVariant) {
            this.variantPresetFormArray.controls.forEach((control, index) => {
                if (control.value.variant._id === deletedVariant._id) {
                    this.variantPresetFormArray.removeAt(index);
                }
            });
        }

        // Check Added
        const addedVariant = variants.find(variant => !this.selectedVariants.find(selectedVariant => selectedVariant._id === variant._id));
        this.selectedVariants = variants;

        if (addedVariant) {
            const variantPresetForm = new UntypedFormGroup({
                variant: new UntypedFormControl(addedVariant),
                masterPage: new UntypedFormControl(null),
                library: new UntypedFormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                ruleSet: new UntypedFormControl({value: null, disabled: true}, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
            });
            const insertAt = this.variants.findIndex(variant => variant._id === addedVariant._id);
            this.variantPresetFormArray.insert(insertAt, variantPresetForm);
        }
    }
}
