<div class="header">
    <h1>Campaign setup and briefing</h1>
    <nuc-button-bar>
        <div class="import">
            <nuc-button-secondary
                *ngIf="(permissions.POST_CAMPAIGN_JOB | userIsAllowedTo) || (permissions.POST_MASTER_BRIEFING_JOB | userIsAllowedTo)"
                text="Import briefing"
                icon="nucicon_dots_vertical"
                iconPosition="end"
                [matMenuTriggerFor]="menu"></nuc-button-secondary>
            <mat-menu #menu="matMenu" class="menu-items-container">
                <button mat-menu-item
                        *ngIf="permissions.POST_CAMPAIGN_JOB | userIsAllowedTo"
                        (click)="openImportBriefingFileModal()"
                        [disableRipple]="true">
                    From file
                </button>
                <button mat-menu-item
                        *ngIf="permissions.POST_MASTER_BRIEFING_JOB | userIsAllowedTo"
                        (click)="openImportFromMasterBriefingModal()"
                        [disableRipple]="true">
                    From master briefing
                </button>
            </mat-menu>
        </div>

        <nuc-button-secondary *ngIf="campaign" text="Export briefing" (click)="openExportBriefingModal()" i18n></nuc-button-secondary>
        <nuc-button-primary *ngIf="permissions.POST_CAMPAIGN_ITEM | userIsAllowedTo" [disabled]="!campaign" text="Add briefing item"
                            (click)="openBriefingForm()" i18n></nuc-button-primary>
    </nuc-button-bar>
</div>

<briefing-table [campaignId]="campaign._id"
                [actions]="actionTypes"
                [briefingTableOptions]="briefingTableOptions"
                [tableId]="tableId"
                (actionClicked)="handleTableRowAction($event)"
                [isLoading]="apiRequestSubscription && !apiRequestSubscription.closed">
</briefing-table>
