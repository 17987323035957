import {autoserialize, autoserializeAs, inheritSerialization} from 'cerialize';
import {ValueModel} from './ruleset-value.model';
import {ITableItem} from '@relayter/rubber-duck';
import {ConditionGroupsModel} from '../../../../models/api/condition-groups.model';
import {ApiConstants} from '../../../../api/api.constant';

export class FormatRulesetBaseItem implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public layer: string;
    @autoserializeAs(ConditionGroupsModel) public conditions: ConditionGroupsModel;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;

    constructor(name: string,
                layer: string,
                conditions: ConditionGroupsModel) {
        this.name = name;
        this.layer = layer;
        this.conditions = conditions;
    }
}

@inheritSerialization(FormatRulesetBaseItem)
export class FormatRulesetItemModel extends FormatRulesetBaseItem {
    @autoserialize public libraryItem: string;
    @autoserialize public itemTypes: string[];
    @autoserialize public defaultX: number;
    @autoserialize public defaultY: number;
    @autoserialize public defaultWidth: number;
    @autoserialize public defaultHeight: number;
    @autoserialize public scale: string;
    @autoserialize public anchor: string;
    @autoserializeAs(ValueModel) public values: ValueModel[];
    @autoserialize public itemGroup: string;

    constructor(name: string,
                libraryItem: string,
                layer: string,
                itemGroup: string,
                itemTypes: string[],
                defaultX: number,
                defaultY: number,
                defaultWidth: number,
                defaultHeight: number,
                scale: string,
                anchor: string,
                conditions: ConditionGroupsModel,
                values: ValueModel[]) {
        super(name, layer, conditions);
        this.itemGroup = itemGroup;
        this.libraryItem = libraryItem;
        this.itemTypes = itemTypes;
        this.defaultX = defaultX;
        this.defaultY = defaultY;
        this.defaultWidth = defaultWidth;
        this.defaultHeight = defaultHeight;
        this.scale = scale;
        this.anchor = anchor;
        this.values = values;
    }

    public getApiPath(): string {
        return ApiConstants.API_METHOD_ITEMS;
    }
}
