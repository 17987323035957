import {Pipe, PipeTransform} from '@angular/core';
import {EDataFieldTypes} from '../../app.enums';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {RLDatePipe} from '../rl-date.pipe';

@Pipe({
    name: 'getDataFieldDisplayValue'
})
export class GetDataFieldDisplayValuePipe implements PipeTransform {
    transform(value: any, dataFieldType?: EDataFieldTypes): string | number {
        if (value === null || value === undefined) return value;

        if (Array.isArray(value) && value.every((v) => v instanceof DropdownItem)) { // list, multi-select
            return value.map((v) => v.getValue()).join(', ');
        } else if (value instanceof DropdownItem) { // boolean, single-select
            return value.getTitle();
        } else if (dataFieldType === EDataFieldTypes.DATE) {
            return RLDatePipe.format(value, RLDatePipe.dateFormats.DEFAULT);
        }

        return value;
    }
}
