import {TemplateSizeModel} from '../template-size/template-size.model';

export class TemplateContentModel {
    public readonly x: number;
    public readonly y: number;
    public readonly width: number;
    public readonly height: number;
    public readonly columns: number;
    public readonly rows: number;
    public readonly columnGutter: number;
    public readonly rowGutter: number;
    public readonly _id: string;

    public static formSingleContentFromTemplateSizeModel(sizeModel: TemplateSizeModel, contentId?: string): TemplateContentModel {
        return new TemplateContentModel(
            sizeModel.margins.marginStart,
            sizeModel.margins.marginTop,
            sizeModel.width - sizeModel.margins.marginStart - sizeModel.margins.marginEnd,
            sizeModel.height - sizeModel.margins.marginTop - sizeModel.margins.marginBottom,
            1,
            1,
            0,
            0,
            contentId
        );
    }

    constructor(x: number,
                y: number,
                width: number,
                height: number,
                columns: number,
                rows: number,
                columnGutter: number,
                rowGutter: number,
                _id?: string) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.columns = columns;
        this.rows = rows;
        this.columnGutter = columnGutter;
        this.rowGutter = rowGutter;
        this._id = _id;
    }
}
