import {autoserialize} from 'cerialize';
import { RuleConditionModel } from './rule-condition.model';
import {RULESET_OPERATORS} from '../../app.enums';

export class ConditionGroupModel {
    @autoserialize public operator: RULESET_OPERATORS;
    @autoserialize public rules?: RuleConditionModel[];

    constructor(operator: RULESET_OPERATORS, rules?: RuleConditionModel[]) {
        if (operator) this.operator = operator;
        if (rules) this.rules = rules;
    }
}
