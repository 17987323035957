import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PropertySettingsModel} from './property-settings.model';
import {ARLogger} from '@relayter/core';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';

export enum EPropertySettingsContext {
    PRODUCT = 'product',
    BRIEFING = 'briefing',
    ASSET = 'asset'
}

@Injectable({
    providedIn: 'root'
})
export class PropertySettingsService {

    private static readonly BRIEFING_PROPERTY_SETTINGS_KEY = 'property-settings';
    private static readonly PRODUCT_PROPERTY_SETTINGS_KEY = 'product-property-settings';
    private static readonly ASSET_PROPERTY_SETTINGS_KEY = 'asset-property-settings';

    private briefingSettingsSubject = new BehaviorSubject<PropertySettingsModel>(this.getSettingsFromStorage(EPropertySettingsContext.BRIEFING));
    private productSettingsSubject = new BehaviorSubject<PropertySettingsModel>(this.getSettingsFromStorage(EPropertySettingsContext.PRODUCT));
    private assetSettingsSubject = new BehaviorSubject<PropertySettingsModel>(this.getSettingsFromStorage(EPropertySettingsContext.ASSET));

    private static getSettingsKey(propertySettingsContext: EPropertySettingsContext): string {
        switch (propertySettingsContext) {
            case EPropertySettingsContext.BRIEFING:
                return PropertySettingsService.BRIEFING_PROPERTY_SETTINGS_KEY;
            case EPropertySettingsContext.PRODUCT:
                return PropertySettingsService.PRODUCT_PROPERTY_SETTINGS_KEY;
            case EPropertySettingsContext.ASSET:
                return PropertySettingsService.ASSET_PROPERTY_SETTINGS_KEY;
            default:
                ARLogger.error('Incorrect context for PropertySettingsService');
                return null;
        }
    }

    constructor(private userSettingsStorageService: UserSettingsStorageService) {}

    public getSettings(propertySettingsContext: EPropertySettingsContext): Observable<PropertySettingsModel> {
        switch (propertySettingsContext) {
            case EPropertySettingsContext.BRIEFING:
                return this.briefingSettingsSubject.asObservable();
            case EPropertySettingsContext.PRODUCT:
                return this.productSettingsSubject.asObservable();
            case EPropertySettingsContext.ASSET:
                return this.assetSettingsSubject.asObservable();
            default:
                ARLogger.error('Incorrect context for PropertySettingsService');
                return null;
        }
    }

    public storeSettings(settings: PropertySettingsModel, propertySettingsContext: EPropertySettingsContext): void {
        const key = PropertySettingsService.getSettingsKey(propertySettingsContext);

        this.userSettingsStorageService.storeSettings<PropertySettingsModel>(key, settings, PropertySettingsModel);

        switch (propertySettingsContext) {
            case EPropertySettingsContext.BRIEFING:
                this.briefingSettingsSubject.next(settings);
                break;
            case EPropertySettingsContext.PRODUCT:
                this.productSettingsSubject.next(settings);
                break;
            case EPropertySettingsContext.ASSET:
                this.assetSettingsSubject.next(settings);
                break;
        }
    }

    private getSettingsFromStorage(propertySettingsContext: EPropertySettingsContext): PropertySettingsModel {
        const key = PropertySettingsService.getSettingsKey(propertySettingsContext);
        const settings = this.userSettingsStorageService.loadSettings(key, PropertySettingsModel);
        return settings ? settings : new PropertySettingsModel();
    }
}
