import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {Invite, InviteBodyModel} from '../../models/api/invite.model';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {RoleModel} from '../../models/api/role.model';
import {RolesService} from '../../api/services/roles.service';
import {
    NucDialogConfigModel,
    NucDialogRef,
    NucDialogService,
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService
} from '@relayter/rubber-duck';
import {ErrorConstants} from '../../api/error.constants';
import {GroupService} from '../../api/services/group.service';
import {GroupModel} from '../../models/api/group.model';
import {map, distinctUntilChanged} from 'rxjs/operators';
import {InviteService} from '../../api/services/invites.service';

@Component({
    selector: 'rl-invite-user-form-component',
    templateUrl: 'invite-user-form.component.html',
    styleUrls: ['invite-user-form.component.scss']
})

export class InviteUserFormComponent implements OnInit {
    private dialog: NucDialogRef;
    private saveConfig: ButtonConfig;

    public form: UntypedFormGroup;

    public groups: GroupModel[];
    public roles: RoleModel[];

    public groupsLoading: boolean;
    public rolesLoading: boolean;

    public validationMessages;

    constructor(private formBuilder: UntypedFormBuilder,
                private inviteService: InviteService,
                private rolesService: RolesService,
                private dialogService: NucDialogService,
                private fullModalService: FullModalService,
                private groupService: GroupService
    ) {
    }

    public ngOnInit(): void {
        this.getAvailableRoles();
        this.getGroups();

        this.validationMessages = {
            email: RLValidatorConstants.MESSAGE_SETS.EMAIL,
            roles: RLValidatorConstants.MESSAGE_SETS.REQUIRED
        };
        const formData = {
            email: ['', RLValidatorConstants.VALIDATOR_SETS.EMAIL],
            roles: ['', RLValidatorConstants.VALIDATOR_SETS.REQUIRED],
            groups: []
        };
        this.form = this.formBuilder.group(formData);

        this.form.statusChanges.pipe(
            map((status) => status === 'VALID'),
            distinctUntilChanged()
        ).subscribe((valid) => this.saveConfig.disabled = !valid);
        this.initButtons();
    }

    private getGroups(): void {
        this.groupsLoading = true;
        this.groupService.getAllGroups().subscribe(
            (results) => {
                this.groupsLoading = false;
                this.groups = results;
            });
    }

    private getAvailableRoles(): void {
        this.rolesLoading = true;
        this.rolesService.getRoles(null, 0).subscribe(
            (result: ARPagedResponseDataModel<RoleModel>) => {
                this.rolesLoading = false;
                this.roles = result.items;
            }
        );
    }

    private initButtons(): void {
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Send invite', false, false, true);
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.onSubmitValid());

        const actions = [cancelAction, saveAction];
        this.fullModalService.setModalActions(actions);
    }


    public onSubmitValid(): void {
        this.saveConfig.loading = true;
        const inviteBody = new InviteBodyModel(this.form.value.email, this.form.value.roles.map((role) => role._id));
        if (this.form.value.groups) {
            inviteBody.groupIds = this.form.value.groups.map((group) => group._id);
        }
        this.inviteService.postInvite(inviteBody).subscribe(
            (res: Invite) => this.fullModalService.close({email: res.email}),
            (err: ARApiError) => {
                this.saveConfig.loading = false;
                err.code === ErrorConstants.API_ERROR_CODES.USER_LIMIT_REACHED_ERROR ?
                    this.openUserLimitReachedDialog() :
                    this.fullModalService.close({error: err});
            });
    }

    private openUserLimitReachedDialog(): void {
        const userLimitReachedDialog = new NucDialogConfigModel('User limit reached',
            'The user limit of your team has been reached. Contact Relayter customer service to increase your user limit.');
        userLimitReachedDialog.addAction('close', BUTTON_TYPE.SECONDARY).subscribe(() => {
            this.dialog.close();
            this.fullModalService.close();
        });

        this.dialog = this.dialogService.openDialog(userLimitReachedDialog);
    }
}
