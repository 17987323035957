/**
 * Created by nandamhuis on 08/05/2017.
 */

import {autoserialize} from 'cerialize';

export class SizeModel {
    @autoserialize public width: number = 0;
    @autoserialize public height: number = 0;

    constructor(width?: number, height?: number) {
        this.width = width || 0;
        this.height = height || 0;
    }
}
