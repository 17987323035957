export class ResizeBodyPixi extends PIXI.Container {

    private static BACKGROUND_COLOR = 0xB7DFCD;
    private static BORDER_COLOR = 0x85AC9A;

    constructor(x: number, y: number, width: number, height: number) {
        super();
        this.x = x;
        this.y = y;

        const area = new PIXI.Graphics();
        area.beginFill(ResizeBodyPixi.BACKGROUND_COLOR);
        area.lineStyle(1, ResizeBodyPixi.BORDER_COLOR, 1);
        area.drawRect(0, 0, width, height);
        area.endFill();
        area.alpha = 0.5;

        this.addChild(area);
    }

}
