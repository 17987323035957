<ng-container *ngIf="tabs.length > 0">

    <h1>Team settings</h1>

    <nuc-tab-bar [tabs]="tabs"
                 [(activeTab)]="activeTab"
                 (activeTabChange)="onActiveTabChanged($event)">
    </nuc-tab-bar>

    <hr>

    <router-outlet></router-outlet>
</ng-container>
