<ng-container *ngIf="!form; else importForm">
    <rl-file-component (onFileChanged)="onFileChanged($event)"
                       [fileTypeCategories]="fileTypes">
    </rl-file-component>
</ng-container>

<ng-template #importForm>
    <form [formGroup]="form">
        <div class="container" *ngIf="form.controls.variant">
            <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">Variant</nuc-label>
            <div class="variant-selection">
                <p>Import variants data fields for: </p>
                <nuc-dropdown class="variant"
                              placeholder="Select variant"
                              formControlName="variant"
                              [nullOption]=false
                              [items]="variants">
                </nuc-dropdown>
            </div>
        </div>

        <div class="identifier-header" *ngIf="form.controls.identifier">
            <p class="section-header">Select your main identifier</p>
            <p>You can use this field to identify and update your items.</p>
        </div>
        <div formGroupName="identifier" class="identifier-form">
            <div class="identifier-dropdown">
                <p class="body-strong">Column</p>
                <nuc-dropdown formControlName="column"
                              placeholder="Choose a CSV column"
                              [searchable]="true"
                              (requestData)="searchColumnFields($event)"
                              [items]="columns">
                </nuc-dropdown>
            </div>
            <div class="identifier-dropdown">
                <p class="body-strong">Field</p>
                <nuc-dropdown formControlName="field"
                              placeholder="Choose a field"
                              [searchable]="true"
                              [total]="identifierFields?.length"
                              [items]="identifierFields"
                              (requestData)="searchIdentifierFields($event)">
                </nuc-dropdown>
            </div>
        </div>
        <ng-container *ngIf="form.controls.mappings">
            <hr>
            <p>Map columns from the uploaded .csv/.xlf file to the Relayter fields. Fields that are not mapped will not
                be imported.</p>

            <div class="columns-display form-header">
                <p class="body-strong">Column to import</p>
                <p class="body-strong">Map to field</p>
            </div>

            <div class="mapping-form columns-display">
                <ng-container *ngFor="let subFormGroup of form.get('mappings')['controls'] index as index"
                              [formGroup]="subFormGroup">
                    <p class="csv-header text-ellipsis">{{subFormGroup.get('column').value}}</p>
                    <div class="field-selection">
                        <nuc-dropdown formControlName="field"
                                      [searchable]="true"
                                      (requestData)="searchAllowedFields($event)"
                                      placeholder="Not imported"
                                      [items]="allowedFields">
                        </nuc-dropdown>
                        <ng-container *ngIf="subFormGroup.get('identifier')">
                            <div class="mapping-identifier-dropdown">
                                <span><i class="nucicon_connector"></i></span>
                                <nuc-dropdown formControlName="identifier"
                                              (requestData)="searchAllowedFields($event)"
                                              [placeholder]="defaultFields[subFormGroup.get('field')?.value?.getValue()]?.placeholder"
                                              [items]="defaultFields[subFormGroup.get('field')?.value?.getValue()]?.options"></nuc-dropdown>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </form>
</ng-template>
