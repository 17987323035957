import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {EUploadStatus} from '../../components/upload-file-component/upload.model';
import {IUploadUpdate} from '../../components/upload-file-component/upload-file.component';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {Subject, combineLatest, BehaviorSubject} from 'rxjs';
import {IndesignLibraryModel, IndesignLibraryPatchModel} from '../../modules/format-rulesets/models/api/indesign-library.model';
import {takeUntil, map, startWith, distinctUntilChanged} from 'rxjs/operators';
import {
    ELibraryJobTypes,
    ICreateLibraryJobData,
    ILibraryJobData,
    IndesignLibraryService,
    IUpdateLibraryJobData
} from '../../api/services/indesign-library.service';
import {JobModel} from '../../models/api/job.model';
import {ARApiError, ARLogger} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {FileTypeUtil} from '../../classes/file-type.util';

export interface ILibraryFormData {
    library?: IndesignLibraryModel;
}

export interface ILibraryFormResult {
    jobId?: string;
}

@Component({
    selector: 'rl-library-form-component',
    templateUrl: 'library-form.component.html',
    styleUrls: ['library-form.component.scss']
})
export class LibraryFormComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public validationMessages = {
        name: RLValidatorConstants.MESSAGE_SETS.REQUIRED
    };

    private saveButton: ButtonConfig;

    public fileTypeCategories = [FileTypeUtil.CATEGORIES.INDESIGN_LIBRARY];
    private uploadData: IUploadUpdate;

    public uploadUpdateSubject = new BehaviorSubject<IUploadUpdate>(null);
    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private indesignLibraryService: IndesignLibraryService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: ILibraryFormData) {
    }


    public ngOnInit(): void {
        this.setupForm();
        this.setupModalActions();
        this.trackStatus();
    }

    private setupForm(): void {
        this.form = new UntypedFormGroup({
            name: new UntypedFormControl(this.modalData.library?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
        });
    }

    private setupModalActions(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null);
        const saveAction = new FullModalActionModel(this.saveButton);
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.modalData.library ? this.updateLibrary() : this.createLibrary();
        });

        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private trackStatus(): void {
        combineLatest([
            this.form.statusChanges.pipe(startWith(this.form.status), distinctUntilChanged(), map((status) => status === 'VALID')),
            this.uploadUpdateSubject.pipe(map((uploadUpdate) => {
                this.uploadData = uploadUpdate;
                return uploadUpdate?.status === EUploadStatus.Done || (this.modalData.library && !uploadUpdate);
            }))])
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(([formValid, uploadValid]) => this.saveButton.disabled = !formValid || !uploadValid);
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private updateLibrary(): void {
        if (this.uploadData?.s3Key) {
            const jobData =
                {libraryId: this.modalData.library._id, name: this.form.value.name.trim(), s3Key: this.uploadData.s3Key} as IUpdateLibraryJobData;
            this.postLibraryJob(ELibraryJobTypes.UPDATE_INDESIGN_LIBRARY_JOB, jobData);
        } else {
            const body = new IndesignLibraryPatchModel();
            body.name = this.form.value.name.trim();
            this.indesignLibraryService.patchLibrary(this.modalData.library._id, body).subscribe({
                next: () => {
                    this.fullModalService.close({});
                    Toaster.success('InDesign library updated successfully');
                },
                error: this.handleError
            });
        }
    }

    private createLibrary(): void {
        const jobData = {name: this.form.value.name.trim(), s3Key: this.uploadData.s3Key} as ICreateLibraryJobData;
        this.postLibraryJob(ELibraryJobTypes.CREATE_INDESIGN_LIBRARY, jobData);
    }

    private postLibraryJob(jobType: ELibraryJobTypes, jobData: ILibraryJobData): void {
        this.indesignLibraryService.postJob(jobType, jobData).subscribe({
            next: (job: JobModel) => {
                ARLogger.debug('InDesign Library Job scheduled: ' + job._id);
                this.fullModalService.close({jobId: job._id} as ILibraryFormResult); // close with job id for refreshing
            },
            error: this.handleError
        });
    }

    private handleError(error: ARApiError): void {
        Toaster.handleApiError(error);
        this.saveButton.loading = false;
    }
}
