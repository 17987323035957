import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {filter, map, takeWhile} from 'rxjs/operators';
import {ETransitionStatus, TransitionItemModel} from '../../models/api/transition-item.model';
import {Deserialize} from 'cerialize';
import {SocketService} from './socket/socket.service';

@Injectable({
    providedIn: 'root'
})
export class MonitoredTransitionsService {
    private monitoredTransitions: TransitionItemModel[] = [];
    public monitoredTransitionsSubject: ReplaySubject<TransitionItemModel[]> = new ReplaySubject<TransitionItemModel[]>(1);

    private workflowStepItemsChangedSubject = new Subject<TransitionItemModel>();
    public workflowStepItemsChangedSubject$ = this.workflowStepItemsChangedSubject.asObservable();

    constructor(private socketService: SocketService) {}

    /**
     * Clears a transition item from the monitoredTransitions array
     * @param {string} transitionItemId
     */
    public removeMonitoredTransition(transitionItemId: string): void {
        this.monitoredTransitions = this.monitoredTransitions
            .filter((monitoredTransition) => monitoredTransition._id !== transitionItemId);
        this.announceMonitorTransitions();
    }

    /**
     * Creates a new Observable to monitor a transition
     * @param {string} transitionItemId
     * @returns {Observable<TransitionItemModel>}
     */
    public getTransitionMonitor(transitionItemId: string): Observable<TransitionItemModel> {
        return this.socketService.publicationUpdates$
            .pipe(
                map(messageBody => Deserialize(messageBody.data, TransitionItemModel)),
                filter((transitionItem: TransitionItemModel) => transitionItem._id === transitionItemId),
                takeWhile((transitionItem: TransitionItemModel) =>
                    ![ETransitionStatus.DONE, ETransitionStatus.FAILED].includes(transitionItem.status), true)
            );
    }

    /**
     * announce monitored transitions
     */
    private announceMonitorTransitions(): void {
        this.monitoredTransitionsSubject.next(Array.from(this.monitoredTransitions));
    }

    /**
     * Add/updates transition item to monitor
     * @param {TransitionItemModel} transitionItem
     */
    public updateTransitionMonitor(transitionItem: TransitionItemModel): void {
        const updateTransitionItem = this.monitoredTransitions.find(item => item._id === transitionItem._id);
        if (updateTransitionItem) {
            updateTransitionItem.status = transitionItem.status;
            updateTransitionItem.error = transitionItem.error;
            updateTransitionItem.progress = transitionItem.progress;
        } else {
            this.monitoredTransitions.unshift(transitionItem);
        }
        this.announceMonitorTransitions();
    }

    public workflowStepItemsChanged(transitionItem: TransitionItemModel): void {
        this.workflowStepItemsChangedSubject.next(transitionItem);
    }

    /**
     * Remove all monitored transitions, used when user moving away from the publication and is not longer subscribed to publication updates
     */
    public removeAllMonitoredTransitions(): void {
        this.monitoredTransitions = [];
        this.announceMonitorTransitions();
    }
}
