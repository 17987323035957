<div class="detail-container">
    <rl-loading-indicator *ngIf="loading"></rl-loading-indicator>

    <ng-container *ngIf="!loading">

        <div class="changelog-subtitle" *ngIf="!changelogs || changelogs.length === 0">
            <p>No changes were made to this note.</p>
        </div>

        <div class="changelog" *ngFor="let changelog of changelogs; let i = index">
            <div class="row">
                <div class="line-container">
                    <img *ngIf="changelog.name === EChangelogField.MESSAGE" [src]="null | getNotePinImagePipe:changelog.message"/>
                    <img *ngIf="changelog.name === EChangelogField.STATUS" [src]="changelog.value | getNotePinImagePipe"/>
                    <div class="line" [class.last]="i === changelogs.length - 1"></div>
                </div>
                <div class="content-container">
                    <p class="body-strong black text-ellipsis">
                        <ng-container *ngIf="changelog.name === EChangelogField.MESSAGE">
                            Text edit
                        </ng-container>
                        <ng-container *ngIf="changelog.name !== EChangelogField.MESSAGE">
                            {{changelog.name | titlecase}}<span>: {{changelog.value | titlecase}}</span>
                        </ng-container>
                    </p>
                    <p class="body-strong grey">{{changelog.createdAt | RLDatePipe:dateFormats.STICKY_CHANGELOG}}</p>
                    <p class="body-strong grey">By {{changelog.userFullName | nullUndefinedFormatter:DELETED_USER}}</p>
                    <p *ngIf="changelog.message" class="message grey">
                        {{(readMore[i] || changelog.message?.length <= viewMoreLength) ? changelog.message : (changelog.message | slice:0:viewMoreLength)}}
                        <a *ngIf="changelog.message?.length > viewMoreLength" class="body-strong dark-grey" (click)="readMore[i]=!readMore[i]">{{readMore[i] ? ' View less' : ' ...View more'}}</a>
                    </p>
                </div>
            </div>
        </div>
    </ng-container>
</div>
