import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {IDeactivateComponent} from './deactivate-component.interface';
import {BUTTON_TYPE, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';

@Injectable()
export abstract class BaseDeactivateGuard  {
    public destinationRouteEqualsCurrentRoute: boolean = false;

    protected constructor(private dialogService: NucDialogService) {
    }

    /**
     * CanDeactivate<T>
     * @param {IDeactivateComponent} component
     * @param {ActivatedRouteSnapshot} currentRoute
     * @param {RouterStateSnapshot} currentState
     * @param {RouterStateSnapshot} nextState
     * @returns {boolean}
     */
    public canDeactivate(component: IDeactivateComponent,
                         currentRoute: ActivatedRouteSnapshot,
                         currentState: RouterStateSnapshot,
                         nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        this.setDestinationRouteEqualsCurrentRoute(currentState, nextState);

        return this.componentCanBeDeactivated(component, currentRoute, currentState, nextState);
    }

    /**
     * Method mandatory to be implemented by subclasses to determine if component can be deactivated
     * Default true
     * @returns {boolean}
     */
    public abstract componentCanBeDeactivated(component: IDeactivateComponent,
                                              currentRoute: ActivatedRouteSnapshot,
                                              currentState: RouterStateSnapshot,
                                              nextState?: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

    /**
     * Method that sets the property 'destinationRouteEqualsCurrentRoute' based on the current url and the next url.
     * Parses both urls based on the base url, removing all query parameters in order to do the comparison.
     * If nextState is null, destinationRouteEqualsCurrentRoute stays false
     * @param {RouterStateSnapshot} currentState
     * @param {RouterStateSnapshot} nextState
     */
    private setDestinationRouteEqualsCurrentRoute(currentState: RouterStateSnapshot,
                                                  nextState?: RouterStateSnapshot): void {
        const queryParamAnnouncerCharacter = ';';

        if (nextState) {
            // Need to manually strip the url strings
            // RouterStateSnapshot does not provide a reliable way to determine if user is navigating to same base path.
            const currentUrlWithoutQueryParameters = currentState.url.split(queryParamAnnouncerCharacter)[0];
            const nextUrlWithoutQueryParameters = nextState.url.split(queryParamAnnouncerCharacter)[0];

            this.destinationRouteEqualsCurrentRoute = currentUrlWithoutQueryParameters === nextUrlWithoutQueryParameters;
        }
    }

    protected openConfirmDialog(confirmSubject: Subject<boolean>): void {
        const confirmDialogConfig = new NucDialogConfigModel('Unsaved changes',
            'You are currently editing data, if you leave this page you will lose this data. Are you sure?');
        const confirmDialog = this.dialogService.openDialog(confirmDialogConfig);
        confirmDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
            confirmDialog.close();
            confirmSubject.next(false);
        });
        confirmDialogConfig.addAction('Yes', BUTTON_TYPE.PRIMARY).subscribe(() => {
            confirmDialog.close();
            confirmSubject.next(true);
        });

    }

}
