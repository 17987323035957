export class PixiSizeIndicator extends PIXI.Container {
    private static readonly TEXT_COLOR = '#000a12';
    private static readonly SIZE_INDICATOR_BORDER_COLOR = 0xE5E5E5;

    constructor(size, text) {
        super();
        this.width = size;

        const textStyle = new PIXI.TextStyle({fontSize: 12, fontWeight: 'bold', fill: [PixiSizeIndicator.TEXT_COLOR]});
        const sizeText = new PIXI.Text(text, textStyle);
        sizeText.x = (size - sizeText.width) / 2;
        this.addChild(sizeText);

        const indicator = new PIXI.Graphics();
        const indicatorLength = (size - sizeText.width - 10) / 2;

        indicator.beginFill(PixiSizeIndicator.SIZE_INDICATOR_BORDER_COLOR);
        indicator.drawRect(0, (this.height - 1) / 2, indicatorLength, 1);
        indicator.drawRect(size - indicatorLength, (this.height - 1) / 2, indicatorLength, 1);
        indicator.endFill();

        this.addChild(indicator);

        this.pivot.x = this.width / 2;
        this.pivot.y = this.height / 2;
    }

}
