import {Injectable} from '@angular/core';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {ApiConstants} from '../api.constant';
import {QueryParams} from '../../classes/query-params';
import {BaseApiRequestService} from './base-api-request.service';
import {Observable} from 'rxjs';
import {Invite, InviteBodyModel} from '../../models/api/invite.model';
import {environment} from '../../../environments/environment';
import {SortDirection} from '@angular/material/sort';

@Injectable({
    providedIn: 'root'
})
export class InviteService extends BaseApiRequestService {

    /**
     * Get a user invite with token
     * @param {string} token
     * @returns {Observable<Invite>}
     */
    public getUserInvite(token: string): Observable<Invite> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, ApiConstants.API_METHOD_REGISTER, token]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, Invite);
        });
    }

    public postInvite(inviteBody: InviteBodyModel): Observable<Invite> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_METHOD_INVITES]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = inviteBody;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, Invite);
        });
    }

    public getInvites(limit?: number,
                      offset?: number,
                      sortProperty?: string,
                      sortOrder?: SortDirection,
                      search?: string): Observable<ARPagedResponseDataModel<Invite>> {
        const queryParams = new QueryParams()
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setLimitAndOffsetParams(limit, offset)
            .setSearchParams(search);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_METHOD_INVITES], queryParams.getParams());
        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => this.handlePagedResponse(options, obs, Invite));
    }

    public deleteInvite(id: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_METHOD_INVITES, id]);
        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;

        return new Observable((obs) => this.handleNoErrorResponse(options, obs));
    }
}
