import {Component, HostBinding, Input, OnChanges} from '@angular/core';
import {UserModel} from '../../models/api/user.model';
import {AppConstants} from '../../app.constants';

@Component({
    selector: 'rl-user-icon-component',
    templateUrl: 'user-icon.component.html',
    styleUrls: ['user-icon.component.scss']
})

export class UserIconComponent implements OnChanges {

    private static readonly OWNER_COLOR = AppConstants.FIRST_BRAND_COLOR;
    private static readonly USER_COLORS = [
        '#B90505', // Pomegranate
        '#FA3E3E', // Watermelon
        '#FF6E39', // Coral
        '#F39C12', // Sunflower
        '#9B59B6', // Wisteria
        '#D980FA', // Amethyst
        '#5352ED', // Blueberry
        '#70A1FF', // Sky
        '#1289A7', // River
        '#12CBC4', // Turqoise
        '#FC427B', // Rose
        '#FF6B81', // Lotus
        '#009432', // Emerald
        '#A3CB38', // Grass
    ];

    @HostBinding('style.background') private backgroundColor: string;
    @Input() user: UserModel;

    public ngOnChanges(): void {
        if (this.user) {
            const index = UserIconComponent.hash(this.user.fullName) % UserIconComponent.USER_COLORS.length;
            this.backgroundColor = UserIconComponent.USER_COLORS[index];
        } else {
            this.backgroundColor = UserIconComponent.OWNER_COLOR;
        }
    }
/* eslint-disable no-bitwise */
    private static hash(str): number {
        let hash = 5381;
        let i = str.length;

        while(i) {
            hash = (hash * 33) ^ str.charCodeAt(--i);
        }

        /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
         * integers. Since we want the results to be always positive, convert the
         * signed int to an unsigned by doing an unsigned bitshift. */
        return hash >>> 0;
    }
/* eslint-enable no-bitwise */
}
