import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {QueryParams} from '../../classes/query-params';
import {FormatRulesetModel, FormatRulesetPatchModel, FormatRulesetPostModel} from '../../modules/format-rulesets/models/api/format-ruleset.model';
import {UsageModel} from '../../models/api/usage.model';
import {Cursor} from '../api-cursor';
import {SortDirection} from '@angular/material/sort';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-jobs.service';
import {Serialize} from 'cerialize';
import {FormatRulesetItemModel} from '../../modules/format-rulesets/models/api/format-ruleset-item.model';
import {FormatRulesetAssetItemModel} from '../../modules/format-rulesets/models/api/format-ruleset-asset-item.model';
import {FormatRulesetItemGroupModel} from '../../modules/format-rulesets/models/api/format-ruleset-item-group.model';
import {EInsertLocation} from '@relayter/rubber-duck';

export enum EFormatRulesetJobs {
    FORMAT_RULESET_COPY_JOB = 'FORMAT_RULESET_COPY_JOB'
}

export interface IFormatRulesetCopyJobData {
    formatRulesetId: string;
}

type ItemModel = FormatRulesetItemModel | FormatRulesetItemGroupModel | FormatRulesetAssetItemModel;

@Injectable({
    providedIn: 'root'
})
export class FormatRulesetService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    /**
     * Get a paged array of format rulesets
     *
     * @param {string} [libraryId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Cursor} cursor
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @returns {Observable<ARPagedResponseDataModel<FormatRulesetModel>>}
     */
    public getFormatRulesets(libraryId?: string, limit?: number, offset?: number, cursor?: Cursor, sortProperty?: string,
                             sortOrder?: SortDirection, search?: string): Observable<ARPagedResponseDataModel<FormatRulesetModel>> {

        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setSearchParams(search)
            .addParam('libraryId', libraryId);

        if (cursor) {
            queryParams.setCursor(cursor);
        }

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, FormatRulesetModel);
        });
    }

    /**
     * Get a format ruleset
     *
     * @param {string} formatRulesetId
     * @returns {Observable<ARPagedResponseDataModel<FormatRulesetModel>>}
     */
    public getFormatRuleset(formatRulesetId: string): Observable<FormatRulesetModel> {

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, FormatRulesetModel);
        });
    }

    /**
     * Get format ruleset usage
     *
     * @param {string} formatRulesetId
     * @returns {Observable<ARPagedResponseDataModel<UsageModel>>}
     */
    public getFormatRulesetUsage(formatRulesetId: string): Observable<UsageModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId,
            ApiConstants.API_METHOD_USAGE
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable<UsageModel>((obs) => {
            this.handleDetailResponse(options, obs, UsageModel);
        });
    }

    /**
     * Delete a Format ruleset
     *
     * @param {string} formatRulesetId
     * @returns {Observable<boolean>}
     */
    public deleteFormatRuleset(formatRulesetId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable<boolean>((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Post a new format ruleset
     *
     * @param {FormatRulesetPostModel} formatRulesetId
     * @returns {Observable<FormatRulesetModel>}
     */
    public postFormatRuleset(formatRulesetId: FormatRulesetPostModel): Observable<FormatRulesetModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = Serialize(formatRulesetId, FormatRulesetPostModel);

        return new Observable<FormatRulesetModel>((obs) => {
            this.handleDetailResponse(options, obs, FormatRulesetModel);
        });
    }

    /**
     * Update a format ruleset
     *
     * @param {string} formatRulesetId
     * @param {FormatRulesetPostModel} formatRuleset
     * @returns {Observable<FormatRulesetModel>}
     */
    public patchFormatRuleset(formatRulesetId: string, formatRuleset: FormatRulesetPatchModel): Observable<FormatRulesetModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = Serialize(formatRuleset, FormatRulesetPostModel);

        return new Observable<FormatRulesetModel>((obs) => {
            this.handleDetailResponse(options, obs, FormatRulesetModel);
        });
    }

    /**
     * Post a format ruleset job
     *
     * @param {EFormatRulesetJobs} jobType
     * @param {Record<string, any>} jobData
     * @returns Observable<JobModel>
     */
    public postJob(jobType: EFormatRulesetJobs, jobData: IFormatRulesetCopyJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            ApiConstants.API_METHOD_JOBS
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
        );
    }

    /**
     * Get format ruleset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @param {ItemModel} model
     * @returns Observable<ItemModel>
     */
    private getFormatRulesetItem<T extends ItemModel>(
        formatRulesetId: string,
        formatRulesetItemId: string,
        model: new (...args: any[]) => T): Observable<T> {

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId,
            new model().getApiPath(),
            formatRulesetItemId
        ]);

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => this.handleDetailResponse(options, obs, model));
    }

    /**
     * Update format ruleset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @param {ItemModel} item
     * @param {ItemModel} model
     * @returns Observable<ItemModel>
     */
    private updateFormatRulesetItem<T extends ItemModel>(
        formatRulesetId: string,
        formatRulesetItemId: string,
        item: T,
        model: new (...args: any[]) => T): Observable<T> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId,
            new model().getApiPath(),
            formatRulesetItemId
        ]);

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = item;
        return new Observable((obs) => this.handleDetailResponse(options, obs, model));
    }

    /**
     * Post format ruleset item
     *
     * @param {string} formatRulesetId
     * @param {ItemModel} item
     * @param {ItemModel} model
     * @returns Observable<ItemModel>
     */
    private postFormatRulesetItem<T extends ItemModel>(
        formatRulesetId: string,
        item: ItemModel,
        model: new (...args: any[]) => T): Observable<T> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId,
            new model().getApiPath()
        ]);

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = item;
        return new Observable((obs) => {
            this.handleDetailResponse<ItemModel>(options, obs, model);
        });
    }

    /**
     * Delete a format ruleset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @param {ItemModel} model
     * @returns Observable<boolean>
     */
    private deleteFormatRulesetItem<T extends ItemModel>(
        formatRulesetId: string,
        formatRulesetItemId: string,
        model: new (...args: any[]) => T): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId,
            new model().getApiPath(),
            formatRulesetItemId
        ]);

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => this.handleNoErrorResponse(options, obs));
    }

    /**
     * Get format ruleset items/asset items
     *
     * @param {string} formatRulesetId
     * @param {number} limit
     * @param {number} offset
     * @param {ItemModel} model
     * @param {string} [sortProperty]
     * @param {SortDirection} [sortOrder]
     * @param {string} [search]
     * @param {string} [itemGroupId]
     * @returns Observable<ARPagedResponseDataModel<ItemModel>>
     */
    private getFormatRulesetItems<T extends ItemModel>(
        formatRulesetId: string,
        limit = 20,
        offset = 0,
        model: new (...args: any[]) => T,
        sortProperty?: string,
        sortOrder?: SortDirection,
        search?: string,
        itemGroupId?: string):
        Observable<ARPagedResponseDataModel<ItemModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder);

        if (search) {
            queryParams.addParam('search', search);
        }

        if (itemGroupId) {
            queryParams.addParam('itemGroupId', itemGroupId);
        }

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId,
            new model().getApiPath()
        ], queryParams.getParams());

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, model);
        });
    }

    /**
     * Get format ruleset asset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<FormatRulesetAssetItemModel>
     */
    public getFormatRulesetAssetItem(formatRulesetId: string, formatRulesetItemId: string):
        Observable<FormatRulesetAssetItemModel> {
        return this.getFormatRulesetItem(formatRulesetId, formatRulesetItemId, FormatRulesetAssetItemModel) as
            Observable<FormatRulesetAssetItemModel>;
    }

    /**
     * Get format ruleset library item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<FormatRulesetItemModel>
     */
    public getFormatRulesetLibraryItem(formatRulesetId: string, formatRulesetItemId: string): Observable<FormatRulesetItemModel> {
        return this.getFormatRulesetItem(formatRulesetId, formatRulesetItemId, FormatRulesetItemModel) as Observable<FormatRulesetItemModel>;
    }

    /**
     * Get format ruleset item group
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<FormatRulesetItemGroupModel>
     */
    public getFormatRulesetItemGroup(formatRulesetId: string, formatRulesetItemId: string): Observable<FormatRulesetItemGroupModel> {
        return this.getFormatRulesetItem(
            formatRulesetId,
            formatRulesetItemId,
            FormatRulesetItemGroupModel) as Observable<FormatRulesetItemGroupModel>;
    }

    /**
     * Update format ruleset asset item
     *
     * @param {string} formatRulesetId
     * @param {string} itemId
     * @param {FormatRulesetAssetItemModel} item
     * @returns Observable<FormatRulesetAssetItemModel>
     */
    public updateFormatRulesetAssetItem(formatRulesetId: string, itemId: string, item: FormatRulesetAssetItemModel):
        Observable<FormatRulesetAssetItemModel> {
        return this.updateFormatRulesetItem(
            formatRulesetId,
            itemId,
            item,
            FormatRulesetAssetItemModel) as Observable<FormatRulesetAssetItemModel>;
    }

    /**
     * Update format ruleset library item
     *
     * @param {string} formatRulesetId
     * @param {string} itemId
     * @param {FormatRulesetItemModel} item
     * @returns Observable<FormatRulesetItemModel>
     */
    public updateFormatRulesetLibraryItem(formatRulesetId: string, itemId: string, item: FormatRulesetItemModel): Observable<FormatRulesetItemModel> {
        return this.updateFormatRulesetItem(
            formatRulesetId,
            itemId,
            item,
            FormatRulesetItemModel) as Observable<FormatRulesetItemModel>;
    }

    /**
     * Update format ruleset item group
     * @param {string} formatRulesetId
     * @param {string} itemId
     * @param {FormatRulesetItemGroupModel} item
     * @returns Observable<FormatRulesetItemGroupModel>
     */
    public updateFormatRulesetItemGroup(formatRulesetId: string, itemId: string, item: FormatRulesetItemGroupModel):
        Observable<FormatRulesetItemGroupModel> {
        return this.updateFormatRulesetItem(formatRulesetId, itemId, item, FormatRulesetItemGroupModel) as Observable<FormatRulesetItemGroupModel>;
    }

    /**
     * Post a format ruleset asset item
     *
     * @param {string} formatRulesetId
     * @param {FormatRulesetAssetItemModel} rulesetAssetItem
     * @returns Observable<FormatRulesetAssetItemModel>
     */
    public postFormatRulesetAssetItem(formatRulesetId: string, rulesetAssetItem: FormatRulesetAssetItemModel):
        Observable<FormatRulesetAssetItemModel> {
        return this.postFormatRulesetItem(formatRulesetId, rulesetAssetItem, FormatRulesetAssetItemModel) as Observable<FormatRulesetAssetItemModel>;
    }

    /**
     * Post a format ruleset library item
     *
     * @param {string} formatRulesetId
     * @param {FormatRulesetItemModel} formatRulesetItem
     * @returns Observable<FormatRulesetItemModel>
     */
    public postFormatRulesetLibraryItem(formatRulesetId: string, formatRulesetItem: FormatRulesetItemModel):
        Observable<FormatRulesetItemModel> {
        return this.postFormatRulesetItem(formatRulesetId, formatRulesetItem, FormatRulesetItemModel) as Observable<FormatRulesetItemModel>;
    }

    /**
     * Post a format ruleset item group
     *
     * @param {string} formatRulesetId
     * @param {FormatRulesetItemGroupModel} item
     * @returns Observable<FormatRulesetItemGroupModel>
     */
    public postFormatRulesetItemGroup(formatRulesetId: string, item: FormatRulesetItemGroupModel): Observable<FormatRulesetItemGroupModel> {
        return this.postFormatRulesetItem(formatRulesetId, item, FormatRulesetItemGroupModel) as Observable<FormatRulesetItemGroupModel>;
    }

    /**
     * Delete a format ruleset asset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<boolean>
     */
    public deleteFormatRulesetAssetItem(formatRulesetId: string, formatRulesetItemId: string): Observable<boolean> {
        return this.deleteFormatRulesetItem(formatRulesetId, formatRulesetItemId, FormatRulesetAssetItemModel);
    }

    /**
     * Delete a format ruleset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<boolean>
     */
    public deleteFormatRulesetLibraryItem(formatRulesetId: string, formatRulesetItemId: string): Observable<boolean> {
        return this.deleteFormatRulesetItem(formatRulesetId, formatRulesetItemId, FormatRulesetItemModel);
    }

    /**
     * Delete a format ruleset item group
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<boolean>
     */
    public deleteFormatRulesetItemGroup(formatRulesetId: string, formatRulesetItemId: string): Observable<boolean> {
        return this.deleteFormatRulesetItem(formatRulesetId, formatRulesetItemId, FormatRulesetItemGroupModel);
    }


    /**
     * Get format ruleset asset items
     *
     * @param {string} formatRulesetId
     * @param {number} limit
     * @param {number} offset
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @returns Observable<ARPagedResponseDataModel<FormatRulesetAssetItemModel>>
     */
    public getFormatRulesetAssetItems(formatRulesetId: string,
                                      limit = 20,
                                      offset = 0,
                                      sortProperty?: string,
                                      sortOrder?: SortDirection,
                                      search?: string): Observable<ARPagedResponseDataModel<FormatRulesetAssetItemModel>> {
        return this.getFormatRulesetItems(formatRulesetId,
            limit,
            offset,
            FormatRulesetAssetItemModel,
            sortProperty,
            sortOrder,
            search) as Observable<ARPagedResponseDataModel<FormatRulesetAssetItemModel>>;
    }

    /**
     * Get format ruleset library items
     *
     * @param {string} formatRulesetId
     * @param {number} limit
     * @param {number} offset
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @param {string} itemGroup
     * @returns Observable<ARPagedResponseDataModel<FormatRulesetItemModel>>
     */
    public getFormatRulesetLibraryItems(formatRulesetId: string,
                                        limit = 20,
                                        offset = 0,
                                        sortProperty?: string,
                                        sortOrder?: SortDirection,
                                        search?: string,
                                        itemGroup?: string): Observable<ARPagedResponseDataModel<FormatRulesetItemModel>> {
        return this.getFormatRulesetItems(formatRulesetId,
            limit,
            offset,
            FormatRulesetItemModel,
            sortProperty,
            sortOrder,
            search,
            itemGroup) as Observable<ARPagedResponseDataModel<FormatRulesetItemModel>>;
    }

    /**
     * Get format ruleset item groups
     *
     * @param {string} formatRulesetId
     * @param {number} limit
     * @param {number} offset
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @returns Observable<ARPagedResponseDataModel<FormatRulesetItemGroupModel>>
     */
    public getFormatRulesetItemGroups(formatRulesetId: string,
                                        limit = 20,
                                        offset = 0,
                                        sortProperty?: string,
                                        sortOrder?: SortDirection,
                                        search?: string): Observable<ARPagedResponseDataModel<FormatRulesetItemGroupModel>> {
        return this.getFormatRulesetItems(formatRulesetId,
            limit,
            offset,
            FormatRulesetItemGroupModel,
            sortProperty,
            sortOrder,
            search) as Observable<ARPagedResponseDataModel<FormatRulesetItemGroupModel>>;
    }

    public orderRulesetItems(formatRulesetId, targetId, sourceId, insertLocation?: EInsertLocation): Observable<FormatRulesetModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_FORMAT_RULESETS,
            formatRulesetId,
            ApiConstants.API_METHOD_ORDER
        ]);

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = {
            targetId,
            sourceId
        };
        if (insertLocation) {
            options.body.insertLocation = insertLocation;
        }
        return new Observable((obs) => {
            this.handleDetailResponse<FormatRulesetModel>(options, obs, FormatRulesetModel);
        });
    }
}
