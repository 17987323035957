import {Pipe, PipeTransform} from '@angular/core';
import {ENotificationActionType} from '../../../models/api/notification.model';
import {ARLogger} from '@relayter/core';

@Pipe({
    name: 'notificationIcon'
})
export class NotificationIconPipe implements PipeTransform {

    public transform(notificationType: ENotificationActionType): string {
        switch (notificationType) {
            case ENotificationActionType.DOWNLOAD:
                return 'nucicon_download';
            case ENotificationActionType.NAVIGATE:
                return 'nucicon_search_go';
            default:
                ARLogger.error(`No icon for notification action type: ${notificationType}`);
                return 'nucicon_question_line';
        }
    }

}
