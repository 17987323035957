<form [formGroup]="formGroup">
    <nuc-form-field label="Format Ruleset name">
        <nuc-input formControlName="name" placeholder="Format Ruleset name"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="InDesign library">
        <nuc-dropdown-multiselect formControlName="designLibraries"
                                  placeholder="Select one or more InDesign libraries"
                                  [items]="designLibraries$ | async">
        </nuc-dropdown-multiselect>
    </nuc-form-field>
</form>
