<form [formGroup]="formGroup">
    <nuc-form-field label="Name">
        <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Publication type">
        <nuc-dropdown formControlName="publicationType"
                      [items]="publicationTypes"
                      [total]="publicationTypes.length"
                      placeholder="Select a publication type"
                      required>
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Description">
        <nuc-textarea formControlName="description" placeholder="Enter description" [noHorizontalResize]="true"></nuc-textarea>
    </nuc-form-field>

</form>
