import {autoserialize, autoserializeAs} from 'cerialize';
import {ChannelModel} from './channel.model';
import {PublicationItemModel} from './publication-item.model';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';
import {PrintPublicationItemModel} from './custom-workflow/print-publication-item.model';
import {PosPublicationItemModel} from './pos-publication-item.model';
import {WebPublicationItemModel} from './web-publication-item.model';
import {ARLogger} from '@relayter/core';

export class PublicationModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserializeAs(ChannelModel) public channel: ChannelModel;
    @autoserialize public workflow: string;
    @autoserialize public variants: string[];
    @autoserializeAs(Date) public deadline: Date;

    /**
     * Returns a boolean to check if the channel is from the WEB channel
     * @returns {boolean}
     */
    public channelIsWeb(): boolean {
        return this.channel ? this.channel.isWebChannel() : false;
    }

    /**
     * Returns a boolean to check if the channel is from the POS channel
     * @returns {boolean}
     */
    public channelIsPos(): boolean {
        return this.channel ? this.channel.isPosChannel() : false;
    }

    /**
     * Returns a boolean to check if the channel is from the print channel
     * @returns {boolean}
     */
    public channelIsPrintPublication(): boolean {
        return this.channel ? this.channel.isPrintChannel() : false;
    }

    public getItemModel(): new (...args: any[]) => PublicationItemModel {
        switch (this.channel?.name) {
            case EPublicationType.PRINT_MAGAZINE:
                return PrintPublicationItemModel;
            case EPublicationType.POS:
                return PosPublicationItemModel;
            case EPublicationType.WEB:
                return WebPublicationItemModel;
            default:
                ARLogger.warn(`Missing correct implementation for publication type: ${this.channel?.name}`);
                return PublicationItemModel;
        }
    }
}

export class PublicationPostModel {
    @autoserialize public name: string;
    @autoserialize public channel: string;
    @autoserialize public tags: string[];
    @autoserialize public workflow: string;
    @autoserialize public variants: string[];
    @autoserializeAs(Date) public deadline: Date;

    constructor(name: string, channel: string, workflow: string, tags: string[], variants: string[], deadline: Date) {
        this.name = name;
        this.channel = channel;
        this.workflow = workflow;
        this.tags = tags;
        this.variants = variants;
        this.deadline = deadline ? deadline : null;  // Null to reset the date
    }
}

export class PublicationPatchModel {
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserializeAs(Date) public deadline: Date;

    constructor(name: string, tags: string[], deadline: Date) {
        this.name = name;
        this.tags = tags;
        this.deadline = deadline ? deadline : null; // Null to reset the date
    }
}
