import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FileTypeUtil} from '../../classes/file-type.util';
import {filter, map, takeUntil, withLatestFrom} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {
    EUploadStatus,
    UploadModel
} from './upload.model';
import {AmazonService} from '../../api/services/amazon.service';
import {EToastType, ToastDataModel, ToastService} from '@relayter/rubber-duck';
import {GetFilePathPipe} from '../../pipes/get-file-path';
import {Toaster} from '../../classes/toaster.class';

export interface IUploadUpdate {
    status: EUploadStatus;
    s3Key: string;
    signedUrl: string;
    fileProperties: { fileType: string; fileName: string };
}

@Component({
    selector: 'rl-upload-file-component',
    templateUrl: 'upload-file.component.html',
    styleUrls: ['upload-file.component.scss']
})

export class UploadFileComponent implements OnInit, OnDestroy {

    @Output() public onUploadUpdate = new EventEmitter<IUploadUpdate>();

    @Input() public fileTypeCategories: string[];
    @Input() public title = 'File';
    @Input() public subtitle: string;

    public upload: UploadModel;
    public EUploadStatus = EUploadStatus;
    public fileTypeImage = FileTypeUtil.FILE_TYPE_IMAGES.IMAGE;
    public newPath: string;

    private uploadSubscription: Subscription;
    private onDestroySubject = new Subject<void>();

    public get fileTypeExtensionsString() {
        return FileTypeUtil.getExtensionsFromFileCategories(this.fileTypeCategories).join('/');
    }

    constructor(private amazonService: AmazonService,
                private toastService: ToastService) {
    }

    public ngOnInit(): void {
        if (!this.subtitle) {
            this.subtitle = this.fileTypeCategories?.length ? `Drag your ${this.fileTypeExtensionsString} file here` : 'Drag your file here';
        }
        if (this.fileTypeCategories?.length === 1) {
            const extension = FileTypeUtil.FILE_TYPE_CATEGORIES[this.fileTypeCategories[0]]?.extensions[0];
            this.fileTypeImage = GetFilePathPipe.getFilePath(extension);
        } else {
            this.fileTypeImage = FileTypeUtil.FILE_TYPE_IMAGES.MULTIPLE;
        }
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * When a new file is dragged in, validate and start a new upload
     */
    public onFilesChanged(files: File[]): void {
        this.newPath = null;
        if (!files || !files.length) {
            return;
        }

        const file = files[0];
        if (this.fileTypeCategories) {
            const originalLength = files.length;
            const filteredFiles = FileTypeUtil.filterFiles([file], this.fileTypeCategories);
            if (originalLength > filteredFiles.length) {
                const toastData = new ToastDataModel(EToastType.WARNING,
                    `Please provide a file of the correct type (${this.fileTypeExtensionsString})`);
                return this.toastService.show(toastData);
            }
        }

        this.upload = this.amazonService.createUpload(file);
        const fileProperties = {
            fileType: this.upload.fileType,
            fileName: this.upload.filename
        };
        this.onUploadUpdate.emit({status: EUploadStatus.Uploading, s3Key: null, signedUrl: null, fileProperties});
        this.uploadSubscription = this.upload.progress$.pipe(
            filter((progress) => progress === EUploadStatus.Done || progress === EUploadStatus.Failed),
            withLatestFrom(this.upload.s3Key$, this.upload.signedUrl$),
            map(([status, s3Key, path]) => {
                return {status, s3Key, path};
            }),
            takeUntil(this.onDestroySubject)
        ).subscribe({
            next: (data) => {
                this.newPath = data.path;
                this.onUploadUpdate.emit({
                    status: data.status,
                    s3Key: data.s3Key,
                    signedUrl: data.path,
                    fileProperties
                });
            },
            error: Toaster.handleApiError
        });
    }

    /**
     * When delete is clicked clean up the subscription and emit null
     */
    public onDeleteClicked(): void {
        this.uploadSubscription?.unsubscribe();
        this.upload = null;
        this.onUploadUpdate.emit(null);
    }

}
