/**
 *  aws-signed-url-header-model
 *  app-relator-web
 *  Created by marcelhoogesteger on 02/04/2019.
 *  Copyright © 2019 Creative Media Network. All rights reserved.
 */
import {autoserialize} from 'cerialize';

export class AwsSignedUrlHeaderModel {
    @autoserialize public key: string;
    @autoserialize public value: string;
}
