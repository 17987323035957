import {Validators} from '@angular/forms';
import {RLValidatorRegExConstants} from './rl-validator-regex.constant';
import {RLRepeatablePasswordValidator} from './rl-validator-repeatable-password.validator';
import {RLRepeatPasswordValidator} from './rl-validator-repeat-password.validator';
import {AppConstants} from '../../app.constants';
import {CustomRequiredValidator} from './rl-custom-required.validator';
import {ExactlyOneValidator} from './rl-custom-exaclty-one.validator';
import {AtLeastOneValidator} from './rl-custom-at-least-one.validator';
import {CustomUniqueFormArrayValidator} from './rl-custom-unique-form-array.validator';
import {RLMustMatchValidator} from './rl-must-match.validator';

export class RLValidatorConstants {

    public static readonly KEYS = {
        REQUIRED: 'required',
        MIN_LENGTH: 'minlength',
        MAX_LENGTH: 'maxlength',
        MIN: 'min',
        MAX: 'max',
        PASSWORD: 'password',
        EMAIL: 'email',
        PATTERN: 'pattern',
        NOT_ENOUGH_ITEMS: 'notenoughitems',
        EVEN_NUMBER: 'evennumber',
        URL: 'url',
        ALPHANUMERIC: 'alphanumeric',
        NUMBER: 'number',
        EXACTLY_ONE: 'exactlyone',
        UNIQUE_FORM_ARRAY: 'uniqueformarray'
    };

    // Message keys are concatenated without underscore because reactive forms needs valid object properties
    public static readonly MESSAGES = {
        REQUIRED: 'Required',
        MINLENGTH: 'Value must be at least 4 characters long.',
        MAXLENGTH: 'Value cannot be more than 24 characters long.',
        MIN: 'Amount should at least be 0',
        MAX: 'Amount is too large, please lower the value', // Number.MAX_VALUE
        PASSWORD: 'Password requires at least 10 characters',
        EMAIL: 'Not valid',
        VALIDDATERANGE: 'Please enter a valid date range',
        NOTEQUALS: 'Passwords do not match',
        EVEN_NUMBER: 'The number you have entered should be an even number (e.g. 2, 4, 6, 20, 100)',
        EAN: 'Please enter a valid ean number, more information about EAN/GTIN ' +
            '<a href="http://www.gtin.info" target="_blank">here</a>',
        MIN_INDESIGN_BOUNDARIES: `Value should at least be ${AppConstants.INDESIGN_DOCUMENT_BOUNDARIES.MINIMUM_HEIGHT_AND_WIDTH}`,
        URL: 'Please enter a valid url',
        ALPHANUMERIC: 'Value can only contain letters, numbers, \'-\', \'_\' and \'?\'',
        NUMBER: 'Value should be a valid number',
        GREATER_THAN_ZERO: 'Value should be bigger than 0',
        EXACTLY_ONE: 'There should be exactly one value',
        UNIQUE_FORM_ARRAY: 'Value cannot be used multiple times',
        DATA_FIELD_NAME: 'Value can only contain letters, numbers, spaces, \'-\' and \'_\'',
        UNIQUE: 'Value should be unique',
        LAYOUT_REQUIRED: 'Layout is required when using PDF Export settings'
    };
    public static readonly MESSAGE_SETS = {
        TAGS: {
            notenoughitems: 'Please add more tags',
            required: RLValidatorConstants.MESSAGES.REQUIRED
        },
        EXACTLY_ONE: {
            exactlyone: RLValidatorConstants.MESSAGES.EXACTLY_ONE
        },
        REQUIRED: {
            required: RLValidatorConstants.MESSAGES.REQUIRED
        },
        REPEAT_PASSWORD: {
            required: RLValidatorConstants.MESSAGES.REQUIRED,
            notequals: RLValidatorConstants.MESSAGES.NOTEQUALS
        },
        REPEATABLE_PASSWORD: {
            required: RLValidatorConstants.MESSAGES.REQUIRED,
            maxlength: RLValidatorConstants.MESSAGES.MAXLENGTH,
            minlength: RLValidatorConstants.MESSAGES.PASSWORD
        },
        EMAIL: {
            required: RLValidatorConstants.MESSAGES.REQUIRED,
            pattern: RLValidatorConstants.MESSAGES.EMAIL
        },
        EAN: {
            pattern: RLValidatorConstants.MESSAGES.EAN
        },
        DATERANGE: {
            required: RLValidatorConstants.MESSAGES.VALIDDATERANGE,
        },
        POSITIVE_NUMBER: {
            min: RLValidatorConstants.MESSAGES.MIN,
            max: RLValidatorConstants.MESSAGES.MAX
        },
        POSITIVE_NUMBER_REQUIRED: {
            required: RLValidatorConstants.MESSAGES.REQUIRED,
            min: RLValidatorConstants.MESSAGES.MIN,
            max: RLValidatorConstants.MESSAGES.MAX
        },
        INDESIGN_DOCUMENT_SIZE_BOUNDARIES: {
            required: RLValidatorConstants.MESSAGES.REQUIRED,
            min: RLValidatorConstants.MESSAGES.MIN_INDESIGN_BOUNDARIES
        },
        URL: {
            pattern: RLValidatorConstants.MESSAGES.URL,
        },
        ALPHANUMERIC: {
            required: RLValidatorConstants.MESSAGES.REQUIRED,
            pattern: RLValidatorConstants.MESSAGES.ALPHANUMERIC
        },
        NUMBER: {
            pattern: RLValidatorConstants.MESSAGES.NUMBER
        },
        GREATER_THAN_ZERO: {
            required: RLValidatorConstants.MESSAGES.REQUIRED,
            min: RLValidatorConstants.MESSAGES.GREATER_THAN_ZERO
        },
        UNIQUE_FORM_ARRAY: {
            uniqueformarray: RLValidatorConstants.MESSAGES.UNIQUE_FORM_ARRAY
        }
    };
    public static readonly VALIDATOR_SETS = {
        PASSWORD: [
            CustomRequiredValidator,
            Validators.minLength(10)
        ],
        EXACTLY_ONE: [
            ExactlyOneValidator
        ],
        TAGS: [
            AtLeastOneValidator
        ],
        REPEATABLE_PASSWORD: [
            CustomRequiredValidator,
            Validators.minLength(10),
            RLRepeatablePasswordValidator
        ],
        REPEAT_PASSWORD: [
            CustomRequiredValidator,
            RLRepeatPasswordValidator
        ],
        NAME: [
            CustomRequiredValidator
        ],
        EMAIL: [
            CustomRequiredValidator,
            Validators.pattern(RLValidatorRegExConstants.EMAIL)
        ],
        EAN: [
            Validators.pattern(RLValidatorRegExConstants.EAN)
        ],
        REQUIRED: [
            CustomRequiredValidator
        ],
        REQUIRED_NUMBER: [
            Validators.required
        ],
        GREATER_THAN_ZERO: [
            CustomRequiredValidator,
            Validators.min(1)
        ],
        EVEN_NUMBER: [
            CustomRequiredValidator,
            Validators.pattern(RLValidatorRegExConstants.EVEN_NUMBER),
            Validators.min(0),
            Validators.max(1000)
        ],
        POSITIVE_NUMBER: [
            Validators.min(0),
            Validators.max(Number.MAX_VALUE)
        ],
        POSITIVE_NUMBER_REQUIRED: [
            Validators.required,
            Validators.min(0),
            Validators.max(Number.MAX_VALUE)
        ],
        INDESIGN_DOCUMENT_SIZE_BOUNDARIES: [
            CustomRequiredValidator,
            Validators.min(AppConstants.INDESIGN_DOCUMENT_BOUNDARIES.MINIMUM_HEIGHT_AND_WIDTH),
        ],
        URL: [
            Validators.pattern(RLValidatorRegExConstants.URL)
        ],
        ALPHANUMERIC: [
            CustomRequiredValidator,
            Validators.pattern(RLValidatorRegExConstants.ALPHANUMERIC)
        ],
        NUMBER: [
            Validators.pattern(RLValidatorRegExConstants.NUMBER)
        ],
        UNIQUE_FORM_ARRAY: [
            CustomUniqueFormArrayValidator
        ],
        DATA_FIELD_NAME: [
            Validators.required,
            Validators.pattern(RLValidatorRegExConstants.DATA_FIELD_NAME)
        ]
    };

    public static readonly FORM_GROUP_VALIDATOR = {
        MUST_MATCH: RLMustMatchValidator
    };
}
