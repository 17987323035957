/* "Barrel" of Http Interceptors */
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {JwtInterceptor} from './authentication.interceptor';
import {CredentialsInterceptor} from './credentials.interceptor';
import {ServiceUnavailableInterceptor} from './service-unavailable.interceptor';

/** Http interceptor providers in outside-in order (Requests going down - Responses going up to the chain) */
export const httpInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: ServiceUnavailableInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true}
];
