import {ITableAction} from '@relayter/rubber-duck';

export class TableActionTypeModel implements ITableAction {
    public title: string;
    public icon: string;

    constructor(title: string, icon: string) {
        this.title = title;
        this.icon = icon;
    }
}
