import {EDataCollectionName, EDataFieldFormatter, EDataFieldTypes} from '../../app.enums';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DataFieldDataTypeEnumerationModel} from '../api/data-field-data-type-enumeration.model';

export enum EDataFilterModels {
    DATA_FILTER = 'DATA_FILTER',
    DATA_FIELD_FILTER = 'DATA_FIELD_FILTER'
}

export class DataFilterModel<T = string> {
    public modelName: EDataFilterModels;
    public displayName: string;
    public property: string;
    public type: EDataFieldTypes;
    public items: IDropdownItem<T>[];

    public collectionName?: EDataCollectionName;
    public requestOptions?: Record<string, any>;
    public formatter?: EDataFieldFormatter;
    public enumeration?: DataFieldDataTypeEnumerationModel;

    public workflowFilterId: string;

    constructor(displayName: string, property: string, type: EDataFieldTypes,
                items?: IDropdownItem<T>[], enumeration?: DataFieldDataTypeEnumerationModel,
                requestOptions?: Record<string, any>, collectionName?: EDataCollectionName, workflowFilterId?: string) {
        this.modelName = EDataFilterModels.DATA_FILTER;

        this.displayName = displayName;
        this.property = property;
        this.type = type;

        if (items) this.items = items;
        if (enumeration) this.enumeration = enumeration;
        if (requestOptions) this.requestOptions = requestOptions;
        if (collectionName) this.collectionName = collectionName;
        if (workflowFilterId) this.workflowFilterId = workflowFilterId;
    }
}
