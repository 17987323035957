import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, map, takeUntil, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {FormatRulesetModel, FormatRulesetPatchModel, FormatRulesetPostModel} from '../models/api/format-ruleset.model';
import {FormatRulesetService} from '../../../api/services/format-ruleset.service';
import {AppConstants} from '../../../app.constants';
import {ARPagedResponseDataModel} from '@relayter/core';
import {IndesignLibraryService} from '../../../api/services/indesign-library.service';
import {Toaster} from '../../../classes/toaster.class';
import {IndesignLibraryModel} from '../models/api/indesign-library.model';

export interface IFormatRulesetFormComponentData {
    ruleSet?: FormatRulesetModel;
}

@Component({
    selector: 'ruleset-form',
    templateUrl: './ruleset-form.component.html',
    styleUrls: ['./ruleset-form.component.scss']
})
export class RulesetFormComponent implements OnInit, OnDestroy {
    public nameControl = new FormControl('', Validators.required);
    public designLibrariesControl = new FormControl('', Validators.required);

    public formGroup: FormGroup = new FormGroup({
        name: this.nameControl,
        designLibraries: this.designLibrariesControl,
    });

    public designLibraries$: Observable<IndesignLibraryModel[]>;
    private saveButtonConfig: ButtonConfig;
    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private formatRulesetService: FormatRulesetService,
                private inDesignLibraryService: IndesignLibraryService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IFormatRulesetFormComponentData) {
    }

    public ngOnInit(): void {
        this.initButtons();
        this.getInDesignLibraries();
        this.updateFormStatus();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initButtons(): void {
        this.saveButtonConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.modalData.ruleSet ? 'Save' : 'Create', false, false, true);
        const cancelButtonConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const saveAction = new FullModalActionModel(this.saveButtonConfig);
        const cancelAction = new FullModalActionModel(cancelButtonConfig);
        const actions = [
            cancelAction,
            saveAction,
        ];

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveRuleset());

        this.fullModalService.setModalActions(actions);
    }

    private updateFormStatus(): void {
        this.formGroup.statusChanges.pipe(
            map((status) => status === 'VALID'),
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((valid) => this.saveButtonConfig.disabled = !valid);
    }

    private getInDesignLibraries(): void {
        // TODO: add filter on library ids
        const observable: Observable<ARPagedResponseDataModel<IndesignLibraryModel>> =
            this.inDesignLibraryService.getIndesignLibraries(AppConstants.PAGE_SIZE_MAX, 0);

        this.designLibraries$ =
            observable.pipe(
                map((value) => value.items),
                tap((libraries) => {
                    if (this.modalData.ruleSet) {
                        const ruleSet = this.modalData.ruleSet;
                        const selectedLibraries = libraries.filter((library) =>
                            ruleSet.libraries.some((selectedLibraryId) => selectedLibraryId === library._id));
                        const update = {
                            name: ruleSet.name,
                            designLibraries: selectedLibraries
                        };
                        this.formGroup.patchValue(update);
                    }
                }),
                takeUntil(this.onDestroySubject));
    }

    private saveRuleset(): void {
        const name = this.formGroup.value.name;

        const designLibraries = this.formGroup.value.designLibraries ?
            this.formGroup.value.designLibraries.map((library) => library._id) : [];
        const ruleSet = this.modalData.ruleSet ?
            new FormatRulesetPatchModel(name, designLibraries) : new FormatRulesetPostModel(name, designLibraries);
        const postOrPatch$ = this.modalData.ruleSet ?
            this.formatRulesetService.patchFormatRuleset(this.modalData.ruleSet._id, ruleSet as FormatRulesetPatchModel) :
            this.formatRulesetService.postFormatRuleset(ruleSet as FormatRulesetPostModel);

        postOrPatch$.pipe(takeUntil(this.onDestroySubject)).subscribe(
            (result) => {
                Toaster.success(`Successfully ${this.modalData.ruleSet ? 'updated' : 'created'} ruleset : ${name}`);
                this.fullModalService.close(result);
            },
            (error) => Toaster.handleApiError(error));
    }

}
