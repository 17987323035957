import {autoserialize} from 'cerialize';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export const publicationTypeDisplayNames = new Map<EPublicationType, string>([
    [EPublicationType.PRINT_MAGAZINE, 'Print magazine'],
    [EPublicationType.POS, 'POS'],
    [EPublicationType.WEB, 'Web']
]);

export class ChannelModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: EPublicationType;

    /**
     * Check if a channel is the print channel
     * @returns {boolean}
     */
    public isPrintChannel(): boolean {
        return this.isPublicationType(EPublicationType.PRINT_MAGAZINE);
    }

    /**
     * Check if a channel is the pos channel
     * @returns {boolean}
     */
    public isPosChannel(): boolean {
        return this.isPublicationType(EPublicationType.POS);
    }

    /**
     * Check if a channel is the web channel
     * @returns {boolean}
     */
    public isWebChannel(): boolean {
        return this.isPublicationType(EPublicationType.WEB);
    }

    /**
     * Check if a channel is the given publicationType
     * @returns {boolean}
     */
    public isPublicationType(publicationType: EPublicationType): boolean {
        return this.name === publicationType;
    }

    public getTitle(): string {
        return publicationTypeDisplayNames.get(this.name);
    }

    public getValue(): string {
        return this._id;
    }
}
