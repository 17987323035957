import {autoserialize, autoserializeAs} from 'cerialize';
import {PublicationModel} from './publication.model';
import {WorkflowAutomationModel} from './workflow-automation.model';
export class TemplateUsageInCampaignModel {
    @autoserialize public campaignId: string;
    @autoserialize public name: string;
    @autoserialize public status: string;
    @autoserializeAs(PublicationModel) public publications: PublicationModel[];
}

export class TemplateUsageModel {
    @autoserializeAs(TemplateUsageInCampaignModel) public campaigns: TemplateUsageInCampaignModel[];
    @autoserializeAs(WorkflowAutomationModel) public workflowAutomations: WorkflowAutomationModel[];
}
