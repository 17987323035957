import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, distinctUntilChanged, takeUntil, startWith} from 'rxjs/operators';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    NavigationEnd,
    PRIMARY_OUTLET,
    Router
} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
    selector: 'rl-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
    public locationName: string;
    private onDestroySubject = new Subject<void>();

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public ngOnInit(): void {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            // when load the page without any event, we need initialize first
            startWith(this.activatedRoute),
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe(() => this.getLocationName());
    }

    private getLocationName(): void {
        // most part of the code is from angular this.buildTitle(snapshot: RouterStateSnapshot): string | undefined;
        let route: ActivatedRouteSnapshot|undefined = this.activatedRoute.snapshot;
        let campaignName: string|undefined;
        let publicationName: string|undefined;
        const data = [];

        while (route !== undefined) {
            if (route.data?.campaign) {
                campaignName = route.data?.campaign?.name;
            }
            if (route.data?.publication) {
                publicationName = route.data?.publication?.name;
            }
            route = route.children.find(child => child.outlet === PRIMARY_OUTLET);
        }

        if (campaignName) data.push(campaignName);
        if (publicationName) data.push(publicationName);

        this.locationName = data.join(' - ');
    }
}
