import {Injectable, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {pairwise} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PageNavigationService implements OnDestroy {
    private pageNavigationSubject = new ReplaySubject<string>(2);
    public pageNavigationSubject$: Observable<[string, string]>;

    constructor() {
        // Init subject with 'null' page
        this.pageNavigationSubject.next(null);
        this.pageNavigationSubject.next(null);

        // This observable will combine the last 2 routes as an array ([from, to])
        this.pageNavigationSubject$ = this.pageNavigationSubject.asObservable().pipe(pairwise());
    }

    public ngOnDestroy(): void {
        this.pageNavigationSubject.complete();
    }

    public setPageNavigationRoute(currentRoute: string): void {
        this.pageNavigationSubject.next(currentRoute);
    }
}
