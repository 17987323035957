import {MenuItem} from './menu-item.model';

export class MenuSection {
    public title: string = '';
    public items: MenuItem[] = [];

    constructor(title: string, items: MenuItem[]) {
        this.title = title;
        this.items = items;
    }
}
