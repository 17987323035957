import {EMenuItemPosition} from '../../models/ui/menu-item.model';

export class MenuConstants {
    public static readonly MENU_COMPONENTS = {
        SUB_MENU: 'sub-menu',
        NOTIFICATIONS: 'notification-sub-menu',
    };

    public static readonly MENU_SECTIONS = {
        ASSETS: {
            NAME: '',
            MENU_ITEMS: {
                ASSETS: {
                    ROUTE: '/assets',
                    TITLE: 'Assets',
                    ICON: '/assets/images/menu/icon_sidemenu_assets_active.svg',
                    POSITION: EMenuItemPosition.TOP,
                    SUB_MENU_SECTIONS: []
                }
            }
        },
        PRODUCTS: {
            NAME: '',
            MENU_ITEMS: {
                PRODUCTS: {
                    ROUTE: '/products',
                    TITLE: 'Products',
                    ICON: '/assets/images/menu/icon_sidemenu_products_active.svg',
                    POSITION: EMenuItemPosition.TOP,
                    SUB_MENU_SECTIONS: []
                }
            }
        },
        MAIN: {
            NAME: '',
            MENU_ITEMS: {
                CAMPAIGNS: {
                    ROUTE: '/campaigns',
                    TITLE: 'Campaigns',
                    ICON: '/assets/images/menu/icon_sidemenu_campaigns_active.svg',
                    POSITION: EMenuItemPosition.TOP,
                    SUB_MENU_SECTIONS: [],
                }
            }
        },
        TEMPLATES: {
            NAME: 'templating',
            MENU_ITEMS: {
                TEMPLATES: {
                    ROUTE: '/templates/print-magazine',
                    TITLE: 'Templating',
                    ICON: '/assets/images/menu/icon_sidemenu_templates_active.svg',
                    POSITION: EMenuItemPosition.TOP,
                    COMPONENT: MenuConstants.MENU_COMPONENTS.SUB_MENU,
                    SUB_MENU_SECTIONS: [{
                        TITLE: 'Design templates',
                        ITEMS: [
                            {
                                ROUTE: '/templates/print-magazine',
                                TITLE: 'Print magazine',
                            },
                            {
                                ROUTE: '/templates/pos',
                                TITLE: 'POS',
                            },
                            {
                                ROUTE: '/templates/web',
                                TITLE: 'Web',
                            }]
                    }, {
                        TITLE: 'Design elements',
                        ITEMS: [
                            {
                                ROUTE: '/templates/indesign-libraries',
                                TITLE: 'InDesign libraries',
                            },
                            {
                                ROUTE: '/templates/master-pages',
                                TITLE: 'Master pages',
                            },
                            {
                                ROUTE: '/templates/format-rulesets',
                                TITLE: 'Format rulesets',
                            }]
                    }]
                }
            }
        },
        WORKFLOWS: {
            NAME: 'workflows',
            MENU_ITEMS: {
                WORKFLOWS: {
                    ROUTE: '/workflows/package-setups',
                    TITLE: 'Workflows',
                    ICON: '/assets/images/menu/icon_sidemenu_workflows_active.svg',
                    POSITION: EMenuItemPosition.TOP,
                    COMPONENT: MenuConstants.MENU_COMPONENTS.SUB_MENU,
                    SUB_MENU_SECTIONS: [{
                        ITEMS: [
                            {
                                ROUTE: '/workflows/workflow-automations',
                                TITLE: 'Workflow automations',
                            },
                            {
                                ROUTE: '/workflows/package-setups',
                                TITLE: 'Package setups',
                            },
                            {
                                ROUTE: '/workflows/configurations',
                                TITLE: 'Configurations',
                            }
                        ]
                    }],
                },
            }
        },
        NOTIFICATIONS: {
            NAME: 'notifications',
            MENU_ITEMS: {
                NOTIFICATIONS: {
                    ROUTE: '',
                    TITLE: 'Notifications',
                    ICON: '/assets/images/menu/icon_sidemenu_notifications_active.svg',
                    POSITION: EMenuItemPosition.BOTTOM,
                    COMPONENT: MenuConstants.MENU_COMPONENTS.NOTIFICATIONS,
                    SUB_MENU_SECTIONS: []
                },
            }
        },
        SETTINGS: {
            NAME: 'settings',
            MENU_ITEMS: {
                SETTINGS: {
                    ROUTE: '/settings/user-management',
                    TITLE: 'Settings',
                    ICON: '/assets/images/menu/icon_sidemenu_settings_active.svg',
                    POSITION: EMenuItemPosition.BOTTOM,
                    COMPONENT: MenuConstants.MENU_COMPONENTS.SUB_MENU,
                    SUB_MENU_SECTIONS: [
                        {
                            TITLE: 'Users',
                            ITEMS: [
                                {
                                    ROUTE: '/settings/user-management',
                                    TITLE: 'User management',
                                },
                                {
                                    ROUTE: '/settings/role-management',
                                    TITLE: 'Role management',
                                },
                                {
                                    ROUTE: '/settings/team-setup/general-information',
                                    TITLE: 'Team settings'
                                }
                            ]
                        },
                        {
                            TITLE: 'Data management',
                            ITEMS: [
                                {
                                    ROUTE: '/settings/briefing-data-management',
                                    TITLE: 'Briefing data'
                                },
                                {
                                    ROUTE: '/settings/product-data-management',
                                    TITLE: 'Product data'
                                },
                                {
                                    ROUTE: '/settings/asset-data-management',
                                    TITLE: 'Asset data'
                                },
                                {
                                    ROUTE: '/settings/variants',
                                    TITLE: 'Variants'
                                }
                            ]
                        },
                        {
                            TITLE: 'Content permissions',
                            ITEMS: [
                                {
                                    ROUTE: '/settings/content-rules',
                                    TITLE: 'Content rules',
                                },
                                {
                                    ROUTE: '/settings/content-groups',
                                    TITLE: 'Content groups',
                                }
                            ]
                        }
                    ]
                }
            }
        },
        // TODO: Remove this from the menuitems. This is a quick fix and will be replaced by a new user badge component compatible with the new menu
        ACCOUNT: {
            NAME: 'account-management',
            MENU_ITEMS: {
                ACCOUNT: {
                    ROUTE: '/my-account',
                    TITLE: 'Account',
                    ICON: '/assets/images/menu/icon_sidemenu_profile_active.svg',
                    POSITION: EMenuItemPosition.BOTTOM,
                    COMPONENT: MenuConstants.MENU_COMPONENTS.SUB_MENU,
                    SUB_MENU_SECTIONS: [{
                        ITEMS: [
                            {
                                ROUTE: '/my-account',
                                TITLE: 'My account',
                            },
                            {
                                ROUTE: '/dummy-route',
                                TITLE: 'Logout',
                                ACTION: 'logout'
                            }
                        ]
                    }]
                },
            }
        }
    };
}
