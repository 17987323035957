<div [formGroup]="form" class="form-container" *ngIf="permissions.GET_PACKAGE_DETAILS | userIsAllowedTo; else noPermission">
    <div *ngIf="dataFields?.length; else otherState"
         class="material-field-form" formArrayName="{{'campaignPackage_' + campaignPackage._id}}">
        <h3>{{campaignPackage.name}}</h3>
        <div class="form-row" *ngFor="let field of dataFields">
            <rl-data-field-input [dataField]="field"
                                 [options]="{label: field.name}"
                                 [controlName]="field.fieldName"
                                 [form]="formGroup">
            </rl-data-field-input>
        </div>
    </div>
</div>

<ng-template #otherState>
    <ng-container *ngIf="!campaignPackage; else loading">
        <nuc-empty-state-component imageUrl="assets/images/empty_states/packages.svg"
                                   title="No package"
                                   subtitle="You have to select a package first.">
        </nuc-empty-state-component>
    </ng-container>
</ng-template>
<ng-template #loading>
    <ng-container *ngIf="!campaignPackageSubscription?.closed; else emptyState">
        <rl-loading-indicator></rl-loading-indicator>
    </ng-container>
</ng-template>
<ng-template #emptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/packages.svg"
                               title="No material data"
                               subtitle="The selected package has no material data.">
    </nuc-empty-state-component>
</ng-template>
<ng-template #noPermission>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
