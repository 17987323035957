import {autoserialize, autoserializeAs} from 'cerialize';
import {EDataFieldTypes} from '../../app.enums';
import {RLDatePipe} from '../../pipes/rl-date.pipe';

export interface IPropertyField {
    getDataType(): EDataFieldTypes;
    getPropertyField(): string;
    getDateFormat(): Record<string, any>;
}

export class PropertySetting implements IPropertyField {
    @autoserialize public readonly title: string;
    @autoserialize public readonly propertyName: string;
    @autoserialize public readonly type: EDataFieldTypes;
    public readonly dateFormat: Record<string, any>;

    constructor(title: string, propertyName: string, type: EDataFieldTypes, dateFormat?: Record<string, any>) {
        this.title = title;
        this.propertyName = propertyName;
        // Check for valid format
        this.type = type;
        this.dateFormat = dateFormat;
    }

    public getDataType(): EDataFieldTypes {
        return this.type;
    }

    public getPropertyField(): string {
        return this.propertyName;
    }

    public getDateFormat(): Record<string, any> {
        return this.dateFormat || (this.type === EDataFieldTypes.DATE ? RLDatePipe.dateFormats.DEFAULT : null);
    }

}

export class PropertySettingsModel {
    @autoserializeAs(PropertySetting) public readonly relayterFields: PropertySetting[];
    @autoserializeAs(PropertySetting) public readonly dataFields: PropertySetting[];

    constructor(relayterFields: PropertySetting[] = [], dataFields: PropertySetting[] = []) {
        this.relayterFields = relayterFields;
        this.dataFields = dataFields;
    }
}
