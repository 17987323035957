import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {CampaignItemResultModel} from '../../models/ui/campaign-item-result.model';

@Injectable({providedIn: 'root'})
export class BriefingActionsService implements OnDestroy {
    private campaignItemSubject = new Subject<CampaignItemResultModel>();
    public campaignItem$ = this.campaignItemSubject.asObservable();

    private requestCampaignItemSubject = new Subject<void>();
    public requestCampaignItem$ = this.requestCampaignItemSubject.asObservable();

    private transitionPostedSubject = new Subject<string>();
    public transitionPosted$ = this.transitionPostedSubject.asObservable();

    private subjects = [
        this.campaignItemSubject,
        this.requestCampaignItemSubject,
        this.transitionPostedSubject
    ];

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    public requestCampaignItem(): void {
        this.requestCampaignItemSubject.next();
    }

    public setCampaignItem(result: CampaignItemResultModel): void {
        this.campaignItemSubject.next(result);
    }

    public transitionPosted(transitionItemId: string): void {
        this.transitionPostedSubject.next(transitionItemId);
    }
}
