import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class ContentRuleCollection implements IDropdownItem {

    public static CAMPAIGN = new ContentRuleCollection('Campaigns', 'RLCampaign');
    public static PUBLICATION = new ContentRuleCollection('Publications', 'RLPublication');
    public static PUBLICATION_ITEM = new ContentRuleCollection('Publication Items', 'RLPublicationItem');
    public static CAMPAIGN_ITEM = new ContentRuleCollection('Campaign Items', 'RLCampaignItem');
    public static TEMPLATES = new ContentRuleCollection('Templates', 'RLTemplate');
    public static COLLECTIONS = [
        ContentRuleCollection.CAMPAIGN,
        ContentRuleCollection.CAMPAIGN_ITEM,
        ContentRuleCollection.PUBLICATION,
        ContentRuleCollection.PUBLICATION_ITEM,
        ContentRuleCollection.TEMPLATES,
    ];

    public static getCollection(collectionTitle: string): ContentRuleCollection {
        return ContentRuleCollection.COLLECTIONS.find((collection) => collection.getTitle() === collectionTitle);
    }
    constructor(private displayName, private collectionName) {}

    public getTitle(): string {
        return this.displayName;
    }

    public getValue(): string {
        return this.collectionName;
    }
}
