/**
 *  rl-validator-repeat-password.validator
 *  app-relayter-web
 *  Created by nandamhuis on 09/02/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */

import {UntypedFormControl} from '@angular/forms';

export function RLRepeatPasswordValidator(field: UntypedFormControl): {notequals: boolean} {
    if (field['_parent']) {
        const parent = field['_parent'];
        if (!parent.get('repeatablePassword')) {
            throw new Error('To use the repeatpassword validator you need to implement a "repeatablePassword" field');
        }

        // TODO: Find a better way, this is kinda shady, but for lack of better atm @ by Nan
        const password = parent.get('repeatablePassword');
        if (field.value !== password.value) {
            return {notequals: true};
        }
    }
    return null;
}
