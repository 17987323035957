import {autoserialize} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class GroupModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public users: string[];
    @autoserialize public userCount: number;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}

export class GroupPostModel {
    @autoserialize public name: string;
    @autoserialize public users: string[];

    constructor(name: string, users: string[]) {
        this.name = name;
        this.users = users;
    }
}

export class GroupPatchModel {
    @autoserialize public name: string;
    @autoserialize public users: string[];

    constructor(name: string, users: string[]) {
        this.name = name;
        this.users = users;
    }
}
