import {autoserialize} from 'cerialize';

/**
 * PlanModel
 */
export class PlanModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public maxUsers: number;
    @autoserialize public maxImages: number;
    @autoserialize public userPrice: number;
    @autoserialize public dataGBprice: number;
}
