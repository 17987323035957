<div class="content-container">
    <rl-reactive-file-input
        class="file-input"
        name="file"
        type="file"
        (filesChanged)="onFilesChanged($event)">
    </rl-reactive-file-input>

    <ng-content></ng-content>

    <div class="images">
        <file-upload-card-view *ngFor="let uploadingFile of uploadingFiles"
                               [uploadFile]="uploadingFile"
                               (deleteAssetClicked)="onDeleteButtonClicked($event)">
        </file-upload-card-view>
    </div>

</div>
