import {Component, OnDestroy, OnInit} from '@angular/core';
import {TemplateContentModel} from './template-content.model';
import {ETemplateType, TemplateSizeModel} from '../template-size/template-size.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../../../../classes/validators/rl-validators.constant';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {TemplateDataService} from '../template.data-service';
import {Subject} from 'rxjs';

@Component({
    selector: 'rl-template-content',
    templateUrl: 'template-content.component.html',
    styleUrls: ['template-content.component.scss']
})

export class TemplateContentComponent implements OnInit, OnDestroy {

    private templateSize: TemplateSizeModel;

    public hoveredContentIndex: number;
    public selectedContentIndex: number;

    public contents: TemplateContentModel[];
    private selectedContentId: string;

    public xControl = new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED);
    public yControl = new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED);
    public widthControl = new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO);
    public heightControl = new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO);
    public columnsControl = new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO);
    public rowsControl = new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO);
    public columnGutterControl = new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED);
    public rowGutterControl = new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED);
    public formGroup: UntypedFormGroup = new UntypedFormGroup({
        x: this.xControl,
        y: this.yControl,
        width: this.widthControl,
        height: this.heightControl,
        columns: this.columnsControl,
        rows: this.rowsControl,
        columnGutter: this.columnGutterControl,
        rowGutter: this.rowGutterControl
    });

    private onDestroySubject = new Subject<void>();

    constructor(public templateDataService: TemplateDataService) {}

    public ngOnInit(): void {
        this.templateDataService.contents$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((contents) => this.contents = contents);

        this.templateDataService.templateSize$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((templateSize) => this.templateSize = templateSize);

        this.templateDataService.hoveredContentIndex$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((hoveredContentIndex) => this.hoveredContentIndex = hoveredContentIndex);

        this.templateDataService.selectedContentIndex$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((selectedContentIndex) => {
            this.selectedContentIndex = selectedContentIndex;
            const selectedContent = this.contents[this.selectedContentIndex];
            if (selectedContent) {
                this.selectedContentId = selectedContent._id;
                this.formGroup.patchValue({
                    x: selectedContent.x,
                    y: selectedContent.y,
                    width: selectedContent.width,
                    height: selectedContent.height,
                    columns: selectedContent.columns,
                    rows: selectedContent.rows,
                    columnGutter: selectedContent.columnGutter,
                    rowGutter: selectedContent.rowGutter
                }, {emitEvent: false});
            }
        });

        this.formGroup.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(50),
            filter(() => this.formGroup.status === 'VALID'),
            takeUntil(this.onDestroySubject),
        ).subscribe((formValue) => {
            const content =
                new TemplateContentModel(
                    formValue.x,
                    formValue.y,
                    formValue.width,
                    formValue.height,
                    formValue.columns,
                    formValue.rows,
                    formValue.columnGutter,
                    formValue.rowGutter,
                    this.selectedContentId);
            this.contents[this.selectedContentIndex] = content;
            this.templateDataService.setContents(this.contents);
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public onAddContentButtonClicked(): void {
        const x = this.templateSize.templateType === ETemplateType.Spread ? this.templateSize.margins.marginEnd :
            this.templateSize.margins.marginStart;
        const newContent = new TemplateContentModel(x, this.templateSize.margins.marginTop,
            (this.templateSize.width - (this.templateSize.margins.marginStart + this.templateSize.margins.marginEnd)) / 2,
            (this.templateSize.width - (this.templateSize.margins.marginStart + this.templateSize.margins.marginEnd)) / 2,
            1, 1, 0, 0, undefined);

        this.contents = [...this.contents, newContent];

        this.templateDataService.setContents(this.contents);
        this.templateDataService.setSelectedContentIndex(this.contents.length - 1);
    }

    public onDeleteButtonClicked(deletedContentIndex: number): void {
        this.contents.splice(deletedContentIndex, 1);
        this.templateDataService.setSelectedContentIndex(-1);
        this.templateDataService.setContents(this.contents);
    }
}
