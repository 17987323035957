import {Component} from '@angular/core';

@Component({
    selector: 'master-briefings-overview',
    templateUrl: './master-briefings-overview.component.html',
    styleUrls: ['./master-briefings-overview.component.scss']
})
export class MasterBriefingsOverviewComponent {
    // Table setup
    public readonly tableId = 'master-briefing-table';
}
