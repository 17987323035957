export class ButtonPixi extends PIXI.Container {

    private idleImage: string;
    private hoverImage: string;
    private clickImage: string;

    private idleSprite: PIXI.Sprite;
    private hoverSprite: PIXI.Sprite;
    private clickSprite: PIXI.Sprite;

    constructor(public x: number, public y: number, width: number, height: number, private image: string) {
        super();

        this.interactive = true;
        this.buttonMode = true;
        this.on('mouseover', () => this.onHover())
            .on('mouseout', () => this.onHoverEnd())
            .on('mousedown', () => this.onClickStart())
            .on('mouseup', () => this.onClickEnd())
            .on('mouseupoutside', () => this.onClickEnd())
            .on('touchstart', () => this.onClickStart())
            .on('touchend', () => this.onClickEnd())
            .on('ontouchendoutside', () => this.onClickEnd());

        this.idleImage = `/assets/pixi/buttons/${image}/idle.svg`;
        this.hoverImage = `/assets/pixi/buttons/${image}/hover.svg`;
        this.clickImage = `/assets/pixi/buttons/${image}/click.svg`;

        const assetLoader = new PIXI.loaders.Loader();
        assetLoader.add(this.idleImage);
        assetLoader.add(this.hoverImage);
        assetLoader.add(this.clickImage);

        assetLoader.load((loader, resources) => {
            this.idleSprite = new PIXI.Sprite(resources[this.idleImage]['texture']);
            this.hoverSprite = new PIXI.Sprite(resources[this.hoverImage]['texture']);
            this.clickSprite = new PIXI.Sprite(resources[this.clickImage]['texture']);

            this.idleSprite.width = width;
            this.idleSprite.height = height;
            this.hoverSprite.width = width;
            this.hoverSprite.height = height;
            this.clickSprite.width = width;
            this.clickSprite.height = height;

            this.hoverSprite.alpha = 0;
            this.clickSprite.alpha = 0;

            this.addChild(this.idleSprite);
            this.addChild(this.hoverSprite);
            this.addChild(this.clickSprite);
        });
    }

    private onHover(): void {
        this.idleSprite.alpha = 0;
        this.hoverSprite.alpha = 1;
    }

    private onHoverEnd(): void {
        this.idleSprite.alpha = 1;
        this.hoverSprite.alpha = 0;
    }

    private onClickStart(): void {
        this.clickSprite.alpha = 1;
    }

    private onClickEnd(): void {
        this.clickSprite.alpha = 0;
    }

}
