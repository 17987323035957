<ng-container *ngIf="!readonly else displayView" [ngSwitch]="dataField.dataType.type" [formGroup]="form">

    <nuc-label *ngIf="showLabels" [icon]="selectedVariant && dataField.enableVariants ? 'nucicon_variant' : null"
               [iconColorClass]="selectedVariant && dataField.enableVariants ? 'variant-info' : null">
        {{dataField.name}}
        <ng-container *ngIf="selectedVariant && dataField.enableVariants"> - {{selectedVariant.getTitle()}}</ng-container>
    </nuc-label>
    <!-- Enum data type: Dropdowns -->
    <ng-container *ngSwitchCase="EDataFieldTypes.ENUM">
        <nuc-dropdown-multiselect *ngIf="enumeration.multiSelect; else singleSelect"
                                  [items]="dropdownItems"
                                  [formControl]="form.controls[controlName]"
                                  [placeholder]="options?.placeholder || 'Select the ' + dataField.name + '(s)'">
        </nuc-dropdown-multiselect>

        <ng-template #singleSelect>
            <nuc-dropdown [items]="dropdownItems"
                          [formControl]="form.controls[controlName]"
                          [placeholder]="options?.placeholder || 'Select the ' + dataField.name">
            </nuc-dropdown>
        </ng-template>
    </ng-container>

    <!-- Number data type: number inputs -->
    <ng-container *ngSwitchCase="EDataFieldTypes.NUMBER">
        <ng-container [ngSwitch]="formatter">

            <nuc-input *ngSwitchCase="EDataFieldFormatter.CURRENCY"
                       [formControl]="form.controls[controlName]"
                       [type]="type"
                       [placeholder]="options?.placeholder || 'Fill in the ' + dataField.name"
                       [prefix]="inputPrefix">
            </nuc-input>

            <nuc-input *ngSwitchCase="EDataFieldFormatter.NONE"
                       [formControl]="form.controls[controlName]"
                       [type]="type"
                       [placeholder]="options?.placeholder || 'Fill in the ' + dataField.name">
            </nuc-input>

            <nuc-error *ngSwitchDefault>Unrecognized data field number formatter {{formatter}}</nuc-error>

        </ng-container>
    </ng-container>

    <!-- String data type: textarea and normal input-->
    <ng-container *ngSwitchCase="EDataFieldTypes.STRING">
        <nuc-textarea *ngIf="options?.multiline; else singleLineInput"
                      [formControl]="form.controls[controlName]"
                      [noHorizontalResize]="true"
                      [placeholder]="options?.placeholder || 'Fill in the ' + dataField.name"
                      [minRows]="3">
        </nuc-textarea>

        <ng-template #singleLineInput>
            <nuc-suggestive-string-input *ngIf="dataField.enableAutocomplete else normalInput" [formControl]="form.controls[controlName]"
                                  [placeholder]="options?.placeholder || 'Fill in the ' + dataField.name"
                                  (searchTermChange)="search($event)" [options]="dataFieldData" >
            </nuc-suggestive-string-input>
            <ng-template #normalInput>
                <nuc-input  [formControl]="form.controls[controlName]"
                            [type]="type"
                            [placeholder]="options?.placeholder || 'Fill in the ' + dataField.name">
                </nuc-input>
            </ng-template>
        </ng-template>
    </ng-container>

    <!-- Boolean data type: dropdown -->
    <ng-container *ngSwitchCase="EDataFieldTypes.BOOLEAN">
        <nuc-dropdown [items]="dropdownItems"
                      [formControl]="form.controls[controlName]"
                      [placeholder]="options?.placeholder || 'Select option'">
        </nuc-dropdown>
    </ng-container>

    <!-- List data type: suggestive input -->
    <ng-container *ngSwitchCase="EDataFieldTypes.LIST">
        <nuc-suggestive-input
            [options]="dropdownItems"
            (searchTermChange)="onSearchListDataField($event)"
            [formControl]="form.controls[controlName]"
            [placeholder]="options?.placeholder || 'Enter items'">
        </nuc-suggestive-input>
    </ng-container>

    <!-- Date data type: datepicker input -->
    <ng-container *ngSwitchCase="EDataFieldTypes.DATE">
        <nuc-datepicker [formControl]="form.controls[controlName]"
                        [placeholder]="options?.placeholder || 'Select a date'">
        </nuc-datepicker>
    </ng-container>

    <nuc-error *ngSwitchDefault>Unrecognized data field type {{type}}</nuc-error>

</ng-container>

<ng-template #displayView>
    <nuc-label [icon]="selectedVariant && dataField.enableVariants ? 'nucicon_variant' : null"
               [iconColorClass]="selectedVariant && dataField.enableVariants ? 'variant-info' : null">
        {{dataField.name}}
        <ng-container *ngIf="selectedVariant && dataField.enableVariants"> - {{selectedVariant.getTitle()}}</ng-container>
    </nuc-label>
    <span>{{form.controls[this.controlName]?.value | getDataFieldDisplayValue:type | nullUndefinedFormatter}}</span>
</ng-template>
