/**
 *  rl-edit-route-guard
 *  app-relayter-web
 *  Created by nandamhuis on 28/02/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */
import {Injectable} from '@angular/core';
import {RolePermissionsComponent} from '../../role-detail/role-permissions/role-permissions.component';
import {BaseDeactivateGuard} from './base/base-deactivate.guard';
import {NucDialogService} from '@relayter/rubber-duck';
import {Observable, Subject} from 'rxjs';
import {UrlTree} from '@angular/router';

@Injectable({providedIn: 'root'})
export class RLCanDeactivateManagementRouteGuard extends BaseDeactivateGuard {
    constructor(dialogService: NucDialogService) {
        super(dialogService);
    }

    /**
     * Overrides BaseDeactivateGuard method
     */
    public componentCanBeDeactivated(component: RolePermissionsComponent):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const canBeDeactivated = (!component || component.canDeactivate()) || this.destinationRouteEqualsCurrentRoute;

        if (!canBeDeactivated) {
            const confirmSubject = new Subject<boolean>();
            this.openConfirmDialog(confirmSubject);
            return confirmSubject.asObservable();
        }

        return true;
    }
}
