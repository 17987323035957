import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '@relayter/rubber-duck';
import {PublicationItemModel} from './publication-item.model';

export class PackageItemModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserializeAs(Object) public dataFields: Record<string, any>;
    @autoserializeAs(PublicationItemModel) public publicationItem: PublicationItemModel;
}

export class PackageItemPostModel {
    @autoserialize public campaignPackage: string;
    @autoserialize public publication: string;
    @autoserialize public publicationItem: string;
    @autoserializeAs(Object) public dataFields: Record<string, any>; // materialData and projected campaignData
}
