<div class="package-rule-progress"></div>
<div [formGroup]="form" class="form-container">
    <div class="package-rule-material-field-form" [formArrayName]="FORM_GROUP_NAME">
        <p class="section-header">Material data</p>
        <p class="explanation">Provide additional material data for the package.</p>
        <div class="form-row" *ngFor="let field of dataFields">
            <rl-data-field-input [dataField]="field"
                                 [options]="{label: field.name}"
                                 [controlName]="field.fieldName"
                                 [form]="formGroup">
            </rl-data-field-input>
        </div>
    </div>
</div>
