<form *ngIf="form" [formGroup]="form">
    <nuc-form-field label="Current password">
        <nuc-input formControlName="oldPassword"
                   type="password"
                   placeholder="Fill in your password">
        </nuc-input>
    </nuc-form-field>

    <nuc-form-field label="New password">
        <nuc-input formControlName="repeatablePassword"
                   type="password"
                   placeholder="Fill in your new password">
        </nuc-input>

        <nuc-hint *ngIf="!form.controls.repeatablePassword.errors?.minlength">Minimum of 10 characters.</nuc-hint>

        <ng-container *ngIf="form.controls.repeatablePassword.touched && form.controls.repeatablePassword.errors as error">
            <nuc-error *ngIf="error.required">{{validationMessages.repeatablePassword.required}}</nuc-error>
            <nuc-error *ngIf="!error.required && error.minlength">{{validationMessages.repeatablePassword.minlength}}</nuc-error>
            <nuc-error *ngIf="!error.required && error.maxlength">{{validationMessages.repeatablePassword.maxlength}}</nuc-error>
        </ng-container>
    </nuc-form-field>

    <nuc-form-field label="Repeat password">
        <nuc-input formControlName="repeatPassword"
                   type="password"
                   placeholder="Repeat your new password">
        </nuc-input>

        <ng-container *ngIf="form.controls.repeatPassword.touched && form.controls.repeatPassword.errors as error">
            <nuc-error *ngIf="error.required">{{validationMessages.repeatPassword.required}}</nuc-error>
            <nuc-error *ngIf="!error.required && error.notequals">{{validationMessages.repeatPassword.notequals}}</nuc-error>
        </ng-container>
    </nuc-form-field>
</form>
