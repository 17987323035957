import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ChangelogModel} from '../../models/api/changelog.model';
import {BaseApiRequestService} from './base-api-request.service';
import {ARApiUrlBuilderService, ARRequestOptions, ARPagedResponseDataModel} from '@relayter/core';
import {environment} from '../../../environments/environment';
import {ApiConstants} from '../api.constant';
import {AppConstants} from '../../app.constants';
import {QueryParams} from '../../classes/query-params';
import {EChangeLogCollectionName} from '../../app.enums';

@Injectable({
    providedIn: 'root'
})
export class ChangelogService extends BaseApiRequestService {

    /**
     * get the changelog by the itemId
     * @param {string} itemId
     * @param {EChangeLogCollectionName} collectionName
     * @param {number} [limit]
     * @param {number} [offset]
     * @returns {Observable<ARPagedResponseDataModel<ChangelogModel>>}
     */
    public getChangelog(itemId: string,
                        collectionName: EChangeLogCollectionName,
                        limit: number = AppConstants.PAGE_SIZE_DEFAULT,
                        offset: number = 0): Observable<ARPagedResponseDataModel<ChangelogModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .addParam('collectionName', collectionName)
            .addParam('itemId', itemId);
        const url = ARApiUrlBuilderService.urlFromComponents([
                environment.API_SERVER,
                ApiConstants.API_BASE_PATH,
                ApiConstants.API_GROUP_CHANGELOGS],
            queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => this.handlePagedResponse(options, obs, ChangelogModel));
    }

}
