import {Observable} from 'rxjs';
import {RLFileSizeUtil} from '../../classes/file-size.util';
import {FileTypeUtil} from '../../classes/file-type.util';
import {delay, filter, map, shareReplay, startWith, withLatestFrom} from 'rxjs/operators';
import {HttpEvent, HttpEventType, HttpProgressEvent, HttpResponseBase} from '@angular/common/http';
import {merge, ReplaySubject} from 'rxjs';
import {AwsSignedUrlModel} from '../../models/api/aws-signed-url.model';
import {AppConstants} from '../../app.constants';

export enum EUploadStatus {
    Uploading = 1,
    Done = 101,
    Failed = -1
}

export class UploadFileStatus {
    constructor(public name: string, public description: string, public icon: string, public iconColor: string, public status: EUploadStatus) {
    }
}

export class UploadModel {
    public readonly filename: string;
    public readonly fileType: string;
    public readonly fileSize: string;
    public fileStatuses: UploadFileStatus[] = [
        AppConstants.UPLOADED_FILE_STATUS.DONE,
        AppConstants.UPLOADED_FILE_STATUS.UPLOADING,
        AppConstants.UPLOADED_FILE_STATUS.ERROR
    ];

    private s3KeySubject = new ReplaySubject<string>(1);
    private signedUrlSubject = new ReplaySubject<string>(1);
    public statusSubject = new ReplaySubject<UploadFileStatus>(1);
    public s3Key$ = this.s3KeySubject.asObservable();
    public signedUrl$ = this.signedUrlSubject.asObservable();
    public status$ = this.statusSubject.asObservable();
    public progress$: Observable<number>;

    constructor(private file: File,
                private awsSignedUrl$: Observable<AwsSignedUrlModel>,
                private upload$: Observable<HttpEvent<Record<string, any>>>) {
        this.filename = file.name;
        this.fileType = FileTypeUtil.getExtensionFromFileName(file.name).toLowerCase();
        this.fileSize = RLFileSizeUtil.formatBytesToReadableFileSize(file.size);

        const uploadProgress$ = upload$.pipe(
            filter((httpEvent) => !(httpEvent instanceof Error) && httpEvent.type === HttpEventType.UploadProgress),
            map((httpEvent: HttpProgressEvent) => {
                this.statusSubject.next(this.fileStatuses.find(fileStatus => fileStatus.status === EUploadStatus.Uploading));
                return Math.round(100 * httpEvent.loaded / httpEvent.total);
            }),
            startWith(1)
        );

        const uploadDone$ = upload$.pipe(
            filter((httpEvent) => httpEvent instanceof HttpResponseBase),
            withLatestFrom(awsSignedUrl$),
            map(([httpEvent, signedUrl]) => {
                this.s3KeySubject.next(signedUrl.s3Key);
                this.signedUrlSubject.next(signedUrl.signedUrl);

                if (httpEvent['ok']) {
                    this.statusSubject.next(this.fileStatuses.find(fileStatus => fileStatus.status === EUploadStatus.Done));
                    return EUploadStatus.Done;
                } else {
                    this.statusSubject.next(this.fileStatuses.find(fileStatus => fileStatus.status === EUploadStatus.Failed));
                    return EUploadStatus.Failed;
                }
            }),
        );

        const uploadFailed$ = upload$.pipe(
            filter((error) => error instanceof Error),
            map(() => {
                this.statusSubject.next(this.fileStatuses.find(fileStatus => fileStatus.status === EUploadStatus.Failed));
                return EUploadStatus.Failed;
            })
        );

        const uploadDoneOrFailed$ = merge(uploadDone$, uploadFailed$).pipe(
            delay(300)
        );

        this.progress$ = merge(uploadProgress$, uploadDoneOrFailed$).pipe(
            shareReplay(1)
        );
    }

}
