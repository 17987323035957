import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {CustomWorkflowComponent} from '../../campaigns/publication/custom-workflow/custom-workflow.component';

@Injectable({
    providedIn: 'root'
})
export class CanLeaveComponentGuard {
    constructor(private router: Router) {
    }

    canDeactivate(
        component: CustomWorkflowComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // Only check if the user leaves the workflow component ignore if a step/component is switched.
        if (currentState.url.slice(0, 73) === nextState.url.slice(0, 73) || this.router.getCurrentNavigation().extras?.state?.skipGuards) {
            return true;
        } else {
            return component.close();
        }
    }

}
