/**
 *  template-type.model
 *  app-relayter-web
 *  Created by nandamhuis on 04/01/2018.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */
import {autoserialize} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class TemplateTypeModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}
