import {SubMenuSection} from './sub-menu-section.model';

export const enum EMenuItemPosition {TOP, BOTTOM}

export class MenuItem {
    public route: string = '';
    public title: string = '';
    public icon: string = '';
    public subMenuSections: SubMenuSection[] = [];
    public position: EMenuItemPosition = EMenuItemPosition.TOP;
    public component: string;

    constructor(route: string,
                title: string,
                subMenuSections: SubMenuSection[],
                icon: string,
                position: EMenuItemPosition,
                component?: string) {
        this.route = route;
        this.title = title;
        this.subMenuSections = subMenuSections;
        this.icon = icon;
        this.position = position;
        if (component) {
            this.component = component;
        }
    }
}
