<nuc-card-view class="file"
               [size]="cardSize"
               [itemWidth]="itemWidth"
               [hoverImage]="false"
               [hoverCard]="false"
               [image]="uploadFile.filename | getFilePathPipe:(uploadFile.signedUrl$ | async)"
               [badgeText]="badgeText">
    <div content>
        <nuc-label>
            <span class="file-name">{{uploadFile.filename}}</span> - <span>{{uploadFile.fileSize}}</span>
        </nuc-label>

        <ng-container>
            <div class="file-status">
                <ng-content select="[file-status]"></ng-content>
            </div>
            <div class="upload-status">
                <div class="progress-container" *ngIf="uploadFile.status$ | async as uploadFileStatus">
                    <div *ngIf="uploadFileStatus.status === EUploadStatus.Done || uploadFileStatus.status === EUploadStatus.Failed" class="loading-status" [style.color]="uploadFileStatus.iconColor">
                        <i class="green {{uploadFileStatus.icon}}" [style.color]="uploadFileStatus.iconColor"></i>
                        <div class="status-update">
                            <p class="green small" [style.color]="uploadFileStatus.iconColor">{{uploadFileStatus.name}}</p>
                            <p class="green small" [style.color]="uploadFileStatus.iconColor">{{uploadFileStatus.description}}</p>
                        </div>
                    </div>
                    <div class="progress" *ngIf="uploadFileStatus.status !== EUploadStatus.Done && uploadFileStatus.status !== EUploadStatus.Failed && {value: uploadFile.progress$ | async} as progress">
                        <nuc-progress-bar [value]="progress.value" [showPercentage]="true"></nuc-progress-bar>
                    </div>
                </div>
                <nuc-button-secondary icon="nucicon_trash_fill"
                                      (click)="deleteAssetClicked.emit(uploadFile)"></nuc-button-secondary>
            </div>
        </ng-container>
    </div>
</nuc-card-view>
