import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {DataFieldModel} from '../../models/api/data-field.model';
import {EBooleanText, EDataFieldFormatter, EDataFieldTypes} from '../../app.enums';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {UntypedFormGroup} from '@angular/forms';
import {DataFieldDataTypeEnumerationModel} from '../../models/api/data-field-data-type-enumeration.model';
import {FormFieldOptionsModel} from '../../models/api/form.model';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {CustomWorkflowFilterOptionModel} from '../../models/api/custom-workflow-filter-option.model';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {VariantModel} from '../../models/api/variant.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-data-field-input',
    templateUrl: './data-field-input.component.html',
    styleUrls: ['./data-field-input.component.scss']
})
export class DataFieldInputComponent implements OnInit {
    @Input() public readonly = false;

    @Input() public dataField: DataFieldModel;
    @Input() public selectedVariant: VariantModel;
    @Input() public showLabels: boolean = true;

    // Form control setup
    @Input() public form: UntypedFormGroup;
    @Input() public controlName: string;

    // Form component settings
    @Input() public inputPrefix: string;

    @Input() public options: FormFieldOptionsModel;

    // constants
    public EDataFieldTypes = EDataFieldTypes;
    public EDataFieldFormatter = EDataFieldFormatter;

    // Handy properties used for readability of template file
    public type: EDataFieldTypes;
    public formatter: EDataFieldFormatter;
    public enumeration: DataFieldDataTypeEnumerationModel;

    // Formatted data field values to be compatible with drop-downs
    public dropdownItems: IDropdownItem<any>[];

    protected dataFieldData: DropdownItem<string>[];
    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(private dataFieldsApiService: DataFieldsApiService) {
    }

    public ngOnInit(): void {
        this.type = this.dataField.dataType.type;
        this.formatter = this.dataField.dataType.formatter;
        this.enumeration = this.dataField.dataType.enumeration;

        if (!this.readonly) this.setupControlData();
    }

    public setupControlData(): void {
        switch (this.dataField.dataType.type) {
            case this.EDataFieldTypes.ENUM:
                this.dropdownItems = this.dataField.dataType.enumeration.items.map((item) => new DropdownItem(item, item));
                break;
            case this.EDataFieldTypes.BOOLEAN:
                this.dropdownItems = [
                    new DropdownItem(EBooleanText.TRUE, true),
                    new DropdownItem(EBooleanText.FALSE, false)
                ];
                break;
            default:
                // Nothing
                break;
        }
    }

    public onSearchListDataField(term: string = ''): void {
        if (!term || term.length < 2) {
            this.dropdownItems = [];
            return;
        }

        this.dataFieldsApiService.getDataFieldValues(this.dataField._id, {}, 10, 0, term)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: ARPagedResponseDataModel<CustomWorkflowFilterOptionModel>) => {
                    this.dropdownItems = res.items.map(item => new DropdownItem(item.displayName, item.displayName));
                },
                error: Toaster.handleApiError
            });
    }

    public search(searchValue: string): void {
        // Get dataField values
        this.dataFieldsApiService.getDataFieldValues(this.dataField._id, {}, 100, 0, searchValue,
            this.selectedVariant ? this.selectedVariant.key : null)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.dataFieldData = result.items.map((item) => {
                        return new DropdownItem<string>(item.displayName, item.displayName);
                    })
                },
                error: (error) => {
                    Toaster.error(error);
                }
            })
    }

}
