import {BehaviorSubject, Subject,} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';

@Injectable()
export class ConnectionDataService implements OnDestroy{
    private saveButtonStateDisabled = new BehaviorSubject<boolean>(true);
    public saveButtonStateDisabled$ = this.saveButtonStateDisabled.asObservable();

    private saveButtonStateLoading = new BehaviorSubject<boolean>(false);
    public saveButtonStateLoading$ = this.saveButtonStateLoading.asObservable();

    private saveButtonClickEvent = new Subject<void>();
    public saveButtonClickEvent$ = this.saveButtonClickEvent.asObservable();

    private subjects = [
        this.saveButtonStateDisabled,
        this.saveButtonStateLoading,
        this.saveButtonClickEvent
    ];

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    public setSaveButtonStateDisabled(disabled: boolean): void {
        this.saveButtonStateDisabled.next(disabled);
    }

    public setSaveButtonStateLoading(loading: boolean): void {
        this.saveButtonStateLoading.next(loading);
    }

    public saveButtonClicked(): void {
        this.saveButtonClickEvent.next();
    }

}
