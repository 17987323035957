import {UntypedFormControl} from '@angular/forms';

/**
 * Checks if the field is an array and has exactly one value
 * @param field
 * @returns {object | null}
 * @constructor
 */
export function ExactlyOneValidator(field: UntypedFormControl): {exactlyone: boolean} {
    if (!field.value || (field.value && Array.isArray(field.value) && field.value.length !== 1)) {
        return {exactlyone: true};
    }
    return null;
}
