import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {RELAYTER_ROUTE_DEFINITIONS} from './relayter-router.constants';

@NgModule({
    imports: [RouterModule.forChild(RELAYTER_ROUTE_DEFINITIONS)],
    exports: [RouterModule]
})
export class RelayterRouterModule {
}
