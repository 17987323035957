import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {concat, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {PermissionModel} from '../../models/api/permission.model';
import {QueryParams} from '../../classes/query-params';
import {SortDirection} from '@angular/material/sort';
import {AppConstants} from '../../app.constants';
import {reduce, switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService extends BaseApiRequestService {
    public getTeamsPermissions(limit?: number,
                               offset?: number,
                               sortProperty?: string,
                               sortOrder?: SortDirection,
                               search?: string): Observable<ARPagedResponseDataModel<PermissionModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder);

        if (search) queryParams.addParam('search', search);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_TEAMS,
            ApiConstants.API_METHOD_PERMISSIONS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, PermissionModel);
        });
    }

    public getAllPermissions(sortProperty?: string, sortOrder?: SortDirection): Observable<PermissionModel[]> {
        return this.getTeamsPermissions(AppConstants.PAGE_SIZE_MAX, 0, sortProperty, sortOrder)
            .pipe(
                switchMap((result) => {
                    const observables = [of(result)];
                    const totalPages = Math.ceil(result.total / AppConstants.PAGE_SIZE_MAX);

                    for (let page = 0; page < totalPages - 1; page++) {
                        observables.push(
                            this.getTeamsPermissions(AppConstants.PAGE_SIZE_MAX, (page + 1) * AppConstants.PAGE_SIZE_MAX, sortProperty, sortOrder));
                    }

                    return concat(...observables);
                }),
                reduce((acc, val) => acc.concat(val.items), [])
            );
    }
}
