<div class="title">
    <h1>Notifications</h1>
    <nuc-button-secondary icon="nucicon_close" (click)="closeClicked.emit()"></nuc-button-secondary>
</div>

<nuc-tab-bar [tabs]="tabs" [(activeTab)]="activeTab"></nuc-tab-bar>

<div class="content">
    <ng-container *ngIf="activeTab === directTab">
        <ng-container *ngIf="sortedNotifications$ | async as notifications; else loading">
            <ng-container *ngIf="notifications.today.length||notifications.yesterday.length||notifications.older.length; else emptyState">
                <ng-container *ngFor="let property of ['today', 'yesterday', 'older']">
                    <ng-container *ngFor="let notification of notifications[property]; let first = first">
                        <p class="section-header" *ngIf="first">{{property | titlecase}}</p>
                        <rl-notification-item (click)="onNotificationClicked(notification, ENotificationStatus.READ)"
                                              (archiveButtonClicked)="onNotificationClicked(notification, ENotificationStatus.ARCHIVED)"
                                              (actionClicked)="onNotificationActionClicked($event)"
                                              [notification]="notification"
                                              [showFullDate]="property === 'older'"
                                              [selected]="notification._id === selectedNotificationId">
                        </rl-notification-item>
                    </ng-container>
               </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="activeTab === archiveTab">
        <ng-container *ngIf="notificationsArchived$ | async as notifications; else loading">
            <ng-container *ngIf="notifications.length > 0; else emptyState">
                <rl-notification-item *ngFor="let notification of notifications"
                                      (click)="selectedArchivedNotificationId = notification._id"
                                      (actionClicked)="onNotificationActionClicked($event)"
                                      [notification]="notification"
                                      [selected]="notification._id === selectedArchivedNotificationId"
                                      [showFullDate]="true">
                </rl-notification-item>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #loading>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
<ng-template #emptyState>
    <nuc-empty-state-component
        [small]="true"
        imageUrl="assets/images/empty_states/notifications.svg"
        title="It's Quiet... Too Quiet"
        subtitle="There are no notification messages.">
    </nuc-empty-state-component>
</ng-template>
