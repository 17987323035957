import {autoserialize} from 'cerialize';
import {ConditionGroupModel} from './condition-group.model';
import {RULESET_OPERATORS} from '../../app.enums';

export class ConditionGroupsModel {
    @autoserialize public operator: RULESET_OPERATORS;
    @autoserialize public groups?: ConditionGroupModel[];

    constructor(operator: RULESET_OPERATORS, groups?: ConditionGroupModel[]) {
        if (operator) this.operator = operator;
        if (groups) this.groups = groups;
    }
}
