import {autoserialize, autoserializeAs} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {PackageSetupModel} from './package-setup.model';
import {ITableItem} from '@relayter/rubber-duck';

export class PackageModel implements IDropdownItem, ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public description: string;
    @autoserializeAs(PackageSetupModel) public packageSetup: PackageSetupModel;
    @autoserialize public updatedAt: string;
    @autoserialize public createdAt: string;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}

export class PackagePatchModel {
    @autoserialize public name: string;
    @autoserialize public description: string;

    constructor(name: string, description?: string) {
        this.name = name;
        this.description = description || '';
    }
}
