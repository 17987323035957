import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '../interfaces/table-item.interface';
import {RoleModel} from './role.model';

export class Invite implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public email: string;
    @autoserialize public token: string;
    @autoserializeAs(RoleModel) public roles: RoleModel[];
    @autoserialize public groups: string[];
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public expiresIn: Date;
    @autoserialize public link: string;

    public get formattedRoles(): string {
        return this.roles.map(role => role.name).join(', ');
    }
}

export class InviteBodyModel {
    public email: string;
    public roleIds: string[];
    public groupIds: string[];

    constructor(email: string, roleIds: string[], groupIds?: string[]) {
        this.email = email;
        this.roleIds = roleIds;
        this.groupIds = groupIds;
    }
}
