import {Pipe, PipeTransform} from '@angular/core';
import {DataFieldModel} from '../../../../models/api/data-field.model';
import {IFormRow} from './form-builder.component';

@Pipe({name: 'dataFieldFilter'})
export class DataFieldFilterPipe implements PipeTransform {

    public transform(values: DataFieldModel[], rows: IFormRow[]): DataFieldModel[] {
        return values.filter((value) => !rows.some((row) => row.fields.some((field) => field._id === value._id)));
    }

}
