import {Pipe, PipeTransform} from '@angular/core';
import {ENotificationStatus, NotificationModel} from '../models/api/notification.model';

@Pipe({
  name: 'unreadNotifications'
})
export class UnreadNotificationsPipe implements PipeTransform {

  public transform(notifications: NotificationModel[]): number {
    return notifications.filter((notification) => notification.status === ENotificationStatus.UNREAD).length;
  }

}
