import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MenuSection} from '../../models/ui/menu-section.model';
import {MenuItem} from '../../models/ui/menu-item.model';
import {SubMenuItem} from '../../models/ui/sub-menu-item.model';
import {MenuConstants} from './menu.constants';
import {SubMenuSection} from '../../models/ui/sub-menu-section.model';

const {MENU_SECTIONS} = MenuConstants;

@Injectable({providedIn: 'root'})
export class MenuDataService {
    private menuSectionsSubject = new BehaviorSubject<MenuSection[]>(MenuDataService.getMenuSections());
    public menuSections$: Observable<MenuSection[]> = this.menuSectionsSubject.asObservable();

    // TODO: This should be done by cerialize
    private static getMenuSections(): MenuSection[] {
        const menuSections = [];
        for (const section of Object.keys(MENU_SECTIONS)) {
            const menuSection = new MenuSection(MENU_SECTIONS[section].NAME, []);
            const menuItems = MENU_SECTIONS[section].MENU_ITEMS;

            for (const item of Object.keys(menuItems)) {
                const menuItem = menuItems[item];

                const createdMenuItem = new MenuItem(menuItem.ROUTE, menuItem.TITLE, [], menuItem.ICON, menuItem.POSITION, menuItem.COMPONENT);
                const subMenuSections = menuItem.SUB_MENU_SECTIONS;

                for (const subSection of subMenuSections) {
                    const subMenuItems = [];

                    for (const subItem of subSection.ITEMS) {
                        subMenuItems.push(new SubMenuItem(subItem.ROUTE, subItem.TITLE, subItem.ACTION));
                    }

                    const createdSubSection = new SubMenuSection(subMenuItems, subSection.TITLE);
                    createdMenuItem.subMenuSections.push(createdSubSection);
                }
                menuSection.items.push(createdMenuItem);
            }
            menuSections.push(menuSection);
        }
        return menuSections;
    }
}
