import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadcrumbModel} from '../../models/ui/breadcrumb.model';
import {filter, distinctUntilChanged, takeUntil, startWith} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'rl-breadcrumb',
    templateUrl: 'breadcrumb.component.html',
    styleUrls: ['breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit, OnDestroy {

    public items: BreadcrumbModel[];
    private onDestroySubject = new Subject<void>();

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    public ngOnInit(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                // when load the page without any event, we need initialize first
                startWith(this.activatedRoute),
                distinctUntilChanged(),
                takeUntil(this.onDestroySubject))
            .subscribe(() => this.items = this.buildBreadcrumbsRecursively(this.activatedRoute));
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * Traverses through the route and creates an array of breadcrumbs
     * @param {ActivatedRoute} route
     * @param {string} url
     * @param {BreadcrumbModel[]} breadcrumbs
     * @return {BreadcrumbModel[]}
     */
    private buildBreadcrumbsRecursively(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbModel[] = []): BreadcrumbModel[] {
        const path = route.snapshot.url.join('/');
        const nextUrl = `${url}${path}/`;
        const title = route.routeConfig.data ? route.routeConfig.data['breadcrumb'] : null;
        const newBreadcrumbs = title ? [...breadcrumbs, new BreadcrumbModel(title, nextUrl)] : breadcrumbs;

        if (route.firstChild) { // If we have children, continue the recursion
            return this.buildBreadcrumbsRecursively(route.firstChild, nextUrl, newBreadcrumbs);
        }
        return newBreadcrumbs;
    }

}
