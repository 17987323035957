import {VariantModel} from '../../../../../models/api/variant.model';
import {TemplateVariantPresetModel} from '../template-preset/template-preset.model';

export class TemplateVariantModel {
    public get variants(): VariantModel[] {
        return this.variantPresets?.map(preset => preset.variant) || [];
    }
    public variantPresets: TemplateVariantPresetModel[] = [];
    public static fromFormValues(formValues: any): TemplateVariantModel {
        return new TemplateVariantModel(formValues.map((formValue) => TemplateVariantPresetModel.fromFormValues(formValue)));
    }

    constructor(variantPresets: TemplateVariantPresetModel[]) {
        this.variantPresets = variantPresets || [];
    }

    public isValid(): boolean {
        return this.variantPresets.every(preset => preset.isValid());
    }
}
