import {ApiConstants} from '../api.constant';
import {ChannelModel} from '../../models/api/channel.model';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class PublicationTypesService extends ApiService<ChannelModel> {
    constructor() {
        super([ApiConstants.API_GROUP_CHANNELS], ChannelModel);
    }
}
