<ng-container *ngIf="subscription?.closed; else loading">
    <nuc-tab-bar [tabs]="tabbarItems"
                 [(activeTab)]="selectedTab">
    </nuc-tab-bar>
    <hr class="full-width-tab-hr"/>

    <form [formGroup]="form">
        <ng-container [ngSwitch]="selectedTab.index">
            <package-rule-information-form-component *ngSwitchCase="TAB_INFORMATION"
                [form]="form"
                [packageRule]="packageRule">
            </package-rule-information-form-component>
            <rule-conditions-form-component *ngSwitchCase="TAB_CONDITIONS"
                imageName="package_rule_illustration_conditions.svg"
                [form]="form"
                [variants]="variants"
                [ruleConditions]="packageRule.conditions"
                [ruleProperties]="ruleProperties">
            </rule-conditions-form-component>
            <package-rule-material-data-form-component *ngSwitchCase="TAB_MATERIAL_DATA"
                [form]="form"
                [dataFieldValues]="packageRule.dataFields"
                [dataFields]="modalData.packageSetup.materialDataFields">
            </package-rule-material-data-form-component>
        </ng-container>
    </form>
</ng-container>

<ng-template #loading>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>

