<rl-multiple-file-input *ngIf="uploadTransition; else noPermission"
    [fileTypeCategories]="fileTypeCategories"
    [removeWhenReady]="true"
    (uploadedS3Keys)="onFilesChanged($event)"
    [confirmOnDelete]="true">

    <div class="progress" *ngIf="transitionsInProgress">
        <nuc-label>Upload progress</nuc-label>
        <nuc-progress-bar [value]="transitionProgress | async" [showPercentage]="true"></nuc-progress-bar>
    </div>
</rl-multiple-file-input>

<ng-template #noPermission>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="You or your team does not have permissions to view this section. Contact the team owner to enable this section."
                               [small]="true">
    </nuc-empty-state-component>
</ng-template>
