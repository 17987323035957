<form [formGroup]="formGroup">
    <nuc-form-field label="Template name">
        <nuc-input placeholder="Template name" formControlName="name" type="string"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Tags">
        <nuc-suggestive-input
            formControlName="tags"
            (searchTermChange)="onTagChanged($event)"
            [options]="tagOptions"
            placeholder="Add tags">
        </nuc-suggestive-input>
    </nuc-form-field>

    <nuc-form-field *ngIf="publicationType === EPublicationType.POS" label="Template type">
        <nuc-suggestive-input
            formControlName="templateType"
            (searchTermChange)="onTemplateTypeSearchChanged($event)"
            (valueAdded)="onTemplateTypeAdded($event)"
            [options]="templateTypes || []"
            [maxValues]="1"
            type="string">
        </nuc-suggestive-input>
    </nuc-form-field>

    <template-variant-preset
        [formGroup]="formGroup"
        [publicationType]="publicationType">
    </template-variant-preset>
</form>
