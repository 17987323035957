import {Component, Input} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {Toaster} from '../../classes/toaster.class';
import {RLBaseComponent} from '../rl-base-component/rl-base.component';

@Component({
    selector: 'key-viewer',
    templateUrl: './key-viewer.component.html',
    styleUrls: ['./key-viewer.component.scss']
})
export class KeyViewerComponent extends RLBaseComponent {
    @Input() public key: string;
    @Input() public disabled: boolean;

    public viewKey: boolean = false;

    constructor(private clipboard: Clipboard) {
        super();
    }

    public toggleVisibility(): void {
        this.viewKey = !this.viewKey;
    }

    public copyKey(): void {
        this.clipboard.copy(this.key) ? Toaster.success('Copied to clipboard!') :
            Toaster.error('Could not copy to clipboard');
    }
}
