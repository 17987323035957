<ng-container *ngIf="uploadAction?.transition; else invalidConfig">
    <nuc-button-bar>
        <nuc-button-secondary text="Cancel" (click)="clearUploadGroups()"></nuc-button-secondary>
        <nuc-button-secondary text="Browse files" (click)="fileInput.click()"></nuc-button-secondary>
        <nuc-button-primary text="Save" [disabled]="saveButtonDisabled" (click)="onSaveButtonClicked()"></nuc-button-primary>
    </nuc-button-bar>

    <div class="file-upload-container" (dragenter)="dragEnter()">
        <div class="uploads" *ngIf="uploadGroups$ | async as uploadGroups">

            <ng-container *ngFor="let uploadGroup of uploadGroups" [ngSwitch]="uploadGroup.viewType">
                <ng-container *ngSwitchCase="EUploadCategory.TEMPLATE">
                    <div class="upload-template-item">
                        <div class="top-container">
                            <ng-container *ngTemplateOutlet="matchedPublicationItem; context: uploadGroup"></ng-container>

                            <nuc-button-secondary icon="nucicon_trash_fill"
                                                  (click)="onDeleteButtonClicked(uploadGroup)"></nuc-button-secondary>
                        </div>

                        <div class="files">
                            <div class="file" *ngFor="let fileTypeCategory of uploadGroup.requiredFileExtensions"
                                 [class.inactive]="!getUploadForFileType(uploadGroup.uploads, fileTypeCategory)">
                                <img class="file-type-image" [src]="fileTypeCategory | fileTypeIcon"/>
                                <div class="file-info progress">
                                    <p>{{fileTypeCategory | uppercase}}</p>
                                    <ng-container
                                        *ngIf="getUploadForFileType(uploadGroup.uploads, fileTypeCategory) as upload; else placeholderText">
                                        <ng-container
                                            *ngIf="{value: upload.progress$ | async} as progress; else placeholderText">
                                            <img class="status-icon" *ngIf="progress.value === EUploadStatus.Done"
                                                 src="/assets/images/icon_success.svg"/>
                                            <img class="status-icon" *ngIf="progress.value === EUploadStatus.Failed"
                                                 src="/assets/images/icon_failed.svg"/>
                                            <nuc-progress-bar class="progress-bar" [value]="progress.value"
                                                              *ngIf="progress.value !== EUploadStatus.Done && progress.value !== EUploadStatus.Failed"></nuc-progress-bar>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #placeholderText>
                                        <p>Not uploaded</p>
                                    </ng-template>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="EUploadCategory.FILE">
                    <div class="display-asset-item">
                        <file-upload-card-view *ngFor="let uploadFile of uploadGroup.uploads"
                                               class="item"
                                               [itemWidth]="200"
                                               [uploadFile]="uploadFile"
                                               (deleteAssetClicked)="onDeleteButtonClicked(uploadGroup)">
                            <div file-status>
                                <ng-container
                                    *ngTemplateOutlet="matchedPublicationItem; context: uploadGroup"></ng-container>
                            </div>
                        </file-upload-card-view>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="file-input-area"  [class.visible]="(uploadGroups$ | async)?.length < 1 || isDragging">
            <div class="input-group">
                <input type="file"
                       #fileInput
                       name="file-[]"
                       id="fileInput"
                       class="input-file"
                       [class.isDragging]="isDragging"
                       multiple
                       (change)="onFilesSelected($event);"/>

                <label class="head-label" for="fileInput">
                    <div class="drag-drop-row image">
                        <div class="drop-img" [class.isDragging]="isDragging"></div>
                    </div>
                    <div class="drag-drop-row margin-bot-base">
                        <p class="text-center">{{isDragging ? 'Now drop it' : 'Drag your files here'}}</p>
                    </div>
                    <div class="drag-drop-row">
                        <nuc-button-secondary (click)="fileInput.click()" text="Browse files"></nuc-button-secondary>
                    </div>
                </label>
            </div>
        </div>
        <div class="drag-detector" *ngIf="isDragging" (dragleave)="dragLeave()" (drop)="drop($event)"
             (dragover)="dragOver($event)" #fileInput></div>
    </div>
</ng-container>

<ng-template #matchedPublicationItem let-publicationItem$="publicationItem$" let-variant="variant"
             let-validationStatus$="validationStatus$" let-identifier="identifier">
    <div>
        <div class="title-container">
            <ng-container *ngIf="publicationItem$ | async as publicationItem">
                <p>{{publicationItem | publicationItemDisplay:EPublicationDisplayProperties.UPLOAD_DISPLAY_NAME || 'Item not found'}}</p>
                <ng-container *ngIf="variant">
                    <p>/</p>
                    <p>{{variant.getTitle()}}</p>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="validationStatus$ | async as validationStatus">
                <i class="status-icon green nucicon_check_round_fill"
                   *ngIf="validationStatus === VALIDATION_STATUS.VALID"></i>
                <span *ngIf="validationStatus === VALIDATION_STATUS.INVALID" class="text-ellipsis red">
                    No matching items
                    <i class="status-icon red nucicon_close_round_fill"></i>
                </span>
            </ng-container>
        </div>

        <ng-container *ngIf="validationStatus$ | async as validationStatus">
            <p *ngIf="validationStatus === VALIDATION_STATUS.VALID">{{identifier}}</p>
        </ng-container>
    </div>
</ng-template>

<ng-template #invalidConfig>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission_small.svg"
                               [small]="true"
                               title="Your team does not have configured workflow transitions for uploading files. Contact the team owner to enable this section.">
    </nuc-empty-state-component>
</ng-template>
