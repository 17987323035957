/**
 * Created by nandamhuis on 09/01/2017.
 */

import {autoserialize, autoserializeAs} from 'cerialize';
import {DropdownItem} from '../ui/dropdown-item.model';

export class CollectionOptionsModel {

    public static readonly DEFAULT_PAGE_SIZE = 20;

    @autoserialize public mode: string; // Collection view mode
    @autoserialize public sortDescending: boolean = false; // Are the results sorted descending or ascending
    @autoserializeAs(DropdownItem) public sort: DropdownItem<string>; // Property to sort by
    @autoserialize public title: string; // Title of the items
    @autoserialize public search: string = ''; // Searchvalue used while isLoading
    @autoserialize public filters: string[]; // Filters for data
    @autoserialize public total: number = 0; // Total items
    @autoserialize public page: number = 1; // Current page
    @autoserialize public pageSize: number = CollectionOptionsModel.DEFAULT_PAGE_SIZE; // The amount of items per page
    @autoserialize public shouldPage: boolean = true; // The collection is allowed to page
    @autoserialize public isLoading: boolean = false; // Currently isLoading
    @autoserialize public allowSuggest: boolean = true; // Is search suggesting allowed
    @autoserialize public initialized: boolean = true; // (Optional)to prevent resetting the page and page size before restoring from url
    @autoserialize public configVisibleColumns: boolean = true; // (Optional) to allow configure the visible columns by the user
}
