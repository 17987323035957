import {ApiRequest} from './api.request';
import {ERequestMethod} from '../../app.enums';
import {ARRequestOptions} from '@relayter/core';
import {IResponseModel} from '../../models/interfaces/response-model.interface';
import {ApiConstants} from '../api.constant';

export class ApiPostJobRequest extends ApiRequest {
    public apiMethod = ERequestMethod.POST;

    protected get urlComponents() {
        return [
            ...super.urlComponents,
            ApiConstants.API_METHOD_JOBS
        ];
    };

    constructor(public pathComponents: string[] = [],
                public model: IResponseModel,
                public jobType: string,
                public jobData: Record<string, any>) {
        super(pathComponents, model);
    }

    public get options(): ARRequestOptions {
        const options = super.options;
        options.body = {jobType: this.jobType, jobData: this.jobData};

        return options;
    }
}
