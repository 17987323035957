<div class="grid">
    <rl-new-template-editor></rl-new-template-editor>

    <div class="card-container" *ngIf="tabs?.length">

        <div class="bottom-line">
            <nuc-tab-bar [tabs]="tabs" [(activeTab)]="activeTab" (activeTabChange)="onActiveTabChanged($event)"></nuc-tab-bar>
        </div>

        <rl-template-preset *ngIf="activeTab === presetTab"></rl-template-preset>
        <rl-template-size *ngIf="activeTab === sizesTab"></rl-template-size>
        <rl-template-content *ngIf="activeTab === contentTab"></rl-template-content>
        <template-variant *ngIf="activeTab === variantsTab" [variants]="variants">
        </template-variant>

    </div>

</div>
