import {Component, EventEmitter, Output} from '@angular/core';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {NotificationsDataService} from '../../api/services/notifications.data-service';
import {ENotificationActionType, ENotificationStatus, NotificationModel} from '../../models/api/notification.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {INotificationActionEvent} from './notification-item/notification-item.component';
import {ARLogger} from '@relayter/core';
import {Router} from '@angular/router';
import {EToastType, ToastDataModel, ToastService} from '@relayter/rubber-duck';
import {NotificationsService} from '../../api/services/notifications.service';

interface INotificationData {
    today: NotificationModel[];
    yesterday: NotificationModel[];
    older: NotificationModel[];
}

@Component({
    selector: 'rl-notifications-sub-menu',
    templateUrl: './notifications-sub-menu.component.html',
    styleUrls: ['./notifications-sub-menu.component.scss'],
})
export class NotificationsSubMenuComponent {
    @Output()
    public closeClicked = new EventEmitter<void>();

    public directTab: ITabBarItem = {title: 'Direct'};
    public archiveTab: ITabBarItem = {title: 'Archive'};
    public tabs = [this.directTab, this.archiveTab];

    public activeTab = this.directTab;

    public selectedNotificationId: string;
    public selectedArchivedNotificationId: string;
    public readonly ENotificationStatus = ENotificationStatus;

    public sortedNotifications$: Observable<INotificationData> = this.notificationsDataService.notifications$.pipe(
        map((notifications) => {
            if (!notifications) return;

            const now = new Date();
            const today = new Date(now.toDateString());
            const yesterday = new Date(today.toDateString());
            yesterday.setDate(yesterday.getDate() - 1);

            return {
                today: notifications.filter((notification) => notification.createdAt >= today),
                yesterday: notifications.filter((notification) => notification.createdAt < today && notification.createdAt >= yesterday),
                older: notifications.filter((notification) => notification.createdAt < yesterday),
            };
        })
    );
    public notificationsArchived$: Observable<NotificationModel[]> = this.notificationsDataService.archivedNotifications$;

    constructor(private notificationsDataService: NotificationsDataService,
                private notificationsService: NotificationsService,
                private toastService: ToastService,
                private router: Router) {}

    public onNotificationClicked(notification: NotificationModel, status?: ENotificationStatus): void {
        switch (status) {
            case ENotificationStatus.READ:
                this.selectedNotificationId = notification._id;
                if (notification.status === ENotificationStatus.UNREAD) {
                    this.notificationsDataService.markAsRead(notification._id);
                }
                break;
            case ENotificationStatus.ARCHIVED:
                this.selectedNotificationId = null;
                if (notification.status !== ENotificationStatus.ARCHIVED) {
                    this.notificationsDataService.markAsArchived(notification._id);
                }
                break;
            default:
                this.selectedNotificationId = notification._id;
                break;
        }
    }

    public onNotificationActionClicked(event: INotificationActionEvent): void {
        switch (event.action.type) {
            case ENotificationActionType.NAVIGATE:
                // TODO: Find a clean way to force reloads on the components we navigate to instead of this hack
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigateByUrl(event.action.url).catch((reason) => {
                        this.toastService.show(new ToastDataModel(EToastType.WARNING, 'Navigation failed', 'The notification link no longer works'));
                        ARLogger.error(`Navigation for notification failed: ${reason}`);
                    });
                });
                this.closeClicked.emit();
                break;
            case ENotificationActionType.DOWNLOAD:
                window.open(event.action.url);
                break;
            default:
                ARLogger.error(`Unhandled notification action ${event.action.type} for notification: ${event.notification.title}`);
        }
    }
}
