<nuc-tab-bar [tabs]="tabbarItems"
             [(activeTab)]="selectedTab">
</nuc-tab-bar>
<hr class="full-width-tab-hr"/>

<form [formGroup]="form">
    <ng-container [ngSwitch]="selectedTab.index">
        <package-setup-information-form-component *ngSwitchCase="TAB_INFORMATION"
                                                  [form]="form"
                                                  [packageSetup]="packageSetup">
        </package-setup-information-form-component>

        <package-setup-package-data-form-component *ngSwitchCase="TAB_PACKAGE_DATA"
                                                   [form]="form"
                                                   [properties]="properties"
                                                   [variants]="variants"
                                                   [packageSetup]="packageSetup">
        </package-setup-package-data-form-component>
        <package-setup-export-settings-form-component *ngSwitchCase="TAB_EXPORT_SETTINGS"
                                                           [form]="form"
                                                           [packageSetup]="packageSetup">
        </package-setup-export-settings-form-component>
    </ng-container>
</form>
