import {ARLogger} from '@relayter/core';
import {UserService} from '../api/services/users.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TemporaryStorageUtil {

    private static readonly SESSION_STORAGE_KEY_CAMPAIGN_STATUS: string = 'selected-campaign-status';

    constructor(private userService: UserService) {}

    public storeSelectedCampaignStatus(selectedStatus: string): void {
        const user = this.userService.getLoggedInUser();
        if (!user) return;

        const userId = user._id;
        const key = TemporaryStorageUtil.SESSION_STORAGE_KEY_CAMPAIGN_STATUS;

        if (selectedStatus) {
            try {
                sessionStorage.setItem(key, JSON.stringify({[userId]: selectedStatus}));
            } catch (error) {
                // When something goes wrong delete the entry from session storage
                ARLogger.warn('Storing selected campaign status failed, removing it from storage');
                sessionStorage.removeItem(key);
            }
        } else {
            sessionStorage.removeItem(key);
        }
    }

    public getSelectedCampaignStatus(): string {
        const user = this.userService.getLoggedInUser();
        const userId = user?._id;
        const key = TemporaryStorageUtil.SESSION_STORAGE_KEY_CAMPAIGN_STATUS;
        try {
            const statusConfig = JSON.parse(sessionStorage.getItem(key));
            return statusConfig ? statusConfig[userId] : null;
        } catch (error) {
            ARLogger.warn('Retrieving selected campaign status failed, removing it from storage');
            sessionStorage.removeItem(key);
            return null;
        }
    }

}
