/**
 * Created by nandamhuis on 08/05/2017.
 */

import {autoserialize} from 'cerialize';

export class PointModel {
    @autoserialize public x: number = 0;
    @autoserialize public y: number = 0;

    constructor(x?: number, y?: number) {
        this.x = x || 0;
        this.y = y || 0;
    }
}
