<form [formGroup]="formGroup">
    <nuc-form-field label="Master page name">
        <nuc-input formControlName="name" placeholder="Name of master page" required></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Publication type">
        <nuc-dropdown formControlName="publicationType"
                      [items]="channels"
                      [total]="channels.length"
                      placeholder="Select a publication type"
                      required>
        </nuc-dropdown>
    </nuc-form-field>

    <rl-upload-file-component
        title="Master page file"
        (onUploadUpdate)="uploadUpdateSubject.next($event)"
        [fileTypeCategories]="fileTypeCategories ">
    </rl-upload-file-component>
</form>
