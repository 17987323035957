import {autoserialize, autoserializeAs} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {IResponseModel} from '../interfaces/response-model.interface';

export class LayoutItemModel {
    @autoserialize public repeat: boolean;
    @autoserialize public numberOfHorizontalItems: number;

    constructor(repeat: boolean, numberOfHorizontalItems: number) {
        this.repeat = repeat;
        this.numberOfHorizontalItems = numberOfHorizontalItems;
    }
}

export class LayoutModel implements IDropdownItem, IResponseModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(LayoutItemModel) public items: LayoutItemModel[];

    getTitle(): string {
        return this.name;
    }

    getValue(): string {
        return this._id;
    }

    static getDefaultLayout(): LayoutModel {
        const layout = new LayoutModel();
        layout.name = 'Default Layout';
        layout.items = [
            new LayoutItemModel(false, 1),
            new LayoutItemModel(true, 1),
            new LayoutItemModel(false, 1)
        ];

        return layout;
    }
}
