import {Pipe, PipeTransform} from '@angular/core';
import {PublicationItemModel} from '../models/api/publication-item.model';

export enum EPublicationDisplayProperties {
    TITLE,
    SUBTITLE,
    THUMBNAIL_URL,
    UPLOAD_DISPLAY_NAME}

@Pipe({
    name: 'publicationItemDisplay'
})
export class PublicationItemDisplayPipe implements PipeTransform {

    public transform(publicationItem: PublicationItemModel, property: EPublicationDisplayProperties|string, variant?: string): string {
        switch (property) {
            case EPublicationDisplayProperties.TITLE:
                return publicationItem.getNavigationItemTitle();
            case EPublicationDisplayProperties.SUBTITLE:
                return publicationItem.getNavigationItemSubtitle();
            case EPublicationDisplayProperties.THUMBNAIL_URL:
                return publicationItem.getThumbnailURL(variant);
            case EPublicationDisplayProperties.UPLOAD_DISPLAY_NAME:
                return publicationItem.getUploadDisplayName();
            default:
                throw new Error(`PublicationItemDisplayPipe: Unhandled property ${property} on publicationItem ${publicationItem}`);
        }
    }
}
