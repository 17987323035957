<div class="d-flex justify-content-center align-items-center h-100">
    <div>
        <ng-content></ng-content>
        <div class="rl-loading-indicator " [ngClass]="size" [hidden]="hide">
            <svg class="rl-loading-indicator__svg" [ngClass]="{'path-inverse': inverse, 'path-regular': !inverse}"
                 viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
        </div>
    </div>
</div>

