import {BehaviorSubject, Observable, of} from 'rxjs';
import {UploadModel} from '../../../../../../../components/upload-file-component/upload.model';
import {catchError, map, shareReplay, startWith} from 'rxjs/operators';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {VariantModel} from '../../../../../../../models/api/variant.model';


export enum EUploadCategory {
    TEMPLATE = 'TEMPLATE',
    SOURCE = 'SOURCE',
    EXPORT = 'EXPORT',
    FILE = 'FILE'
}

export class UploadGroupModel {
    public static readonly VALIDATION_STATUS = {
        VALIDATING: 'VALIDATING',
        VALID: 'VALID',
        INVALID: 'INVALID'
    };

    public identifier: string;
    public publicationItem: PublicationItemModel;
    public publicationItem$: Observable<PublicationItemModel>;
    public uploadCategory: EUploadCategory;
    public variant: VariantModel;
    public requiredFileExtensions: string[] = [];
    public get viewType(): EUploadCategory {
        return this.requiredFileExtensions.length > 1 ? EUploadCategory.TEMPLATE : EUploadCategory.FILE;
    };
    public uploads: UploadModel[] = [];
    public validationStatus$: Observable<string>;

    /**
     * @constructor
     * @param {string} identifier
     * @param {PublicationItemModel} publicationItem
     * @param {VariantModel} variant
     * @param {EUploadCategory} uploadCategory
     */
    constructor(identifier: string, publicationItem: PublicationItemModel, variant: VariantModel, uploadCategory?: EUploadCategory) {
        this.identifier = identifier;
        this.publicationItem = publicationItem;

        if (variant) this.variant = variant;
        if (uploadCategory) this.uploadCategory = uploadCategory;

        this.publicationItem$ = new BehaviorSubject<PublicationItemModel>(publicationItem);
        this.validationStatus$ = this.publicationItem$.pipe(
            catchError(() => of(null)), // Catch a connection error
            map((item) => item ? UploadGroupModel.VALIDATION_STATUS.VALID : UploadGroupModel.VALIDATION_STATUS.INVALID),
            startWith(UploadGroupModel.VALIDATION_STATUS.VALIDATING),
            shareReplay(1)
        );

    }
}
