import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TemplateBodyModel, TemplateDetailModel} from '../../../../models/api/template.model';
import {TemplateService} from '../../../../api/services/templates.service';
import {Toaster} from '../../../../classes/toaster.class';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {TemplateDataService} from './template.data-service';
import {forkJoin, Subject} from 'rxjs';
import {filter, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {EPublicationType} from './publication-type.enum';
import {ChannelModel} from '../../../../models/api/channel.model';
import {ARLogger} from '@relayter/core';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService} from '@relayter/rubber-duck';
import {EDataFieldCollectionName} from '../../../../app.enums';
import {VariantModel} from '../../../../models/api/variant.model';
import {VariantService} from '../../../../api/services/variant.service';
import {PublicationTypesService} from '../../../../api/services/publication-types.service';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';

@Component({
    selector: 'rl-template-detail',
    templateUrl: 'template-detail.component.html',
    styleUrls: ['template-detail.component.scss'],
    providers: [TemplateDataService]
})

export class TemplateDetailComponent implements OnInit, OnDestroy {

    @Input() public template: TemplateDetailModel;
    @Input() public publicationType: EPublicationType = EPublicationType.PRINT_MAGAZINE;

    public presetTab: ITabBarItem = {title: 'Preset'};
    public sizesTab: ITabBarItem = {title: 'Sizes'};
    public contentTab: ITabBarItem = {title: 'Content'};
    public variantsTab: ITabBarItem = {title: 'Variants'};

    public tabs: ITabBarItem[];

    public activeTab = this.presetTab;

    private channels: ChannelModel[];

    public variants: VariantModel[];
    public variantsEnabled = false;

    private saveButtonConfig: ButtonConfig;

    private onDestroySubject = new Subject<void>();

    constructor(private templateService: TemplateService,
                private publicationTypesService: PublicationTypesService,
                private fullModalService: FullModalService,
                public templateDataService: TemplateDataService,
                private variantService: VariantService,
                private dataFieldsService: DataFieldsApiService) {
    }

    public ngOnInit(): void {
        // On selection of content in template editor, open the content tab
        this.templateDataService.selectedContentIndex$.pipe(
            filter((selectedContentIndex) => selectedContentIndex >= 0 && this.publicationType === EPublicationType.PRINT_MAGAZINE),
            takeUntil(this.onDestroySubject)
        ).subscribe(() => this.activeTab = this.contentTab);

        this.initVariantData();
        this.initModalButtons();
    }

    private initVariantData(): void {
        forkJoin([
            this.variantService.getVariants(),
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM)
        ]).pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: ([variantResults, dataFields]) => {
                    this.variantsEnabled = dataFields.some(field => field.enableVariants);
                    this.variants = variantResults.items;

                    this.initTemplate();
                    this.initTabBar();
                },
                error: Toaster.handleApiError
            });
    }

    private initTemplate(): void {
        this.templateDataService.setPublicationType(this.publicationType);
        if (this.template) {
            this.templateDataService.initFromTemplate(this.template);
        } else {
            this.templateDataService.initWithDefaults(this.publicationType);
        }
    }

    private initTabBar(): void {
        switch (this.publicationType) {
            case EPublicationType.PRINT_MAGAZINE:
                this.tabs = [this.presetTab, this.sizesTab, this.contentTab];
                break;
            case EPublicationType.POS:
            case EPublicationType.WEB:
                this.tabs = [this.presetTab, this.sizesTab];
                break;
            default:
                ARLogger.warn(`Template detail opened with incorrect publication type: ${this.publicationType}`);
                break;
        }

        if (this.variantsEnabled && this.tabs?.length) this.tabs.push(this.variantsTab);
    }

    private initModalButtons(): void {
        this.saveButtonConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        const save = new FullModalActionModel(this.saveButtonConfig);
        save.observable.pipe(
            withLatestFrom(this.templateDataService.template$)
        ).subscribe(([, template]) => this.onSaveButtonClicked(template));
        this.fullModalService.setModalActions([cancel, save]);

        // Channels are required to covert from EPublicationType to channelId
        // Wait until we have channels before updating the saveButtonConfig, so we can only save once channels are loaded
        this.publicationTypesService.find().pipe(
            switchMap((result) => {
                this.channels = result.items;
                return this.templateDataService.template$;
            }),
            takeUntil(this.onDestroySubject),
        ).subscribe({
            next: (template) => this.saveButtonConfig.disabled = !template,
            error: Toaster.handleApiError
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public onActiveTabChanged(tab: ITabBarItem): void {
        // If we navigate from the content tab somewhere else & have a selected template content item, deselect it
        if (tab !== this.contentTab) {
            this.templateDataService.setSelectedContentIndex(-1);
        }
    }

    public onSaveButtonClicked(template: TemplateBodyModel): void {
        let observable;
        let successMessage;
        template.channel = this.channels.find((channel) => channel.name === this.publicationType)._id;

        if (this.template) {
            observable = this.templateService.updateTemplate(this.template._id, template);
            successMessage = 'Successfully updated template';
        } else {
            observable = this.templateService.postTemplate(template);
            successMessage = 'Successfully saved template';
        }

        observable.subscribe(
            () => {
                Toaster.success(successMessage);
                this.fullModalService.close(true, false);
            },
            (error) => Toaster.handleApiError(error));
    }
}
