import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ICollectionViewItem} from '../../models/interfaces/collection-view-item.interface';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {EPropertySettingsContext} from '../property-settings/property-settings.service';
import {OptionsMutationType} from '../collection-options-view/collection-options-view.component';

@Component({
    selector: 'rl-collection-view',
    templateUrl: 'collection-view.component.html',
    styleUrls: ['collection-view.component.scss']
})

export class CollectionViewComponent {
    private _sort: DropdownItem<string>;
    @Output() public sortChange: EventEmitter<DropdownItem<string>> = new EventEmitter<DropdownItem<string>>();
    @Input()
    public get sort(): DropdownItem<string> {
        return this._sort;
    }
    public set sort(val) {
        this._sort = val;
        this.sortChange.emit(val);
    }

    private _sortDescending: boolean;
    @Output() public sortDescendingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input()
    public get sortDescending(): boolean {
        return this._sortDescending;
    }
    public set sortDescending(val) {
        this._sortDescending = val;
        this.sortDescendingChange.emit(val);
    }
    @Input()
    public searchValue: string;

    @Input() public propertySettingsContext: EPropertySettingsContext;
    @Input() public items: ICollectionViewItem[];
    @Input() public isLoading: boolean;
    @Input() public selectedItem: ICollectionViewItem;
    @Input() public sortingOptions: DropdownItem<string>[];
    @Input() public selectMultipleItems: boolean = false;
    @Input() public selectedItems: string[];
    @Input() public advancedFilter: boolean;
    @Input() public searchPlaceholder: string;
    @Output() public onSearchValueUpdated = new EventEmitter<string>();
    @Input() public disableNextPage = false;
    @Input() public disablePageOptions = false;
    @Input() public viewId: string;
    @Input() public disableSort = false;
    @Input() public isSearching = false;

    @Output() public collectionViewOptionsChanged = new EventEmitter();
    @Output() public collectionViewItemClicked = new EventEmitter();
    @Output() public collectionViewItemDoubleClicked = new EventEmitter();

    /**
     * If collection options changed, request new data
     */
    public onCollectionOptionsChanged(type: OptionsMutationType): void {
        this.collectionViewOptionsChanged.emit(type);
    }

    /**
     * On item clicked, emit clicked event
     * @param item
     */
    public onGridViewItemClicked(item: ICollectionViewItem): void {
        this.collectionViewItemClicked.emit(item);
    }

    /**
     * On item double clicked, emit double clicked event
     * @param item
     */
    public onGridViewItemDoubleClicked(item: ICollectionViewItem): void {
        this.collectionViewItemDoubleClicked.emit(item);
    }

}
