<ng-container *ngIf="permissions.GET_WORKFLOW_CONFIGURATION | userIsAllowedTo; else noPermissionEmptyState">
    <div class="header flex-header" *ngIf="workflow; else loadingIndicator">
        <div class="button-container left">
            <nuc-button-secondary *ngIf="(permissions.GET_STICKY_NOTES_LOG | userIsAllowedTo) && stickyLogComponent"
                                  text="Note log" (click)="openStickyLog()"></nuc-button-secondary>

            <nuc-button-secondary *ngIf="(permissions.GET_PUBLICATION_ITEMS | userIsAllowedTo) && overviewComponent"
                                  icon="nucicon_grid" (click)="openOverview()"></nuc-button-secondary>

        </div>

        <rl-workflow-indicator [activeStep]="activeStep"
                               (activeStepChange)="setActiveStep($event)"
                               [counts]="counts"
                               [workflowConfiguration]="workflow"></rl-workflow-indicator>

        <div class="button-container right">

            <!-- Variant selector -->
            <nuc-dropdown class="variant variant-highlight" *ngIf="variants.length > 0"
                          placeholder="Select variant"
                          (dataChange)="setVariant($event)"
                          [data]="activeVariant"
                          [nullOption]="false"
                          icon="nucicon_variant"
                          [items]="variants"></nuc-dropdown>

            <rl-custom-workflow-filters *ngIf="workflow.filters?.length"></rl-custom-workflow-filters>

            <div class="popout-button" *ngIf="workflowActions && workflowActions.length">
                <nuc-button-secondary
                    [text]="!activeFilters || activeFilters.size === 0 ? 'Move all items' : 'Move ' + getFilteredItemCount() + ' items'"
                    icon="nucicon_dots_vertical"
                    iconPosition="end"
                    [matMenuTriggerFor]="menu">
                </nuc-button-secondary>

                <mat-menu #menu="matMenu" class="menu-items-container">
                    <ng-container *ngFor="let action of workflowActions">
                        <button mat-menu-item
                                (click)="onTransitionActionClicked(action)"
                                [disableRipple]="true">
                            {{action.title}}
                        </button>
                    </ng-container>
                </mat-menu>
            </div>
        </div>
    </div>
</ng-container>

<div class="header">
    <workflow-tab-bar-view *ngIf="workflow"
                              [tabs]="tabs"
                              [activeTab]="activeTab"
                              (requestTabChange)="onRequestTabChanged($event)">
    </workflow-tab-bar-view>
</div>

<div class="workflow-component" *ngIf="!transitionItemLoading && !isStepDisabled">
    <ng-container *ngIf="activeStep && activeTab" [ngSwitch]="component.componentType">

        <!-- Place workflow components here -->
        <rl-custom-workflow-multi-selection-component
                *ngSwitchCase="ECustomWorkflowComponentType.MULTI_SELECTION"
                [campaign]="campaign"
                [publication]="publication"
                [component]="component">
        </rl-custom-workflow-multi-selection-component>

        <rl-custom-workflow-files-component
                *ngSwitchCase="ECustomWorkflowComponentType.FILES"
                [publication]="publication"
                [workflow]="workflow"
                [step]="activeStep"
                [component]="component"
                [activeFilters]="activeFilters">
        </rl-custom-workflow-files-component>

        <rl-custom-workflow-overview-items-component *ngSwitchCase="ECustomWorkflowComponentType.STEP_OVERVIEW" [sliderValue]="1">
        </rl-custom-workflow-overview-items-component>

        <rl-custom-workflow-sticky-list-component
                *ngSwitchCase="ECustomWorkflowComponentType.STICKY_LIST"
                [publication]="publication"
                [workflow]="workflow"
                [step]="activeStep"
                [component]="component"
                [activeFilters]="activeFilters">
        </rl-custom-workflow-sticky-list-component>

        <rl-custom-workflow-add-pages-component *ngSwitchCase="ECustomWorkflowComponentType.ADD_PAGES">
        </rl-custom-workflow-add-pages-component>

        <rl-custom-workflow-layout-component *ngSwitchCase="ECustomWorkflowComponentType.LAYOUT">
        </rl-custom-workflow-layout-component>

        <rl-custom-workflow-preview-component *ngSwitchCase="ECustomWorkflowComponentType.PREVIEW">
        </rl-custom-workflow-preview-component>

        <rl-custom-workflow-briefing-component *ngSwitchCase="ECustomWorkflowComponentType.BRIEFING">
        </rl-custom-workflow-briefing-component>

        <rl-custom-workflow-list-view-component *ngSwitchCase="ECustomWorkflowComponentType.LIST_VIEW">
        </rl-custom-workflow-list-view-component>

        <custom-workflow-package-management-component *ngSwitchCase="ECustomWorkflowComponentType.PACKAGE_MANAGEMENT">
        </custom-workflow-package-management-component>

        <rl-custom-workflow-automation-component *ngSwitchCase="ECustomWorkflowComponentType.AUTOMATION">
        </rl-custom-workflow-automation-component>

        <custom-workflow-upload-assets-component *ngSwitchCase="ECustomWorkflowComponentType.UPLOAD_ASSETS"></custom-workflow-upload-assets-component>

        <custom-workflow-upload-component *ngSwitchCase="ECustomWorkflowComponentType.UPLOAD"></custom-workflow-upload-component>

        <!-- Placeholder component -->
        <rl-custom-workflow-placeholder
                *ngSwitchDefault
                [component]="component">
        </rl-custom-workflow-placeholder>

    </ng-container>
</div>

<nuc-empty-state-component *ngIf="isStepDisabled"
                           [small]="true"
                           imageUrl="assets/images/empty_states/no_permission_small.svg"
                           [title]="'No permission to view this page. \nPlease contact your administrator.'">
</nuc-empty-state-component>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
