import {TemplateAreaModel} from '../../../../../../../../models/api/template-area.model';
import {IEditorOptions} from '../spread-editor.component';

export class TemplateAreaItemPixi extends PIXI.Graphics {

    private static BORDER_COLOR = 0x9B9B9B; // light-grey
    private static BACKGROUND_COLOR = 0xF3F3F3; // dove

    private static HIGHLIGHT_BORDER_COLOR = 0x3FBB87; // relayter-green
    private static HIGHLIGHT_BACKGROUND_COLOR = 0x3FBB87; // relayter-green (alpha 0.25)

    private highlighted: boolean;
    private hovered: boolean;

    private scaleRatio: number;

    constructor(public templateArea: TemplateAreaModel, public row: number, public column: number,
                private editorOptions: IEditorOptions, scaleRatio: number = 1) {
        super();
        this.scaleRatio = scaleRatio;
        this.update();

        this.interactive = true;

        this.on('mouseover', () => this.onHover());
        this.on('mouseout', () => this.onHoverEnd());
    }

    private onHover(): void {
        this.setHovered(true);
    }

    private onHoverEnd(): void {
        this.setHovered(false);
    }

    public update(): void {
        this.clear();

        const columnWidth = this.templateArea.getColumnWidth();
        const rowHeight = this.templateArea.getRowHeight();

        let borderColor = TemplateAreaItemPixi.BORDER_COLOR;
        let backgroundColor = TemplateAreaItemPixi.BACKGROUND_COLOR;
        let backgroundColorAlpha = 1;
        let alpha = .5;
        if (this.highlighted || this.hovered) {
            borderColor = TemplateAreaItemPixi.HIGHLIGHT_BORDER_COLOR;
            backgroundColor = TemplateAreaItemPixi.HIGHLIGHT_BACKGROUND_COLOR;
            backgroundColorAlpha = .25;
            alpha = 1;
        }

        this.beginFill(backgroundColor, backgroundColorAlpha);
        this.lineStyle(1 / this.scaleRatio, borderColor);
        this.drawRect(
            (columnWidth + this.templateArea.columnGutter) * this.column,
            (rowHeight + this.templateArea.rowGutter) * this.row,
            columnWidth,
            rowHeight);
        this.endFill();
        this.alpha = alpha;
    }

    private setHovered(hovered: boolean): void {
        if (this.hovered !== hovered) {
            this.hovered = hovered;
            this.update();
        }
    }

    public setHighlighted(highlight: boolean): void {
        if (this.highlighted !== highlight) {
            this.highlighted = highlight;
            this.update();
        }
    }
}
