import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FileTypeUtil} from '../../../classes/file-type.util';
import {Toaster} from '../../../classes/toaster.class';
import {AmazonService} from '../../../api/services/amazon.service';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {
    EUploadStatus,
    UploadModel
} from '../../upload-file-component/upload.model';
import {filter, map, shareReplay, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {BUTTON_TYPE, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';

@Component({
    selector: 'rl-multiple-file-input',
    templateUrl: 'multiple-file-input.component.html',
    styleUrls: ['multiple-file-input.component.scss']
})
/**
 * @deprecated
 */
export class MultipleFileInputComponent implements OnInit, OnDestroy {
    @Input() public removeWhenReady: boolean;
    @Input() public fileTypeCategories: string[];
    @Input() public confirmOnDelete: boolean;

    @Output() public uploadsStarted = new EventEmitter<void>();
    @Output() public uploadedS3Keys = new EventEmitter<string[]>();

    public uploadingFiles: UploadModel[] = [];
    public uploadFiles$ = new EventEmitter<void>();

    protected onDestroySubject = new Subject<void>();

    public uploadsReady$: Observable<boolean> = this.uploadFiles$.pipe(
        switchMap(() => {
            if (this.uploadingFiles.length === 0) {
                return of(true);
            }

            const progressDone = this.uploadingFiles
                .map((upload) => upload.progress$.pipe(map((progress) => progress === EUploadStatus.Done)));

            const itemsValid = this.uploadingFiles.map((uploadFile) => uploadFile.signedUrl$.pipe(
                map((s3Key) => !!s3Key)));

            return combineLatest([...itemsValid, ...progressDone]).pipe(map((results) => results.every((result) => result)));
        }),
        startWith(false),
        shareReplay(1),
    );

    constructor(private amazonService: AmazonService,
                private dialogService: NucDialogService) {}

    /**
     * Get config settings from config storage
     */
    public ngOnInit(): void {
        this.uploadsReady$
            .pipe(
                filter(ready => !!ready),
                switchMap(() => {
                    if (this.uploadingFiles.length) {
                        return combineLatest([...this.uploadingFiles.map(uploadFile => uploadFile.s3Key$)]);
                    }
                    return of([]);
                }),
                takeUntil(this.onDestroySubject)
            )
            .subscribe(s3Keys => {
                this.uploadedS3Keys.emit(s3Keys);
                if (this.removeWhenReady) {
                    this.uploadingFiles = [];
                }
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * On updated files, check if the file extension, get signed url, update uploading assets
     * @param {File[]} files
     */
    public onFilesChanged(files: File[]): void {
        let filteredFiles = [];
        if (this.fileTypeCategories) {
            filteredFiles = FileTypeUtil.filterFiles(files, this.fileTypeCategories);
            if (files.length > filteredFiles.length) {
                Toaster.warn('One or more file types are not supported and are filtered out of the results');
            }
        }

        if (filteredFiles.length > 0) {
            // Announce starting the upload
            this.uploadsStarted.emit();
            // Add all new files to the uploading files
            this.uploadingFiles.push(...filteredFiles.map(file => this.amazonService.createUpload(file)));
            this.uploadFiles$.emit();
        }
    }

    /**
     * Responds to delete button clicks
     * @param {UploadModel} uploadFile
     */
    public onDeleteButtonClicked(uploadFile: UploadModel): void {
        if (this.confirmOnDelete) {
            const deleteDialogConfig = new NucDialogConfigModel('Delete file',
                'Please confirm that you wish to delete this file.');
            const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
            deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
            deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
                deleteDialog.close();
                this.deleteFile(uploadFile);
            });
        } else {
            this.deleteFile(uploadFile);
        }
    }

    private deleteFile(uploadFile: UploadModel): void {
        this.uploadingFiles = this.uploadingFiles.filter((uploadingFile) => uploadingFile !== uploadFile);
        this.uploadFiles$.emit();
    }
}
