import { Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CampaignModel, CampaignPostModel, CampaignPutModel} from '../../models/api/campaign.model';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {Toaster} from '../../classes/toaster.class';
import {ARApiError} from '@relayter/core';
import {CampaignService} from '../../api/services/campaigns.service';
import {map, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {ESegmentTrackEvents, ISegmentService, SEGMENT_SERVICE} from '../../services/segment/segment.service.interface';
import {VariantService} from '../../api/services/variant.service';
import {Subject} from 'rxjs';
import {VariantModel} from '../../models/api/variant.model';

export interface ICampaignFormData {
    campaign?: CampaignModel;
}

@Component({
    selector: 'rl-campaign-form-component',
    templateUrl: 'campaign-form.component.html',
    styleUrls: ['campaign-form.component.scss']
})

/**
 * CampaignFormComponent
 * Form that manages the creation of a new product
 */
export class CampaignFormComponent implements OnInit, OnDestroy {
    private static readonly WEEK = 7 * 24 * 60 * 60 * 1000;

    public statusOptions = [
        new DropdownItem('New', 'NEW'),
        new DropdownItem('Briefing', 'BRIEFING'),
        new DropdownItem('Production', 'PRODUCTION'),
        new DropdownItem('Ready', 'READY'),
        new DropdownItem('Done', 'DONE')
    ];

    public dateFormGroup = new UntypedFormGroup({
        startDate: new UntypedFormControl(new Date(), Validators.required),
        endDate: new UntypedFormControl(new Date(Date.now() + CampaignFormComponent.WEEK), Validators.required),
    }, (formGroup) => formGroup.value.startDate < formGroup.value.endDate ? null : { dateError: 'Start and end dates are conflicting' });

    public formGroup = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        tags: new UntypedFormControl([]),
        dates: this.dateFormGroup,
        status: new UntypedFormControl(this.statusOptions[0], Validators.required),
        variants: new UntypedFormControl([])
    });

    public tagOptions: DropdownItem<string>[];
    public variants: VariantModel[] = [];

    public saveConfig: ButtonConfig;
    private onDestroySubject = new Subject<void>();

    constructor(private campaignService: CampaignService,
                private fullModalService: FullModalService,
                private variantService: VariantService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: ICampaignFormData,
                @Inject(SEGMENT_SERVICE) private segmentService: ISegmentService) {}


    public ngOnInit(): void {
        this.initButtons();
        this.initForm();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initButtons(): void {
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.saveCampaign());

        const actions = [cancelAction, saveAction];
        this.fullModalService.setModalActions(actions);
    }

    private initForm(): void {
        this.formGroup.statusChanges.pipe(
            map((status) => status === 'VALID'),
            distinctUntilChanged()
        ).subscribe((valid) => this.saveConfig.disabled = !valid);

        if (this.modalData.campaign) {
            this.formGroup.get('variants').disable({emitEvent: false});
            this.formGroup.patchValue({
                name: this.modalData.campaign.name,
                dates: {
                    startDate: this.modalData.campaign.startDate,
                    endDate: this.modalData.campaign.endDate,
                },
                tags: this.modalData.campaign.tags ? this.modalData.campaign.tags.map((tag) => new DropdownItem(tag, tag)) : [],
                status: this.statusOptions.find((status) => status.getValue() === this.modalData.campaign.status)
            });
        }

        this.getVariants();
    }

    public onTagChanged(event: string): void {
        this.tagOptions = event && event.length && event.trim().length ? [new DropdownItem<string>(event.trim(), event.trim())] : [];
    }

    public getVariants(): void {
        this.variantService.getVariants()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(results => {
                this.variants = results.items;
                // Set selected values
                if (this.modalData.campaign?.variants?.length) {
                    const selectedVariants = this.variants.filter((variant) =>
                        this.modalData.campaign.variants.some((campaignVariant) => campaignVariant._id === variant._id));
                    this.formGroup.patchValue({variants: selectedVariants});
                }
            }, Toaster.handleApiError);

    }

    public saveCampaign(): void {
        const name = this.formGroup.value.name;
        const tags = this.formGroup.value.tags ? this.formGroup.value.tags.map((tag: DropdownItem<string>) => tag.getValue()) : [];
        const startDate = this.dateFormGroup.value.startDate;
        const endDate = this.dateFormGroup.value.endDate;
        const status = this.formGroup.value.status.getValue();

        if (this.modalData.campaign) {
            const campaign = new CampaignPutModel(name, tags, startDate, endDate, status);
            this.campaignService.putCampaign(this.modalData.campaign._id, campaign).subscribe(
                (result) => {
                    this.segmentService.track(ESegmentTrackEvents.TRACK_EDIT_CAMPAIGN);
                    this.fullModalService.close(result);
                    Toaster.success('Campaign updated successfully');
                },
                (error: ARApiError) => Toaster.handleApiError(error));
        } else {
            const variants = this.formGroup.value.variants.map((selectedVariant) => selectedVariant._id);
            const campaign = new CampaignPostModel(name, tags, startDate, endDate, status, variants);
            this.campaignService.postCampaign(campaign).subscribe(
                (result) => {
                    this.segmentService.track(ESegmentTrackEvents.TRACK_ADD_CAMPAIGN);
                    this.fullModalService.close(result);
                    Toaster.success('Campaign created successfully');
                },
                (error) => Toaster.handleApiError(error));
        }
    }
}
