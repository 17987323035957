import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {getLocaleFirstDayOfWeek, getLocaleId} from '@angular/common';
import {NativeDateAdapter} from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    private static readonly DEFAULT_LOCALE = environment.DEFAULT_LOCALE;

    // Get first day of the week for current locale
    // Returns 0 = Sunday, 1 = Monday, etc
    getFirstDayOfWeek(): number {
        try {
            // Throws an error when locale not found
            return getLocaleFirstDayOfWeek(navigator.language || CustomDateAdapter.DEFAULT_LOCALE);
        } catch(error) {
            // Locale not found, return for default language
            return getLocaleFirstDayOfWeek(getLocaleId(CustomDateAdapter.DEFAULT_LOCALE));
        }
    }
}
