/**
 * custom-workflow-action.model
 * app-relayter-web
 * Created by borisnelissen on 12/02/2019.
 * Copyright © 2019 Creative Media Network. All rights reserved.
 */
import {autoserialize} from 'cerialize';

export class CustomWorkflowFilterOptionModel {
    @autoserialize public _id: string;
    @autoserialize public displayName: string;
    @autoserialize public key: string;

    constructor(title: string, value: string) {
        this.displayName = title;
        this.key = value;
    }

    get title(): string {
        return this.displayName;
    }

    get value(): string {
        return this.key;
    }

}
