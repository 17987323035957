/**
 *  country.model
 *  app-relayter-web
 *  Created by nandamhuis on 23/02/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */

/**
 * Country model
 */
export class CountryModel {
    public code: string;
    public title: string;

    constructor(item: Record<string, string>) {
        this.code = item['code'];
        this.title = item['title'];
    }
}
