/**
 * Created by nandamhuis on 06/01/2017.
 */
import {inheritSerialization} from 'cerialize';
import {PublicationItemModel} from './publication-item.model';

@inheritSerialization(PublicationItemModel)
export class PosPublicationItemModel extends PublicationItemModel {
    public getModel(): typeof PublicationItemModel {
        return PosPublicationItemModel;
    }

    /**
     * Interface method
     * @return {string}
     */
    public getNavigationItemSubtitle(): string {
        return this.template?.templateType.name;
    }
}
