import {Component, Inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    DialogCustomContentActionModel,
    NUC_DIALOG_CUSTOM_CONTENT_DATA,
    NucDialogCustomContentService
} from '@relayter/rubber-duck';
import {DropdownItem} from '../../../../../../../../../models/ui/dropdown-item.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {map} from 'rxjs/operators';
import {SpreadNoteModel} from '../../../../../../../../../models/api/spread-note.model';

export interface INoteFormData {
    note?: SpreadNoteModel;
}

const COLORS = {
    RED: '#F70000',
    TURBO: '#F7F300',
    GREEN: '#04F700',
    BLUE: '#0056f7',
    VIOLET: '#DE00F7',
    CINNAMON: '#824700',
    SILVER: '#A5A5A5'
};

@Component({
    selector: 'rl-note-form-component',
    templateUrl: './note-form.component.html',
    styleUrls: ['./note-form.component.scss']
})
export class NoteFormComponent implements OnInit {
    private successButton: DialogButtonConfig;

    public formGroup: UntypedFormGroup;
    public colorOptions = Object.keys(COLORS).map((key) => new DropdownItem(key, COLORS[key]));

    constructor(private dialogCustomContentService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) private dialogData: INoteFormData) {
    }

    public ngOnInit(): void {
        this.buildForm();
        this.initButtons();
        this.trackFormStatus();
    }

    private initButtons(): void {
        this.successButton = new DialogButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, this.formGroup.status !== 'VALID');
        const success = new DialogCustomContentActionModel(this.successButton);

        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new DialogCustomContentActionModel(cancelButton);

        success.observable.subscribe(() => this.successClicked());
        cancel.observable.subscribe(() => this.cancelClicked());
        this.dialogCustomContentService.setDialogActions([cancel, success]);
    }

    private buildForm(): void {
        const note = this.dialogData.note;
        const color = this.colorOptions.find((item) => item.getValue() === note?.color);
        this.formGroup = new UntypedFormGroup({
            color: new UntypedFormControl(color, Validators.required),
            message: new UntypedFormControl(note?.message, Validators.required)
        });
    }

    private trackFormStatus(): void {
        this.formGroup.statusChanges.pipe(
            map((status) => status === 'VALID')
        ).subscribe((valid) => this.successButton.disabled = !valid);
    }

    private successClicked(): void {
        const note = new SpreadNoteModel(undefined, this.formGroup.value.message, this.formGroup.value.color.getValue());
        this.dialogCustomContentService.close(note);
    }

    private cancelClicked(): void {
        this.dialogCustomContentService.close();
    }
}
