import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class DropdownItem<T> implements IDropdownItem<T> {

    constructor(private title: string, private value: T, private _permanent = false, public icon?: string) {
    }

    public getTitle(): string {
        return this.title;
    }

    public getValue(): T {
        return this.value;
    }

    public get permanent(): boolean {
        return this._permanent;
    }
}
