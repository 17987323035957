import {Component, Inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    DialogCustomContentActionModel,
    NUC_DIALOG_CUSTOM_CONTENT_DATA,
    NucDialogCustomContentService
} from '@relayter/rubber-duck';
import {SpreadNoteModel} from '../../../../../../../../../models/api/spread-note.model';
import * as Color from 'color';

export interface INotePreviewData {
    note: SpreadNoteModel;
}

@Component({
    selector: 'note-preview-component',
    templateUrl: './note-preview.component.html',
    styleUrls: ['./note-preview.component.scss']
})
export class NotePreviewComponent implements OnInit {
    public readonly note: SpreadNoteModel;
    public transformedColor: string;

    constructor(private dialogCustomContentService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) private dialogData: INotePreviewData) {
        this.note = this.dialogData.note;
    }

    public ngOnInit(): void {
        this.transformedColor = Color(this.note.color).alpha(0.1).toString();
        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Close');
        const cancel = new DialogCustomContentActionModel(cancelButton);
        cancel.observable.subscribe(() => this.dialogCustomContentService.close());
        this.dialogCustomContentService.setDialogActions([cancel]);
    }
}
