import {autoserialize, autoserializeAs} from 'cerialize';
import {CustomWorkflowStepModel} from './custom-workflow-step.model';
import {
    CUSTOM_WORKFLOW_DESTRUCTIVE_RECIPE_TASK_NAMES,
    CustomWorkflowTransitionModel,
    CustomWorkflowTransitionRecipeTaskModel
} from './custom-workflow-transition.model';
import {CustomWorkflowComponentModel} from './custom-workflow-component.model';
import {CustomWorkflowFilterModel} from './custom-workflow-filter.model';
import {CustomWorkflowActionModel} from './custom-workflow-action.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {LayoutModel} from './layout.model';
import {ChannelModel} from './channel.model';
import {ITableItem} from '@relayter/rubber-duck';
import {CustomWorkflowIdentifierSettingModel} from './custom-workflow-identifier-setting.model';

export class WorkflowConfigurationModel implements IDropdownItem, ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public numberOfComponents: number;
    @autoserialize public numberOfSteps: number;
    @autoserializeAs(ChannelModel) public publicationType: ChannelModel;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
    @autoserializeAs(LayoutModel) public layout: LayoutModel;
    @autoserializeAs(CustomWorkflowComponentModel) public components: CustomWorkflowComponentModel[];
    @autoserializeAs(CustomWorkflowActionModel) public actions: CustomWorkflowActionModel[];
    @autoserializeAs(CustomWorkflowStepModel) public steps: CustomWorkflowStepModel[];
    @autoserializeAs(CustomWorkflowTransitionModel) public transitions: CustomWorkflowTransitionModel[];
    @autoserializeAs(CustomWorkflowFilterModel) public filters: CustomWorkflowFilterModel[];
    @autoserializeAs(CustomWorkflowIdentifierSettingModel) public identifierSetting: CustomWorkflowIdentifierSettingModel;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }

    public getTransitionById(transitionId: string): CustomWorkflowTransitionModel {
        return this.transitions.find(transition => transition._id === transitionId);
    }

    public findFirstDestructiveRecipeTask(transitionId: string): CustomWorkflowTransitionRecipeTaskModel {
        const transition = this.transitions.find(trans => trans._id === transitionId);
        return transition?.recipe.find(recipeTask => !!CUSTOM_WORKFLOW_DESTRUCTIVE_RECIPE_TASK_NAMES[recipeTask.name]);
    }

    public hasDestructiveTransitionRecipeTask(transitionId: string): boolean {
        return !!this.findFirstDestructiveRecipeTask(transitionId);
    }
}
