/**
 * Created by marcelhoogesteger on 30/04/2018.
 */
import {autoserialize} from 'cerialize';
import {ISpreadContentTypeBody} from '../interfaces/spread-content-type-body.interface';

export class SpreadNoteModel implements ISpreadContentTypeBody {
    @autoserialize public _id: string;
    @autoserialize public message: string;
    @autoserialize public color: string;

    /**
     * @constructor
     * @param {string} _id
     * @param {string} message
     * @param {string} color
     */
    constructor(_id: string, message: string, color: string) {
        this._id = _id;
        this.message = message;
        this.color = color;
    }

    /**
     * Interface method to check if this Content Type Body has loadable content (meaning it has images that need to be loaded by PIXI.loader)
     * @returns {boolean}
     */
    public isLoadable(): boolean {
        return false;
    }

    /**
     * Interface / Convenience method to get an array of loadable image url for PIXI.loader
     * @param {string} [category]
     * @returns {string[]}
     */
    /* "@ts-expect-error" */

    // TODO check if this is actually used or a bug
    public getUniqueAssetUrls(): string[] {
        return null;
    }

    public getSpreadEditorTitle(): string {
        return `Note:\n${this.message}`;
    }

}
