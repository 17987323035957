import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TabBarItemModel} from '../../../models/ui/tab-bar-item.model';

@Component({
    selector: 'workflow-tab-bar-view',
    templateUrl: 'workflow-tab-bar-view.component.html',
    styleUrls: ['workflow-tab-bar-view.component.scss']
})
export class WorkflowTabBarViewComponent implements OnChanges {
    @Input() public tabs: TabBarItemModel[] = [];
    private _activeTab: TabBarItemModel;
    @Input()
    public get activeTab(): TabBarItemModel {
        return this._activeTab;
    }
    public set activeTab(val: TabBarItemModel) {
        this._activeTab = val;
        this.activeTabIndex = this.tabs.indexOf(val);
    }
    @Output() public requestTabChange: EventEmitter<TabBarItemModel> = new EventEmitter();

    public activeTabIndex: number = 0;

    public tabWidth = 200;
    public animationEnabled: boolean;

    /**
     * Angular lifecycle method
     * Temporarily disable the indicator slide animation
     * @param {SimpleChanges} changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.tabs) {
            // On tabs changed, temporarily disable the indicator slide animation
            this.animationEnabled = false;
            setTimeout(() => this.animationEnabled = true);
        }
    }

    public onTabClicked(tab: TabBarItemModel): void {
        this.requestTabChange.emit(tab);
    }
}
