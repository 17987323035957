import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, PRIMARY_OUTLET, RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {ISegmentService, SEGMENT_SERVICE} from '../services/segment/segment.service.interface';

@Injectable({providedIn: 'root'})
export class RelayterPageTitleStrategy extends TitleStrategy {

    constructor(@Inject(SEGMENT_SERVICE) protected segmentService: ISegmentService) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        const {pageTitle, data} = this.getTitleAndData(routerState);
        document.title = data.length > 0 ? data.join(' - ') : (pageTitle ?? 'Relayter');
        this.segmentService.page(pageTitle); // title from router config, for segment tracking
    };

    private getTitleAndData(snapshot: RouterStateSnapshot): Record<string, any> {
        // most part of the code is from angular this.buildTitle(snapshot: RouterStateSnapshot): string | undefined;
        let pageTitle: string|undefined;
        let route: ActivatedRouteSnapshot|undefined = snapshot.root;
        let campaignName: string|undefined;
        let publicationName: string|undefined;
        const data = [];

        while (route !== undefined) {
            if (route.data?.campaign) {
                campaignName = route.data?.campaign?.name;
            }
            if (route.data?.publication) {
                publicationName = route.data?.publication?.name;
            }
            pageTitle = this.getResolvedTitleForRoute(route) ?? pageTitle;
            route = route.children.find(child => child.outlet === PRIMARY_OUTLET);
        }

        if (campaignName) data.push(campaignName);
        if (publicationName) data.push(publicationName);

        return {pageTitle, data};
    }

}
