import {RulePropertyModel} from '../models/api/rule-property.model';
import {VariantModel} from '../models/api/variant.model';
export class RulePropertyUtil {
    public static findRulePropertyAndVariantByPath(path: string, variants: VariantModel[], ruleProperties: RulePropertyModel[]): Record<string, any> {
        if (!path) return {ruleProperty: null, variant: null};
        // we store for example 'campaignItems.dataFields.MB-description.f1d0nt0w' as property, this is not inline with properties api call
        // we first check if the last party of path is a variant key, then we get the property name
        const lastPart = path.split('.').splice(-1)[0];
        const variant = variants.find((item) => item.key === lastPart);
        const propertyName = variant ? path.slice(0, path.lastIndexOf('.')) : path;

        const ruleProperty = ruleProperties.find((item) => item.getValue() === propertyName);
        return {ruleProperty, variant};
    }
}
