<div class="upload-box">
    <div class="upload-header">
        <div class="upload-title title body-strong black">{{fileUploadTitle}}</div>
        <i class="nucicon_check upload-check" [ngClass]="{'active': active}"></i>
    </div>
    <div class="file-input">
        <rl-reactive-file-input
            inactive-title="Drag your {{dragTitle}} here"
            [multiple]="multiple"
            (filesChanged)="onFileChanged($event)">
        </rl-reactive-file-input>
    </div>
</div>
