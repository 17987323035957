/**
 *  rl-custom-reqyured.validator
 *  app-relayter-web
 *  Created by nandamhuis on 09/02/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */

import {UntypedFormControl} from '@angular/forms';

/**
 * Checks if the field is filled in, returns required error if not the case
 * @param {FormControl} field
 * @returns {object | null}
 * @constructor
 */
export function CustomRequiredValidator(field: UntypedFormControl): {required: boolean} {
    if (!field.value || (field.value && field.value.toString().trim().length === 0)) {
        return {required: true};
    }
    return null;
}
