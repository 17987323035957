import {ApiRequest} from './api.request';
import {ApiConstants} from '../api.constant';

export class ApiAutocompleteRequest extends ApiRequest {
    protected get urlComponents() {
        return [
            ...super.urlComponents,
            ApiConstants.API_METHOD_AUTOCOMPLETE
        ];
    };

    constructor(public pathComponents: string[] = [],
                public model = null) {
        super(pathComponents, model);
    }
}
