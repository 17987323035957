<div
    class="menu-item"
    tabindex="-1"
    (click)="onClick.emit(menuItem)"
    [class.active-route]="currentRoute && menuItem.route.includes(currentRoute)"
    [class.focused]="menuItem === selectedMenuItem"
    [nucTooltip]="menuItem.title"
    [nucTooltipPosition]="'right'"
    [nucTooltipDisabled]="selectedMenuItem?.title === menuItem.title">
    <div class="unread-notifications-icon" *ngIf="unreadIcon"></div>
    <div class="background"></div>
    <div class="highlight"></div>
    <img class="menu-icon" [src]="menuItem.icon"/>
</div>
