import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ELastViewedItemsContext, LastViewedItemsService} from '../../api/services/last-viewed-items.service';
import {FormGroup, UntypedFormControl} from '@angular/forms';

@Component({
    selector: ' last-viewed-items-checkbox',
    templateUrl: './last-viewed-items-checkbox.component.html',
    styleUrls: ['./last-viewed-items-checkbox.component.scss']
})
export class LastViewedItemsCheckboxComponent implements OnInit, OnDestroy {
    @Input() public context: ELastViewedItemsContext;
    @Input() public text: string;
    public formControl = new UntypedFormControl();
    public formGroup = new FormGroup({applied: this.formControl});

    private onDestroySubject = new Subject<void>();

    constructor(private lastViewedItemsService: LastViewedItemsService) {}

    public ngOnInit(): void {
        this.lastViewedItemsService.getState(this.context)
            .pipe(take(1), takeUntil(this.onDestroySubject))
            .subscribe((result) => this.formControl.patchValue(result?.applied));
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public setApplied(applied: boolean): void {
        this.lastViewedItemsService.setApplied(this.context, applied);
    }
}
