/**
 * Created by nandamhuis on 06/01/2017.
 */
import {autoserialize} from 'cerialize';

/**
 * TeamAccountSignupModel
 */
export class TeamAccountSignupModel {
    @autoserialize public email: string;
}
