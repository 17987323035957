<nuc-master-detail-view *ngIf="permissions.GET_ASSETS | userIsAllowedTo; else noPermissionState"
                        class="assets-container"
                        [open]="selectedAsset && !linkAssetsToProductsActive"
                        [detailWidth]="DEFAULT_SIDEBAR_WIDTH"
                        [closeLocationClass]="'header-buttons'"
                        (closeClicked)="selectedAsset = null">
    <div master class="master">
        <div class="header-row">
            <h1 class="page-title">Asset overview</h1>
            <nuc-button-bar>
                <nuc-button-secondary text="Export assets"
                                      *ngIf="(permissions.EXPORT_ASSETS_JOB | userIsAllowedTo) && !linkAssetsToProductsActive"
                                      (click)="exportAssetsJob()"
                                      i18n>
                </nuc-button-secondary>
                <nuc-button-secondary text="Link to products"
                                      (click)="linkAssetsToProductsActive = true"
                                      *ngIf="(permissions.LINK_ASSETS_TO_PRODUCTS | userIsAllowedTo) && !linkAssetsToProductsActive"
                                      i18n>
                </nuc-button-secondary>
                <nuc-button-primary text="Add assets"
                                    (click)="onAddAssetsClicked()"
                                    *ngIf="(permissions.POST_ASSETS | userIsAllowedTo) && !linkAssetsToProductsActive" i18n>
                </nuc-button-primary>
                <nuc-button-secondary text="Cancel"
                                      (click)="deactivateLinkingAssetsToProducts()"
                                      *ngIf="(permissions.LINK_ASSETS_TO_PRODUCTS | userIsAllowedTo) && linkAssetsToProductsActive"
                                      i18n>
                </nuc-button-secondary>

                <nuc-button-primary text="{{selectedAssets.length}} asset(s) selected"
                                    (click)="onLinkAssetToProductClicked()"
                                    *ngIf="(permissions.LINK_ASSETS_TO_PRODUCTS | userIsAllowedTo) && linkAssetsToProductsActive"
                                    [disabled]="selectedAssets.length === 0" i18n>
                </nuc-button-primary>
            </nuc-button-bar>
        </div>
        <rl-collection-view [items]="data"
                            [propertySettingsContext]="EPropertySettingsContext.ASSET"
                            (collectionViewOptionsChanged)="onCollectionViewOptionsChanged()"
                            [selectedItem]="getSelectedAsset()"
                            [selectedItems]="selectedAssets"
                            (collectionViewItemClicked)="onCollectionViewItemClicked($event)"
                            (collectionViewItemDoubleClicked)="onCollectionViewItemDoubleClicked($event)"
                            [(sort)]="sort"
                            [(sortDescending)]="sortDescending"
                            [sortingOptions]="sortingOptions"
                            [disableSort]="!!searchValue"
                            [isLoading]="!assetsSubscription?.closed"
                            [isSearching]="isSearching"
                            [selectMultipleItems]="linkAssetsToProductsActive"
                            [searchPlaceholder]="'Search assets'"
                            [searchValue]="searchValue"
                            (onSearchValueUpdated)="onSearch($event)"
                            [advancedFilter]="true"
                            [disableNextPage]="disableNextPage"
                            [disablePageOptions]="!!searchValue"
                            [viewId]="tableId">
        </rl-collection-view>
    </div>

    <div detail *ngIf="selectedAsset" class="detail-sidebar">
        <rl-asset-detail-page-component [assetId]="selectedAsset._id"
                                        (onAssetDeleted)="onAssetDeleted()"
                                        (onAssetEdited)="setPageIndex()">
        </rl-asset-detail-page-component>
        <nuc-button-bar class="asset-actions">
            <nuc-button-secondary *ngIf="permissions.GET_ASSET_DETAILS | userIsAllowedTo"
                                  text="View more on detail page"
                                  [routerLink]="['/assets/' + selectedAsset._id]" i18n></nuc-button-secondary>
        </nuc-button-bar>
    </div>
</nuc-master-detail-view>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
