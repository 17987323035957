<div class="app-container">
    <div class="rl-relayter-main">
        <rl-header></rl-header>
        <router-outlet></router-outlet>
    </div>

    <!--rl-menu is after .rl-relayter-main to have higher z-index -->
    <rl-menu [sections]="menuDataService.menuSections$ | async"></rl-menu>

    <div class="pre-load" [ngClass]="{'hidden': hidden}"></div>

    <div class="rl-app-loading-indicator" [ngClass]="{'hidden': hidden}">
        <i class="nucicon_gear nuc-spin"></i>
        <span class="sr-only">Loading...</span>
    </div>

    <div class="notifications">
        <rl-job-notification *ngFor="let jobItem of jobItems" [jobInput]="jobItem"></rl-job-notification>
        <transition-notification *ngFor="let transitionItem of transitionItems" [transitionInput]="transitionItem"></transition-notification>
    </div>

</div>
