import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {KeyViewerComponent} from '../key-viewer/key-viewer.component';

@Component({
    selector: 'api-key-viewer',
    templateUrl: './api-key-viewer.component.html',
    styleUrls: ['./api-key-viewer.component.scss']
})
export class ApiKeyViewerComponent extends KeyViewerComponent {
    @Input() public apiKey: string;
    @Input() public hasRefreshToken: boolean;

    @Output() public requestApiKey = new EventEmitter<void>();
    @Output() public refreshApiKey = new EventEmitter<void>();

    public viewApiKey: boolean = false;

    constructor(clipboard: Clipboard) {
        super(clipboard);
    }

    public toggleVisibility(): void {
        this.viewApiKey = !this.viewApiKey;
    }
}
