<overlay-button icon="nucicon_filter"
                [showIndicator]="activeFilterCount > 0"
                [nucTooltip]="'Filter'"
                [content]="advancedFilters">
</overlay-button>

<ng-template #advancedFilters>
    <div class="content">
        <div class="filters-header">
            <div class="title">
                <h2>Filter</h2>
                <nuc-hint>{{activeFilterCount}} active</nuc-hint>
            </div>
            <div class="buttons">
                <nuc-button-secondary icon="nucicon_reset" [nucTooltip]="'Reset'" (click)="onReset()"></nuc-button-secondary>
                <nuc-button-primary [disabled]="filtersForm?.pristine || !filtersForm?.valid" text="Apply" (click)="onApply()"></nuc-button-primary>
            </div>
        </div>
        <form *ngIf="filtersForm" [formGroup]="filtersForm">
            <div *ngFor="let dataFilter of filters" class="data-field-filter-input">
                <p class="body-strong">{{dataFilter.displayName}}</p>
                <div class="input-field"
                     [class.flex-input-field]="dataFilter.modelName === EDataFilterModels.DATA_FILTER && dataFilter.type === FILTER_DATA_TYPES.LIST">
                    <nuc-input *ngIf="dataFilter.modelName === EDataFilterModels.DATA_FILTER && dataFilter.type === FILTER_DATA_TYPES.STRING"
                               pattern="^[a-zA-Z0-9].*"
                               type="text"
                               [prefix]="'nucicon_search'"
                               [formControlName]="dataFilter.property"
                               [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)">
                    </nuc-input>

                    <nuc-input *ngIf="dataFilter.type === FILTER_DATA_TYPES.NUMBER"
                               type="number"
                               [prefix]="'nucicon_search'"
                               [formControlName]="dataFilter.property"
                               [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)">
                    </nuc-input>

                    <ng-container *ngIf="dataFilter.type === FILTER_DATA_TYPES.ENUM">
                        <nuc-dropdown *ngIf="!dataFilter.enumeration?.multiSelect"
                                      [formControlName]="dataFilter.property"
                                      [items]="dataFilter.items"
                                      [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)">
                        </nuc-dropdown>
                        <nuc-dropdown-multiselect *ngIf="dataFilter.enumeration?.multiSelect"
                                                  [formControlName]="dataFilter.property"
                                                  [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)"
                                                  [items]="dataFilter.items">
                        </nuc-dropdown-multiselect>
                    </ng-container>

                    <nuc-dropdown *ngIf="dataFilter.type === FILTER_DATA_TYPES.BOOLEAN"
                                  [formControlName]="dataFilter.property"
                                  [items]="booleanDropdownItems"
                                  [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)">
                    </nuc-dropdown>

                    <nuc-datepicker *ngIf="dataFilter.type === FILTER_DATA_TYPES.DATE"
                                    [formControlName]="dataFilter.property"
                                    [placeholder]="'Filter by ' + (dataFilter.displayName | lowercase)">
                    </nuc-datepicker>


                    <ng-container *ngIf="dataFilter.modelName === EDataFilterModels.DATA_FILTER">
                        <rl-data-filter-suggestive
                            *ngIf="dataFilter.type === FILTER_DATA_TYPES.LIST"
                            [dataFilter]="dataFilter"
                            [formControlName]="dataFilter.property">
                        </rl-data-filter-suggestive>
                    </ng-container>

                    <ng-container *ngIf="dataFilter.modelName === EDataFilterModels.DATA_FIELD_FILTER">
                        <nuc-suggestive-string-input *ngIf="dataFilter.enableAutocomplete && dataFilter.type !== FILTER_DATA_TYPES.LIST"                                                     [formControlName]="dataFilter.property"
                                                     [placeholder]="'Fill in the ' + (dataFilter.displayName | lowercase)" [enableNewValue]="false"
                                                     (searchTermChange)="search($event, dataFilter)" [options]="dataFieldData[dataFilter.dataFieldId] || []" >
                        </nuc-suggestive-string-input>


                        <nuc-input  *ngIf="!dataFilter.enableAutocomplete && dataFilter.type !== FILTER_DATA_TYPES.LIST"
                                    [formControlName]="dataFilter.property"
                                    [type]="dataFilter.type"
                                    [placeholder]="'Fill in the ' + (dataFilter.displayName | lowercase)">
                        </nuc-input>


                        <nuc-suggestive-input *ngIf="dataFilter.type === FILTER_DATA_TYPES.LIST"
                                              [formControlName]="dataFilter.property"
                                              [placeholder]="'Fill in the ' + (dataFilter.displayName | lowercase)" [enableNewValue]="false"
                                              (searchTermChange)="search($event, dataFilter)" [options]="dataFieldData[dataFilter.dataFieldId] || []" >
                        </nuc-suggestive-input>
                    </ng-container>
                </div>
            </div>
        </form>
    </div>
</ng-template>
