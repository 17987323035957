import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {StickyNoteModel} from '../../../../../../../models/api/sticky-note.model';
import {CampaignItemModel} from '../../../../../../../models/api/campaign-item.model';

@Injectable()
export class StickyNotesDataService implements OnDestroy {
    private selectedStickyNote = new ReplaySubject<StickyNoteModel>(1);
    public selectedStickyNote$ = this.selectedStickyNote.asObservable();

    private refreshStickyNotes = new Subject<void>();
    public refreshStickyNotes$ = this.refreshStickyNotes.asObservable();

    private updatedLinkedCampaignItem = new Subject<CampaignItemModel>();
    public updatedLinkedCampaignItem$ = this.updatedLinkedCampaignItem.asObservable();

    private subjects = [
        this.selectedStickyNote,
        this.refreshStickyNotes,
        this.updatedLinkedCampaignItem
    ];

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    public setSelectedStickyNote(stickyNote: StickyNoteModel): void {
        this.selectedStickyNote.next(stickyNote);
    }

    public announceRefreshStickyNotes(): void {
        this.refreshStickyNotes.next();
    }

    public announceUpdatedLinkedCampaignItem(campaignItem: CampaignItemModel): void {
        this.updatedLinkedCampaignItem.next(campaignItem);
    }
}
