// TODO: Remove actions from subMenutItem. This is a quick fix and will be replaced by a new user badge component compatible with the new menu
export class SubMenuItem {
    public route: string = '';
    public title: string = '';
    public action: string;

    constructor(route: string, title: string, action?: string) {
        this.route = route;
        this.title = title;
        this.action = action;
    }
}
