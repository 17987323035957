import {autoserializeAs} from 'cerialize';
import {FormatRulesetModel} from '../../modules/format-rulesets/models/api/format-ruleset.model';
import {WorkflowConfigurationModel} from './workflow-configuration.model';
import {PackageSetupModel} from './package-setup.model';
import {WorkflowAutomationModel} from './workflow-automation.model';

export class DataFieldUsageModel {
    @autoserializeAs(FormatRulesetModel) public ruleSets: FormatRulesetModel[];
    @autoserializeAs(WorkflowConfigurationModel) public workflows: WorkflowConfigurationModel[];
    @autoserializeAs(PackageSetupModel) public packageSetups: PackageSetupModel[];
    @autoserializeAs(WorkflowAutomationModel) public workflowAutomations: WorkflowAutomationModel[];
}
