import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

// Provided in the user-management component
@Injectable()
export class UserManagementDataService {
    private refreshInvitesSubject = new Subject<void>();
    public refreshInvites$ = this.refreshInvitesSubject.asObservable();

    public announceInvitesRefresh(): void {
        this.refreshInvitesSubject.next();
    }
}
