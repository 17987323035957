import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'firstSentence'
})
export class FirstSentencePipe implements PipeTransform {

    public transform(message: string): string {
        const sentences = message.split('.');
        return sentences[0] ? `${sentences[0]}.` : null;
    }

}
