import {Pipe, PipeTransform} from '@angular/core';
import {PublicationItemModel} from '../models/api/publication-item.model';
import {CampaignModel} from '../models/api/campaign.model';
import {PublicationModel} from '../models/api/publication.model';

@Pipe({name: 'workflowUrl'})
export class WorkflowUrlPipe implements PipeTransform {
    private readonly URL_ACTIONS = {WORKFLOW_PUBLICATION_ITEM: 'workflow-publication-item'};

    public transform(publicationItem: PublicationItemModel, campaign: CampaignModel, publication: PublicationModel): string {
        const firstComponent = publicationItem.step?.components[0];
        const baseUrl = `/campaigns/${campaign._id}/publications/${publication._id}`;
        return firstComponent?.componentType === 'PREVIEW'
            ? `${baseUrl}?action=${this.URL_ACTIONS.WORKFLOW_PUBLICATION_ITEM}&data=${publicationItem._id}`
            : `${baseUrl};step=${publicationItem.step?._id};component=${firstComponent?._id}`;
    }
}
