import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {UserService} from '../../api/services/users.service';
import {ARApiError} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService} from '@relayter/rubber-duck';
import {ChangePassword} from '../../models/api/change-password.model';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Component({
    selector: 'rl-change-password-form',
    templateUrl: 'change-password-form.component.html',
    styleUrls: ['change-password-form.component.scss']
})

export class ChangePasswordFormComponent implements OnInit {
    public form: UntypedFormGroup;
    public validationMessages: any;

    private saveButton: ButtonConfig;

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private fullModalService: FullModalService) {
    }

    public ngOnInit(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save');
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.onSubmitValid());
        this.fullModalService.setModalActions([cancelAction, saveAction]);

        this.validationMessages = {
            repeatablePassword: RLValidatorConstants.MESSAGE_SETS.REPEATABLE_PASSWORD,
            repeatPassword: RLValidatorConstants.MESSAGE_SETS.REPEAT_PASSWORD,
        };

        this.form = this.fb.group({
            oldPassword: [''],
            repeatablePassword: ['', RLValidatorConstants.VALIDATOR_SETS.REPEATABLE_PASSWORD],
            repeatPassword: ['', RLValidatorConstants.VALIDATOR_SETS.REPEAT_PASSWORD]
        });

        this.form.statusChanges.pipe(
            map((status) => status === 'VALID'),
            distinctUntilChanged()
        ).subscribe((valid) => this.saveButton.disabled = !valid);
    }

    public onSubmitValid(): void {
        this.saveButton.loading = true;
        const changePasswordBody = new ChangePassword(this.form.value.repeatablePassword, this.form.value.oldPassword);

        this.userService.changePasswordMe(changePasswordBody).subscribe(
            (success: boolean) => {
                this.saveButton.loading = false;
                if (success) {
                    Toaster.success('Password changed');
                    this.fullModalService.close();
                } else {
                    this.userService.logoutAndRedirectToLogin();
                }
            },
            (err: ARApiError) => {
                this.saveButton.loading = false;
                Toaster.handleApiError(err);
                this.fullModalService.close();
            }
        );
    }
}
