import {inheritSerialization} from 'cerialize';
import {PublicationItemModel} from './publication-item.model';

@inheritSerialization(PublicationItemModel)
export class WebPublicationItemModel extends PublicationItemModel {
    public getModel(): typeof PublicationItemModel {
        return WebPublicationItemModel;
    }

    /**
     * @return {string}
     */
    public getNavigationItemSubtitle(): string {
        return this.template?.name;
    }
}
