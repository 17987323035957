import {animate, AnimationTriggerMetadata, keyframes, style, transition, trigger} from '@angular/animations';

const timingFunction = '150ms ease-in-out';

const unlockBadge = [
    transition('void => *', [
        animate(timingFunction, keyframes([
            style({opacity: 0, bottom: '15px'}),
            style({opacity: 1, bottom: 0}),
        ]))
    ]),
    transition('* => void', [
        animate(timingFunction, keyframes([
            style({opacity: 1, bottom: 0}),
            style({opacity: 0, bottom: '15px'}),
        ])),
    ]),
];


export const unlockedBadgeAnimation: {
    readonly unlockBadge: AnimationTriggerMetadata;
} = {
    unlockBadge: trigger('unlockedBadgeAnimation', unlockBadge)
};
