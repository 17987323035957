<div class="menu-bar" #origin>
    <div class="menu-items top">
        <div class="relayter-logo">
            <img src="/assets/images/Relayter_logo_mark_green.svg" alt="Relayter"/>
        </div>
        <ng-container *ngFor="let menuItem of topMenuItems">
            <ng-container *ngTemplateOutlet="menuItemTemplate; context:{item: menuItem}"></ng-container>
        </ng-container>
    </div>

    <div class="menu-items bottom">
        <div id="intercom_menu_icon"
            class="menu-item"
            tabindex="-1"
            [nucTooltipPosition]="'right'"
             #supportButton
            (click)="supportButton.classList.toggle('focused')"
            nucTooltip="Support" >
            <div class="background"></div>
            <div class="highlight"></div>
            <img class="menu-icon" src="/assets/images/menu/icon_sidemenu_intercom_active.svg"/>
        </div>
        <ng-container *ngFor="let menuItem of bottomMenuItems">
            <ng-container *ngTemplateOutlet="menuItemTemplate; context:{item: menuItem}"></ng-container>
        </ng-container>
    </div>
</div>

<ng-template let-menuItem="item" #menuItemTemplate>
    <ng-container [ngSwitch]="menuItem.component">
        <rl-menu-icon *ngSwitchDefault
                      [menuItem]="menuItem"
                      [selectedMenuItem]="selectedMenuItem"
                      [currentRoute]="rootRoute"
                      (onClick)="handleItemClick(menuItem)">
        </rl-menu-icon>
        <ng-container *ngIf="notificationDataService.notifications$ | async as notifications">
            <rl-notifications-menu-icon *ngSwitchCase="MENU_COMPONENTS.NOTIFICATIONS"
                                        [unreadIcon]="(notifications | unreadNotifications) > 0"
                                        [menuItem]="menuItem"
                                        [selectedMenuItem]="selectedMenuItem"
                                        [currentRoute]="rootRoute"
                                        (onClick)="handleItemClick(menuItem)">
            </rl-notifications-menu-icon>
        </ng-container>
    </ng-container>
</ng-template>

<!-- Standard sub menu component-->
<ng-template #subMenu>
    <rl-sub-menu-container #activeItem (animationStateChanged)="onAnimationStateChange($event)">
        <rl-sub-menu [menuItem]="selectedMenuItem" (closeClicked)="closeAndUnsetSelectedItem()"></rl-sub-menu>
    </rl-sub-menu-container>
</ng-template>

<!-- Notifications component -->
<ng-template #notificationsSubMenu>
    <rl-sub-menu-container #activeItem (animationStateChanged)="onAnimationStateChange($event)">
        <rl-notifications-sub-menu (closeClicked)="closeAndUnsetSelectedItem()"></rl-notifications-sub-menu>
    </rl-sub-menu-container>
</ng-template>
